import { Card, Col, Row, Statistic, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../api/calls";
import PartnerLeadAddForm from "../components/PartnerLeads/PartnerLeadAddForm";

const { Title } = Typography;

const gutter = [16, 24];

const PartnerLeadsIndex = () => {
  const { t } = useTranslation();

  const [partnerLeadList, setPartnerLeadList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    coreApi
      .get("/partners/leads")
      .then((response) => {
        setPartnerLeadList(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const addPartnerLead = (values) => {
    coreApi
      .post("/partners/leads", values)
      .then((response) => {
        let payload = {
          partner_id: response.data.partnerlead.partner_id,
          user_id: user.id,
          from_state: 1,
          to_state: 1,
        };

        coreApi
          .post("/partners/leads/state", payload)
          .then((res) => {
            setPartnerLeadList([...partnerLeadList, response.data.partnerlead]);
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally();
  };

  const columns = [
    {
      title: t("common.fullName"),
      key: "name",
      render: (lead) => <Link to={"/partner-leads/" + lead.partner_id}>{lead.first_name + " " + lead.last_name}</Link>,
    },
    {
      title: t("common.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("common.phone"),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: t("partnerLeads.state"),
      dataIndex: "last_state",
      key: "last_state.created_at",
      render: (state) => {
        return (
          <Tag
            color={
              state?.translation_key === "canceled"
                ? "red"
                : state?.translation_key === "done"
                ? "green"
                : state?.translation_key === "doesntWantToCooperate"
                ? "red"
                : "blue"
            }
          >
            {t("partnerLeads.stateList." + state?.translation_key)}
          </Tag>
        );
      },
    },
    {
      title: t("employees.employee"),
      dataIndex: "employee",
      key: "employee.id",
      render: (row) => {
        return <Tag>{row?.first_name + " " + row?.last_name}</Tag>;
      },
    },
    {
      title: t("expansion.city"),
      dataIndex: "city_branches",
      render: (row) => {
        return row?.map((item) => {
          return <Tag key={item.city_branch_id}>{item?.name}</Tag>;
        });
      },
    },
  ];

  return (
    <React.Fragment>
      <Title>{t("partnerLeads.partnerLeadsOverviewHeader")}</Title>
      <Row gutter={gutter}>
        <Col span={6}>
          <Card>
            <Statistic title={t("partnerLeads.newLeads")} value={47}></Statistic>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic title={t("partnerLeads.allLeads")} value={172}></Statistic>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic title={t("partnerLeads.leadsWeekly")} value={31}></Statistic>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic title={t("partnerLeads.newDocuments")} value={14}></Statistic>
          </Card>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col span={24}>
          <Table loading={isLoading} columns={columns} dataSource={partnerLeadList} rowKey={"id"}></Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <PartnerLeadAddForm onFinishAction={addPartnerLead}></PartnerLeadAddForm>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PartnerLeadsIndex;
