import { Modal, Form, notification, Row, Col, Divider } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import BuildingUnitSelect from "../Buildings/BuildingUnitSelect";
import CleaningScheduleCell from "../Cleanings/CleaningScheduleCell";
import ClaimCleaningsCalendar from "./ClaimCleaningsCalendar";

const ClaimAddForm = ({ isOpen, close, refreshParent }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [buildingUnitId, setBuildingUnitId] = useState(null);

  const [recurringCleanings, setRecurringCleanings] = useState([]);
  const [oneTimeCleanings, setOneTimeCleanings] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedCleanings, setSelectedCleanings] = useState([]);

  const handleCalendarChange = useCallback(
    async (date) => {
      if (!buildingUnitId) return;

      let dateFrom = date.clone().startOf("month");
      let dateTo = date.clone().endOf("month");

      setLoading(true);

      axios
        .all([
          coreApi.get("/cleanings/recurring", {
            params: {
              building_unit_id: buildingUnitId,
              date_from: dateFrom.format("YYYY-MM-DD"),
              date_to: dateTo.format("YYYY-MM-DD"),
            },
          }),
          coreApi.get("/cleanings/one-time", {
            params: {
              building_unit_id: buildingUnitId,
              date_from: dateFrom.format("YYYY-MM-DD"),
              date_to: dateTo.format("YYYY-MM-DD"),
            },
          }),
          coreApi.get("/buildings/units/rules/" + buildingUnitId),
        ])
        .then(
          axios.spread((recurringCleaningsResponse, oneTimeCleaningsResponse, rulesResponse) => {
            setRecurringCleanings(() => [...recurringCleaningsResponse.data]);
            setOneTimeCleanings(() => [...oneTimeCleaningsResponse.data]);
            setSchedules(() => [...rulesResponse.data]);
          })
        )
        .catch((error) => {
          notification.error({ message: error?.response?.data?.message });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [buildingUnitId]
  );

  const getDayFilteredCleanings = (value) => {
    let cleaningsMerged = [...recurringCleanings, ...oneTimeCleanings];

    return cleaningsMerged.filter((o) => o?.date_expected === value?.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    if (buildingUnitId) {
      setSelectedCleanings(() => getDayFilteredCleanings(selectedDate));
      handleCalendarChange(selectedDate);
    }
  }, [selectedDate, buildingUnitId]);

  const dateCellRender = (value) => {
    return <CleaningScheduleCell cleanings={getDayFilteredCleanings(value)} />;
  };

  const handleFinish = async (values) => {
    try {
      const response = await coreApi.post("/claims", values);
      notification.success({ message: t("claims.successfullyAdded") });
      refreshParent();
      close();
    } catch (error) {
      notification.error({ message: error?.response?.data?.message });
    }
  };

  return (
    <Modal
      title={t("claims.addClaim")}
      open={isOpen}
      onCancel={close}
      onOk={() => form.submit()}
      width={900}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
      >
        <BuildingUnitSelect
          label={t("buildingSetupChanges.buildingUnit")}
          name="building_unit"
          required
          validationMessage={t("buildingSetupChanges.validation.requiredBuildingUnit")}
          onChange={setBuildingUnitId}
        />
      </Form>
      {buildingUnitId && (
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Divider orientation={"left"}>{t("schedules.currentScheduleRules")}</Divider>
            <ClaimCleaningsCalendar
              loading={loading}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedCleanings={selectedCleanings}
              setSelectedCleanings={setSelectedCleanings}
              dateCellRender={dateCellRender}
              handleCalendarChange={handleCalendarChange}
              buildingUnitId={buildingUnitId}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default ClaimAddForm;
