import { CheckOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Modal, notification, Row } from "antd";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import { OfferContext } from "../../pages/OfferProfile";

const ConfirmOfferModal = ({ idOffer, isOfferConfirmed, isOrderConfirmed, isDisabled, priceOffer }) => {
  const { t } = useTranslation();

  const { refreshOffer } = useContext(OfferContext);

  const [isModalOpen, setModalOpen] = useState(false);
  const [dateSignedForm] = Form.useForm();

  return (
    <React.Fragment>
      {!isOrderConfirmed && (
        <React.Fragment>
            <Button
              disabled={isDisabled}
              type="primary"
              icon={<CheckOutlined />}
              onClick={(e) => {
                setModalOpen(true);
              }}
            >
              {t("offers.confirmOffer")}
            </Button>
        </React.Fragment>
      )}
      <Modal
        open={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={() => {
          dateSignedForm.submit();
          setModalOpen(false);
        }}
      >
        <Form
          name={"dateSignedForm"}
          form={dateSignedForm}
          onFinish={(values) => {
            if (parseFloat(priceOffer) === 0) {
              notification.error({message: 'Cena v objednávce je 0 Kč/€.'});
              return;
            }

            let payload = {};
            if (!isOfferConfirmed) {
              payload = {
                date_signed: values.date_signed?.toISOString(),
                date_active_to: values.date_active_to?.toISOString(),
                date_active_from: values.date_active_from?.toISOString(),
                offer_id: idOffer,
                offer_state_id: 4,
              };

              coreApi
                .post("offers/convert-to-order", payload)
                .then((res) => {
                  notification.success({ message: res.data.message });
                  setTimeout(function () {
                    window.location.href = "/orders/" + idOffer;
                  }, 1000);
                })
                .catch((err) => notification.error(err.response.message));
            }
          }}
        >
          <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
            {!isOfferConfirmed ? (
              <Col span={24}>
                <Row gutter={[8, 0]}>
                  <Col span={12}>
                    <Form.Item
                      name={"date_active_from"}
                      label={t("offers.dateActiveFrom")}
                      initialValue={moment()}
                      rules={[
                        {
                          required: true,
                          message: "Vyber datum!",
                        },
                      ]}
                    >
                      <DatePicker style={{ width: "100%" }}></DatePicker>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name={"date_active_to"} label={t("offers.dateActiveTo")}>
                      <DatePicker style={{ width: "100%" }}></DatePicker>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 0]}>
                  <Col span={24}>
                    <FormItem
                      name={"date_signed"}
                      label={t("offers.dateSigned")}
                      initialValue={moment()}
                      rules={[
                        {
                          required: true,
                          message: t("offers.validate.dateSigned"),
                        },
                      ]}
                    >
                      <DatePicker></DatePicker>
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col span={24}>
                <FormItem
                  name={"date_confirmed"}
                  label={t("offers.dateSigned")}
                  initialValue={moment()}
                  rules={[
                    {
                      required: true,
                      message: t("offers.validate.dateSigned"),
                    },
                  ]}
                >
                  <DatePicker></DatePicker>
                </FormItem>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ConfirmOfferModal;
