import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

/**
 * @component
 * @alias EmployeeLink
 * @property {object} employee
 * @returns <Link /> with employee's name and href to their profile
 */
const EmployeeLink = ({ employee }) => {
  const fullName = `${employee.first_name} ${employee.last_name}`;

  return (
    <Tooltip title={fullName}>
      <Link to={"/employees/" + employee.id} style={{ borderBottom: "1px dotted" }}>
        {fullName}
      </Link>
    </Tooltip>
  );
};

export default EmployeeLink;
