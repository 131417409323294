import { CopyOutlined } from "@ant-design/icons";
import {Button, Card, Col, Form, Input, message, Radio, Row, Select, Space, Table, Tag, Tooltip} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAreaScope } from "../actions";
import { coreApi } from "../api/calls";
import BuildingSelect from "../components/Buildings/BuildingSelect";
import PartnerSelect from "../components/Partners/PartnerSelect";

const ContactsIndex = (props) => {
  const { t } = useTranslation();

  const [filterForm] = Form.useForm();

  const [contacts, setContacts] = useState([]);
  const [sorter, setSorter] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [selectedCells, setSelectedCells] = useState([]);
  const [filters, setFilters] = useState({});

  const areaScope = useSelector(getAreaScope);
  const selectedCity = areaScope.areaScope;
  const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

  const prepareDataForClipboard = () => {
    let outString = "";
    selectedCells.forEach((item) => {
      if (item.email && item.email !== '---') outString += item.email + "\n";
    });
    return outString;
  };

  const settingData = (data, rest) => {
    setContacts(null);

    const updatedContacts = [];
    data.map(item => {
      if (item.buildings && item.buildings.length > 1) {
        item.buildings.slice(1).forEach((address, index) => {
          const duplicate = { ...item, buildings: [address] };
          updatedContacts.push(duplicate);
        });
        item.buildings = [item.buildings[0]];
      }
      return item;
    });

    updatedContacts.map((item, index) => {
      item.id = `${item.id}-${index}`
      return item;
    });

    setContacts([...data]);
    setPagination(rest);
  };

  const allDataForClipboard = () => {
    let outString = "";
    contacts.forEach((item) => {
      if (item.email && item.email !== '---') outString += item.email + "\n";
    });
    return outString;
  };

  const columns = [
    {
      title: t("buildings.registrationNumber"),
      width: "10%",
      sorter: true,
      key: "buildings.registration_number",
      render: (rowData) => {
        const firstBuilding = rowData?.buildings && rowData.buildings[0];
        return (
          <div style={{ display: "flex" }}>
            {firstBuilding && (
              <Tooltip title={firstBuilding?.registration_number} key={firstBuilding?.id}>
                <Link to={`/buildings/${firstBuilding?.id}`} style={{ borderBottom: "1px dotted" }}>
                  {firstBuilding?.registration_number}
                </Link>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: t("contacts.filters.building"),
      width: "20%",
      sorter: true,
      key: "building_units.street",
      render: (rowData) => {
        const firstBuilding = rowData?.buildings && rowData.buildings[0];
        return (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            {firstBuilding && (
              <Tooltip title={firstBuilding?.reference} key={firstBuilding?.id}>
                <Link to={`/buildings/${firstBuilding?.id}`} style={{ borderBottom: "1px dotted" }}>
                  {firstBuilding?.reference}
                </Link>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: t("contacts.name"),
      sorter: false,
      width: "20%",
      key: "last_name",
      render: (rowData) => {
        return (
          <Link to={"/contacts/" + rowData?.id} style={{ fontWeight: 500 }}>
            {!rowData?.first_name && !rowData?.last_name
              ? t("common.empty")
              : (rowData?.first_name || "") + " " + (rowData?.last_name || "")}
          </Link>
        );
      },
    },
    {
      title: t("contacts.phone"),
      sorter: false,
      key: "phone",
      width: "15%",
      render: (rowData) => {
        return (
          <a href={`tel:${rowData.phone}`} style={{ borderBottom: "1px dotted" }}>
            {rowData?.phone}
          </a>
        );
      },
    },
    {
      title: (
        <Space>
          <Tooltip title={t("common.howToCopy")}>{t("contacts.email")}</Tooltip>
            <Tooltip title={t("common.copy")}>
              <Button
                style={{ border: "0px", background: "none" }}
                icon={<CopyOutlined />}
                onClick={() => {
                  if (selectedCells.length > 0) {
                    try {
                      navigator.clipboard.writeText(prepareDataForClipboard());
                      message.success(t("common.copySuccess"), 1);
                    } catch (err) {
                      message.error(t("common.copyError"));
                    }
                    setTimeout(() => setSelectedCells(() => []), 250);
                  } else {
                    try {
                      navigator.clipboard.writeText(allDataForClipboard());
                      message.success(t("common.copySuccess"), 1);
                    } catch (err) {
                      message.error(t("common.copyError"));
                    }
                  }
                }}
              ></Button>
            </Tooltip>
        </Space>
      ),
      sorter: false,
      key: "email",
      width: "20%",
      render: (rowData) => {
        return (
          <span
            style={
              selectedCells.includes(rowData)
                ? {
                    height: "100%",
                    padding: "8px",
                    background: "rgba(105, 192, 255, 0.6)",
                    borderRadius: "5px",
                  }
                : { height: "100%", padding: "8px" }
            }
          >
            {rowData.email}
          </span>
        );
      },
      onCell: (record, _) => {
        return {
          onClick: (event) => {
            if (event.shiftKey) {
              if (selectedCells.includes(record)) {
                setSelectedCells(() => [...selectedCells.filter((item) => item !== record)]);
              } else {
                setSelectedCells(() => [...selectedCells, record]);
              }
            }
          },
        };
      },
    },
    {
      title: t("schedules.cleaningDays"),
      sorter: true,
      key: "service_rrules.rrule",
      width: "20%",
      render: (rowData) => {
        return (
          <div style={{ display: "flex" }}>
            {rowData?.days && rowData.days.length > 0 ? (
              rowData.days
                .map((item) => {

                  if (item.includes(',')) {
                    return item
                      .split(',')
                      .map((day) => t(`rrules.byweekday.${day}`))
                      .join(', ');
                  } else {
                    return t(`rrules.byweekday.${item}`);
                  }
                })
                .join(", ")
            ) : (
              rowData?.buildings?.length > 0 && (() => {
                const uniqueCleaningDays = new Set();

                rowData.buildings.forEach((building) => {
                  building.building_units?.forEach((unit) => {
                    unit.cleaning_days?.forEach((day) => uniqueCleaningDays.add(day));
                  });
                });

                return Array.from(uniqueCleaningDays)
                  .map((item) => {

                    if (item.includes(',')) {
                      return item
                        .split(',')
                        .map((day) => t(`rrules.byweekday.${day}`))
                        .join(', ');
                    } else {
                      return t(`rrules.byweekday.${item}`);
                    }
                  })
                  .join(", ");
              })()
            )}
          </div>
        );
      },
    },
    {
      title: t("contacts.contactType"),
      width: "30%",
      render: (contact) => {
        const sortOrder = ['isOperation', 'isInvoice', 'isResponsible', 'isHappiness'];
        const sortedTags = contact?.tags
          ?.filter((a, i) => contact?.tags.findIndex((s) => a.id === s.id) === i)
          ?.sort((a, b) => {
            return sortOrder.indexOf(a.key) - sortOrder.indexOf(b.key);
          });
        return (
          <Row gutter={[8, 0]}>
            {sortedTags?.map((tag) => {
              return (
                <Col span={5} key={tag?.id}>
                  <Tag
                    color={t(`contacts.tagColors.${tag?.key}`)}
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    {tag?.name}
                  </Tag>
                </Col>
              );
            })}
          </Row>
        );
      },
    },
  ];

  /**
   * Fetch data from API
   */
  const fetchData = useCallback(() => {
    setLoading(true);

    coreApi
      .get("/contacts", {
        params: { ...filters, city_branch_id: selectedCity, sorterOrder: sorter?.order, sorterKey: sorter?.columnKey },
      })
      .then((res) => {
        let { data, ...rest } = res.data;
        settingData(data, rest);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  }, [filters, selectedCity, sorter]);

  useEffect(() => {
    fetchData();
  }, [filters, sorter, fetchData]);

  /**
   * Handle change of the table (e. g. sorting trigger)
   */
  const handleTableChange = (paginating, filter, sorting) => {
    setSorter(sorting);
  };

  const setRadioFilter = (filters, radio, checked) => {
    const keys = ['is_invoice', 'is_operation', 'is_all'];
    keys.forEach(key => {
      if (filters[key]) delete filters[key];
    })

    if (checked) setFilters({ ...filters, [radio]: checked ? 1 : false });
  };

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Col span={24}>
              <Form form={filterForm} layout="horizontal" className="filterForm">
                <Row gutter={[16, 0]}>
                  <Col span={8}>
                    <PartnerSelect
                      name={"partner_id"}
                      label={t("contacts.filters.partner")}
                      value={filters.partner_id}
                      onChange={(e) => setFilters({ ...filters, partner_id: e ? e.id : '' })}
                    />
                  </Col>
                  <Col span={8}>
                    <BuildingSelect
                      name={"building_id"}
                      label={t("contacts.filters.building")}
                      value={filters.building_id}
                      onChange={(e) => setFilters({ ...filters, building_id: e ? e.id : '' })}
                    />
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={"email"}
                      label={t("contacts.filters.email")}
                      value={filters.email}
                      style={{ marginBottom: "12px" }}
                    >
                      <Input
                        onChange={(val) => {
                          if (val.target.value === undefined || val.target.value === "" || val.target.value === null) {
                            let { email, ...rest } = filters;
                            setFilters({
                              ...rest,
                            });
                          } else {
                            setFilters({
                              ...filters,
                              email: val.target.value,
                            });
                          }
                        }}
                      ></Input>
                    </Form.Item>
                  </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={2}>
                      <Form.Item label={t("contacts.isInvoice")}>
                        <Radio
                          checked={filters.is_invoice}
                          onChange={(e) => {
                            setRadioFilter(filters, 'is_invoice', e.target.checked)
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item label={t("contacts.isOperation")}>
                        <Radio
                          checked={filters.is_operation}
                          onChange={(e) => {
                            setRadioFilter(filters, 'is_operation', e.target.checked)
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item label={t("contacts.isAll")}>
                        <Radio
                          checked={filters.is_all}
                          onChange={(e) => {
                            setRadioFilter(filters, 'is_all', e.target.checked)
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                  {/* Filters reset button */}
                  <Form.Item style={{ marginLeft: "12px" }}>
                    <Link
                      onClick={() => {
                        filterForm.resetFields();
                        setFilters({});
                      }}
                    >
                      {t("common.resetFilters")}
                    </Link>
                  </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label={t("schedules.cleaningDay")}
                      name={"cleaning_days"}
                    >
                      <Select
                        mode="multiple"
                        showSearch={true}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(e) => setFilters({ ...filters, cleaningDay: e })}
                      >
                        {days.map((day) => (
                          <Select.Option key={day} value={day}>
                            {t("rrules.byweekday." + day)}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Table
              size={"small"}
              loading={isLoading}
              rowKey={"id"}
              dataSource={contacts}
              style={{ pointerEvents: "stroke" }}
              columns={columns}
              pagination={{
                pageSize: pagination?.perPage,
                total: pagination?.total,
                showSizeChanger: true,
                onChange: (e, perPage) => {
                  setFilters({ ...filters, page: e, perPage: perPage });
                },
                showTotal: (total, range) => (
                  <p>
                    {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
                    {" " + t("common.paginationPartTwo") + " "}
                    <strong>{total}</strong> {t("common.paginationPartThree")}
                  </p>
                ),
              }}
              onChange={handleTableChange}
            ></Table>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ContactsIndex;
