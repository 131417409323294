import { Layout, Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const styleParent = {
  height: "100vh",
};

const styleSpinner = {
  marginTop: "48vh",
};

const SpinnerFullPage = () => {
  const { t } = useTranslation();

  return (
    <Layout style={styleParent}>
      <Spin style={styleSpinner} tip={t("common.loading")} size="large"></Spin>
    </Layout>
  );
};

export default SpinnerFullPage;
