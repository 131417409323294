import {
  ExclamationOutlined,
  EyeOutlined,
  FilePdfOutlined,
  FileZipOutlined,
  ReloadOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, notification, Row } from "antd";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import { InvoiceContext } from "../../pages/InvoiceProfile";

const InvoiceActionBar = ({ id, invoice, setInvoice }) => {
  const { t } = useTranslation();

  const { refreshInvoice } = useContext(InvoiceContext);

  const [isPdfDownloading, setPdfDownloading] = useState(false);
  const [isXmlDownloading, setXmlDownloading] = useState(false);

  return (
    <Row gutter={[16, 16]} style={{ marginTop: "12px" }}>
      <Col>
        <Button
          loading={isPdfDownloading}
          icon={<FilePdfOutlined />}
          onClick={() => {
            setPdfDownloading(true);
            coreApi
              .get("/invoices/download/pdf/" + id, {
                responseType: "blob",
              })
              .then((response) => {
                let fileName;

                if (invoice?.invoice_type === 1 || invoice?.invoice_type === 4) {
                  fileName =
                    invoice?.invoice_number +
                    "_" +
                    moment(invoice?.date_issued).format("MM/YY") +
                    "_" +
                    invoice?.supplier_name +
                    "_" +
                    invoice?.supplier_address;
                } else {
                  fileName =
                    invoice?.invoice_number +
                    "_" +
                    moment(invoice?.date_issued).format("MM/YY") +
                    "_" +
                    invoice?.supplier_name +
                    "_" +
                    invoice?.recipient_name;
                }

                fileDownload(response.data, fileName + ".pdf");
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                setPdfDownloading(false);
              });
          }}
        >
          {t("finance.invoices.downloadPdf")}
        </Button>
      </Col>
      <Col>
        <Button
          loading={isXmlDownloading}
          icon={<FileZipOutlined />}
          onClick={() => {
            setXmlDownloading(true);
            coreApi
              .get("/invoices/download/xml/" + id, {
                responseType: "blob",
              })
              .then((response) => {
                fileDownload(response.data, "faktura.xml");
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                setXmlDownloading(false);
              });
          }}
        >
          {t("finance.invoices.downloadXml")}
        </Button>
      </Col>
      {invoice?.public_url && (
        <Col>
          <Button
            icon={<EyeOutlined />}
            onClick={() => {
              window.open(invoice?.public_url);
            }}
          >
            {t("finance.invoices.previewWebLink")}
          </Button>
        </Col>
      )}

      {!invoice?.date_sent_to_recipient && (
        <Col>
          <Button
            icon={<SendOutlined />}
            onClick={() => {
              coreApi
                .post(`invoices/${invoice?.id}/mark-sent`)
                .then((response) => {
                  setInvoice(response.data?.invoice);
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
          >
            {t("invoices.markSent")}
          </Button>
        </Col>
      )}

      <Col>
        <Button
          icon={<ReloadOutlined />}
          onClick={() => {
            coreApi
              .get(`invoices/${invoice?.id}/synchronize-transactions`)
              .then((response) => {
                setInvoice(response.data?.invoice);
              })
              .catch((e) => {
                console.log(e);
              });
          }}
        >
          {t("finance.invoices.synchronize")}
        </Button>
      </Col>

      {JSON.parse(localStorage.getItem("user")).roles[0].name === "admin" && (
        <Col>
          <Button
            danger
            icon={<ExclamationOutlined />}
            onClick={() => {
              Modal.confirm({
                title: t("finance.invoices.incorrect.alertTitle"),
                content: t("finance.invoices.incorrect.alertMessage"),
                okText: t("finance.invoices.incorrect.submit"),
                okButtonProps: { type: "danger" },
                onOk: () => {
                  coreApi
                    .get(`invoices/${invoice?.id}/mark-incorrectly-issued`)
                    .then((response) => {
                      refreshInvoice(response.data?.invoice);
                      notification.success({ message: response.data?.message });
                      window.location.reload(); // 100% SPA functionality but it works
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                },
              });
            }}
          >
            {t("finance.invoices.markIncorrect")}
          </Button>
        </Col>
      )}

      {/* <Col>
        <InvoiceSendModal id={id} />
      </Col>
      <Col>
        <Button icon={<WarningOutlined />} danger>
          {t("common.delete")}
        </Button>
      </Col> */}
    </Row>
  );
};

export default InvoiceActionBar;
