import React from 'react';
import {Checkbox, Form} from 'antd';
import { useTranslation } from 'react-i18next';

const ProductTypeCheckboxes = ({ partner, readOnly }) => {
  const { t } = useTranslation();
  const [productTypes, setProductTypes] = React.useState(partner?.product_type || []); // ['apartment', 'office']

  const handleProductTypeChange = (name, checked) => {
    let newProductTypes = Array.isArray(productTypes) ? productTypes : JSON.parse(productTypes);
    const updatedProductTypes = checked
      ? [...newProductTypes, name]
      : newProductTypes.filter(type => type !== name);

    setProductTypes(updatedProductTypes);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {Object.entries({
        apart: t("partners.productTypes.apartment"),
        office: t("partners.productTypes.office")
      }).map(([name, label], index) => (
        <Form.Item
          name={name}
          label={index < 1 ? t("partners.productType") : false}
          initialValue={productTypes.includes(name)}
          valuePropName="checked"
         >
          <Checkbox
            key={name}
            checked={productTypes.includes(name)}
            onChange={(e) => handleProductTypeChange(name, e.target.checked)}
            disabled={readOnly}
            style={{ marginRight: '10px' }}
          >
            {label}
          </Checkbox>
        </Form.Item>
      ))}
    </div>
  );
};

export default ProductTypeCheckboxes;
