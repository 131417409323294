import { Row, Col, Divider, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClientInvoiceInfo from "./ClientInvoiceInfo";
import ClientSelect from "./ClientSelect";
import PartnerInvoiceInfo from "./PartnerInvoiceInfo";
import PartnerSelect from "./PartnerSelect";
import InvoiceDetailsForm from "./InvoiceDetailsForm";
import InvoiceLocationForm from "./InvoiceLocationForm";
import AddLineItemForm from "./AddLineItemForm";
import InvoiceSummary from "./InvoiceSummary";
import InvoiceBuildingSelect from "./InvoiceBuildingSelect";

const PartnerClientForm = ({ form, currentStep, setStepTotal }) => {
  const { t } = useTranslation();

  const [selectedSupplier, setSelectedSupplier] = useState();
  const [selectedRecipient, setSelectedRecipient] = useState();

  const { Step } = Steps;

  useEffect(() => {
    form.setFields([
      {
        name: "supplier_name",
        value:
          selectedSupplier && selectedSupplier?.contractors?.length > 0
            ? selectedSupplier?.contractors[0]?.first_name + " " + selectedSupplier?.contractors[0]?.last_name
            : selectedSupplier?.first_name &&
              selectedSupplier?.last_name &&
              selectedSupplier?.first_name + " " + selectedSupplier?.last_name,
      },
      {
        name: "supplier_company_number",
        value:
          selectedSupplier && selectedSupplier?.contractors?.length > 0
            ? selectedSupplier?.contractors[0]?.company_number
            : selectedSupplier?.company_number,
      },
      {
        name: "supplier_address",
        value:
          selectedSupplier && selectedSupplier?.contractors?.length > 0
            ? selectedSupplier?.contractors[0]?.billing_address_street +
              " " +
              selectedSupplier?.contractors[0]?.billing_address_house_number
            : selectedSupplier?.billing_address_street + " " + selectedSupplier?.billing_address_house_number,
      },
      {
        name: "supplier_postal_code",
        value:
          selectedSupplier && selectedSupplier?.contractors?.length > 0
            ? selectedSupplier?.contractors[0]?.billing_address_postal_code
            : selectedSupplier?.billing_address_postal_code,
      },
      {
        name: "supplier_city",
        value:
          selectedSupplier && selectedSupplier?.contractors?.length > 0
            ? selectedSupplier?.contractors[0]?.billing_address_city
            : selectedSupplier?.billing_address_city,
      },
      // ----------------------------------------------
      {
        name: "recipient_name",
        value:
          selectedRecipient && selectedRecipient?.name && "represented_by" in selectedRecipient
            ? selectedRecipient?.represented_by?.name
            : selectedRecipient?.name,
      },
      {
        name: "recipient_company_number",
        value:
          selectedRecipient && "represented_by" in selectedRecipient
            ? selectedRecipient?.represented_by?.company_number
            : selectedRecipient?.company_number,
      },
      {
        name: "recipient_address",
        value:
          selectedRecipient &&
          selectedRecipient?.billing_address_street &&
          selectedRecipient?.billing_address_house_number &&
          "represented_by" in selectedRecipient
            ? selectedRecipient?.represented_by?.billing_address_city +
              " " +
              selectedRecipient?.represented_by?.billing_address_house_number
            : selectedRecipient?.billing_address_street + " " + selectedRecipient?.billing_address_house_number,
      },
      {
        name: "recipient_postal_code",
        value:
          selectedRecipient && "represented_by" in selectedRecipient
            ? selectedRecipient?.represented_by?.billing_address_postal_code
            : selectedRecipient?.billing_address_postal_code,
      },
      {
        name: "recipient_city",
        value:
          selectedRecipient && selectedRecipient?.billing_address_city && "represented_by" in selectedRecipient
            ? selectedRecipient?.represented_by?.billing_address_city
            : selectedRecipient?.billing_address_city + " " + selectedRecipient?.billing_address_house_number,
      },
      {
        name: "invoice_publisher_id",
        value:
          selectedSupplier && selectedSupplier?.contractors?.length > 0
            ? selectedSupplier?.contractors[0]?.id
            : selectedSupplier?.id,
      },
      {
        name: "service_provider_id",
        value: selectedSupplier?.id && selectedSupplier?.id,
      },
    ]);
  }, [form, selectedRecipient, selectedSupplier]);

  const steps = [
    {
      title: t("finance.invoices.recipient") + " / " + t("finance.invoices.supplier"),
      content: (
        <React.Fragment>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={12}>
              <Divider orientation="left">{t("finance.invoices.supplier")}</Divider>
              <PartnerSelect required={true} onChange={(e) => setSelectedSupplier(e)} showSearch={true} />
            </Col>
            <Col xs={24} md={12}>
              <Divider orientation="left">{t("finance.invoices.recipient")}</Divider>
              <ClientSelect required={true} onChange={(e) => setSelectedRecipient(e)} showSearch={true} />
              <InvoiceBuildingSelect idClient={selectedRecipient?.id} required={true} />
            </Col>
            <Col xs={24} md={12}>
              <PartnerInvoiceInfo partner={selectedSupplier} isSupplier={true} />
            </Col>
            <Col xs={24} md={12}>
              <ClientInvoiceInfo client={selectedRecipient} isCompanyNumberRequired={false} />
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: t("finance.invoices.details"),
      content: (
        <React.Fragment>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={12}>
              <Divider>{t("finance.invoices.details")}</Divider>
              <InvoiceDetailsForm />
            </Col>
            <Col xs={24} md={12}>
              <Divider>{t("finance.invoices.locale")}</Divider>
              <InvoiceLocationForm />
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: t("finance.lineItems.lineItems"),
      content: (
        <React.Fragment>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={24}>
              <Divider>{t("finance.lineItems.lineItems")}</Divider>
              <AddLineItemForm form={form} idBuilding={form?.getFieldValue("building_id")} />
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: t("common.summary"),
      content: (
        <React.Fragment>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={24}>
              <Divider>{t("common.summary")}</Divider>
              <InvoiceSummary form={form} invoiceType={"partnerClient"} />
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
  ];

  useEffect(() => {
    setStepTotal(steps.length - 1);
  }, [setStepTotal, steps.length]);

  return (
    <React.Fragment>
      <Row gutter={[16, 8]} style={{ width: "100%" }}>
        <Col span={24}>
          <Steps current={currentStep}>
            {steps.map((item, i) => (
              <Step key={i} title={item.title}></Step>
            ))}
          </Steps>
        </Col>
        <Divider></Divider>
        <Col span={24}>
          <div className="steps-content">{steps[currentStep].content}</div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PartnerClientForm;
