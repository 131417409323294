import { Form, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const BiweeklyTypeSelect = ({ postChange, hasFeedback, biweeklyType = null, required = false }) => {
  const { t } = useTranslation();

  const handleChange = (value) => {
    postChange(value === undefined ? null : value);
  };

  return (
    <Form.Item
      label={t("schedules.biweeklyType")}
      name="biweeklyType"
      hasFeedback={hasFeedback}
      rules={[
        {
          required: required,
          message: t("schedules.biweeklyTypeRequiredMessage"),
        },
      ]}
      initialValue={biweeklyType}
    >
      <Select
        onChange={handleChange}
      >
        <Select.Option value="odd">{t("schedules.oddWeeks")}</Select.Option>
        <Select.Option value="even">{t("schedules.evenWeeks")}</Select.Option>
      </Select>
    </Form.Item>
  );
};

export default BiweeklyTypeSelect;
