import React from "react";
import { useTranslation } from "react-i18next";
import { RRule, rrulestr } from "rrule";

const RruleCell = ({ rule }) => {
  const { t } = useTranslation();

  let rrule = rrulestr(rule);

  let result =
    rrule.origOptions.byweekday.length +
    "x " +
    (rrule.origOptions.interval || 1) +
    " ";

  if (rrule.origOptions.interval > 1 && rrule.origOptions.interval < 5) {
    result =
      result +
      t("rrules.freq.pl" + RRule.FREQUENCIES[rrule.origOptions.freq]) +
      " (";
  } else if (rrule.origOptions.interval >= 5) {
    result =
      result +
      t("rrules.freq.mpl" + RRule.FREQUENCIES[rrule.origOptions.freq]) +
      " (";
  } else {
    result =
      result +
      t("rrules.freq.sg" + RRule.FREQUENCIES[rrule.origOptions.freq]) +
      " (";
  }

  let days = rrule.origOptions.byweekday.map((item, index) => {
    return index === rrule.origOptions.byweekday.length
      ? t("rrules.byweekday." + RRule[item]).toLowerCase() + ", "
      : t("rrules.byweekday." + RRule[item]).toLowerCase();
  });

  return <React.Fragment>{result + days + ")"}</React.Fragment>;
};

export default RruleCell;
