import { Card, Col, Row } from "antd";
import React from "react";
import CleaningsList from "../components/Cleanings/CleaningList";

// Gutter for <Row>
const gutter = [16, 24];

/**
 * Page with all cleanings
 * @component
 * @alias ClaimsIndex
 * @returns <CleaningsList />
 */
const CleaningsIndex = () => {
  return (
    <React.Fragment>
      <Row gutter={gutter}>
        <Col span={24}>
          <Card>
            <CleaningsList />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CleaningsIndex;
