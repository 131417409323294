import { Col, Form, Input, notification, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../api/calls";
import ProfileFormActionSpace from "./ProfileFormActionSpace";
import EmailInputWithValidation from "./custom/Form/EmailInputWithValidation";

const EmployeeGeneralPane = ({ employee }) => {
  const { t } = useTranslation();
  const [addContactForm] = Form.useForm();
  const [isFormReadOnly, setFormReadOnly] = useState(true);
  const [isFormSending, setFormSending] = useState(false);

  const [employeeForm] = Form.useForm();

  return (
    <React.Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <ProfileFormActionSpace
            formInstance={employeeForm}
            isFormReadOnly={isFormReadOnly}
            isFormSending={isFormSending}
            setFormEditable={() => {
              setFormReadOnly(false);
            }}
            setFormDisabled={() => {
              setFormReadOnly(true);
            }}
          />
        </Col>
        <Col span={24}>
          <Form
            form={employeeForm}
            onFinish={(values) => {
              setFormSending(true);
              coreApi
                .put("/employees/" + employee.id, values)
                .then((response) => {
                  setFormReadOnly(true);
                  setFormSending(false);
                  employeeForm.resetFields();
                  notification.success({
                    message: response?.data?.message || t("successNotifications.saveChanges.title"),
                  });
                })
                .catch((error) => console.log(error))
                .finally(() => {
                  setFormSending(false);
                });
            }}
          >
            <Row gutter={[16, 0]}>
              <Form.Item name="employee_id" hidden initialValue={employee?.id}></Form.Item>

              <Col span={6}>
                <Form.Item name="first_name" label={t("common.firstName")} initialValue={employee?.first_name}>
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="last_name" label={t("common.lastName")} initialValue={employee?.last_name}>
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <EmailInputWithValidation
                  form={addContactForm}
                  name="email_work"
                  label={t("employees.email")}
                  message={t("common.emailValidation")}
                  initialValue={employee?.email_work}
                  validationMessage={t("employees.validation.email")}
                  readOnly={isFormReadOnly}
                />
              </Col>
              <Col span={12}>
                <EmailInputWithValidation
                  form={addContactForm}
                  name="email_personal"
                  label={t("employees.emailPersonal")}
                  message={t("common.emailValidation")}
                  initialValue={employee?.email_personal}
                  validationMessage={t("employees.validation.emailPersonal")}
                  readOnly={isFormReadOnly}
                />
              </Col>
              <Col span={12}>
                <Form.Item name="phone_work" label={t("employees.phone")} initialValue={employee?.phone_work}>
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phone_personal"
                  label={t("employees.phonePersonal")}
                  initialValue={employee?.phone_personal}
                >
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="company_number"
                  label={t("employees.companyIdentificationNumber")}
                  initialValue={employee?.company_number}
                >
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default EmployeeGeneralPane;
