import { Divider } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import AccountBankCodeInput from "../Accounts/AccountBankCodeInput";
import AccountIbanInput from "../Accounts/AccountIbanInput";
import AccountNumberInput from "../Accounts/AccountNumberInput";
import AccountPrefixInput from "../Accounts/AccountPrefixInput";
import AccountSwiftInput from "../Accounts/AccountSwiftInput";

const ClientBankingSection = ({ client, readOnly }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Divider orientation="left">{t("common.bankingInformation")}</Divider>
      <AccountPrefixInput
        initialValue={client?.account_prefix}
        readOnly={readOnly}
      />
      <AccountNumberInput
        initialValue={client?.account_number}
        readOnly={readOnly}
      />
      <AccountBankCodeInput
        initialValue={client?.account_bank_code}
        readOnly={readOnly}
      />
      <AccountIbanInput
        initialValue={client?.account_iban}
        readOnly={readOnly}
      />
      <AccountSwiftInput
        initialValue={client?.account_swift}
        readOnly={readOnly}
      />
    </React.Fragment>
  );
};

export default ClientBankingSection;
