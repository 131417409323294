import { Badge, Button, Card, Col, notification, Row, Space, Table, Typography } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../api/calls";
import InvoiceActionBar from "../components/Invoices/InvoiceActionBar";
import InvoiceOverviewContainer from "../components/Invoices/InvoiceOverviewContainer";
import InvoiceSummary from "../components/Invoices/InvoiceSummary";
import LineItemContainer from "../components/Invoices/LineItemContainer";
import { UserOutlined, BankOutlined, MinusOutlined } from "@ant-design/icons";

export const InvoiceContext = React.createContext();

const InvoiceProfile = (props) => {
  const { t } = useTranslation();

  const [invoice, setInvoice] = useState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    coreApi
      .get("/invoices/" + props.match.params.id)
      .then((response) => {
        setInvoice(response.data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [props.match.params.id]);

  const refreshInvoice = (newInvoice) => {
    setInvoice(newInvoice);
  };

  /**
   * Unassign the transaction from the invoice.
   *
   * @param {int} id
   */
  const unassignTransaction = (id) => {
    coreApi
      .post("/transactions/unassign-from-invoice", {
        transaction_id: id,
        invoice_id: invoice?.id,
      })
      .then((response) => {
        setInvoice(response.data?.invoice);

        notification.success({
          message: response.data?.message,
        });
      })
      .catch((e) => {
        notification.error({
          message: e?.response?.data?.message,
        });
      });
  };

  return (
    <InvoiceContext.Provider value={{ invoice, refreshInvoice }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Badge.Ribbon
                text={
                  invoice?.invoice_type === 4
                    ? invoice?.date_paid
                      ? t("finance.invoices.paid") + " - " + moment(invoice?.date_paid).format("DD. MM. YYYY")
                      : t("finance.invoices.unpaid")
                    : invoice?.date_paid
                    ? invoice?.transactions?.reduce(function (prev, current) {
                        return prev + +current.pivot?.amount;
                      }, 0) > invoice?.total_amount
                      ? t("finance.invoices.overpaid")
                      : t("finance.invoices.paid") + " - " + moment(invoice?.date_paid).format("DD. MM. YYYY")
                    : t("finance.invoices.unpaid")
                }
                placement={"end"}
                color={
                  invoice?.invoice_type === 4
                    ? invoice?.date_paid
                      ? "green"
                      : "red"
                    : invoice?.date_paid
                    ? invoice?.transactions?.reduce(function (prev, current) {
                        return prev + +current.pivot?.amount;
                      }, 0) > invoice?.total_amount
                      ? "orange"
                      : "green"
                    : "red"
                }
              >
                <Card loading={isLoading}>
                  <Typography.Title>{invoice?.name}</Typography.Title>

                  <div>
                    <Typography.Title
                      level={5}
                      type="secondary"
                      style={{ color: "#1890ff", fontWeight: 400, display: "flex", alignItems: "center", gap: "16px" }}
                    >
                      {invoice?.client_id ? (
                        <Space size={[6, 12]}>
                          <Link
                            to={`/clients/${invoice?.client_id}`}
                            style={{ display: "flex", gap: "4px", alignItems: "center" }}
                          >
                            <BankOutlined />
                            {invoice?.recipient_name}
                          </Link>
                        </Space>
                      ) : null}

                      <Space size={[6, 12]}>
                        <Link
                          to={`/partners/${invoice?.invoice_publisher_id}`}
                          style={{ display: "flex", gap: "4px", alignItems: "center" }}
                        >
                          <UserOutlined />
                          {invoice?.supplier_name}
                        </Link>
                      </Space>
                    </Typography.Title>
                  </div>

                  <Typography.Text type="secondary">
                    {t("common.recordCreated") + ": " + moment(invoice?.created_at).format("LLL")}
                  </Typography.Text>
                  <br />

                  <Typography.Text type="secondary" style={{ paddingBottom: "12px" }}>
                    {t("common.recordLastUpdate") + ": " + moment(invoice?.updated_at).format("LLL")}
                  </Typography.Text>

                  <InvoiceActionBar invoice={invoice} setInvoice={setInvoice} id={props.match.params.id} />
                </Card>
              </Badge.Ribbon>
            </Col>
          </Row>
        </Col>
        <Col xs={24} xxl={18}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title={t("finance.invoices.overview")} loading={isLoading}>
                <InvoiceOverviewContainer />
              </Card>
            </Col>
            <Col span={24}>
              <Card title={t("finance.lineItems.lineItems")}>
                <LineItemContainer idInvoice={invoice?.id} lineItems={invoice?.line_items} />
              </Card>
            </Col>

            <Col span={24}>
              <Card title={t("finance.invoices.linkedTransactions")}>
                <Table
                  pagination={false}
                  loading={isLoading}
                  size="small"
                  columns={[
                    {
                      title: t("finance.transactions.transactionNumber"),
                      key: "id",
                      dataIndex: "id",
                    },
                    {
                      title: t("finance.transactions.accountNumberFrom"),
                      key: "account_number_from",
                      render: (transaction) => (
                        <Typography.Text copyable key={transaction.id}>
                          {transaction.account_number_from + "/" + transaction.bank_code_from}
                        </Typography.Text>
                      ),
                    },
                    {
                      title: t("finance.transactions.accountNameFrom"),
                      key: "account_name_from",
                      dataIndex: "account_name_from",
                    },
                    {
                      title: t("finance.transactions.variableSymbol"),
                      key: "variable_symbol",
                      dataIndex: "variable_symbol",
                      render: (variableSymbol) => (
                        <Typography.Text copyable key={variableSymbol}>
                          {variableSymbol}
                        </Typography.Text>
                      ),
                    },
                    {
                      title: t("finance.transactions.date"),
                      key: "date_transaction",
                      dataIndex: "date_transaction",
                      render: (date_transaction) => moment(date_transaction).format("L"),
                    },
                    {
                      title: t("finance.transactions.amount"),
                      key: "amount",
                      render: (transaction) => {
                        return new Intl.NumberFormat("cs-CZ", {
                          style: "currency",
                          currency: transaction?.currency_iso_4217 || "CZK",
                        }).format(transaction?.pivot?.amount);
                      },
                    },
                    {
                      title: t("finance.transactions.unassignTransaction"),
                      key: "unassignTransaction",
                      render: (transaction) => {
                        return (
                          <Button
                            danger
                            shape="circle"
                            icon={<MinusOutlined />}
                            size={"small"}
                            onClick={() => unassignTransaction(transaction?.id)}
                          />
                        );
                      },
                    },
                  ]}
                  dataSource={invoice?.transactions}
                ></Table>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} xxl={6}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} xxl={24}>
              <Card title={t("finance.invoices.summary")}>
                <InvoiceSummary
                  loading={isLoading}
                  currencyIso4217={invoice?.currency_iso_4217}
                  discountAmount={invoice?.discount_amount}
                  vatAmount={invoice?.vat_amount}
                  subtotalAmount={invoice?.subtotal_amount}
                  totalAmount={invoice?.total_amount}
                  invoiceType={invoice?.invoice_type}
                />
              </Card>
            </Col>
            <Col xs={24} md={12} xxl={24}>
              <Card title={t("finance.invoices.linkedTransactions")}>
                <Table
                  pagination={false}
                  loading={isLoading}
                  size="small"
                  columns={[
                    {
                      title: t("finance.transactions.transactionNumber"),
                      key: "id",
                      dataIndex: "id",
                    },
                    {
                      title: t("finance.transactions.amount"),
                      key: "amount",
                      render: (transaction) => {
                        return new Intl.NumberFormat("cs-CZ", {
                          style: "currency",
                          currency: transaction?.currency_iso_4217 || "CZK",
                        }).format(transaction?.pivot?.amount);
                      },
                    },
                  ]}
                  dataSource={invoice?.transactions}
                ></Table>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </InvoiceContext.Provider>
  );
};

export default InvoiceProfile;
