import { Form, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

/**
 * Cleaning Promo Tag Select
 * @component
 * @alias CleaningPromoTagSelect
 * @property {number} initialValue - tag_id
 * @property {bool} readOnly - sets <Form.Item /> read-only
 * @returns
 */
const CleaningPromoTagSelect = ({ initialValue, readOnly }) => {
  const { t } = useTranslation();

  const [promoTags, setPromoTags] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    setLoading(true);
    coreApi
      .get("/cleanings/promo-tags", { params: {} })
      .then((response) => {
        if (isMounted) {
          setPromoTags(response.data);
        }
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Form.Item label={t("cleanings.promoTag")} name={"promo_tag_id"} initialValue={initialValue}>
      <Select loading={loading} disabled={loading} allowClear>
        {promoTags.map((promoTag) => {
          return (
            <Select.Option key={promoTag?.id} value={promoTag?.id}>
              {promoTag.tag_name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default CleaningPromoTagSelect;
