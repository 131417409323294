import { Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const gutter = [8, 0];

const BankingDetailsInputForm = ({
  isAccountRequired,
  isIBANRequired,
  isSWIFTRequired,
}) => {
  BankingDetailsInputForm.defaultProps = {
    isAccountRequired: false,
    isIBANRequired: false,
    isSWIFTRequired: false,
  };

  const { t } = useTranslation();

  const [formRules, setFormRules] = useState({
    prefix: { required: false, message: "" },
    number: { required: false, message: "" },
    bankCode: { required: false, message: "" },
    iban: { required: false, message: "" },
    swift: { required: false, message: "" },
  });

  useEffect(() => {
    if (isAccountRequired) {
      setFormRules({
        prefix: {
          required: true,
          message: t("common.validation.accountPrefix"),
        },
        number: {
          required: true,
          message: t("common.validation.accountNumber"),
        },
        bankCode: {
          required: true,
          message: t("common.validation.accountBankCode"),
        },
        iban: { required: false, message: "" },
        swift: { required: false, message: "" },
      });
    } else if (isIBANRequired) {
      setFormRules({
        prefix: { required: false, message: "" },
        number: { required: false, message: "" },
        bankCode: { required: false, message: "" },
        iban: {
          required: true,
          message: t("common.validation.accountIban"),
        },
        swift: { required: false, message: "" },
      });
    } else if (isSWIFTRequired) {
      setFormRules({
        prefix: { required: false, message: "" },
        number: { required: false, message: "" },
        bankCode: { required: false, message: "" },
        iban: { required: false, message: "" },
        swift: {
          required: true,
          message: t("common.validation.accountSwift"),
        },
      });
    }
  }, [isAccountRequired, isIBANRequired, isSWIFTRequired, t]);

  return formRules.prefix ? (
    <Form.Item label={t("common.bankingInformation")}>
      <Row gutter={gutter}>
        <Col span={7}>
          <Form.Item
            name={"account_prefix"}
            label={t("common.accountPrefix")}
            initialValue={"000000"}
            rules={[
              {
                required: formRules.prefix.required,
                message: formRules.prefix.message,
              },
            ]}
          >
            <Input maxLength={6}></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"account_number"}
            label={t("common.accountNumber")}
            rules={[
              {
                required: formRules.number.required,
                message: formRules.number.message,
              },
            ]}
          >
            <Input maxLength={10}></Input>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            name="account_bank_code"
            label={t("common.bankCode")}
            rules={[
              {
                required: formRules.bankCode.required,
                message: formRules.bankCode.message,
              },
            ]}
          >
            <Input maxLength={4}></Input>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name="account_iban"
            label={t("common.iban")}
            rules={[
              {
                required: formRules.iban.required,
                message: formRules.iban.message,
              },
            ]}
          >
            <Input maxLength={34}></Input>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name="account_swift"
            label={t("common.swift")}
            rules={[
              {
                required: formRules.swift.required,
                message: formRules.swift.message,
              },
            ]}
          >
            <Input maxLength={11}></Input>
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  ) : (
    ""
  );
};

export default BankingDetailsInputForm;
