import { Checkbox, Col, DatePicker, Form, Input, Modal, Row } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import EmailInputWithValidation from "../custom/Form/EmailInputWithValidation";

const PartnerEditWorkerForm = ({ isOpen, close, editWorker, worker, submitting, contractorId = null }) => {
  const { t } = useTranslation();
  const [addContactForm] = Form.useForm();
  const [editWorkerForm] = Form.useForm();

  useEffect(() => {
    editWorkerForm.resetFields();
  });

  return (
    <React.Fragment>
      <Modal
        key={worker?.id}
        open={isOpen}
        onOk={() => {
          editWorkerForm.submit();
        }}
        onCancel={close}
        okText={t("common.save")}
        okButtonProps={{ loading: submitting }}
        title={t("partners.editWorker")}
      >
        <Form
          form={editWorkerForm}
          onFinish={(values) => {
            editWorker(values);
          }}
          layout="vertical"
        >
          <Row gutter={[24, 0]}>
            <Form.Item hidden name="partner_id" initialValue={worker.id}>
              <Input></Input>
            </Form.Item>

            <Form.Item hidden name="contractor_id" initialValue={contractorId}>
              <Input></Input>
            </Form.Item>

            <Col span={12}>
              <Form.Item name="first_name" label={t("common.firstName")} initialValue={worker.first_name} required>
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="last_name" label={t("common.lastName")} initialValue={worker.last_name} required>
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <EmailInputWithValidation
                form={addContactForm}
                name="email"
                label={t("common.email")}
                message={t("common.emailValidation")}
                initialValue={worker.email}
              />
            </Col>
            <Col span={12}>
              <Form.Item name="phone" label={t("common.phone")} initialValue={worker.phone}>
                <Input></Input>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="contract_from"
                label={t("partners.contractFrom")}
                initialValue={worker?.pivot?.date_from ? moment(worker.pivot.date_from) : null}
              >
                <DatePicker
                  disabledDate={(current) =>
                    current.format("YYYY-MM-DD") !== current.clone().startOf("month").format("YYYY-MM-DD")
                  }
                ></DatePicker>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="contract_to"
                label={t("partners.contractTo")}
                initialValue={worker?.pivot?.date_to ? moment(worker.pivot.date_to) : null}
              >
                <DatePicker></DatePicker>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name={"affidavit"}
                valuePropName={"checked"}
                label={t("partners.affidavit")}
                initialValue={worker?.pivot?.affidavit}
              >
                <Checkbox></Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default PartnerEditWorkerForm;
