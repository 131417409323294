import { Col, Divider, Form, Input, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const InvoiceRecipientPane = ({
  readOnly,
  name,
  companyNumber,
  vatNumber,
  address,
  postalCode,
  city,
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Divider orientation="left">{t("finance.invoices.recipient")}</Divider>
      <Form.Item
        name="recipient_name"
        label={t("finance.invoices.recipientName")}
        initialValue={name}
      >
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="recipient_company_number"
            label={t("finance.invoices.recipientCompanyNumber")}
            initialValue={companyNumber}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="recipient_vat_number"
            label={t("finance.invoices.recipientVatNumber")}
            initialValue={vatNumber}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="recipient_address"
        label={t("finance.invoices.recipientAddress")}
        initialValue={address}
      >
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="recipient_postal_code"
            label={t("finance.invoices.recipientPostalCode")}
            initialValue={postalCode}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            name="recipient_city"
            label={t("finance.invoices.recipientCity")}
            initialValue={city}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InvoiceRecipientPane;
