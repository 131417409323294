import { UserOutlined } from "@ant-design/icons";
import { Button, Col, notification } from "antd";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { coreApi } from "../../api/calls";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @alias ContactExportButton
 * @property {number} idCityBranch
 * @property {string} cityName
 * @property {bool} primary - sets <Button /> style primary - defaul false
 * @returns <Button /> with fuctionality to download contact export csv
 */
const ContactExportButton = ({ exportAll, idCityBranch, cityName, primary = false, dateFrom, dateTo }) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  return (
    <Col span={24}>
      <Button
        type={primary ? "primary" : "none"}
        loading={isLoading}
        icon={<UserOutlined />}
        style={{ width: "100%" }}
        onClick={() => {
          setLoading(true);
          coreApi
            .get("/contacts/export/" + idCityBranch, {
              responseType: "blob",
              params: {
                exportAll: exportAll,
                dateFrom,
                dateTo,
              },
            })
            .then((response) => {
              fileDownload(response.data, cityName !== "" ? cityName + "-contacts-export.csv" : "contacts-export.csv");
            })
            .catch((err) => notification.error({ message: err.response.data.message }))
            .finally(() => setLoading(false));
        }}
      >
        {t("exports.contactExport")}
      </Button>
    </Col>
  );
};

export default ContactExportButton;
