import { Statistic } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const InvoiceSummary = ({
  currencyIso4217,
  subtotalAmount,
  vatAmount,
  discountAmount,
  totalAmount,
  loading,
  invoiceType = null,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {invoiceType === 2 && (
        <Statistic
          loading={loading}
          title={t("finance.invoices.subtotalAmount")}
          value={new Intl.NumberFormat("cs-CZ", {
            style: "currency",
            currency: currencyIso4217 || "CZK",
          }).format(subtotalAmount)}
        />
      )}

      {invoiceType === 2 && (
        <Statistic
          loading={loading}
          title={t("finance.invoices.discountAmount")}
          value={new Intl.NumberFormat("cs-CZ", {
            style: "currency",
            currency: currencyIso4217 || "CZK",
          }).format(discountAmount)}
        />
      )}

      {invoiceType === 2 && (
        <Statistic
          loading={loading}
          title={t("finance.invoices.vatAmount")}
          value={new Intl.NumberFormat("cs-CZ", {
            style: "currency",
            currency: currencyIso4217 || "CZK",
          }).format(vatAmount)}
        />
      )}

      {invoiceType === 2 && <hr></hr>}

      <Statistic
        loading={loading}
        title={t("finance.invoices.totalAmount")}
        value={new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: currencyIso4217 || "CZK",
        }).format(totalAmount)}
      />
    </React.Fragment>
  );
};

export default InvoiceSummary;
