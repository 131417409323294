import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Divider, Form, notification, Row, Typography, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import OneTimeCleaningEditForm from "./OneTimeCleaningEditForm";
import RecurringCleaningEditForm from "./RecurringCleaningEditForm";

/**
 * Cleaning Overview
 * @component
 * @alias CleaningOverview
 * @property {object} cleaning
 * @returns Overview of a cleaning and its frequency and content in collapsable list
 */
const CleaningOverview = ({ cleaning, cleanings = null, setCleanings = null, refreshCalendarView = null }) => {
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [cleaningForm] = Form.useForm();

  const onFinish = (values, path) => {
    setSubmitting(true);

    // check if value is not null because of moment.js is unable to handle null
    values.date_real = values?.date_real ? values.date_real.format("YYYY-MM-DD") : null;

    values.date_satisfaction_check = values?.date_satisfaction_check
      ? values.date_satisfaction_check.format("YYYY-MM-DD")
      : null;

    coreApi
      .put(path + cleaning?.id, values)
      .then((response) => {
        notification.success({
          message: response.data.message,
        });
        let newCleaningData = response.data.cleaning;

        // again, value check because of moment can NOT handle null value
        newCleaningData.date_expected = newCleaningData.date_expected ? moment(newCleaningData.date_expected) : null;

        newCleaningData.date_real = newCleaningData.date_real ? moment(newCleaningData.date_real) : null;

        newCleaningData.date_satisfaction_check = newCleaningData.date_satisfaction_check
          ? moment(newCleaningData.date_satisfaction_check)
          : null;

        // set form values
        cleaningForm.setFieldsValue(newCleaningData);
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  /**
   * Deleting recurring cleaning.
   *
   * @returns void
   */
  const deleteCleaning = () => {
    setDeleting(true);
    Modal.confirm({
      title: t("cleanings.recurring.deleteTitle"),
      content: t("cleanings.recurring.deleteWarning"),
      okText: t("common.delete"),
      onOk: () => {
        coreApi
          .delete("cleanings/" + cleaning.id)
          .then((res) => {
            notification.success({ message: res?.data?.message });
            setCleanings([...cleanings.filter((item) => item.id !== cleaning.id)]);
            if (refreshCalendarView) refreshCalendarView();
          })
          .catch((err) => notification.error({ message: err?.response?.data?.message }))
          .finally(() => setDeleting(false));
      },
    });
  };

  useEffect(() => {
    cleaningForm.resetFields();
  }, [cleaning, cleaningForm]);

  return (
    <div
      key={cleaning?.id}
      style={{ maxWidth: "330px" }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Row>
        {cleaning.is_recurring ? (
          <RecurringCleaningEditForm
            cleaning={cleaning}
            form={cleaningForm}
            onFinish={onFinish}
            layout={"vertical"}
            size={"small"}
          />
        ) : (
          <OneTimeCleaningEditForm
            cleaning={cleaning}
            form={cleaningForm}
            onFinish={onFinish}
            layout={"vertical"}
            size={"small"}
          />
        )}
        <Col span={24}>
          <Divider />
          {cleaning.is_recurring ? (
            <React.Fragment>
              <Collapse collapsible="header">
                {cleaning?.building_unit?.cleaning_service_groups?.map((group, index) => {
                  return (
                    <React.Fragment>
                      {group.cleaning_service_group_contents?.length > 0 && (
                        <Collapse.Panel key={group.id} header={group.name}>
                          {group.cleaning_service_group_contents?.map((content, index) => {
                            return content.service;
                          })}
                        </Collapse.Panel>
                      )}
                    </React.Fragment>
                  );
                })}
              </Collapse>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography.Text>{t("cleanings.oneTime.emptyServiceContent")}</Typography.Text>
            </React.Fragment>
          )}
        </Col>
        <Divider />
        <Col span={12}>
          <Button
            block
            loading={submitting}
            size={"large"}
            type={"primary"}
            icon={<SaveOutlined />}
            onClick={() => {
              cleaningForm.submit();
            }}
          >
            {t("common.save")}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            style={{ marginLeft: "8px" }}
            block
            loading={deleting}
            size={"large"}
            type={"danger"}
            icon={<DeleteOutlined />}
            onClick={() => {
              deleteCleaning();
            }}
          >
            {t("common.delete")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default CleaningOverview;
