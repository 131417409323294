import { DatePicker, Form, Input, Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Cleaning Detail Popup
 * @component
 * @alias CleaningDetailPopup
 * @property {bool} isOpen - pop-up should be displayed or not
 * @property {function} onCancel - parent function - what should be done onCancel
 * @property {date} cleaningDate - date of cleaning
 * @property {string} cleaningType - recurring or one-time
 * @property {numeric} cleaningPrice
 * @property {string} buildingStreet
 * @returns <Form /> component with information about cleaning
 */
const CleaningDetailPopup = ({
  isOpen,
  onCancel,
  cleaningDate,
  cleaningType,
  cleaningPrice,
  buildingStreet,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <React.Fragment>
      <Modal
        open={isOpen}
        title={buildingStreet + " - " + cleaningDate}
        onCancel={onCancel}
        onOk={form.submit}
      >
        <Form onFinish={onCancel}>
          <Form.Item label={t("cleanings.dateExpected")}>
            <Input readOnly value={cleaningDate}></Input>
          </Form.Item>
          <Form.Item label={t("cleanings.dateReal")}>
            <DatePicker></DatePicker>
          </Form.Item>
          <Form.Item label={t("cleanings.price")}>
            <Input value={cleaningPrice}></Input>
          </Form.Item>
          <Form.Item label={t("cleanings.type")}>
            <Input readOnly value={cleaningType}></Input>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default CleaningDetailPopup;
