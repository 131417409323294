import { Col, Row } from "antd";
import React from "react";
import ClientBankingSection from "./ClientBankingSection";
import ClientBasicInfoSection from "./ClientBasicInfoSection";
import ClientBillingAddressSection from "./ClientBillingAddressSection";
import RepresentedClientsPane from "./RepresentedClientsPane";

const RepresentativeGeneralPane = ({ client, buildings, readOnly }) => {
  return (
    <Row gutter={[16, 24]}>
      <Col md={24} xl={12}>
        <ClientBasicInfoSection client={client} readOnly={readOnly} />
      </Col>
      <Col md={24} xl={12}>
        <ClientBillingAddressSection client={client} readOnly={readOnly} />
      </Col>
      <Col md={24} xl={12}>
        <ClientBankingSection client={client} readOnly={readOnly} />
      </Col>
      <Col md={24} xl={24}>
        <RepresentedClientsPane buildings={buildings} client={client} />
      </Col>
    </Row>
  );
};

export default RepresentativeGeneralPane;
