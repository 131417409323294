import { Button, Card, Col, Form, Modal, Row, Select, Space, Steps } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const { Step } = Steps;

const gutter = [16, 24];

const PartnerLeadStatus = ({ setLeadCompleted, partnerLeadId, partnerLeadStates }) => {
  const { t } = useTranslation();

  const [stepSelectForm] = Form.useForm();

  const [defaultSteps, setDefaultSteps] = useState([]); // default states loaded from database

  const [componentLoading, setComponentLoading] = useState(true); // component loading - boolean
  const [steps, setSteps] = useState([]); // array of done steps
  const [currentStateIndex, setCurrentStateIndex] = useState(0); // id of current step in steps array
  const [tempStates, setTempStates] = useState([]); // temporary steps for preview (unfinished steps)
  const [tempStateId, setTempStateId] = useState(0); // temporary value of currentState
  const [disabled, setDisabled] = useState(false); // basic controls disabled
  const [selectData, setSelectData] = useState([]); // data for select - decision tree

  const [leadCanceled, setLeadCanceled] = useState(false); // lead canceled boolean

  const [stepsDone, setStepsDone] = useState([]); // array of steps - loaded from database

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    coreApi
      .get("partners/leads/state/list")
      .then((res) => {
        setDefaultSteps([...res.data]);
      })
      .catch((err) => console.log(err));

    if (partnerLeadId) {
      // if (partnerLeadStates.length === 0) {
      // 	setSteps([defaultSteps[0]]);
      // 	setCurrentStateIndex(0);
      // } else {
      // }
      setStepsDone([...stepBackFilterData(partnerLeadStates)]);
    }
  }, [partnerLeadId, partnerLeadStates]);

  useEffect(() => {
    let steps = [];
    defaultSteps.forEach((stepDef) => {
      stepsDone.forEach((stepDone) => {
        if (stepDef.state_id === stepDone.from_state || stepDef.state_id === stepDone.to_state) {
          steps = [...steps, stepDef];
        }
      });
    });

    steps = uniq(steps);
    setSteps(steps);
    setCurrentStateIndex(steps.length - 1);
  }, [stepsDone, defaultSteps]);

  // filtering array - only unique items
  const uniq = (a) => {
    return a.sort().filter(function (item, pos, ary) {
      return !pos || item !== ary[pos - 1];
    });
  };

  // filter array by another array
  const filterByArray = (array_a, array_b) => {
    return array_a.filter((item) => !array_b.includes(item));
  };

  // getting data for nextStep select
  const getDataForSelect = useCallback(() => {
    let selectData = [];
    if (currentStateIndex === 0) {
      defaultSteps.forEach((state) => {
        if (state.state_id === 2 || state.state_id === 3 || state.state_id === 4) {
          selectData.push(state);
        }
      });
    } else if (steps[currentStateIndex]?.state_id === 6) {
      defaultSteps.forEach((state) => {
        if (state.state_id === 7 || state.state_id === 8) {
          selectData.push(state);
        }
      });
    }
    return selectData.reverse();
  }, [currentStateIndex, defaultSteps, steps]);

  useEffect(() => {
    setSelectData(getDataForSelect());
    if (
      steps[currentStateIndex]?.state_id === 7 ||
      steps[currentStateIndex]?.state_id === 8 ||
      steps[currentStateIndex]?.state_id === 2
    ) {
      setDisabled(true);
    }
  }, [currentStateIndex, steps, getDataForSelect]);

  // load temp steps for preview if 2nd step already exist
  const loadTempStates = useCallback(() => {
    //check for cancel state that is not last state in list
    let cancelExists = false;
    let foundCancelState = steps.find((item) => item.state_id === 8);
    if (foundCancelState) {
      cancelExists = true;
      setLeadCanceled(true);
      setDisabled(true);
    }

    if (!cancelExists) {
      let initStep = steps[1];
      let tempSteps = [];

      if (initStep?.state_id === 3) {
        defaultSteps.forEach((step) => {
          if (step.state_id === 4 || step.state_id === 5 || step.state_id === 6) {
            tempSteps.push(step);
          }
        });
      } else if (initStep?.state_id === 4) {
        defaultSteps.forEach((step) => {
          if (step.state_id === 5 || step.state_id === 6) {
            tempSteps.push(step);
          }
        });
      }
      return tempSteps;
    } else {
      return [];
    }
  }, [defaultSteps, steps]);

  useEffect(() => {
    if (steps[0]?.state_id) {
      setComponentLoading(false);
    }

    if (steps[currentStateIndex]?.state_id === 7) {
      setLeadCompleted(true);
    }

    let temp = loadTempStates(steps);
    let _currentState = [...steps];
    let unique = filterByArray(temp, _currentState);
    setTempStates(unique);
  }, [steps, currentStateIndex, loadTempStates, setLeadCompleted]);

  // load temp steps for preview based on Select component value
  const loadSelectionPreview = useCallback(() => {
    let preview = [];
    if (tempStateId === 2) {
      preview.push(defaultSteps[1]);
    } else if (tempStateId === 3) {
      defaultSteps.forEach((state) => {
        if (state.state_id === 3 || state.state_id === 4 || state.state_id === 5 || state.state_id === 6) {
          preview.push(state);
        }
      });
    } else if (tempStateId === 4) {
      defaultSteps.forEach((state) => {
        if (state.state_id === 4 || state.state_id === 5 || state.state_id === 6) {
          preview.push(state);
        }
      });
    } else if (tempStateId === 7) {
      preview.push(defaultSteps[6]);
    } else if (tempStateId === 8) {
      preview.push(defaultSteps[7]);
    }
    return preview;
  }, [defaultSteps, tempStateId]);

  useEffect(() => {
    setTempStates(loadSelectionPreview());
  }, [tempStateId, loadSelectionPreview]);

  // cancel lead progress function
  const cancelLead = () => {
    let currentState = steps[currentStateIndex];
    let cancelState = { ...defaultSteps[defaultSteps.length - 1] };

    cancelState.status = "error";

    const payload = {
      partner_id: partnerLeadId,
      user_id: user.id,
      from_state: currentState.state_id,
      to_state: cancelState.state_id,
    };

    console.log(payload);

    // send POST request with payload
    coreApi
      .post("partners/leads/state", payload)
      .then((res) => {
        // on POST success
        setTempStates([]);
        setSteps([...steps, cancelState]);
        setCurrentStateIndex(currentState + 1);
      })
      .then((err) => console.log(err));
  };

  // search for step back data and adjust the steps array
  const stepBackFilterData = (stepsCompleted) => {
    let stepsBack = [];
    // from_state - state currently on
    // to_state - move to state with that id
    stepsCompleted.forEach((item) => {
      if (item.from_state > item.to_state) {
        stepsBack.push(item);
      }
    });

    // find steps between from_state and to_state and remove them
    stepsBack.forEach((stepBack) => {
      // find step back index in stepsCompleted array
      let stepBackIndex = stepsCompleted.indexOf(stepBack);

      // find index to go from
      let backFromIndex = stepsCompleted.findIndex((item) => stepBack.from_state === item.from_state);

      // find index to go to
      let backToIndex = stepsCompleted.findIndex((item) => stepBack.to_state === item.to_state);

      if (backFromIndex <= stepBackIndex) {
        // loop through data backwards (from - starting index, to - ending index)
        for (let i = backFromIndex; i > backToIndex; i--) {
          // remove item if currentState.from_state === stateBefore.to_state
          stepsCompleted.splice(i, 1);
        }
      }
    });
    return stepsCompleted;
  };

  // move to next step by clicking nextStep button
  const moveToNextStep = () => {
    let currentState = steps[currentStateIndex];
    let nextState = tempStates[0];

    const payload = {
      partner_id: partnerLeadId,
      user_id: user.id,
      from_state: currentState.state_id,
      to_state: nextState.state_id,
    };

    if (payload.to_state === 7) {
      setLeadCompleted(true);
    }

    // send POST request to api with payload
    coreApi
      .post("partners/leads/state", payload)
      .then((res) => {
        // on POST success
        setSteps([...steps, nextState]);
        setTempStates([tempStates.shift()]);
        setCurrentStateIndex(currentStateIndex + 1);
      })
      .then((err) => console.log(err));
  };

  const moveToPrevStep = () => {
    let currentState = steps[currentStateIndex];

    if (currentStateIndex >= 1 && currentStateIndex < steps.length) {
      let prevState = steps[currentStateIndex - 1];

      let payload = {
        partner_id: partnerLeadId,
        user_id: user.id,
        from_state: currentState.state_id,
        to_state: prevState.state_id,
      };

      coreApi
        .post("partners/leads/state", payload)
        .then((res) => {
          let temp = [...tempStates];
          temp.unshift(steps[currentStateIndex]);
          setTempStates([...temp]);
          let steps_temp = [...steps];
          steps_temp.pop();
          setSteps([...steps_temp]);
          setCurrentStateIndex(currentStateIndex - 1);
        })
        .catch((err) => console.log(err));
    }
  };

  const resetStates = () => {
    let currentState = steps[currentStateIndex];
    let nextState = defaultSteps[0];

    const payload = {
      partner_id: partnerLeadId,
      user_id: user.id,
      from_state: currentState.state_id,
      to_state: nextState.state_id,
    };

    // send POST request to api with payload
    coreApi
      .post("partners/leads/state", payload)
      .then((res) => {
        // on POST success
        setTempStates([]);
        setCurrentStateIndex(0);
        setSteps([defaultSteps[0]]);
        setLeadCompleted(false);
        setDisabled(false);
      })
      .then((err) => console.log(err));
  };

  return (
    <Row gutter={gutter}>
      <Col span={24}>
        <Card title={t("partnerLeads.state")} loading={componentLoading}>
          <Row gutter={gutter} span={24}>
            <Col span={24}>
              <Steps current={currentStateIndex}>
                {/* render steps that are done or in progress */}
                {steps.map((item) => (
                  <Step key={item?.state_id} title={t("partnerLeads.stateList." + item?.translation_key)} />
                ))}
                {/* render temps states for "preview" if exists */}
                {tempStates?.map((item) => (
                  <Step key={item?.state_id} title={t("partnerLeads.stateList." + item?.translation_key)} />
                ))}
              </Steps>
            </Col>
          </Row>
          <Form
            form={stepSelectForm}
            onFinish={async (values) => {
              // call move to next step function
              moveToNextStep();
            }}
            layout="vertical"
          >
            <Row gutter={gutter}>
              {/* State tree - decision points */}
              {steps[currentStateIndex]?.state_id === 1 || steps[currentStateIndex]?.state_id === 6 ? (
                <Col>
                  {/* Render only if not disabled */}
                  {!disabled && (
                    <Space>
                      {steps[currentStateIndex]?.state_id !== 1 && (
                        <Form.Item>
                          <Button
                            danger
                            disabled={steps.length < currentStateIndex + 1 ? true : false}
                            onClick={() => {
                              Modal.confirm({
                                title: "Opravdu chcete stav na předchozí?",
                                okText: "Ok",
                                okButtonProps: { type: "danger" },
                                onOk: () => {
                                  moveToPrevStep();
                                },
                              });
                            }}
                          >
                            Předchozí krok
                          </Button>
                        </Form.Item>
                      )}
                      <Form.Item
                        name="select_value"
                        valuePropName="option"
                        initialValue={null}
                        rules={[
                          {
                            required: true,
                            message: "Vyber postup!",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: 240 }}
                          value={null}
                          onChange={(value) => {
                            setTempStateId(value);
                          }}
                        >
                          {selectData?.map((item) => {
                            return (
                              <Select.Option value={item.state_id}>
                                {t("partnerLeads.stateList." + item.translation_key)}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type={("submit", "primary")}
                          onClick={() => {
                            Modal.confirm({
                              title: "Opravdu chcete změnit na další stav?",
                              okText: "Ok",
                              okButtonProps: { type: "primary" },
                              onOk: () => {
                                stepSelectForm.submit();
                              },
                            });
                          }}
                        >
                          {currentStateIndex + 1 === steps.length ? "Vybrat další postup" : "Vybrat uvodní stav"}
                        </Button>
                      </Form.Item>
                      {/* Render only if it isnt last step */}
                      {steps[currentStateIndex]?.state_id < 6 && (
                        <Form.Item style={{ float: "right" }}>
                          <Space>
                            <Button
                              danger
                              onClick={() => {
                                Modal.confirm({
                                  title: "Opravdu chcete přerušit lead?",
                                  okText: "Přerušit",
                                  okButtonProps: { type: "danger" },
                                  onOk: () => {
                                    cancelLead();
                                  },
                                });
                              }}
                            >
                              Přerušit lead
                            </Button>
                          </Space>
                        </Form.Item>
                      )}
                    </Space>
                  )}
                </Col>
              ) : (
                <Col span={24}>
                  {/* Render only if not disabled otherwise show reset button*/}
                  {!disabled ? (
                    <React.Fragment>
                      <Space style={{ float: "left" }}>
                        <Button
                          danger
                          onClick={() => {
                            Modal.confirm({
                              title: "Opravdu chcete stav na předchozí?",
                              okText: "Ok",
                              okButtonProps: { type: "danger" },
                              onOk: () => {
                                moveToPrevStep();
                              },
                            });
                          }}
                        >
                          Předchozí krok
                        </Button>
                        <Button
                          style={{ float: "left" }}
                          type="primary"
                          onClick={() => {
                            Modal.confirm({
                              title: "Opravdu chcete změnit stav?",
                              okText: "Ok",
                              okButtonProps: { type: "primary" },
                              onOk: () => {
                                moveToNextStep();
                              },
                            });
                          }}
                        >
                          Další krok
                        </Button>
                      </Space>
                      <Space style={{ float: "right" }}>
                        {/* show cancel lead button if not canceled already */}
                        {!leadCanceled && (
                          <Button
                            danger
                            onClick={() => {
                              Modal.confirm({
                                title: "Opravdu chcete přerušit lead?",
                                okText: "Přerušit",
                                okButtonProps: { type: "danger" },
                                onOk: () => {
                                  cancelLead();
                                },
                              });
                            }}
                          >
                            Přerušit lead
                          </Button>
                        )}
                      </Space>
                    </React.Fragment>
                  ) : (
                    <Space style={{ float: "right" }}>
                      <Button
                        danger
                        onClick={(e) => {
                          resetStates();
                        }}
                      >
                        Reset
                      </Button>
                    </Space>
                  )}
                </Col>
              )}
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default PartnerLeadStatus;
