import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Empty, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../../api/calls";

/**
 * @component
 * @alias GlobalSearch
 * @property {object} employee
 * @returns view with matched date from Elastic
 */
const GlobalSearch = () => {
  const { t } = useTranslation();

  // States
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchTermMinLength = 3;

  useEffect(() => {
    if (searchTerm.length > searchTermMinLength) {
      const delayDebounceFn = setTimeout(() => {
        setLoading(true);

        // Send Axios request here
        coreApi
          .get("/search", { params: { term: searchTerm } })
          .then((response) => {
            setSearchResults(response.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm]);

  /**
   * Render title to the view.
   *
   * @param {string} title
   * @param {string} displayed
   * @param {string} total
   * @returns
   */
  const renderTitle = (title, displayed, total) => (
    <span>{title + " (" + displayed + t("common.outOfTotal") + total + t("common.resultsFound") + ")"}</span>
  );

  /**
   * Render item with links to the view.
   *
   * @param {object} result
   * @param {object} category
   * @returns
   */
  const renderItem = (result, category) => {
    console.log(result, category);
    return {
      value: category.primary_key + result[category.primary_key],
      label: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {category.model === "BuildingUnit" ? (
            <Link to={"/buildings/" + result.building_id + "/unit/" + result[category.primary_key]}>
              {result?.name
                ? result.name
                : result?.registration_number
                ? result.registration_number
                : result?.first_name && result?.last_name
                ? result.first_name + " " + result.last_name
                : result?.street && result?.house_number
                ? result.street + " " + result.house_number
                : result?.company_name
                ? result.company_name
                : result?.id
                ? result.id
                : "undefined"}
            </Link>
          ) : (
            <Link to={"/" + category.url + "/" + result[category.primary_key]}>
              {result?.name
                ? result.name
                : result?.registration_number
                ? result?.registration_number + " " + result.reference
                : result?.first_name && result?.last_name
                ? result?.first_name + " " + result?.last_name
                : result?.street && result?.house_number
                ? result.street + " " + result.house_number
                : result?.company_name
                ? result.company_name
                : result?.email
                ? result?.email
                : result?.phone
                ? result?.phone
                : result?.id
                ? result.id
                : "undefined"}
            </Link>
          )}
        </div>
      ),
    };
  };

  return (
    <AutoComplete
      dropdownMatchSelectWidth={437}
      style={{
        width: 200,
      }}
      options={searchResults.map((category) => {
        return {
          label: renderTitle(
            t("menu." + category.translation_key) || category.model,
            [...Object.values(category.results)].length,
            category.results_count
          ),
          options: [...Object.values(category.results)].map((result) => {
            return renderItem(result, category);
          }),
        };
      })}
      notFoundContent={
        loading ? (
          <div style={{ textAlign: "center" }}>
            <Spin size={"large"}></Spin>
          </div>
        ) : (
          <Empty description={t("common.noSearchResults")}></Empty>
        )
      }
      value={searchValue}
      onChange={(value) => {
        setSearchValue(value);
      }}
      onSelect={() => {
        setSearchValue(searchTerm);
      }}
    >
      <Input
        readOnly={loading}
        allowClear
        placeholder={t("common.search")}
        suffix={loading ? <LoadingOutlined /> : false}
        prefix={
          <SearchOutlined
            className="site-form-item-icon"
            style={{ color: "rgba(0, 0, 0, 0.25)", marginRight: "2px" }}
          />
        }
        onChange={(e) => {
          if (e.target.value.length > searchTermMinLength) {
            setSearchTerm(e.target.value);
          } else {
            setSearchResults([]);
          }
        }}
      ></Input>
    </AutoComplete>
  );
};

export default GlobalSearch;
