import { Checkbox, Table, Tag } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

const TaskList = ({ taskList, completeTask }) => {
  const { t } = useTranslation();

  return (
    <Table
      pagination={false}
      dataSource={taskList}
      columns={[
        {
          title: t("tasks.name"),

          key: "name",
          render: (text) => {
            return <p>{text.slug}</p>;
          },
        },
        {
          title: t("tasks.dateDue"),
          dataIndex: "dateDue",
          key: "dateDue",
          sorter: (a, b) =>
            new Date(a.dateDue).getTime() - new Date(b.dateDue).getTime(),
          render: (dateDue) => {
            let dateFormatted = moment(dateDue);
            return <>{dateFormatted.format("LL")}</>;
          },
          defaultSortOrder: "ascend",
        },
        {
          title: t("tasks.priority"),
          key: "priority",
          dataIndex: "priority",
          render: (priority) => (
            <React.Fragment>
              {priority === "low" ? (
                <Tag color="geekblue">{t("tasks.lowPriority")}</Tag>
              ) : (
                ""
              )}
              {priority === "normal" ? (
                <Tag color="green">{t("tasks.normalPriority")}</Tag>
              ) : (
                ""
              )}
              {priority === "high" ? (
                <Tag color="volcano">{t("tasks.highPriority")}</Tag>
              ) : (
                ""
              )}
            </React.Fragment>
          ),
          sorter: (a, b) => {
            const priorityOrder = ["low", "normal", "high"];

            return (
              priorityOrder.indexOf(a.priority) -
              priorityOrder.indexOf(b.priority)
            );
          },
        },
        {
          title: t("tasks.options"),
          key: "options",
          render: (item) => {
            return (
              <Checkbox
                key={item.id}
                onClick={() => {
                  completeTask(item.id);
                }}
                value={item.id}
              >
                {t("tasks.setCompleted")}
              </Checkbox>
            );
          },
        },
      ]}
    ></Table>
  );
};

export default TaskList;
