import { Col, Row } from "antd";
import React from "react";
import AccountBankCodeInput from "./AccountBankCodeInput";
import AccountIbanInput from "./AccountIbanInput";
import AccountNumberInput from "./AccountNumberInput";
import AccountPrefixInput from "./AccountPrefixInput";
import AccountSwiftInput from "./AccountSwiftInput";

/**
 * Account Input Group Wrapper - wraps all needed Account components
 * @component
 * @alias AccountInputGroupWrapper
 * @property {object} account - object with account details
 * @property {bool} readOnly - sets Form Items read-only
 * @returns Structured content of Account in <Row(s) /> and <Col(s) />
 */
const AccountInputGroupWrapper = ({ account, readOnly }) => {
  return (
    <Row>
      <Col span={24}>
        <Row gutter={16}>
          <Col span={6}>
            <AccountPrefixInput
              initialValue={account?.account_prefix}
              readOnly={readOnly}
            />
          </Col>
          <Col span={12}>
            <AccountNumberInput
              initialValue={account?.account_number}
              readOnly={readOnly}
            />
          </Col>
          <Col span={6}>
            <AccountBankCodeInput
              initialValue={account?.account_bank_code}
              readOnly={readOnly}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={16}>
          <Col span={16}>
            <AccountIbanInput
              initialValue={account?.account_iban}
              readOnly={readOnly}
            />
          </Col>
          <Col span={8}>
            <AccountSwiftInput
              initialValue={account?.account_swift}
              readOnly={readOnly}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AccountInputGroupWrapper;
