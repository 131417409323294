import { Layout, Space } from "antd";
import React from "react";
import CityGlobalSelector from "./Cities/CityGlobalSelector";
import GlobalSearch from "./GlobalSearch/GlobalSearch";
import HeaderBarNotificationsPopper from "./HeaderBarNotificationsPopper";
import HeaderBarUserPopper from "./HeaderBarUserPopper";

const { Header } = Layout;

const headerStyle = {
  padding: "0px",
  paddingLeft: "8px",
  paddingRight: "15px",
  backgroundColor: "#fff",
  position: "fixed",
  width: "100%",
  zIndex: 99,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "60px",
  borderBottom: "0.3px solid rgba(0, 0, 0, 0.1)",
};

const HeaderBar = () => {
  return (
    <Header className="" style={headerStyle}>
      <Space style={{ float: "left", marginLeft: "64px" }}>
        <div id="pageTitle" style={{ paddingLeft: "9.9px", paddingTop: "8px" }}></div>
      </Space>
      <Space style={{ float: "right" }}>
        <GlobalSearch />
        <CityGlobalSelector />
        <HeaderBarNotificationsPopper />
        <HeaderBarUserPopper />
      </Space>
    </Header>
  );
};

export default HeaderBar;
