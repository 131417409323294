import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TaskList from "../components/TaskList";

const { Title } = Typography;

const users = [
  {
    id: 1,
    name: "Adrien Rabiot",
  },
  {
    id: 2,
    name: "Mauro Icardi",
  },
  {
    id: 3,
    name: "Cristiano Ronaldo",
  },
];

const taskListMock = [
  {
    id: 1,
    slug: "Zkontrolovat reporting",
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Fusce consectetuer risus a nunc. ",
    dateDue: "2020-07-21",
    priority: "low",
    checked: false,
  },
  {
    id: 2,
    slug: "Zkontrolovat transakce",
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Fusce consectetuer risus a nunc. ",
    dateDue: "2020-07-26",
    priority: "normal",
    checked: false,
  },
  {
    id: 3,
    slug: "Zkontrolovat faktury",
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Fusce consectetuer risus a nunc. ",
    dateDue: "2020-07-25",
    priority: "normal",
    checked: false,
  },
];

const TasksIndex = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [taskList, updateTaskList] = useState(taskListMock);

  const [form] = Form.useForm();

  const addTask = (values) => {
    const newTask = {
      id: Math.random(),
      slug: values.name,
      description: values.description,
      dateDue: values.dateDue.format("YYYY-MM-DD"),
      priority: values.priority,
      checked: false,
    };
    updateTaskList([...taskList, newTask]);
    closeModal();
    form.resetFields();
  };

  const completeTask = (taskId) => {
    const newTaskList = taskList.filter((task) => {
      return task.id !== taskId;
    });

    updateTaskList(newTaskList);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title>{t("tasks.tasksOverviewHeader")}</Title>
      <Row gutter={[16, 24]}>
        <Col className="gutter-row" span={24}>
          <TaskList taskList={taskList} completeTask={completeTask}></TaskList>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col className="gutter-row" span={24}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            onClick={openModal}
          >
            {t("tasks.addTask")}
          </Button>
          <Modal
            title={t("tasks.addTask")}
            onCancel={closeModal}
            open={isModalOpen}
            onOk={form.submit}
            okText={t("common.create")}
          >
            <Form form={form} onFinish={addTask} layout="vertical">
              <Form.Item
                name={"name"}
                label={t("tasks.name")}
                rules={[{ required: true }]}
              >
                <Input form={form}></Input>
              </Form.Item>
              <Form.Item name={"description"} label={t("tasks.description")}>
                <TextArea />
              </Form.Item>
              <Form.Item
                name={"dateDue"}
                label={t("tasks.dateDue")}
                rules={[{ required: true }]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                name={"priority"}
                label={t("tasks.priority")}
                rules={[{ required: true }]}
              >
                <Select>
                  <Select.Option value="low">
                    {t("tasks.lowPriority")}
                  </Select.Option>
                  <Select.Option value="normal">
                    {t("tasks.normalPriority")}
                  </Select.Option>
                  <Select.Option value="high">
                    {t("tasks.highPriority")}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name={"assignedUsers"}
                label={t("tasks.assignedUsers")}
                rules={[{ required: true }]}
              >
                <Select mode="multiple">
                  {users.map((user) => {
                    return (
                      <Select.Option key={user.id} value={user.id}>
                        {user.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Modal>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TasksIndex;
