import { Form, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import {setAreaScope} from "../../actions";

/**
 * City Selector
 * @component
 * @alias CitySelector
 * @property {number} initialValue - id of a city_branch
 * @property {bool} required - sets Form Item required
 * @property {bool} readOnly - sets Form Item read-only
 * @returns <Select />
 */
const CitySelector = ({ initialValue, required, readOnly, name }) => {
  const { t } = useTranslation();

  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    coreApi
      .get("/cities")
      .then((response) => {
        if (isMounted) {
          setCities(response.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Form.Item
      hasFeedback
      name={name || "city_branch_id"}
      label={t("expansion.cityBranch")}
      rules={[
        {
          required: required,
          message: t("expansion.validation.city.required"),
        },
      ]}
      initialValue={initialValue}
    >
      <Select
        autoComplete={"off"}
        loading={loading}
        disabled={readOnly}
        bordered={!readOnly}
        showSearch
        placeholder={t("expansion.selectCity")}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          localStorage.setItem("city", value);
        }}
      >
        {cities.map((city) => {
          return (
            <Select.Option key={city?.id} value={city?.id}>
              {city?.name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default CitySelector;
