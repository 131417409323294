import { Table, Tag } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "./../../api/calls";

const OfferList = () => {
  const { t } = useTranslation();

  const [offers, setOffers] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState();
  const [filters, setFilters] = useState({});

  const [states, setStates] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [cities, setCities] = useState([]);

  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    axios
      .all([coreApi.get("offers/states"), coreApi.get("employees/all"), coreApi.get("cities")])
      .then(
        axios.spread((states, employees, cities) => {
          let filteredStates = states.data.filter(
            (state) => state.state_id === 1 || state.state_id === 2 || state.state_id === 3
          );
          setStates([...filteredStates]);
          setEmployees([...employees.data]);
          setCities([...cities.data]);
        })
      )
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setDataLoading(true);
    coreApi
      .get("/offers", {
        params: {
          ...filters,
        },
      })
      .then((res) => {
        let { data, ...pagination } = res.data;
        setOffers([...data]);
        setPaginationInfo(pagination);
      })
      .catch((err) => console.log(err))
      .finally(() => setDataLoading(false));
  }, [filters]);

  const handleOfferStateFilter = (value, pagination) => {
    if (value !== null) {
      return {
        // ...filters,
        offer_state_id: value[0],
        // page: pagination.current,
      };
    } else {
      return { page: pagination.current };
    }
  };

  const handleEmployeeFilter = (value, pagination) => {
    if (value !== null) {
      return {
        // ...filters,
        employee_id: value[0],
        // page: pagination.current,
      };
    } else {
      return { page: pagination.current };
    }
  };

  const handleCityFilter = (value, pagination) => {
    if (value !== null) {
      return {
        // ...filters,
        city_branch_id: value[0],
        //page: pagination.current,
      };
    } else {
      return { page: pagination.current };
    }
  };

  const handleFilters = (filter, pagination) => {
    let employee = handleEmployeeFilter(filter["employee_id"], pagination);
    let city = handleCityFilter(filter["city_branch_id"], pagination);
    let offer = handleOfferStateFilter(filter["offer_state_id"], pagination);

    setFilters({ ...employee, ...city, ...offer });
  };

  const offerColumns = [
    {
      title: t("offers.dateIssued"),
      key: "date_issued",
      dataIndex: "date_issued",
      render: (date) => moment(date).format("DD.MM.YYYY"),
    },
    {
      title: t("offers.headerClientName"),
      key: "header_client_name",
      render: (rowData) => <Link to={"clients/" + rowData?.client_id}>{rowData?.header_client_name}</Link>,
    },
    {
      title: t("offers.building"),
      key: "building?.id",
      ellipsis: true,
      render: (rowData) => <Link to={"buildings/" + rowData?.building?.id}>{rowData?.building?.reference}</Link>,
    },
    {
      title: t("offers.totalPrice"),
      key: "price",
      render: (rowData) => {
        return (
          <Link to={rowData.date_signed ? "/orders/" + rowData.id : "/offers/" + rowData.id}>
            {new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: rowData.currency_iso_4217 || "CZK",
            }).format(rowData.price)}
          </Link>
        );
      },
    },
    {
      title: t("offers.state"),
      key: "offer_state_id",
      render: (rowData) => <Tag>{t("offers.states." + rowData.state.translation_key)}</Tag>,
      filters: [
        ...states?.map((state) => ({
          text: t("offers.states." + state?.translation_key),
          value: state?.state_id,
        })),
      ],
      filterMultiple: false,
    },
    {
      title: t("employees.employee"),
      key: "employee_id",
      render: (rowData) => (
        <Link to={"employees/" + rowData?.user?.employee?.employee_id}>
          {rowData?.user?.employee?.first_name + " " + rowData?.user?.employee?.last_name}
        </Link>
      ),
      filters: [
        ...employees?.map((employee) => ({
          text: employee.first_name + " " + employee.last_name,
          value: employee.id,
        })),
      ],
      filterMultiple: false,
    },
    {
      title: t("common.cityBranch"),
      key: "city_branch_id",
      render: (rowData) => (
        <Link to={"cities/" + rowData?.building?.city_branch?.id}>{rowData?.building?.city_branch?.name}</Link>
      ),
      filters: [
        ...cities?.map((city) => ({
          text: city.name,
          value: city?.id,
        })),
      ],
      filterMultiple: false,
    },
    {
      title: t("offers.notes"),
      key: "notes",
      dataIndex: "notes",
    },
  ];

  return (
    <Table
      size={"small"}
      loading={dataLoading}
      dataSource={offers}
      columns={offerColumns}
      rowKey={"id"}
      pagination={{
        current: paginationInfo?.current_page,
        total: paginationInfo?.total,
        pageSize: paginationInfo?.per_page,
        showSizeChanger: true,
        showTotal: (total, range) => (
          <p>
            {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
            {" " + t("common.paginationPartTwo") + " "}
            <strong>{total}</strong> {t("common.paginationPartThree")}
          </p>
        ),
      }}
      onChange={(pagination, filter) => {
        handleFilters(filter, pagination);
      }}
    ></Table>
  );
};

export default OfferList;
