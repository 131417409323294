import { Col, Form, Input, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const TransactionProfilePane = ({ transaction }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Form layout={"horizontal"}>
        <Row gutter={16}>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item label={t("finance.transactions.transactionNumber")}>
              <Input value={transaction?.id} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item label={t("finance.transactions.amount")}>
              <Input
                value={new Intl.NumberFormat("cs-CZ", {
                  style: "currency",
                  currency: transaction?.currency_iso_4217 || "CZK",
                }).format(transaction?.amount)}
                readOnly={true}
                bordered={false}
              ></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item label={t("finance.transactions.date")}>
              <Input value={transaction?.date_transaction} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item label={t("finance.transactions.accountNumberFrom")}>
              <Input value={transaction?.account_number_from} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item label={t("finance.transactions.accountBankCodeFrom")}>
              <Input value={transaction?.bank_code_from} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <Form.Item label={t("finance.transactions.swiftFrom")}>
              <Input value={transaction?.swift_from} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label={t("finance.transactions.accountNameFrom")}>
              <Input value={transaction?.account_name_from} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label={t("finance.transactions.message")}>
              <Input value={transaction?.message} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label={t("finance.transactions.userIdentification")}>
              <Input value={transaction?.user_identification} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item label={t("finance.transactions.variableSymbol")}>
              <Input value={transaction?.variable_symbol} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item label={t("finance.transactions.constantSymbol")}>
              <Input value={transaction?.constant_symbol} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item label={t("finance.transactions.specificSymbol")}>
              <Input value={transaction?.specific_symbol} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item label={t("finance.transactions.transactionType")}>
              <Input value={transaction?.transaction_type} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item label={t("finance.transactions.authority")}>
              <Input value={transaction?.authority} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label={t("finance.transactions.comment")}>
              <Input value={transaction?.comment} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item label={t("finance.transactions.idOrder")}>
              <Input value={transaction?.order_id} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item label={t("finance.transactions.reference")}>
              <Input value={transaction?.reference} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item label={t("finance.transactions.idAccount")}>
              <Input value={transaction?.account_id} readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default TransactionProfilePane;
