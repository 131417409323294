import { Col, Form, Input, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Account Name Abbreviation Wrapper - contains Account Name and Account Abbreviation fields
 * @component
 * @alias AccountNameAbbrWrapper
 * @property {object} account - object that contains account details
 * @property {bool} readOnly - sets fields read only
 * @returns Structured AccountName and AccountAbbr fields in <Row(s) /> and <Col(s) />
 */

const AccountNameAbbrWrapper = ({ account, readOnly }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={16}>
      <Col span={18}>
        <Form.Item
          label={t("finance.accounts.accountName")}
          name={"name"}
          initialValue={account?.name}
          hasFeedback
          rules={[
            {
              max: 45,
              required: true,
            },
          ]}
        >
          <Input readOnly={readOnly} bordered={!readOnly}></Input>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label={t("finance.accounts.accountAbbr")}
          name={"account_abbr"}
          initialValue={account?.account_abbr}
          hasFeedback
          rules={[
            {
              max: 10,
              required: true,
              message: t("finance.accounts.validation.accountAbbr"),
            },
          ]}
        >
          <Input readOnly={readOnly} bordered={!readOnly}></Input>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default AccountNameAbbrWrapper;
