import { useTranslation } from "react-i18next";
import { Card, Col, Row, Tabs, Typography, Space } from "antd";
import { coreApi } from "../api/calls";
import React, { useEffect, useState } from "react";
import ContactDetails from "../components/Contacts/ContactDetails";
import ContactBuildingsListPane from "../components/Contacts/ContactBuildingsListPane";
import { MailTwoTone, PhoneTwoTone } from "@ant-design/icons";
import CustomTabs from "../components/custom/CustomTabs";

const { Title } = Typography;

const ContactProfile = (props) => {
  const { t } = useTranslation();

  const [contact, setContact] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    coreApi
      .get("contacts/" + props.match.params.id)
      .then((res) => setContact(res.data))
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, [props.match.params.id]);

  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Card loading={isLoading}>
            <Row gutter={[0, 24]} style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Title>
                  {!contact?.first_name && !contact?.last_name
                    ? contact?.company_name
                      ? contact.company_name
                      : t("common.empty")
                    : (contact?.first_name || "") + " " + (contact?.last_name || "")}
                </Title>

                {(contact.email || contact.phone) && (
                  <Space>
                    {contact.email && (
                      <Title level={5} type="secondary">
                        <a href={"mailto:" + contact?.email}>
                          <Space size={[6, 12]}>
                            <MailTwoTone />
                            {contact?.email}
                          </Space>
                        </a>
                      </Title>
                    )}

                    {contact.phone && (
                      <Title level={5} type="secondary">
                        <a href={"tel:" + contact?.phone}>
                          <Space size={[6, 12]}>
                            <PhoneTwoTone />
                            {contact?.phone}
                          </Space>
                        </a>
                      </Title>
                    )}
                  </Space>
                )}
              </div>
            </Row>
            <CustomTabs props={props}>
              <Tabs.TabPane tab={t("contacts.overview")} key="#overview">
                <ContactDetails contact={contact} setContact={setContact}></ContactDetails>
              </Tabs.TabPane>
              <Tabs.TabPane tab={t("contacts.buildingUnits")} key="#units">
                <ContactBuildingsListPane
                  tags={contact?.tags}
                  buildings={contact?.buildings}
                  buildingUnits={contact?.building_units}
                ></ContactBuildingsListPane>
              </Tabs.TabPane>
            </CustomTabs>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ContactProfile;
