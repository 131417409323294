import { Col, Divider, Form, Input, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const InvoiceSupplierPane = ({
  readOnly,
  name,
  companyNumber,
  vatNumber,
  address,
  postalCode,
  city,
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Divider orientation="left">{t("finance.invoices.supplier")}</Divider>
      <Form.Item
        name="supplier_name"
        label={t("finance.invoices.supplierName")}
        initialValue={name}
      >
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="supplier_company_number"
            label={t("finance.invoices.supplierCompanyNumber")}
            initialValue={companyNumber}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="supplier_vat_number"
            label={t("finance.invoices.supplierVatNumber")}
            initialValue={vatNumber}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="supplier_address"
        label={t("finance.invoices.supplierAddress")}
        initialValue={address}
      >
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="supplier_postal_code"
            label={t("finance.invoices.supplierPostalCode")}
            initialValue={postalCode}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            name="supplier_city"
            label={t("finance.invoices.supplierCity")}
            initialValue={city}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InvoiceSupplierPane;
