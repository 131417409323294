import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ScheduleFirstStepMassEdit = ({ form, moveToSecondStep }) => {
  const { t } = useTranslation();

  const [nextStepEnabled, setNextStepEnabled] = useState(
    form.getFieldValue("date_terminate")
  );

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Form.Item
          name={"date_terminate"}
          label={t("schedules.dateTo")}
          required
        >
          <DatePicker
            onChange={(value) => {
              value ? setNextStepEnabled(true) : setNextStepEnabled(false);
            }}
            disabledDate={(current) =>
              current < moment().startOf("month") ||
              current === moment() ||
              current.format("YYYY-MM-DD") !==
                moment(current).endOf("month").format("YYYY-MM-DD")
            }
          />
        </Form.Item>
        <Typography.Paragraph type={"secondary"}>
          {t("schedules.terminateOnlyOnEndOfMonth")}
        </Typography.Paragraph>
      </Col>
      <Col span={12}></Col>
      <Col span={12}>
        <Button
          type="primary"
          disabled={!nextStepEnabled}
          block
          onClick={() => {
            moveToSecondStep();
          }}
        >
          {t("common.next")}
        </Button>
      </Col>
    </Row>
  );
};

export default ScheduleFirstStepMassEdit;
