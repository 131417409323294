import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


const FrequencySelect = ({
  label,
  name,
  initialValue,
  disabledOption,
  readOnly,
  required,
  validationMessage,
  size,
  onChange = null,
}) => {
  const { t } = useTranslation();

  const [loading] = useState(false);
  const [frequencies, setFrequency] = useState([]);

  useEffect(() => {
    let isMounted = true;
    setFrequency([
      { id: "1x TÝDNĚ",   name: t("offers.frequencies.1xWEEKLY"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO" },
      { id: "2x TÝDNĚ",   name: t("offers.frequencies.2xWEEKLY"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TH" },
      { id: "3x TÝDNĚ",   name: t("offers.frequencies.3xWEEKLY"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,WE,FR" },
      { id: "4x TÝDNĚ",   name: t("offers.frequencies.4xWEEKLY"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TH,SA,SU" },
      { id: "5x TÝDNĚ",   name: t("offers.frequencies.5xWEEKLY"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TH,SA,SU,WE" },
      { id: "6x TÝDNĚ",   name: t("offers.frequencies.6xWEEKLY"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TH,SA,SU,WE,FR" },
      { id: "7x TÝDNĚ",   name: t("offers.frequencies.7xWEEKLY"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TH,SA,SU,WE,FR,TU" },
      { id: "1x 14 DNÍ",  name: t("offers.frequencies.1x14DAYS"), rrule_blueprint: "FREQ=WEEKLY;INTERVAL=2;BYDAY=MO" },
      { id: "1x MĚSÍČNĚ", name: t("offers.frequencies.1xMONTHLY"),rrule_blueprint: "FREQ=MONTHLY;INTERVAL=1;BYMONTHDAY=1" },
      { id: "4x ROČNĚ",   name: t("offers.frequencies.4xYEARLY"), rrule_blueprint: "FREQ=YEARLY;INTERVAL=1;BYMONTH=1,4,7,10;BYMONTHDAY=1" },
      { id: "2x ROČNĚ",   name: t("offers.frequencies.2xYEARLY"), rrule_blueprint: "FREQ=YEARLY;INTERVAL=1;BYMONTH=1,7;BYMONTHDAY=1" },
      { id: "1x ROČNĚ",   name: t("offers.frequencies.1xYEARLY"), rrule_blueprint: "FREQ=YEARLY;INTERVAL=1;BYMONTH=1;BYMONTHDAY=1" }
    ]);
    return () => {
      isMounted = false;
    };
  }, []);

  const handleClear = () => {
    onChange && onChange(null);
  };
  const handlePartnerChange = (frequency) => {
    const selectedFrequency = frequencies.find((freq) => freq.id === frequency);
    onChange && onChange(selectedFrequency);
  };

  return (
    <Form.Item
      hasFeedback
      name={name}
      label={label}
      initialValue={initialValue}
      valuePropName={"value"}
      rules={[
        {
          required: required,
          message: validationMessage,
        },
      ]}
    >
      <Select
        size={size}
        allowClear
        disabled={readOnly}
        bordered={!readOnly}
        loading={loading}
        showSearch={true}
        placeholder={t("schedules.frequancy")}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={handlePartnerChange}
        onClear={handleClear}
      >
        {frequencies.map((frequency) => {
          return (
            <Select.Option
              key={frequency.id}
              value={frequency.id}
              disabled={disabledOption && frequency.id === disabledOption}
            >
              {`${frequency.name}`}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default FrequencySelect;
