import { Button, Col, Divider, Form, Input, Modal, notification, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const UserChangePasswordForm = ({ user }) => {
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const [passwordForm] = Form.useForm();

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const inputWidth = { width: "250px" };

  return (
    <React.Fragment>
      <Button
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t("users.changePassword")}
      </Button>
      <Modal
        open={isModalOpen}
        title={t("users.changePassword")}
        onOk={() => {
          passwordForm.submit();
        }}
        onCancel={() => {
          setModalOpen(false);
          passwordForm.resetFields();
        }}
        okButtonProps={{ loading: submitting }}
      >
        <Form
          form={passwordForm}
          onFinish={(values) => {
            setSubmitting(true);
            coreApi
              .put("/users/password-change", values)
              .then((response) => {
                setModalOpen(false);
                passwordForm.resetFields();
                notification.success({ message: response.data.message });
              })
              .catch((error) => {
                notification.error({ message: error?.response?.data?.message });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          <Row gutter={16}>
            <Form.Item name={"user_id"} initialValue={user.id}>
              <Input hidden></Input>
            </Form.Item>
            {currentUser.is_admin ? (
              ""
            ) : (
              <React.Fragment>
                <Col span={24}>
                  <Form.Item name={"password"} label={t("users.password")} rules={[{ required: true }]}>
                    <Input.Password style={inputWidth}></Input.Password>
                  </Form.Item>
                </Col>
                <Divider></Divider>
              </React.Fragment>
            )}

            <Col span={24}>
              <Form.Item
                name={"new_password"}
                label={t("users.newPassword")}
                rules={[{ required: true, min: 12 }]}
                hasFeedback
              >
                <Input.Password style={inputWidth}></Input.Password>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={"new_password_copy"}
                label={t("users.newPasswordCopy")}
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t("users.passwordsMustMatch")));
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password style={inputWidth}></Input.Password>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default UserChangePasswordForm;
