import { combineReducers } from "redux";
import areaScope from "./areaScope";
import responseStatusHandler from "./responseStatusHandler";
import logoutHandler from "./logoutHandler";

export default combineReducers({
  areaScope,
  responseStatusHandler,
  logoutHandler
});
