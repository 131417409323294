import { Col, DatePicker, Divider, Form, notification, Row, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import ProfileFormActionSpace from "../ProfileFormActionSpace";
import AccountAccessTokenInput from "./AccountAccessTokenInput";
import AccountChangeTokenModal from "./AccountChangeTokenModal";
import AccountControlTokenInput from "./AccountControlTokenInput";
import AccountInputGroupWrapper from "./AccountInputGroupWrapper";
import AccountNameAbbrWrapper from "./AccountNameAbbrWrapper";

/**
 * Account Profile Pane
 * @component
 * @alias AccountProfilePane
 * @property {object} account - object containing accout details
 * @returns Structured content used for profile pane
 */
const AccountProfilePane = ({ account }) => {
  const { t } = useTranslation();

  const [isAccountFormReadOnly, setAccountFormReadOnly] = useState(true);
  const [isAccountFormSubmitting, setAccountFormSubmitting] = useState(false);

  const [accountForm] = Form.useForm();

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <ProfileFormActionSpace
            isFormReadOnly={isAccountFormReadOnly}
            setFormEditable={() => {
              setAccountFormReadOnly(false);
            }}
            setFormDisabled={() => {
              setAccountFormReadOnly(true);
            }}
            formInstance={accountForm}
            isFormSubmitting={isAccountFormSubmitting}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            form={accountForm}
            layout={"vertical"}
            onFinish={(values) => {
              setAccountFormSubmitting(true);
              coreApi
                .put("/accounts/" + account?.id, values)
                .then((response) => {
                  accountForm.setFieldsValue(response.data.account);
                  notification.success({
                    message: response.data.message,
                  });
                })
                .catch((error) => {
                  notification.error({
                    message: error.response.data.message,
                  });
                })
                .finally(() => {
                  setAccountFormSubmitting(false);
                });
            }}
          >
            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <Divider orientation={"left"}>{t("finance.accounts.generalInformation")}</Divider>
                <AccountNameAbbrWrapper account={account} readOnly={isAccountFormReadOnly} />

                <Divider orientation={"left"}>{t("finance.accounts.tokens")}</Divider>

                <Row gutter={[16, 16]}>
                  <Col span={12} style={{ marginBottom: "12px" }}>
                    <DatePicker
                      style={{ width: "100%" }}
                      value={account?.access_token_valid_until ? moment(account?.access_token_valid_until) : undefined}
                      placeholder={t("finance.accounts.accessTokenValidUntil")}
                      disabled
                    />
                  </Col>

                  <Col span={12}>
                    <Tooltip title={t("finance.accounts.tokenChangeWarning")}>
                      <AccountChangeTokenModal
                        label={t("finance.accounts.changeAccessToken")}
                        path={"/accounts/access-token/" + account?.id}
                      >
                        <AccountAccessTokenInput required />
                      </AccountChangeTokenModal>
                    </Tooltip>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <DatePicker
                      style={{ width: "100%" }}
                      value={
                        account?.control_token_valid_until ? moment(account?.control_token_valid_until) : undefined
                      }
                      placeholder={t("finance.accounts.controlTokenValidUntil")}
                      disabled
                    />
                  </Col>
                  <Col span={12}>
                    <Tooltip title={t("finance.accounts.tokenChangeWarning")}>
                      <AccountChangeTokenModal
                        label={t("finance.accounts.changeControlToken")}
                        path={"/accounts/control-token/" + account?.id}
                      >
                        <AccountControlTokenInput required />
                      </AccountChangeTokenModal>
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} lg={12}>
                <Divider orientation={"left"}>{t("finance.accounts.bankingInformation")}</Divider>
                <AccountInputGroupWrapper account={account} readOnly={isAccountFormReadOnly} />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AccountProfilePane;
