import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Descriptions, Form, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import EmailInputWithValidation from "../custom/Form/EmailInputWithValidation";

const gutter = [16, 0];

const PartnerLeadConvertForm = ({
  convertPartnerLeadFunction,
  convertModalOpen,
  setConvertModalOpen,
  partnerLeadData,
}) => {
  const { t } = useTranslation();
  const [isExternalDataFetching, setExternalDataFetching] = useState(false);
  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const [addContactForm] = Form.useForm();
  const [form] = Form.useForm();
  const loadExternalData = (companyNumber) => {
    setExternalDataFetching(true);

    coreApi.get("/partners/get-public-data/" + companyNumber).then((response) => {
      setExternalDataFetching(false);

      form.setFieldsValue({
        company_name: response.data.company_name,
        billing_address_city: response.data.city,
        billing_address_street: response.data.street,
        billing_address_postal_code: response.data.postal_code,
        billing_address_house_number: response.data.house_number,
      });
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      first_name: partnerLeadData.first_name,
      last_name: partnerLeadData.last_name,
      email: partnerLeadData.email,
      phone: partnerLeadData.phone,
    });
  }, [partnerLeadData, form]);

  return (
    <React.Fragment>
      <Modal
        width={600}
        title={t("partnerLeads.convertToPartner")}
        open={convertModalOpen}
        onCancel={() => {
          setConvertModalOpen(false);
        }}
        okText={t("common.add")}
        okButtonProps={{ loading: isFormSubmitting }}
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={async (values) => {
            setFormSubmitting(true);
            await convertPartnerLeadFunction(values);
            setFormSubmitting(false);
            setConvertModalOpen(false);
          }}
          name={"addPartnerForm"}
        >
          <Row gutter={gutter}>
            <Col span={12}>
              <Form.Item label={t("common.firstName")} name="first_name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t("common.lastName")} name="last_name">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={t("partners.identificationNumber")}
            name="company_number"
            rules={[
              {
                required: true,
                message: t("partners.validation.companyNumber"),
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item>
            <Button
              loading={isExternalDataFetching}
              onClick={() => {
                loadExternalData(form.getFieldValue("company_number"));
              }}
              size="small"
            >
              {t("partners.loadExternalData")}
            </Button>
          </Form.Item>
          <Form.Item
            label={t("partners.companyName")}
            name="company_name"
            rules={[
              {
                required: true,
                message: t("partners.validation.companyName"),
              },
            ]}
          >
            <Input></Input>
          </Form.Item>

          <Row gutter={gutter}>
            <Col span={12}>
              <EmailInputWithValidation
                form={addContactForm}
                name="email"
                label={t("common.email")}
                message={t("common.emailValidation")}
              />
            </Col>
            <Col span={12}>
              <Form.Item label={t("common.phone")} name="phone">
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={t("common.billingAddress")}
            rules={[
              {
                required: true,
                message: t("partners.validation.billingAddress"),
              },
            ]}
          >
            <Row gutter={gutter}>
              <Col span={16}>
                <Form.Item required name="billing_address_street">
                  <Input placeholder={t("common.billingAddressStreet")}></Input>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item required name="billing_address_house_number">
                  <Input placeholder={t("common.billingAddressHouseNumber")}></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col span={8}>
                <Form.Item required name="billing_address_postal_code">
                  <Input placeholder={t("common.billingAddressPostalCode")}></Input>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item required name="billing_address_city">
                  <Input placeholder={t("common.billingAddressCity")}></Input>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Descriptions title={t("partners.workers")}></Descriptions>
          <Form.List name="workers">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div key={index}>
                      <Row gutter={gutter}>
                        <Col span={7}>
                          <Form.Item required name={[field.name, "first_name"]}>
                            <Input required placeholder={t("common.firstName")}></Input>
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item required name={[field.name, "last_name"]}>
                            <Input placeholder={t("common.lastName")}></Input>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item name={[field.name, "contract_from"]}>
                            <DatePicker placeholder={t("partners.contractFrom")}></DatePicker>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item name={[field.name, "contract_to"]}>
                            <DatePicker placeholder={t("partners.contractTo")}></DatePicker>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={gutter}>
                        <Col span={9}>
                          <EmailInputWithValidation
                            form={addContactForm}
                            name={[field.name, "email"]}
                            message={t("common.emailValidation")}
                            placeholder={t("common.email")}
                          />
                        </Col>
                        <Col span={9}>
                          <Form.Item name={[field.name, "phone"]}>
                            <Input placeholder={t("common.phone")}></Input>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Button
                            danger
                            type="dashed"
                            onClick={() => {
                              remove(field.name);
                            }}
                            block
                          >
                            <MinusCircleOutlined />
                            {t("common.remove")}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined />
                      {t("partners.addNewWorker")}
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default PartnerLeadConvertForm;
