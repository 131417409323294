import { TeamOutlined } from "@ant-design/icons";
import { Button, Col, notification } from "antd";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const PartnerExportButton = ({ exportAll, idCityBranch, cityName, primary = false, dateFrom, dateTo }) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  return (
    <Col span={24}>
      <Button
        type={primary ? "primary" : "none"}
        loading={isLoading}
        icon={<TeamOutlined />}
        style={{ width: "100%" }}
        onClick={() => {
          setLoading(true);
          coreApi
            .get("/partners/export/" + idCityBranch, {
              responseType: "blob",
              params: {
                exportAll: exportAll,
                dateFrom,
                dateTo,
              },
            })
            .then((response) => {
              fileDownload(response.data, cityName !== "" ? cityName + "-partners-export.csv" : "partners-export.csv");
            })
            .catch((err) => notification.error({ message: err.response.data.message }))
            .finally(() => setLoading(false));
        }}
      >
        {t("exports.partnerExport")}
      </Button>
    </Col>
  );
};

export default PartnerExportButton;
