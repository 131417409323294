import { Card, Col, Row } from "antd";
import React from "react";
import PaymentsForApprovalPane from "../components/Transactions/PaymentsForApprovalPane";

const PaymentsForApprovalIndex = () => {
  return (
    <React.Fragment>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Card>
            <PaymentsForApprovalPane />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PaymentsForApprovalIndex;
