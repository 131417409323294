import React, { useState, useRef } from 'react';
import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { coreApi } from "../api/calls";
import EmailInputWithValidation from "./custom/Form/EmailInputWithValidation";

const gutter = [16, 4];

const ContactAddForm = ({
                          onFinishAction,
                          tags,
                          addingInSelect = false,
                          contactable = null,
                          addExisting = () => {},
                        }) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [addContactForm] = Form.useForm();
  const [addExistingContactForm] = Form.useForm();

  const [isLoading, setLoading] = useState(false);

  const [formToSend, setFormToSend] = useState(addingInSelect ? 2 : 1);

  const [contacts, setContacts] = useState([]);
  const emailInputRef = useRef(null);
  const formRef = useRef();

  const getRequestSeachContacts = (searchTerm) => {
    setLoading(true);
    coreApi
      .get("contacts/search", {
        params: {
          search: searchTerm,
        },
      })
      .then((res) => {
        setContacts(() => [...res.data]);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const AddButton = () => {
    return addingInSelect ? (
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          padding: 8,
        }}
      >
        <button
          style={{
            flex: "none",
            width: "100%",
            border: "none",
            display: "block",
            cursor: "pointer",
          }}
          onClick={() => setModalOpen(true)}
        >
          <PlusOutlined /> {t("common.add")}
        </button>
      </div>
    ) : (
      <Button
        size="large"
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t("contacts.addContact")}
      </Button>
    );
  };

  return (
    <React.Fragment>
      <AddButton />
      <Modal
        title={t("contacts.addContact")}
        okText={t("contacts.addContact")}
        open={isModalOpen}
        onOk={formToSend <= 1 ? addExistingContactForm.submit : addContactForm.submit}
        layout="vertical"
        onCancel={() => setModalOpen(false)}
      >
        <Tabs onChange={(value) => setFormToSend(value)}>
          {!addingInSelect && (
            <Tabs.TabPane tab={t("contacts.addExisting")} key="1">
              <Form
                form={addExistingContactForm}
                layout="vertical"
                autoComplete="off"
                onFinish={async (values) => {
                  await addExisting(values);
                  addExistingContactForm.resetFields();
                  setModalOpen(false);
                }}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item name="contact_id">
                      <Select
                        loading={isLoading}
                        placeholder={t("buildingUnits.searchContact")}
                        showSearch={true}
                        filterOption={false}
                        onSearch={async (e) => {
                          e.length > 2 && getRequestSeachContacts(e);
                        }}
                        style={{ width: "100%" }}
                      >
                        {contacts.map((contact) => (
                          <Select.Option value={contact?.id} key={contact?.id}>
                            {contact.first_name + " " + contact.last_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={gutter}>
                  <Col span={8}>
                    <Form.Item name="tags" valuePropName="checked">
                      <Checkbox.Group style={{ width: "100%" }}>
                        {tags.map((tag) => {
                          return (
                            <Row key={tag?.id}>
                              <Col span={8}>
                                <Checkbox value={tag?.id}>{t(`contacts.${tag?.key}`)}</Checkbox>
                              </Col>
                            </Row>
                          );
                        })}
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Tabs.TabPane>
          )}

          {(contactable?.type === "building" || addingInSelect) && (
            <Tabs.TabPane tab={t("contacts.addContact")} key="2">
              <Form
                layout="vertical"
                autoComplete="off"
                form={addContactForm}
                ref={formRef}
                onFinish={async (values) => {
                    if (emailInputRef.current) {
                      if (await emailInputRef.current.validateEmail()) {
                        onFinishAction(values);
                        addContactForm.resetFields();
                        setModalOpen(false);
                      }
                    }
                }}
              >
                <Row gutter={gutter}>
                  <Col span={12}>
                    <Form.Item name={"first_name"} label={t("contacts.firstName")}>
                      <Input></Input>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name={"last_name"} label={t("contacts.lastName")}>
                      <Input></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={gutter}>
                  <Col span={24}>
                    <Form.Item
                      name={"company_name"}
                      label={t("contacts.companyName")}
                    >
                      <Input></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={gutter}>
                  <Col span={12}>
                    <EmailInputWithValidation
                      form={addContactForm}
                      name="email"
                      label={t("contacts.email")}
                      message={t("common.emailValidation")}
                      ref={emailInputRef}
                      validationOnEnd={true}
                      formRef={formRef}
                    />
                  </Col>
                  <Col span={12}>
                    <Form.Item name={"phone"} label={t("contacts.phone")}>
                      <Input></Input>
                    </Form.Item>
                  </Col>
                </Row>
                {!addingInSelect && (
                  <Row gutter={gutter}>
                    <Col span={8}>
                      <Form.Item name="tags" valuePropName="checked">
                        <Checkbox.Group style={{ width: "100%" }}>
                          {tags.map((tag) => {
                            return (
                              <Row key={tag?.id}>
                                <Col span={8}>
                                  <Checkbox value={tag?.id}>{t(`contacts.${tag?.key}`)}</Checkbox>
                                </Col>
                              </Row>
                            );
                          })}
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row gutter={gutter}>
                  <Col span={24}>
                    <Form.Item name={"notes"} label={t("contacts.notesInfo")}>
                      <Input.TextArea />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Tabs.TabPane>
          )}
        </Tabs>
      </Modal>
    </React.Fragment>
  );
};

export default ContactAddForm;
