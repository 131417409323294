import { Checkbox, Col, DatePicker, Form, Input, Modal, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import WorkerSelect from "./WorkerSelect";

const PartnerLinkWorkerForm = ({ isOpen, close, linkWorker, idContractor, submitting }) => {
  const { t } = useTranslation();

  const [addWorkerForm] = Form.useForm();

  return (
    <React.Fragment>
      <Modal
        open={isOpen}
        onOk={() => {
          addWorkerForm.submit();
        }}
        okButtonProps={{ loading: submitting }}
        onCancel={() => close()}
        okText={t("common.add")}
        title={t("partners.linkWorker")}
      >
        <Form
          form={addWorkerForm}
          onFinish={(values) => {
            linkWorker(values);
          }}
          layout="vertical"
        >
          <Row gutter={[24, 0]}>
            <Form.Item hidden name="contractor_id" initialValue={idContractor}>
              <Input></Input>
            </Form.Item>
            <Col span={24}>
              <WorkerSelect name={"worker_id"} label={t("partners.worker")} />
            </Col>
            <Col>
              <Form.Item
                name="date_from"
                label={t("partners.contractFrom")}
                rules={[
                  {
                    required: true,
                    message: "Vyplň datum od!",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) =>
                    current.format("YYYY-MM-DD") !== current.clone().startOf("month").format("YYYY-MM-DD")
                  }
                ></DatePicker>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="date_to" label={t("partners.contractTo")}>
                <DatePicker></DatePicker>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name={"affidavit"} valuePropName={"checked"} label={t("partners.affidavit")}>
                <Checkbox></Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default PartnerLinkWorkerForm;
