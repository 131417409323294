import { Card, Col, notification, Row, Statistic, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../api/calls";
import ClientLeadAddForm from "../components/ClientLeadAddForm";

const { Title } = Typography;

const gutter = [16, 24];

const ClientLeadsIndex = () => {
  const { t } = useTranslation();

  const [clientLeadList, setClientLeadList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [duplicity, setDuplicity] = useState(false);

  // const user = localStorage.getItem("user");

  useEffect(() => {
    coreApi
      .get("/client-leads")
      .then((response) => {
        setClientLeadList(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const addClientLead = (values) => {
    coreApi
      .post("/client-leads", values)
      .then((response) => {
        const newLeadList = [...clientLeadList, response.data.client_lead];
        setClientLeadList(newLeadList);
      })
      .catch((error) => {
        notification.error({ message: error.response.data.message });
      })
      .finally();
  };

  const checkDuplicity = (values) => {
    setDuplicity(false);
    coreApi
      .get("/client-leads/check-duplicity", { params: { ...values } })
      .then((res) => {
        setDuplicity(false);
      })
      .catch((err) => {
        setDuplicity(true);
      });
  };

  const columns = [
    {
      title: t("clientLeads.name"),
      render: (lead) => <Link to={"/client-leads/" + lead?.client_id}>{lead?.name}</Link>,
    },
    {
      title: t("clientLeads.address"),
      key: "billing_address_street",
      dataIndex: "billing_address_street",
    },
    {
      title: t("clientLeads.unitsCount"),
      dataIndex: "units_count",
      key: "units_count",
    },
    {
      title: t("clientLeads.state"),
      dataIndex: "last_state",
      key: "last_state.created_at",
      render: (state) => {
        return (
          <Tag
            color={
              state?.translation_key === "canceled"
                ? "red"
                : state?.translation_key === "done"
                ? "green"
                : state?.translation_key === "doesntWantToCooperate"
                ? "red"
                : "blue"
            }
          >
            {t("clientLeads.stateList." + state?.translation_key)}
          </Tag>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Title>{t("clientLeads.clientLeadsOverviewHeader")}</Title>
      <Row gutter={gutter}>
        <Col span={6}>
          <Card>
            <Statistic title={t("clientLeads.newLeads")} value={4}></Statistic>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic title={t("clientLeads.allLeads")} value={190}></Statistic>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic title={t("clientLeads.leadsWeekly")} value={32}></Statistic>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic title={t("clientLeads.newDocuments")} value={9}></Statistic>
          </Card>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col span={24}>
          <Table loading={isLoading} columns={columns} dataSource={clientLeadList}></Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <ClientLeadAddForm
            duplicity={duplicity}
            onFinishAction={addClientLead}
            checkDuplicity={checkDuplicity}
          ></ClientLeadAddForm>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ClientLeadsIndex;
