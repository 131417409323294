const initialState = {
  areaScope: null,
  cities: [],
};

const areaScope = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_AREA_SCOPE":
      return { ...state, cities: action.cities };
    case "SET_AREA_SCOPE":
      return { ...state, areaScope: action.areaScope };
    case "ADD_AREA_SCOPE":
      let addedCity = [...state.cities];
      Object.entries(addedCity).forEach(([_, array]) => {
        Object.entries(array).forEach(([countryKey, country]) => {
          if (countryKey === action.city.country_branch.name) country[action.city?.id] = action.city.name;
        });
      });

      return { areaScope: action.areaScope, cities: addedCity };
    case "REMOVE_AREA_SCOPE":
      let removedCity = [...state.cities];
      Object.entries(removedCity).forEach(([_, array]) => {
        Object.entries(array).forEach(([_, country]) => {
          Object.entries(country).forEach(([cityKey, _]) => {
            if (cityKey === "" + action.city.idCity) {
              delete country[cityKey];
            }
          });
        });
      });
      return { areaScope: action.areaScope, cities: removedCity };
    default:
      return state;
  }
};

export default areaScope;
