
export const sortPartners = (partners) => {
  return partners ? partners.sort((a, b) => {
    if (a?.contract_ended === null && b?.contract_ended !== null) return -1;
    if (a?.contract_ended !== null && b?.contract_ended === null) return 1;

    const firstNameA = a.first_name ?? "";
    const firstNameB = b.first_name ?? "";
    if (firstNameA !== firstNameB) return firstNameA.localeCompare(firstNameB);

    const lastNameA = a.last_name ?? "";
    const lastNameB = b.last_name ?? "";
    return lastNameA.localeCompare(lastNameB);
  }) : partners;
};
