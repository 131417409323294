import { Divider, Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const PartnerResidenceInformationSection = ({ partner, readOnly }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Divider orientation="left">{t("common.residenceInformation")}</Divider>
      <Form.Item name="street" label={t("common.street")} initialValue={partner?.street}  rules={[{ required: true }]}>
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>
      <Form.Item name="descriptive_number" label={t("common.descriptiveNumber")} initialValue={partner?.descriptive_number}  rules={[{ required: true }]}>
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>
      <Form.Item name="apartment" label={t("common.apartment")} initialValue={partner?.apartment}>
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>
      <Form.Item name="postcode" label={t("common.postcode")} initialValue={partner?.postcode}  rules={[{ required: true }]}>
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>
      <Form.Item name="city" label={t("common.city")} initialValue={partner?.city}  rules={[{ required: true }]}>
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>
      <Form.Item name="area" label={t("common.area")} initialValue={partner?.area}  rules={[{ required: false }]}>
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>
    </React.Fragment>
  );
};

export default PartnerResidenceInformationSection;
