import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, notification, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import { OfferContext } from "../../pages/OfferProfile";
import CustomRegularityModal from "./CustomRegularityModal";

const OfferItem = ({ offerItem, addCleaningGroup, isOrder }) => {
  const { t } = useTranslation();

  const { offer, refreshOffer } = useContext(OfferContext);

  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [regularityModalOpen, setRegularityModalOpen] = useState(false);


  form.setFieldsValue(offerItem);

  const deleteItem = (item) => {
    coreApi
      .delete("cleaning-service-group-contents/" + item.id)
      .then((res) => {
        refreshOffer({ ...res.data.offer });
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };

  const editItem = (editedItem, offerItemId) => {
    coreApi
      .put("cleaning-service-group-contents/" + offerItemId, editedItem)
      .then((res) => {
        setDisabled(true);
        refreshOffer({ ...res.data.offer });
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err?.response?.data?.message });
      });
  };

  return (
    <Form
      name={offer?.id}
      form={form}
      onFinish={(values) => {
        const updatedValues = {
          ...values,
          cleaning_service_group_id: offerItem.cleaning_service_group_id
        };
        editItem({ ...updatedValues }, offerItem.id);
      }}
    >
      <Row gutter={[8, 0]} style={{ width: "100%" }}>
        <Col span={18}>
          <Form.Item name="service" initialValue={offerItem.service}>
            <TextArea autoSize={true} disabled={disabled}></TextArea>
          </Form.Item>
        </Col>
        {!isOrder && (
          <Col span={3}>
            {disabled ? (
              <Row gutter={[8, 8]}>
                <Col>
                  <Button icon={<EditOutlined />} onClick={(e) => setDisabled(!disabled)}></Button>
                </Col>
                <Col>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                      return Modal.confirm({
                        title: t("offers.confirmations.delete"),
                        onOk() {
                          deleteItem(offerItem);
                        },
                      });
                    }}
                  ></Button>
                </Col>
              </Row>
            ) : (
              <Row gutter={[8, 8]}>
                <Col>
                  <Button icon={<CheckOutlined />} onClick={(e) => form.submit()}></Button>
                </Col>
                <Col>
                  <Button
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      setDisabled(!disabled);
                    }}
                  ></Button>
                </Col>
              </Row>
            )}
          </Col>
        )}
      </Row>
      <CustomRegularityModal
        isModalOpen={regularityModalOpen}
        setModalOpen={setRegularityModalOpen}
        addCleaningGroup={addCleaningGroup}
      ></CustomRegularityModal>
    </Form>
  );
};

export default OfferItem;
