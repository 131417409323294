import { Checkbox, Form, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * Claim status select
 * @component
 * @alias CleaningClaimStatusSelect
 * @property {object} form - parent form object
 * @property {object} cleaning - default null
 * @property {function} showClaimFields
 * @property {function} hideClaimFields
 * @returns <Select /> component for selection claim status of a cleaning
 */
const CleaningClaimStatusSelect = ({
  form,
  cleaning = null,
  showClaimFields,
  hideClaimFields,
}) => {
  const { t } = useTranslation();

  const claimStates = ["no_claim", "in_solution", "solved"];

  const [claimStatus, setClaimStatus] = useState(claimStates[0]);

  useEffect(() => {
    if (cleaning?.is_claim_in_solution) {
      setClaimStatus("in_solution");
    } else if (cleaning?.is_claim_solved) {
      setClaimStatus("solved");
    } else {
      setClaimStatus("no_claim");
    }
  }, [cleaning]);

  useEffect(() => {
    if (claimStatus === "in_solution") {
      form.setFieldsValue({
        is_claim_in_solution: true,
        is_claim_solved: false,
      });
      showClaimFields();
    } else if (claimStatus === "solved") {
      form.setFieldsValue({
        is_claim_in_solution: false,
        is_claim_solved: true,
      });
      showClaimFields();
    } else {
      form.setFieldsValue({
        is_claim_in_solution: false,
        is_claim_solved: false,
      });
      hideClaimFields();
    }
  }, [form, claimStatus, showClaimFields, hideClaimFields]);

  return (
    <React.Fragment>
      <Form.Item
        name={"is_claim_in_solution"}
        initialValue={cleaning?.is_claim_in_solution}
        hidden
        valuePropName={"checked"}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        name={"is_claim_solved"}
        initialValue={cleaning?.is_claim_solved}
        hidden
        valuePropName={"checked"}
      >
        <Checkbox />
      </Form.Item>
      <Radio.Group
        style={{ width: "100%", paddingBottom: 16 }}
        onChange={(e) => {
          setClaimStatus(e.target.value);
        }}
        value={claimStatus}
      >
        <Radio.Button value={"no_claim"}>{t("cleanings.noClaim")}</Radio.Button>
        <Radio.Button value={"in_solution"}>
          {t("cleanings.claimInSolution")}
        </Radio.Button>
        <Radio.Button value={"solved"}>
          {t("cleanings.claimSolved")}
        </Radio.Button>
      </Radio.Group>
    </React.Fragment>
  );
};

export default CleaningClaimStatusSelect;
