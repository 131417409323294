import React from "react";
import { Form, Select, DatePicker, Input } from "antd";
import PartnerSelect from "../Partners/PartnerSelect";
import ManagerSelect from "../ManagerSelect";
import CleaningClaimTagSelect from "../Cleanings/CleaningClaimTagSelect";
import moment from "moment/moment";

const { RangePicker } = DatePicker;

const ClaimsFilterPanel = ({
   filters,
   setFilters,
   fetchClaims,
   t,
   clearDate = false
 }) => {

  const [form] = Form.useForm();

  const handleFilterChange = (changedValues) => {
    const currentValues = form.getFieldsValue();

    const updatedFilters = {
      ...filters,
      ...currentValues,
      ...changedValues,
      date_from: currentValues.date_range ? currentValues.date_range[0]?.format("YYYY-MM-DD") : filters.date_from || null,
      date_to: currentValues.date_range ? currentValues.date_range[1]?.format("YYYY-MM-DD") : filters.date_to || null,
      solved_only: currentValues.claim_status === 'solved_only' ? 1 : 0,
      in_solution_only: currentValues.claim_status === 'in_solution_only' ? 1 : 0,
      zeroPromoOnly: currentValues.promo === 1 ? 1 : 0,
      notZeroPromoOnly: currentValues.promo === 2 ? 1 : 0,
      claimTagIds: currentValues.claim_tag_id || filters.claimTagIds || [],
    };

    setFilters(updatedFilters);
  };

  const handleSearch = () => {
    fetchClaims();
  };

  const handleResetClick = () => {
    form.resetFields();
    setFilters({});
    fetchClaims();
  };

  return (
    <Form
      form={form}
      layout="inline"
      onValuesChange={handleFilterChange}
      onFinish={handleSearch}
      style={{ width: '100%', justifyContent: 'space-between' }}
    >
      <Form.Item name="claim_status" style={{ flex: 1 }}>
        <Select placeholder={t("claims.claimStatus")} allowClear style={{ width: '100%' }}>
          <Select.Option value="in_solution_only">
            {t("claims.claimInSolution")}
          </Select.Option>
          <Select.Option value="solved_only">
            {t("claims.claimSolved")}
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="promo" style={{ flex: 1 }}>
        <Select placeholder={t("cleanings.promo")} allowClear style={{ width: '100%' }}>
          <Select.Option value={1}>{t("cleanings.zeroPromoOnly")}</Select.Option>
          <Select.Option value={2}>{t("cleanings.withPromoOnly")}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="claim_tag_id" style={{ flex: 1 }}>
        <CleaningClaimTagSelect label={false} mode="multiple" style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item name="partner_id" style={{ flex: 1 }}>
        <PartnerSelect
          allowClear
          onChange={(value) => setFilters({ ...filters, partnerIds: value?.id ? [value?.id] : null })}
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item name="building_unit" style={{ flex: 1 }}>
        <Input placeholder={t("schedules.buildingUnit")} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item name="manager_id" style={{ flex: 1 }}>
        <ManagerSelect
          name={"manager_id"}
          clearable={true}
          showSearch={true}
          placeholder={t("claims.selectUser")}
          allowClear
          style={{ width: '100%' }}
          activeOnly={true}
          sendOnchange={(value, option) => setFilters({ ...filters, managerId: value, userId: option ? option['data-user-id'] : null })}
        />
      </Form.Item>

      <Form.Item
        name="date_range"
        style={{ flex: 2 }}
        initialValue={clearDate ? null : [moment().subtract(1, 'months').startOf('month'), moment().endOf("month")]}
      >
        <RangePicker placeholder={[t("common.dateFrom"), t("common.dateTo")]} style={{ width: '100%' }} allowClear={false}/>
      </Form.Item>
      <Form.Item>
        <a
          style={{ float: "inline-end", marginRight: "25px", marginTop: "5px" }}
          onClick={(e) => {
            e.preventDefault();
            handleResetClick();
          }}
        >
          {t("common.resetFilters")}
        </a>
      </Form.Item>
    </Form>
  );
};

export default ClaimsFilterPanel;
