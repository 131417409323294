import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../api/calls";

const ManagerSelect = ({
  clearable = false,
  label,
  validationMessage,
  initialValue,
  required,
  readOnly,
  showSearch = false,
  sendOnchange = null,
  placeholder = null,
  activeOnly = false
}) => {
  const { t } = useTranslation();

  const [managers, setManagers] = useState([]);
  const [selectedValue, setSelectedValue] = useState(initialValue);

  useEffect(() => {
    const url = activeOnly ? "/employees/all-active" : "/employees/all";
    coreApi
      .get(url)
      .then((response) => {
        setManagers(response.data);

        const foundEmployee = response.data.find(employee => employee?.user?.id === initialValue);
        if (!foundEmployee) {
          setSelectedValue(null);
        }
      })
      .catch((error) => console.log(error));
  }, [t, activeOnly]);

  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  return (
    <Form.Item
      name="manager_id"
      label={label}
      rules={[
        {
          required: required,
          message: validationMessage,
        },
      ]}
      initialValue={selectedValue}
      style={{minWidth: '240px'}}
    >
      <Select
        onChange={(value, option) => {
          setSelectedValue(value);
          if (sendOnchange) sendOnchange(value, option);
        }}
        allowClear={clearable}
        disabled={readOnly}
        bordered={!readOnly}
        showSearch={showSearch}
        placeholder={placeholder || t("managers.selectManager")}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {managers.map((manager) => {
          return (
            <Select.Option key={manager.id} value={manager.id} data-user-id={manager?.user?.id || null}>
              {manager.first_name + " " + manager.last_name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default ManagerSelect;
