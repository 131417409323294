import { DeleteOutlined } from "@ant-design/icons";
import { Button, Modal, notification } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const DeleteRuleButton = ({ schedule, schedules, refreshScheduleList }) => {
  const { t } = useTranslation();

  const [isDeleting, setDeleting] = useState(false);

  return (
    <Button
      onClick={() => {
        Modal.confirm({
          title: t("schedules.deleteScheduleRule"),
          content: t("schedules.deleteScheduleRuleWarning"),
          okText: t("common.delete"),
          okButtonProps: { type: "danger", loading: isDeleting },
          onOk: () => {
            setDeleting(true);
            let payload = schedule.rules.map((item) => item.id);
            coreApi
              .delete("/schedules/mass-delete", {
                data: {
                  service_rrules: [...payload],
                },
              })
              .then((response) => {
                refreshScheduleList(schedules.filter((item) => item !== schedule));

                notification.success({
                  message: response.data.message,
                });
              })
              .catch((error) => {
                notification.error({
                  message: error.response.data.message,
                });
              })
              .finally(() => {
                setDeleting(false);
              });
          },
        });
      }}
      danger
      size={"small"}
      icon={<DeleteOutlined />}
    ></Button>
  );
};

export default DeleteRuleButton;
