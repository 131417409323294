import { Button, Divider, Form, Input, Modal, notification, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import UserAccountTypeSelect from "./UserAccountTypeSelect";

const UserAddForm = ({ employee }) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [userForm] = Form.useForm();

  return submitted ? (
    <Typography.Paragraph>{t("users.userAdded")}</Typography.Paragraph>
  ) : (
    <React.Fragment>
      <Typography.Paragraph>{t("users.employeeHasNoUserAccount")}</Typography.Paragraph>
      <Divider></Divider>
      <Button
        type={"primary"}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t("users.addUserAccount")}
      </Button>
      <Modal
        title={t("users.addUserAccount")}
        open={isModalOpen}
        onOk={() => {
          userForm.submit();
        }}
        okButtonProps={{ loading: submitting }}
        onCancel={() => {
          setModalOpen(false);
        }}
      >
        <Form
          form={userForm}
          onFinish={(values) => {
            setSubmitting(true);

            coreApi
              .post("/users", values)
              .then((response) => {
                notification.success({ message: response.data.message });
                setModalOpen(false);
                userForm.resetFields();
                setSubmitted(true);
              })
              .catch((error) => {
                notification.error({ message: error?.response?.data?.message });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          <Form.Item name={"employee_id"} initialValue={employee?.id} hidden>
            <Input></Input>
          </Form.Item>
          <Form.Item name={"username"} label={t("users.username")} rules={[{ required: true, min: 6 }]} hasFeedback>
            <Input></Input>
          </Form.Item>
          <Form.Item
            name={"email"}
            label={t("users.email")}
            rules={[
              {
                required: true,
              },
              {
                type: "email",
                message: t("common.emailValidation"),
              },
            ]}
            hasFeedback
          >
            <Input></Input>
          </Form.Item>
          <UserAccountTypeSelect />
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default UserAddForm;
