import { EditOutlined } from "@ant-design/icons";
import { Button, Space, Table, Tag } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CleaningEditModal from "./CleaningEditModal";

/**
 * Cleaning List Simple
 * @component
 * @alias CleaningListSimple
 * @property {list} cleanings - list of cleanings objects
 * @returns <Table /> component with cleanings overview
 */
const CleaningListSimple = ({ cleanings }) => {
  const { t } = useTranslation();

  const [isEditCleaningOpen, setEditCleaningOpen] = useState(false);
  const [selectedCleaning, setSelectedCleaning] = useState(null);

  return (
    <React.Fragment>
      <Table
        rowKey={"id"}
        size={"small"}
        dataSource={cleanings}
        columns={[
          {
            title: t("schedules.buildingUnit"),
            dataIndex: "building_unit",
            key: "building_unit",
            render: (buildingUnit) => (
              <Link to={"/buildings/" + buildingUnit.building_id + "/unit/" + buildingUnit.id}>
                {buildingUnit.street + " " + buildingUnit.house_number}
              </Link>
            ),
          },
          {
            title: t("cleanings.type"),
            dataIndex: "is_recurring",
            key: "is_recurring",
            render: (isRecurring) =>
              isRecurring ? (
                <Tag>{t("cleanings.recurring.cleaning")}</Tag>
              ) : (
                <Tag>{t("cleanings.oneTime.cleaning")}</Tag>
              ),
          },
          {
            title: t("common.actions"),
            key: "actions",
            render: (cleaning) => (
              <Space>
                <Button
                  size={"small"}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedCleaning(cleaning);
                    setEditCleaningOpen(true);
                  }}
                ></Button>
              </Space>
            ),
          },
        ]}
      ></Table>
      <CleaningEditModal
        cleaning={selectedCleaning}
        isOpen={isEditCleaningOpen}
        close={() => {
          setEditCleaningOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export default CleaningListSimple;
