const initialState = {
  redirect: false,
};

const redirectHandler = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REDIRECT":
      return { redirect: action.redirect };
    case "GET_REDIRECT":
      return { ...state };
    default:
      return state;
  }
};

export default redirectHandler;
