import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { coreApi } from "../../api/calls";

const ContactSelect = ({
  id,
  label,
  required = true,
}) => {

  const [contacts, setContacts] = useState({});

  useEffect(() => {
    coreApi
      .get("buildings/" + id)
      .then((res) => {
        setContacts(res.data.contacts);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <Form.Item name={"to"} label={label} rules={[{required: required}]}>
      <Select>
        {Object.values(contacts).map((contact) => (
          <Select.Option value={contact.id} key={contact.id}>
            {contact.first_name + ' ' + contact.last_name + ' ' + contact.email}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default ContactSelect;
