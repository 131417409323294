import {
  RollbackOutlined,
  SaveOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { Button, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ProfileFormActionSpace = ({
  formInstance,
  isFormReadOnly,
  isFormSubmitting,
  setFormEditable,
  setFormDisabled,
}) => {
  const { t } = useTranslation();
  return (
    <Space style={{ float: "right" }}>
      {isFormReadOnly ? (
        <Button icon={<UnlockOutlined />} onClick={setFormEditable}>
          {t("common.allowChanges")}
        </Button>
      ) : (
        <React.Fragment>
          <Button
            icon={<RollbackOutlined />}
            onClick={() => {
              formInstance.resetFields();
              setFormDisabled();
            }}
          >
            {t("common.discardChanges")}
          </Button>
          <Button
            loading={isFormSubmitting}
            icon={<SaveOutlined />}
            onClick={() => {
              formInstance.submit();
              setFormDisabled();
            }}
          >
            {t("common.saveChanges")}
          </Button>
        </React.Fragment>
      )}
    </Space>
  );
};

export default ProfileFormActionSpace;
