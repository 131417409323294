import { DatePicker, Divider, Form, Input, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const PartnerContractSection = ({ partner, readOnly }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    coreApi
      .get("partners/end-tags")
      .then((response) => {
        setTags(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const { t } = useTranslation();
  const { Option } = Select;

  return (
    <React.Fragment>
      <Divider orientation={"left"}>{t("partners.cooperation")}</Divider>
      <Form.Item
        name={"contract_signed"}
        label={t("partners.contractSigned")}
        initialValue={partner?.contract_signed ? moment(partner.contract_signed) : null}
      >
        <DatePicker disabled={readOnly} bordered={!readOnly} format={"L"}></DatePicker>
      </Form.Item>
      <Form.Item
        name={"contract_ended"}
        label={t("partners.contractEnded")}
        initialValue={partner?.contract_ended ? moment(partner.contract_ended) : null}
      >
        <DatePicker disabled={readOnly} bordered={!readOnly} format={"L"}></DatePicker>
      </Form.Item>

      <Form.Item
        name="ending_reason_tag_id"
        label={t("partners.endingReason")}
        initialValue={partner?.ending_reason_tag_id}
      >
        <Select placeholder={t("partners.endingReason")} disabled={readOnly} bordered={!readOnly}>
          {tags.map((tag) => {
            return <Option value={tag?.id}>{tag?.tag_name}</Option>;
          })}
        </Select>
      </Form.Item>

      <Form.Item name={"ending_note"} label={t("partners.endingNote")} initialValue={partner?.ending_note}>
        <Input.TextArea readOnly={readOnly} bordered={!readOnly}></Input.TextArea>
      </Form.Item>
    </React.Fragment>
  );
};

export default PartnerContractSection;
