import React from 'react';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';

const LanguageSelect = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <div>
      <h4>{t('common.selectPdfLanguage')}</h4>
      <Radio.Group onChange={onChange} value={value} style={{ display: 'flex', flexDirection: 'column' }}>
        <Radio value="cs">
          {t('languages.czech')}
        </Radio>
        <Radio value="sk">
          {t('languages.slovak')}
        </Radio>
        <Radio value="en">
          {t('languages.english')}
        </Radio>
      </Radio.Group>
    </div>
  );
};

export default LanguageSelect;
