import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Account Prefix Input
 * @component
 * @alias AccountPrefixInput
 * @property {string} initialValue - init value for Account Prefix field
 * @property {bool} readOnly - sets Form Item read-only
 * @property {bool} required - sets Form Item required - default false
 * @returns <Form.Item />
 */
const AccountPrefixInput = ({ initialValue, readOnly, required = false }) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      label={t("finance.accounts.accountPrefix")}
      name={"account_prefix"}
      initialValue={initialValue}
      hasFeedback
      rules={[
        {
          required: required,
          message: t("finance.accounts.validation.accountPrefix"),
          max: 6,
          min: 2,
        },
      ]}
    >
      <Input maxLength={6} readOnly={readOnly} bordered={!readOnly}></Input>
    </Form.Item>
  );
};

export default AccountPrefixInput;
