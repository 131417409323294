import { notification } from "antd";

/**
 * Throw notification with all errors.
 *
 * @param {*} error
 */
export default function throwError(error) {
  let errors = "";

  for (const key in error.response.data.errors) {
    errors += error.response.data.errors[key][0] + " ";
  }

  notification.error({
    message: "Chyba!",
    description: errors,
  });
}
