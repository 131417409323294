import { Calendar, Col, Divider, Row, Spin, Form, Button, notification } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import ClaimFormFragment from "../Claims/ClaimFormFragment";

/**
 * Simplified Cleanings Calendar
 * @component
 * @alias ClaimCleaningsCalendar
 * @property {function} handleCalendarChange - parent function that changes changing selected calendars cell
 * @property {function} dateCellRender - parent function for rendering calendar cell content
 * @property {function} setSelectedDate - set currently selected cell
 * @property {object} selectedDate - currently selected cell
 * @property {array} selectedCleanings - selected cleanings array
 * @property {integer} buildingUnitId
 * @property {bool} loading
 * @returns <Calendar /> component without sidebar
 */
const ClaimCleaningsCalendar = ({
  handleCalendarChange,
  dateCellRender,
  setSelectedDate,
  selectedDate,
  selectedCleanings,
  loading,
  buildingUnitId
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);

  const handleSubmit = async (values, cleaning) => {
    setSaving(true);
    try {
      const response = await coreApi.put("/cleanings/mass-edit/claims", {
        ...values,
        selectedRows: [cleaning.id],
      });
      notification.success({
        message: response.data.message,
      });
      handleCalendarChange(selectedDate);
    } catch (error) {
      notification.error({
        message: error.response.data.message,
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <React.Fragment>
      <Divider orientation={"left"}>{t("schedules.scheduleOverview")}</Divider>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Spin spinning={loading}>
            <Calendar
              key={buildingUnitId}
              dateCellRender={dateCellRender}
              onSelect={(value) => {
                setSelectedDate(() => moment(value));
              }}
              onPanelChange={(date) => {
                handleCalendarChange(moment(date));
              }}
            />
          </Spin>
        </Col>
      </Row>
      {!loading && selectedCleanings.length > 0 && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Divider orientation={"left"}>{t("cleanings.claims")}</Divider>
            {selectedCleanings.map((cleaning) => (
              <Form
                key={cleaning.id}
                form={form}
                layout="vertical"
                onFinish={(values) => handleSubmit(values, cleaning)}
              >
                <ClaimFormFragment
                  key={cleaning.id}
                  form={form}
                  cleaning={cleaning}
                  refreshCalendarView={() => {
                    handleCalendarChange(selectedDate);
                  }}
                />
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={saving}
                  >
                    {t("common.save")}
                  </Button>
                </Form.Item>
              </Form>
            ))}
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default ClaimCleaningsCalendar;
