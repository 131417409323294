import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../../api/calls";
import InvoiceTypeSelect from "../InvoiceTypeSelect";
import AnnaPartnerForm from "./AnnaPartnerForm";
import CorrectiveDocumentForm from "./CorrectiveDocumentForm";
import PartnerAnnaForm from "./PartnerAnnaForm";
import PartnerClientForm from "./PartnerClientForm";
import PartnerPartnerForm from "./PartnerPartnerForm";

/**
 * @component
 * @alias InvoiceAddForm
 * @property {array} partnerClientInvoices
 * @property {array} setPartnerClientInvoices
 * @property {array} annaPartnerInvoices
 * @property {array} setAnnaPartnerInvoices
 * @property {array} partnerPartnerInvoices
 * @property {array} setPartnerClientInvoices
 * @property {array} correctiveDocuments
 * @property {array} setCorrectiveDocuments
 * @property {array} partnerAnnaInvoices
 * @property {array} setPartnerAnnaInvoices
 * @returns Template for invoice add form
 */
const InvoiceAddForm = ({
  partnerClientInvoices,
  setPartnerClientInvoices,
  annaPartnerInvoices,
  setAnnaPartnerInvoices,
  partnerPartnerInvoices,
  setPartnerPartnerInvoices,
  correctiveDocuments,
  setCorrectiveDocuments,
  partnerAnnaInvoices,
  setPartnerAnnaInvoices,
}) => {
  const { t } = useTranslation();

  // States
  const [isVisible, setVisible] = useState(false);
  const [invoiceTypeSelected, setInvoiceTypeSelected] = useState(1);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepTotal, setStepTotal] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [appendFormValues, setAppendFormValues] = useState(null);

  const [invoiceForm] = Form.useForm();

  // Handle reset fields
  useEffect(() => {
    invoiceForm.resetFields();
  }, [invoiceTypeSelected, invoiceForm]);

  // Traveling between steps
  const prevStep = () => {
    currentStep !== 0 && setCurrentStep(currentStep - 1);
  };

  // Traveling between steps
  const nextStep = () => {
    currentStep < stepTotal && setCurrentStep(currentStep + 1);
  };

  // Traveling between steps
  const finishStep = () => {
    currentStep === stepTotal && invoiceForm.submit();
  };

  // Handle finish of partner client invoice
  const onFinichActionPartnerClient = (values) => {
    setSubmitting(true);
    coreApi
      .post("/invoices/partner-client", values)
      .then((res) => {
        invoiceForm.resetFields();
        setCurrentStep(0);
        setVisible(false);

        setPartnerClientInvoices([...partnerClientInvoices, res.data]);

        notification.success({ message: t(res.data.message) });
      })
      .catch((err) => notification.error({ message: err?.response?.data?.message }))
      .finally(() => setSubmitting(false));
  };

  // Handle finish of anna partner invoice
  const onFinishActionAnnaPartner = (values) => {
    setSubmitting(true);
    let mergedValues = values;

    appendFormValues &&
      appendFormValues.forEach((item) => {
        mergedValues[item?.name] = item?.value;
      });

    coreApi
      .post("/invoices/anna-partner", mergedValues)
      .then((res) => {
        invoiceForm.resetFields();
        setCurrentStep(0);
        setVisible(false);
        setAnnaPartnerInvoices([...annaPartnerInvoices, res.data]);

        notification.success({ message: t(res.data.message) });
      })
      .catch((err) => notification.error({ message: err.response.data.message }))
      .finally(() => setSubmitting(false));
  };

  // Handle finish of partner anna invoice
  const onFinishActionPartnerAnna = (values) => {
    setSubmitting(true);
    coreApi
      .post("/invoices/partner-anna", values)
      .then((res) => {
        invoiceForm.resetFields();
        setCurrentStep(0);
        setVisible(false);
        setPartnerAnnaInvoices([...partnerAnnaInvoices, res.data]);

        notification.success({ message: t(res.data.message) });
      })
      .catch((err) => notification.error({ message: err.response.data.message }))
      .finally(() => setSubmitting(false));
  };

  // Handle finish of partner partner invoice
  const onFinishPartnerPartner = (values) => {
    setSubmitting(true);
    coreApi
      .post("/invoices/partner-partner", values)
      .then((res) => {
        invoiceForm.resetFields();
        setCurrentStep(0);
        setVisible(false);
        setPartnerPartnerInvoices([...partnerPartnerInvoices, res.data]);

        notification.success({ message: t(res.data.message) });
      })
      .catch((err) => notification.error({ message: err.response.data.message }))
      .finally(() => setSubmitting(false));
  };

  // Handle finish of corrective invoice
  const onFinishCorrective = (values) => {
    setSubmitting(true);
    coreApi
      .post("/invoices/corrective-documents", values)
      .then((res) => {
        invoiceForm.resetFields();
        setCurrentStep(0);
        setVisible(false);
        setCorrectiveDocuments([...correctiveDocuments, res.data]);

        notification.success({ message: t(res.data.message) });
      })
      .catch((err) => notification.error({ message: err.response.data.message }))
      .finally(() => setSubmitting(false));
  };

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setVisible(true);
        }}
      >
        {t("finance.invoices.createInvoice")}
      </Button>
      <Modal
        open={isVisible}
        onCancel={() => setVisible(false)}
        width={1000}
        title={t("finance.invoices.createInvoice")}
        footer={[
          <Button
            key="strorno"
            onClick={() => {
              setCurrentStep(0);
              setVisible(false);
              invoiceForm.resetFields();
            }}
          >
            {t("common.storno")}
          </Button>,
          <Button key="back" onClick={() => prevStep()} disabled={currentStep === 0} type="primary">
            {t("common.back")}
          </Button>,
          currentStep === stepTotal ? (
            <Button
              key="finish"
              onClick={() => {
                invoiceForm
                  .validateFields()
                  .then((values) => finishStep())
                  .catch((err) => console.log(err));
              }}
              loading={submitting}
              type="primary"
            >
              {t("common.finish")}
            </Button>
          ) : (
            <Button
              key="next"
              onClick={() => {
                invoiceForm
                  .validateFields()
                  .then((values) => nextStep())
                  .catch((err) => console.log(err));
              }}
              type="primary"
            >
              {t("common.next")}
            </Button>
          ),
        ]}
      >
        {/* Render form */}
        <Form
          form={invoiceForm}
          onFinish={(values) => {
            // Handle finish for specific invoice type
            switch (invoiceTypeSelected) {
              case 1:
                onFinichActionPartnerClient({
                  ...values,
                  building_id: invoiceForm.getFieldValue("building_id"),
                  client_id: invoiceForm.getFieldValue("client_id"),
                  service_provider_id: invoiceForm.getFieldValue("service_provider_id"),
                  invoice_publisher_id: invoiceForm.getFieldValue("invoice_publisher_id"),
                });
                break;
              case 2:
                onFinishActionAnnaPartner({
                  ...values,
                  service_recipient_id: invoiceForm.getFieldValue("service_recipient_id"),
                  invoice_recipient_id: invoiceForm.getFieldValue("invoice_recipient_id"),
                });
                break;
              case 3:
                onFinishPartnerPartner({
                  ...values,
                  service_provider_id: invoiceForm.getFieldValue("service_provider_id"),
                  invoice_publisher_id: invoiceForm.getFieldValue("invoice_publisher_id"),
                  service_recipient_id: invoiceForm.getFieldValue("service_recipient_id"),
                  invoice_recipient_id: invoiceForm.getFieldValue("invoice_recipient_id"),
                });
                break;
              case 4:
                onFinishCorrective({
                  ...values,
                  service_provider_id: invoiceForm.getFieldValue("service_provider_id"),
                  invoice_publisher_id: invoiceForm.getFieldValue("invoice_publisher_id"),
                  client_id: invoiceForm.getFieldValue("client_id"),
                  building_id: invoiceForm.getFieldValue("building_id"),
                  invoice_id: invoiceForm.getFieldValue("id"),
                });
                break;
              case 5:
                onFinishActionPartnerAnna({
                  ...values,
                  service_provider_id: invoiceForm.getFieldValue("service_provider_id"),
                  invoice_publisher_id: invoiceForm.getFieldValue("invoice_publisher_id"),
                });
                break;
              default:
                break;
            }
          }}
          layout={"vertical"}
          size="medium"
        >
          <Row gutter={[16, 24]}>
            <Col span={24}>
              <InvoiceTypeSelect
                initialValue={invoiceTypeSelected}
                onChange={(e) => {
                  setInvoiceTypeSelected(e);
                  setCurrentStep(0);
                }}
              ></InvoiceTypeSelect>
            </Col>
            <Col span={24}>
              <Row gutter={[8, 8]}>
                {/* Invoice type must be a number - based on backend constants */}
                {invoiceTypeSelected === 1 && (
                  <PartnerClientForm form={invoiceForm} currentStep={currentStep} setStepTotal={setStepTotal} />
                )}
                {invoiceTypeSelected === 2 && (
                  <AnnaPartnerForm
                    form={invoiceForm}
                    currentStep={currentStep}
                    setStepTotal={setStepTotal}
                    onAppendFormValues={(values) => setAppendFormValues(values)}
                  />
                )}
                {invoiceTypeSelected === 3 && (
                  <PartnerPartnerForm form={invoiceForm} currentStep={currentStep} setStepTotal={setStepTotal} />
                )}
                {invoiceTypeSelected === 4 && (
                  <CorrectiveDocumentForm form={invoiceForm} currentStep={currentStep} setStepTotal={setStepTotal} />
                )}
                {invoiceTypeSelected === 5 && (
                  <PartnerAnnaForm form={invoiceForm} currentStep={currentStep} setStepTotal={setStepTotal} />
                )}
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default InvoiceAddForm;
