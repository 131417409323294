import { Col, DatePicker, Form, Row, Space } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import InvoicesTableSimple from "../Invoices/InvoicesTableSimple";
import InvoiceTypeSelect from "../Invoices/InvoiceTypeSelect";
import SmallMessage from "../SmallMessage";

const PartnerInvoicingPane = ({ props, partner }) => {
  // Translations
  const { t } = useTranslation();

  // Params of URL
  const params = new URLSearchParams(props.location.search);

  // States
  const [invoices, setInvoices] = useState([]);
  const [filterForm] = Form.useForm();
  const [invoicesLoading, setInvoicesLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [sorter, setSorter] = useState({});

  // Values for filtering
  const [filters, setFilters] = useState({
    date_issued_from: params.get("date_issued_from")
      ? moment(params.get("date_issued_from"))
      : moment().startOf("month"),
    date_issued_to: params.get("date_to_from") ? moment(params.get("date_issued_to")) : moment().endOf("month"),
    invoice_type: params.get("invoice_type") ? params.get("invoice_type") : 1,
    page: 1,
    page_size: 10,
    service_provider_id: partner?.id,
  });

  /**
   * Fetch data from API
   */
  const fetchData = useCallback(() => {
    setInvoicesLoading(true);

    axios
      .all([
        coreApi.get("/invoices/partner-invoices", {
          params: {
            ...filters,
            date_issued_from: filters?.date_issued_from.format("YYYY-MM-DD"),
            date_issued_to: filters?.date_issued_to.format("YYYY-MM-DD"),
            sorterOrder: sorter?.order,
            sorterKey: sorter?.columnKey,
            page_size: pagination?.pageSize,
            page: pagination?.current,
          },
        }),
      ])
      .then(
        axios.spread((invoices) => {
          const { data, ...pagination } = invoices.data;

          setInvoices(data);
          setPagination(pagination);
        })
      )
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setInvoicesLoading(false);
      });
  }, [filters, pagination, sorter]);

  useEffect(() => {
    fetchData();
  }, [filters]);

  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Space>
            <Form layout={"inline"} form={filterForm} className="filterForm">
              <InvoiceTypeSelect
                initialValue={filters?.invoice_type}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    invoice_type: e,
                  });
                }}
              ></InvoiceTypeSelect>

              <Form.Item
                label={t("common.selectedPeriod")}
                name={"period"}
                initialValue={[filters?.date_issued_from, filters?.date_issued_to]}
              >
                <DatePicker.RangePicker
                  allowClear={false}
                  allowEmpty={false}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      date_issued_from: value[0] || moment().startOf("month"),
                      date_issued_to: value[1] || moment().endOf("month"),
                    });
                  }}
                ></DatePicker.RangePicker>
              </Form.Item>
            </Form>
          </Space>

          <SmallMessage resource="invoices" />
        </Col>
        <Col span={24}>
          <InvoicesTableSimple
            loading={invoicesLoading}
            invoices={invoices}
            pagination={{
                ...pagination,
                showSizeChanger: true
            }}
            setFilters={setFilters}
            filters={filters}
            fetchData={fetchData}
            setPagination={setPagination}
            setSorter={setSorter}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PartnerInvoicingPane;
