import { DownloadOutlined, ExclamationCircleOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Divider, Form, Modal, notification, Row, Space, Statistic, Tag } from "antd";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import PartnerBalanceCitySelector from "./PartnerBalanceCitySelector";
import { useParams } from "react-router-dom";
import PartnerPaymentRequest from "./PartnerPaymentRequest";
import { Link } from "react-router-dom";

const PartnerBalancePane = ({ partner, ...props }) => {
  const { t } = useTranslation();

  const [balances, setBalances] = useState([]);

  // Display balance from all cities, or select specific one
  const [idCityBranch, setIdCityBranch] = useState(null);

  const params = useParams();
  const currency = partner?.city_branches[0]?.country_branch?.iso_4217_currency_code || "CZK";

  const [XML, setXML] = useState(null);

  const [dateFrom, setDateFrom] = useState(moment().subtract(1, "months"));
  const [dateTo, setDateTo] = useState(moment().subtract(1, "months"));
  const [activeCityBranch, setActiveCityBranch] = useState(null);

  const [balanceLoading, setBalanceLoading] = useState(false);

  const regexVarSymbol = XML && XML.match(/<vs>(\d+)<\/vs>/);
  const variableSymbol = regexVarSymbol && regexVarSymbol.length > 1 && regexVarSymbol[1];

  // Fetch data from API
  const fetchData = useCallback(() => {
    setBalanceLoading(true);

    coreApi
      .get("partners/" + params?.id + "/balances", {
        params: {
          date_from: dateFrom.startOf("month").format("YYYY-MM-DD"),
          date_to: dateTo.endOf("month").format("YYYY-MM-DD"),
          city_branch_id: idCityBranch,
        },
      })
      .then((response) => {
        if (!idCityBranch && (response.data.partner_cities?.length || 0) > 1 && props?.activeTabKey === "#balances") {
          notification.warning({
            message: t("expansion.selectCity"),
          });
          setBalances(null);
          return;
        }
        setBalances(response.data);
        setActiveCityBranch(Number(response.data?.partner?.city_branch?.id) || idCityBranch);

        coreApi
          .post("payments/single-partner-payment", {
            date_to: dateTo,
            date_from: dateFrom,
            partner_id: params?.id,
            city_branch_id: idCityBranch || Number(response.data?.partner?.city_branch?.id),
          })
          .then((res) => {
            setXML(res.data.xml);
          })
          .catch((err) => setXML(null));
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setBalanceLoading(false);
      });
  }, [partner, idCityBranch, dateFrom, dateTo, params]);

  const onPaymentSuccess = useCallback(() => {
    fetchData();
  }, [fetchData]);

  const findSentAtDate = balances => {
    if (balances.partner !== null) return balances?.partner?.sent_at;

    return balances?.workers?.find(worker => worker?.sent_at)?.sent_at || null;
  };

  const sendBalanceEmail = useCallback(() => {
    setBalanceLoading(true);

    coreApi
      .post("payments/send-partner-balance-mail", {
        date_to: dateTo.endOf("month").format("YYYY-MM-DD"),
        date_from: dateFrom.startOf("month").format("YYYY-MM-DD"),
        partner_id: params?.id,
        city_branch_id: activeCityBranch,
      })
      .then((response) => {
        notification.success({
          message: response.data?.message || t("finance.balances.emailSentSuccess"),
        });
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message || t("finance.balances.emailSentError"),
        });
      })
      .finally(() => {
        setBalanceLoading(false);
      });
  }, [dateFrom, dateTo, params, activeCityBranch, t]);

  //! this cant work without city_branch_id which isnt set
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <React.Fragment>
      <Row gutter={[16, 0]}>
        <Col>
          <Form.Item label={t("finance.balances.period")}>
            <DatePicker.RangePicker
              defaultValue={[dateFrom, dateTo]}
              picker={"month"}
              format={"MMMM Y"}
              onChange={(selectedDates) => {
                setDateFrom(selectedDates[0]);
                setDateTo(selectedDates[1]);
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <PartnerBalanceCitySelector
            name={"city_branch_id"}
            label={t("expansion.cities")}
            required={false}
            showSearch={true}
            setValue={setIdCityBranch}
            idPartner={params?.id}
          />
        </Col>
      </Row>

      {balances && <Row gutter={[16, 0]}>
        <Col span={8}>
          <Card title={t("finance.invoices.partnerBalance")} bordered={false}>
            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerClientInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.partner?.partner_client_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerPartnerInvoicesPublished")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.partner?.partner_partner_published_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerAnnaInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.partner?.partner_anna_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Divider style={{ margin: "8px 0" }} />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.annaPartnerInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.partner?.anna_partner_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerPartnerInvoicesReceived")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.partner?.partner_partner_received_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.correctiveDocuments")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.partner?.corrective_document_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />
            <Divider style={{ margin: "8px 0" }} />

            <Statistic
              loading={balanceLoading}
              title={"Celkem"}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.partner?.total_sum || 0)}
              valueStyle={{ color: "#096dd9", fontWeight: 500 }}
            />
          </Card>
        </Col>

        <Col span={8}>
          <Card title={t("finance.invoices.workersBalance")} bordered={false}>
            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerClientInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.workers_total?.partner_client_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerPartnerInvoicesPublished")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.workers_total?.partner_partner_published_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerAnnaInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.workers_total?.partner_anna_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Divider style={{ margin: "8px 0" }} />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.annaPartnerInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.workers_total?.anna_partner_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerPartnerInvoicesReceived")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.workers_total?.partner_partner_received_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.correctiveDocuments")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.workers_total?.corrective_document_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />
            <Divider style={{ margin: "8px 0" }} />

            <Statistic
              loading={balanceLoading}
              title={"Celkem"}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.workers_total?.total_sum || 0)}
              valueStyle={{ color: "#096dd9", fontWeight: 500 }}
            />
          </Card>
        </Col>

        <Col span={8}>
          <Card title={t("finance.invoices.totalBalance")} bordered={false}>
            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerClientInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.total?.partner_client_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerPartnerInvoicesPublished")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.total?.partner_partner_published_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerAnnaInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.total?.partner_anna_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Divider style={{ margin: "8px 0" }} />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.annaPartnerInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.total?.anna_partner_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerPartnerInvoicesReceived")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.total?.partner_partner_received_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.correctiveDocuments")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.total?.corrective_document_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />
            <Divider style={{ margin: "8px 0" }} />

            <Statistic
              loading={balanceLoading}
              title={"Celkem"}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.total?.total_sum || 0)}
              valueStyle={{ color: "#096dd9", fontWeight: 500 }}
            />
          </Card>
        </Col>
      </Row> }

      {XML && balances && (
        <>
          <Divider />

          <Row gutter={[0, 16]} style={{ marginTop: "24px" }}>
            <Col span={24}>
              <pre style={{ fontStyle: "italic" }}>{XML?.split("<Orders>")[1].split("</Orders>")[0]}</pre>
            </Col>
          </Row>
        </>
      )}

      {balances && <Col span={16}>
        <Space style={{ marginTop: "12px" }}>
          <Button
            type={"primary"}
            icon={<DownloadOutlined />}
            onClick={() => {
              coreApi
                .post("payments/single-partner-payment", {
                  date_to: dateTo,
                  date_from: dateFrom,
                  partner_id: params?.id,
                  city_branch_id: activeCityBranch,
                })
                .then((res) => {
                  notification.success({ message: res.data.message });
                  fileDownload(
                    res.data.xml,
                    "client-payment-" +
                    params?.id +
                    "-" +
                    moment()?.format("YYYYMMDD") +
                    "-" +
                    moment().hour() +
                    moment().minute() +
                    moment().second() +
                    ".xml"
                  );
                })
                .catch((err) => console.log(err));
            }}
          >
            {t("partners.downloadBalanceOrder")}
          </Button>
          {balances.partner?.transaction_id && (
            <Link to={`/transactions/${balances.partner?.transaction_id}`}>
              <Button>{t("finance.transactions.showTransaction")}</Button>
            </Link>
          )}

          {partner?.company_number &&
            (!findSentAtDate(balances) ? (
              <PartnerPaymentRequest
                partner={partner}
                dateFrom={dateFrom}
                dateTo={dateTo}
                total={balances?.total?.total_sum}
                variableSymbol={variableSymbol}
                idCityBranch={idCityBranch}
                onPaymentSuccess={onPaymentSuccess}
              />
            ) : (
              <Tag color="blue">
                {t("finance.transactions.sentAt")}: {moment(findSentAtDate(balances))?.format("D. M. Y HH:mm")}
              </Tag>
            ))}

          {balances?.total?.total_sum !== 0 && (
            <Button
              type={"primary"}
              icon={<SendOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: t("finance.balances.sureSendingBalance"),
                  icon: <ExclamationCircleOutlined />,
                  okButtonProps: { danger: true, loading: balanceLoading },
                  onOk: sendBalanceEmail,
                });
              }}
            >
              {t("partners.sendBalanceEmail")}
            </Button>
          )}
        </Space>
      </Col>}
    </React.Fragment>
  );
};

export default PartnerBalancePane;
