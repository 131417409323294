import { Row, Space, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RecordActionsBar from "../../components/RecordActionsBar";
import { ClearOutlined, FileDoneOutlined } from "@ant-design/icons";

const PartnerHeaderBar = ({
  companyName,
  firstName,
  lastName,
  createdAt,
  updatedAt,
  idPartner,
  contractors,
  workers,
}) => {
  const { t } = useTranslation();
  const [redirectState, setRedirectState] = useState(false);

  return (
    <React.Fragment>
      <Row gutter={[0, 24]} style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Name of partner */}
        <div>
          <Typography.Title>{companyName ? companyName : firstName + " " + lastName}</Typography.Title>

          <Space>
            <Typography.Title level={5} type="secondary" style={{ color: "#1890ff", fontWeight: 400 }}>
              <Space size={[6, 12]}>
                {contractors && (
                  <React.Fragment>
                    <ClearOutlined />
                    Spolupracovník
                  </React.Fragment>
                )}

                {workers && (
                  <React.Fragment>
                    <FileDoneOutlined />
                    Živnostník
                  </React.Fragment>
                )}
              </Space>
            </Typography.Title>
          </Space>
        </div>

        {/* Actions buttons */}
        <RecordActionsBar
          redirectState={redirectState}
          setRedirect={() => {
            setRedirectState(true);
          }}
          firstModalTitle={t("partners.deleteAlerts.alertTitle")}
          firstModalContent={t("partners.deleteAlerts.alertContent")}
          entityRoute="/partners"
          recordId={idPartner}
        ></RecordActionsBar>
      </Row>
    </React.Fragment>
  );
};

export default PartnerHeaderBar;
