import { Form, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../../api/calls";

/**
 * @component
 * @alias InvoiceSelect
 * @property {string} initialValue
 * @property {boolean} readOnly
 * @property {boolean} onChange
 * @property {boolean} required
 * @property {string} validationMessage
 * @property {boolean} showSearch
 * @property {object} invoice
 * @property {object} setInvoice
 * @property {object} form
 * @returns Select input with invoices
 */
const InvoiceSelect = ({
  initialValue = null,
  readOnly = false,
  onChange = null,
  required = false,
  validationMessage = "",
  showSearch = false,
  invoice,
  setInvoice,
  form,
}) => {
  const { t } = useTranslation();

  // States
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  return (
    <React.Fragment>
      <Form.Item label={t("finance.transactions.assignToInvoice")} name={"invoice_number"}>
        <Select
          loading={loading}
          placeholder={t("finance.invoices.invoiceNumber")}
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onSearch={(val) =>
            val.length > 3 &&
            coreApi
              .get("invoices/invoice-by-number", {
                params: {
                  invoice_number: val,
                },
              })
              .then((res) => {
                setInvoices(res.data.invoices);
                setLoading(false);
              })
              .catch((err) => console.log(err))
          }
          onChange={(e) => {
            let invoice = invoices.find((item) => item.id === e);

            setInvoice(invoice);
          }}
        >
          {invoices.map((invoice, index) => {
            return (
              <Select.Option key={invoice?.id} value={invoice?.id}>
                {invoice?.invoice_number}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </React.Fragment>
  );
};

export default InvoiceSelect;
