import { DatePicker, Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Account Control Token Input - field for bank account control token
 * @component
 * @alias AccountControlTokenInput
 * @property {bool} required
 * @returns <Form.Item />
 */
const AccountControlTokenInput = ({ required }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Form.Item
        label={t("finance.accounts.controlToken")}
        name={"control_token"}
        required={required}
      >
        <Input.TextArea rows={2} />
      </Form.Item>
      <Form.Item
        label={t("finance.accounts.controlTokenValidUntil")}
        name={"control_token_valid_until"}
        required={required}
      >
        <DatePicker />
      </Form.Item>
    </React.Fragment>
  );
};

export default AccountControlTokenInput;
