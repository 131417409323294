import { Button, Card, Col, Form, notification, Row, Space, Timeline, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../api/calls";
import ErrorPane from "../components/ErrorPane";
import PartnerLeadConvertForm from "../components/PartnerLeads/PartnerLeadConvertForm";
import PartnerLeadDetailPane from "../components/PartnerLeads/PartnerLeadDetailPane";
import PartnerLeadStatus from "../components/PartnerLeads/PartnerLeadStatus";
import ProfileFormActionSpace from "../components/ProfileFormActionSpace";
import RecordActionsBar from "../components/RecordActionsBar";
import UploadDocument from "../components/UploadDocument";

const { Title } = Typography;

const gutter = [16, 24];

const PartnerLeadProfile = (props) => {
  const { t } = useTranslation();

  const [isPageLoading, setPageLoading] = useState(false);
  const [isFormReadOnly, setFormReadOnly] = useState(true);
  const [isFormSending, setFormSending] = useState(false);

  const [deleteRedirect, setDeleteRedirect] = useState(false);

  const [partnerLead, setPartnerLead] = useState({});
  const [documents, setDocuments] = useState([]);
  const [errorCode, setErrorCode] = useState(0);

  const [leadCompleted, setLeadCompleted] = useState(false);
  const [convertModalOpen, setConvertModalOpen] = useState(false);

  const [detailForm] = Form.useForm();

  useEffect(() => {
    setPageLoading(true);
    coreApi
      .get("/partners/leads/" + props.match.params.id)
      .then((res) => {
        setPartnerLead(res.data);
        setDocuments(res.data.documents);
      })
      .catch((err) => setErrorCode(err.response.status))
      .finally(() => {
        setPageLoading(false);
      });
  }, [props.match.params.id]);

  const convertPartnerLead = (values) => {
    const convertedLead = {
      billing_address_city: values.billing_address_city,
      billing_address_house_number: values.billing_address_house_number,
      billing_address_postal_code: values.billing_address_postal_code,
      billing_address_street: values.billing_address_street,
      company_number: values.company_number,
      vat_number: values.vat_number,
      company_name: values.company_name,
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      phone: values.phone,
      workers: values.workers,
      partner_id_lead: partnerLead.partner_id_lead,
    };

    coreApi
      .get("/partners/check-duplicate/" + convertedLead.company_number)
      .then((response) => {
        coreApi
          .post("/partners", convertedLead)
          .then((response) => {
            return response.data.created_id;
          })
          .catch((error) => {
            notification.error({
              message: t("common.errorCreating"),
            });
            return false;
          });
      })
      .catch((error) => {
        notification.error({
          message: t("common.duplicity"),
          description: t("partners.alreadyExistsWithSameCompanyNumber"),
        });
      });
  };

  return errorCode ? (
    <ErrorPane errorCode={errorCode} />
  ) : (
    <React.Fragment>
      <Row gutter={gutter}>
        <Col span={12}>
          <Title disabled={isPageLoading}>
            {!isPageLoading ? partnerLead.first_name + " " + partnerLead.last_name : t("common.loading")}
          </Title>
        </Col>
        <Col span={12}>
          <Space style={{ float: "right" }}>
            <ProfileFormActionSpace
              style={{ float: "left !important" }}
              formInstance={detailForm}
              isFormReadOnly={isFormReadOnly}
              isFormSending={isFormSending}
              setFormEditable={() => setFormReadOnly(false)}
              setFormDisabled={() => setFormReadOnly(true)}
            />
            <Button disabled={!leadCompleted} type={"primary"} onClick={(e) => setConvertModalOpen(true)}>
              {t("partnerLeads.convertToPartner")}
            </Button>
            <RecordActionsBar
              redirectState={deleteRedirect}
              setRedirect={() => {
                setDeleteRedirect(true);
              }}
              firstModalTitle={t("partnerLeads.delete.alertTitle")}
              firstModalContent={t("partnerLeads.delete.alertContent")}
              entityRoute="/partners/leads"
              recordId={partnerLead.partner_id}
              redirectRoute="/partner-leads"
            />
          </Space>
        </Col>
      </Row>
      <PartnerLeadStatus
        setLeadCompleted={setLeadCompleted}
        partnerLeadId={partnerLead.partner_id}
        partnerLeadStates={partnerLead.state}
      />
      <Row gutter={gutter}>
        <Col span={12}>
          <Card title={t("partnerLeads.leadInformation")} loading={isPageLoading}>
            <PartnerLeadDetailPane
              partnerLeadId={partnerLead.partner_id}
              partnerLeadIdPropName={"partner_id"}
              firstName={partnerLead.first_name}
              lastName={partnerLead.last_name}
              email={partnerLead.email}
              phoneNumber={partnerLead.phone}
              doNotContact={partnerLead.do_not_contact}
              endpoint={"/partners/leads/"}
              partnerLeadDetailsForm={detailForm}
              setFormReadOnly={setFormReadOnly}
              setFormSending={setFormSending}
              isFormReadOnly={isFormReadOnly}
              isFormSending={isFormSending}
            />
          </Card>
          <Card style={{ marginTop: "20px" }} title={t("common.notes")} loading={isPageLoading}>
            <Form
              form={detailForm}
              onFinish={(values) => {
                console.log(values);
                setFormSending(true);
                coreApi
                  .put("/partners/leads/" + values.partner_id, values)
                  .then((response) => {
                    setFormReadOnly(true);
                    notification.success({
                      message: t("successNotifications.saveChanges.title"),
                    });
                  })
                  .catch((error) => {
                    notification.error({
                      message: t("errorNotifications.saveChanges.title"),
                      description: t("errorNotifications.saveChanges.message"),
                    });
                  })
                  .finally(() => {
                    setFormSending(false);
                  });
              }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item label={t("common.notes")} name="notes" initialValue={partnerLead.notes}>
                    <TextArea readOnly={isFormReadOnly} bordered={!isFormReadOnly}></TextArea>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={6}>
          <Card title={t("common.documents")} style={{ height: "100%" }} loading={isPageLoading}>
            <UploadDocument documents={documents} idPartnerLead={props.match.params.id} />
          </Card>
        </Col>
        <Col span={6}>
          <Card title={t("partnerLeads.communicationLog")} style={{ height: "100%" }} loading={isPageLoading}>
            <Timeline>
              <Timeline.Item color="green">Úvodní telefonát</Timeline.Item>
              <Timeline.Item color="green">Odeslání emailu</Timeline.Item>
              <Timeline.Item color="blue">
                <p>Zaslání kopie výpisu trestů</p>
              </Timeline.Item>
              <Timeline.Item color="red">
                <p>Upomenutí zaslání ŽL</p>
              </Timeline.Item>
            </Timeline>
          </Card>
        </Col>
      </Row>
      <PartnerLeadConvertForm
        partnerLeadData={partnerLead}
        convertModalOpen={convertModalOpen}
        setConvertModalOpen={setConvertModalOpen}
        convertPartnerLeadFunction={convertPartnerLead}
      />
    </React.Fragment>
  );
};

export default PartnerLeadProfile;
