import React, { useState, useEffect } from "react";
import { Card, Descriptions, notification } from "antd";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const CityStatisticsCard = ({ selectedCity = null, cellStyle = {} }) => {
  const { t } = useTranslation();
  const [statisticsData, setStatisticsData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchCityStatistics = () => {
    setLoading(true);
    coreApi
      .get("/approval-payment/city-statistics", {
        params: {
          city_branch_id: Number(selectedCity) ? Number(selectedCity) : null,
        },
      })
      .then((res) => {
        setStatisticsData(res.data);
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: t("finance.transactions.loadError"),
          description: err.message,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchCityStatistics();
  }, [selectedCity]);

  return (
    <Card size={"small"} loading={loading}>
      <Descriptions size={"small"}>
        <Descriptions.Item span={24} label={t("finance.transactions.totalPaymentsForApproval")}
                           contentStyle={cellStyle} labelStyle={{ width: "70%" }}>
          {new Intl.NumberFormat("cs-CZ", {
            style: "currency",
            currency: statisticsData?.currency || "CZK",
          }).format(statisticsData?.totalPaymentsForApproval)}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={t("finance.transactions.totalRewardsForApproval")}
                           contentStyle={cellStyle} labelStyle={{ width: "70%" }}>
          {new Intl.NumberFormat("cs-CZ", {
            style: "currency",
            currency: statisticsData?.currency || "CZK",
          }).format(statisticsData?.totalRewardsForApproval)}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={t("finance.transactions.totalRewardsInReporting")}
                           contentStyle={cellStyle} labelStyle={{ width: "70%" }}>
          {new Intl.NumberFormat("cs-CZ", {
            style: "currency",
            currency: statisticsData?.currency || "CZK",
          }).format(statisticsData?.totalRewardsInReporting)}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={t("finance.transactions.rewardDifference")}
                           contentStyle={cellStyle} labelStyle={{ width: "70%" }}>
          {new Intl.NumberFormat("cs-CZ", {
            style: "currency",
            currency: statisticsData?.currency || "CZK",
          }).format(statisticsData?.rewardDifference)}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={t("finance.transactions.overpayments")}
                           contentStyle={cellStyle} labelStyle={{ width: "70%" }}>
          {new Intl.NumberFormat("cs-CZ", {
            style: "currency",
            currency: statisticsData?.currency || "CZK",
          }).format(statisticsData?.overpayments)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default CityStatisticsCard;
