import { Row, Col, Divider, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PartnerInvoiceInfo from "./PartnerInvoiceInfo";
import PartnerSelect from "./PartnerSelect";
import InvoiceDetailsForm from "./InvoiceDetailsForm";
import InvoiceLocationForm from "./InvoiceLocationForm";
import AddLineItemForm from "./AddLineItemForm";
import InvoiceSummary from "./InvoiceSummary";
import _ from "lodash";
import moment from "moment";

const PartnerPartnerForm = ({ form, currentStep, setStepTotal }) => {
  const { t } = useTranslation();

  const [selectedSupplier, setSelectedSupplier] = useState();
  const [selectedRecipient, setSelectedRecipient] = useState();
  const [selectedCleanings, setSelectedCleanings] = useState([]);

  const { Step } = Steps;

  useEffect(() => {
    form.setFields([
      {
        name: "supplier_name",
        value:
          selectedSupplier && selectedSupplier?.contractors?.length > 0
            ? selectedSupplier?.contractors[0]?.first_name + " " + selectedSupplier?.contractors[0]?.last_name
            : selectedSupplier?.first_name &&
              selectedSupplier?.last_name &&
              selectedSupplier?.first_name + " " + selectedSupplier?.last_name,
      },
      {
        name: "supplier_company_number",
        value:
          selectedSupplier && selectedSupplier?.contractors?.length > 0
            ? selectedSupplier?.contractors[0]?.company_number
            : selectedSupplier?.company_number,
      },
      {
        name: "supplier_address",
        value:
          selectedSupplier && selectedSupplier?.contractors?.length > 0
            ? selectedSupplier?.contractors[0]?.billing_address_street +
              " " +
              selectedSupplier?.contractors[0]?.billing_address_house_number
            : selectedSupplier?.billing_address_street + " " + selectedSupplier?.billing_address_house_number,
      },
      {
        name: "supplier_postal_code",
        value:
          selectedSupplier && selectedSupplier?.contractors?.length > 0
            ? selectedSupplier?.contractors[0]?.billing_address_postal_code
            : selectedSupplier?.billing_address_postal_code,
      },
      {
        name: "supplier_city",
        value:
          selectedSupplier && selectedSupplier?.contractors?.length > 0
            ? selectedSupplier?.contractors[0]?.billing_address_city
            : selectedSupplier?.billing_address_city,
      },
      {
        name: "invoice_publisher_id",
        value:
          selectedSupplier && selectedSupplier?.contractors?.length > 0
            ? selectedSupplier?.contractors[0]?.id
            : selectedSupplier?.id,
      },
      {
        name: "service_provider_id",
        value: selectedSupplier?.id && selectedSupplier?.id,
      },
      // ----------------------------------------------
      {
        name: "recipient_name",
        value:
          selectedRecipient && selectedRecipient?.contractors?.length > 0
            ? selectedRecipient?.contractors[0]?.first_name + " " + selectedRecipient?.contractors[0]?.last_name
            : selectedRecipient?.first_name &&
              selectedRecipient?.last_name &&
              selectedRecipient?.first_name + " " + selectedRecipient?.last_name,
      },
      {
        name: "recipient_company_number",
        value:
          selectedRecipient && selectedRecipient?.contractors?.length > 0
            ? selectedRecipient?.contractors[0]?.company_number
            : selectedRecipient?.company_number,
      },
      {
        name: "recipient_address",
        value:
          selectedRecipient && selectedRecipient?.contractors?.length > 0
            ? selectedRecipient?.contractors[0]?.billing_address_street
            : selectedRecipient?.billing_address_street + " " + selectedRecipient?.billing_address_house_number,
      },
      {
        name: "recipient_postal_code",
        value:
          selectedRecipient && selectedRecipient?.contractors?.length > 0
            ? selectedRecipient?.contractors[0]?.billing_address_postal_code
            : selectedRecipient?.billing_address_postal_code,
      },
      {
        name: "recipient_city",
        value:
          selectedRecipient && selectedRecipient?.contractors?.length > 0
            ? selectedRecipient?.contractors[0]?.billing_address_city
            : selectedRecipient?.billing_address_city,
      },
      {
        name: "invoice_recipient_id",
        value:
          selectedRecipient && selectedRecipient?.contractors?.length > 0
            ? selectedRecipient?.contractors[0]?.id
            : selectedRecipient?.id,
      },
      {
        name: "service_recipient_id",
        value: selectedRecipient?.id && selectedRecipient?.id,
      },
    ]);
  }, [form, selectedRecipient, selectedSupplier]);

  const createInitialLineItems = (cleanings) => {
    if (!cleanings) return;

    let groupped = _.groupBy(selectedCleanings, (cleaning) => cleaning?.building_unit?.building_id);

    groupped = _.values(groupped);

    if (groupped?.length > 0) {
      return groupped?.map((cleanings, index) => {
        return {
          content: t("finance.lineItems.partnerPartnerContent", {
            period: moment()?.format("MM/YYYY"),
            street: cleanings[0].building_unit.street,
            number: cleanings[0].building_unit.house_number,
          }),
          building_unit_id: cleanings[0].building_unit?.id,
          list_price: parseFloat(
            _.sum(
              [...cleanings].map((cleaning) => {
                return cleaning.price;
              })
            )
          ).toFixed(2),
          discount_amount: parseFloat(
            _.sum(
              [...cleanings].map((cleaning) => {
                return cleaning.promo;
              })
            )
          ).toFixed(2),
          vat_percentage: 0.0,
          vat_amount: 0.0,
          total_amount: parseFloat(
            _.sum(
              [...cleanings].map((cleaning) => {
                return cleaning.price - cleaning.promo;
              })
            )
          ).toFixed(2),
        };
      });
    }

    return null;
  };

  const steps = [
    {
      title: t("finance.invoices.recipient") + " / " + t("finance.invoices.supplier"),
      content: (
        <React.Fragment>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={12}>
              <Divider orientation="left">{t("finance.invoices.supplier")}</Divider>
              <PartnerSelect
                selectedKey="supplier"
                required={true}
                onChange={(e) => setSelectedSupplier(e)}
                showSearch={true}
                isSupplier={true}
              />
            </Col>
            <Col xs={24} md={12}>
              <Divider orientation="left">{t("finance.invoices.recipient")}</Divider>
              <PartnerSelect
                selectedKey="recipient"
                required={true}
                onChange={(e) => setSelectedRecipient(e)}
                showSearch={true}
                isSupplier={false}
              />
            </Col>
            <Col xs={24} md={12}>
              <PartnerInvoiceInfo partner={selectedSupplier} isSupplier={true} />
            </Col>
            <Col xs={24} md={12}>
              <PartnerInvoiceInfo partner={selectedRecipient} />
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: t("finance.invoices.details"),
      content: (
        <React.Fragment>
          {/* <Row gutter={[8, 8]}>
            <Col xs={24} md={24}>
              <Divider>{t("reports.city.cleanings.substitutedCleaning")}</Divider>
              <Row gutter={[16, 16]}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <PartnerCleaningSelectModal partner={selectedRecipient} setSelected={setSelectedCleanings} />
                </Col>
                <Col span={24}>
                  <Divider></Divider>
                  <SubsitutedCleaningsList />
                </Col>
              </Row>
            </Col>
          </Row> */}
          <Row gutter={[8, 8]}>
            <Col xs={24} md={12}>
              <Divider>{t("finance.invoices.details")}</Divider>
              <InvoiceDetailsForm />
            </Col>
            <Col xs={24} md={12}>
              <Divider>{t("finance.invoices.locale")}</Divider>
              <InvoiceLocationForm />
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: t("finance.lineItems.lineItems"),
      content: (
        <React.Fragment>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={24}>
              <Divider>{t("finance.lineItems.lineItems")}</Divider>
              <AddLineItemForm initialValue={createInitialLineItems(selectedCleanings)} />
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: t("common.summary"),
      content: (
        <React.Fragment>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={24}>
              <Divider>{t("common.summary")}</Divider>
              <InvoiceSummary form={form} invoiceType={"partnerClient"} />
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
  ];

  useEffect(() => {
    setStepTotal(steps.length - 1);
  }, [setStepTotal, steps.length]);

  return (
    <React.Fragment>
      <Row gutter={[16, 8]} style={{ width: "100%" }}>
        <Col span={24}>
          <Steps current={currentStep}>
            {steps.map((item, i) => (
              <Step key={i} title={item.title}></Step>
            ))}
          </Steps>
        </Col>
        <Divider></Divider>
        <Col span={24}>
          <div className="steps-content">{steps[currentStep].content}</div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PartnerPartnerForm;
