import { Col, Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import UserSettingsForm from "../components/SystemSettingsForm";
import PasswordChangeCard from "../components/Users/PasswordChangeCard";
import UserProfileForm from "../components/UserSettingsForm";

const { Title } = Typography;

const Profile = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title>{t("common.profile")}</Title>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <UserProfileForm />
        </Col>
        <Col span={12}>
          <PasswordChangeCard />
          <UserSettingsForm />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Profile;
