import { notification, Space, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../../api/calls";
import MoneyReturnPane from "../Buildings/MoneyReturnPane";
import MoneyReturnModal from "../Clients/MoneyReturnModal";
import IconTooltip from "../IconTooltip";

/**
 * @component
 * @alias BalancePane
 * @prop {object} client - client with all its details
 */
const BalancePane = ({ client }) => {
  const { t } = useTranslation();

  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filters, setFilters] = useState({});

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setDataLoading(true);
      coreApi
        .get("invoices/partner-client", {
          params: {
            client_id: client?.id,
            page: filters?.page,
          },
        })
        .then((res) => {
          const { data, ...pagination } = res.data;

          setData(data);
          setPagination({ ...pagination });
        })
        .catch((err) => notification.error({ message: err?.response?.data?.message }))
        .finally(() => setDataLoading(false));
    }

    return () => {
      mounted = false;
    };
  }, [client, filters, loading]);

  const formatCurrency = (value, currency) => {
    return new Intl.NumberFormat("cs-CZ", {
      style: "currency",
      currency: currency || "CZK",
    }).format(value);
  };

  const calculateTotal = (invoice) => {
    const invoiceTotal = parseFloat(invoice?.total_amount || 0);
    const correctiveDocumentsTotal =
      invoice?.corrective_documents?.reduce((sum, doc) => sum + parseFloat(doc?.total_amount || 0), 0) || 0;
    return invoiceTotal + correctiveDocumentsTotal;
  };

  const calculatePaid = (invoice) => {
    const paidTransactions =
      invoice?.transactions?.reduce((sum, trans) => sum + parseFloat(trans?.pivot?.amount || 0), 0) || 0;

    const correctivePaidTransactions =
      invoice?.corrective_documents?.reduce(
        (sum, doc) =>
          sum +
          (doc?.transactions?.reduce((s, t) => s + parseFloat(t?.pivot?.amount || 0), 0) || 0),
        0
      ) || 0;

    return paidTransactions + correctivePaidTransactions;
  };


  const balanceTableColumns = [
    {
      title: t("finance.balances.period"),
      key: "date_issued",
      dataIndex: "date_issued",
      width: 100,
      render: (dateIssued) => {
        return moment(dateIssued).format("YYYY/MM");
      },
    },
    {
      title: t("finance.invoices.invoiceNumber"),
      key: "invoice_number",
      width: 120,
      render: (invoice) => {
        return (
          <Link to={`/invoices/${invoice?.id}`} style={{ fontWeight: 500 }}>
            {invoice?.invoice_number}
          </Link>
        );
      },
    },
    {
      title: t("finance.invoices.invoices"),
      key: "invoices",
      children: [
        {
          title: t("finance.balances.toBePaid"),
          key: "total_amount",
          width: 150,
          render: (invoice) => {
            const currency = invoice.currency_iso_4217 || "CZK";
            const invoiceTotal = parseFloat(invoice?.total_amount || 0);
            return formatCurrency(invoiceTotal, currency);
          },
        },
        {
          title: t("finance.balances.paid"),
          key: "partnerClientInvoices",
          width: 150,
          render: (invoice) => {
            const currency = invoice.currency_iso_4217 || "CZK";
            const paidTransactions =
              invoice?.transactions?.reduce((sum, trans) => sum + parseFloat(trans?.pivot?.amount || 0), 0) || 0;
            return formatCurrency(paidTransactions, currency);
          },
        },
      ],
    },
    {
      title: t("finance.invoices.correctiveDocuments"),
      key: "correctiveDocuments",
      children: [
        {
          title: t("finance.balances.toBePaid"),
          key: "correctiveDocuments",
          width: 150,
          render: (invoice) => {
            const currency = invoice.currency_iso_4217 || "CZK";
            const correctiveDocumentsTotal =
              invoice?.corrective_documents?.reduce((sum, doc) => sum + parseFloat(doc?.total_amount || 0), 0) || 0;
            return formatCurrency(correctiveDocumentsTotal, currency);
          },
        },
        {
          title: t("finance.balances.paid"),
          key: "correctiveDocumentsTransactions",
          width: 150,
          render: (invoice) => {
            const currency = invoice.currency_iso_4217 || "CZK";
            const correctivePaidTransactions =
              invoice?.corrective_documents?.reduce(
                (sum, doc) =>
                  sum +
                  (doc?.transactions?.reduce((s, t) => s + parseFloat(t?.pivot?.amount || 0), 0) || 0),
                0
              ) || 0;
            return formatCurrency(-correctivePaidTransactions, currency);
          },
        },
      ],
    },
    {
      title: t("finance.lineItems.totalAmount"),
      key: "totalInvoices",
      children: [
        {
          title: t("finance.balances.toBePaid"),
          key: "totalToBePaid",
          width: 150,
          render: (invoice) => {
            const currency = invoice.currency_iso_4217 || "CZK";
            let totalToBePaid = calculateTotal(invoice);
            if (Math.abs(totalToBePaid) < 0.01) totalToBePaid = 0;
            return formatCurrency(totalToBePaid, currency);
          },
        },
        {
          title: t("finance.balances.paid"),
          key: "totalTransactions",
          width: 150,
          render: (invoice) => {
            const currency = invoice.currency_iso_4217 || "CZK";
            let totalPaid = calculatePaid(invoice);
            if (Math.abs(totalPaid) < 0.01) totalPaid = 0;
            return formatCurrency(totalPaid, currency);
          },
        },
      ],
    },
    {
      title: t("finance.balances.difference"),
      key: "difference",
      width: 150,
      render: (invoice) => {
        const currency = invoice.currency_iso_4217 || "CZK";
        const total = calculateTotal(invoice);
        const paid = calculatePaid(invoice);
        let difference = total - paid;
        if (Math.abs(difference) < 0.01) difference = 0;

        let tooltipMessage =
          difference === 0
            ? t("finance.balances.paid")
            : difference > 0
              ? t("finance.balances.unpaid")
              : t("finance.balances.overpaid");

        return (
          <span>
            {formatCurrency(difference, currency)}
            <IconTooltip message={tooltipMessage} />
          </span>
        );
      },
    },
    {
      title: t("common.action"),
      key: "actions",
      width: 225,
      render: (invoice) => {
        const currency = invoice.currency_iso_4217 || "CZK";
        const total = calculateTotal(invoice);
        const paid = calculatePaid(invoice);
        let difference = total - paid;
        if (Math.abs(difference) < 0.01) difference = 0;

        const dateIssued = invoice.date_issued;

        return (
          <Space>
            <MoneyReturnPane
              idClient={invoice?.client_id}
              buildingId={invoice?.building_id}
              total={difference}
              invoice={invoice}
              client={client}
            />

            {difference < 0 &&
              (!(invoice?.corrective_document?.sent_at || invoice?.sent_at) ? (
                <MoneyReturnModal
                  invoice={invoice}
                  client={client}
                  total={difference}
                  loading={loading}
                  setLoading={setLoading}
                  dateIssued={dateIssued}
                />
              ) : (
                <Tag color="blue" style={{ fontSize: "10px" }}>
                  {t("finance.transactions.sentAt")}:{" "}
                  {moment(invoice?.corrective_document?.sent_at || invoice?.sent_at)?.format("D. M. Y HH:mm")}
                </Tag>
              ))}
          </Space>
        );
      },
    },
  ];

  return (
    <React.Fragment key={"BalancePane"}>
      <div>
        <Table
          style={{ overflowX: "auto", width: "fit-content" }}
          size="small"
          loading={dataLoading}
          bordered
          columns={balanceTableColumns}
          dataSource={data}
          rowKey={"id"}
          pagination={{
            current: pagination?.current_page,
            pageSize: pagination?.per_page,
            total: pagination?.total,
            showSizeChanger: false,
            onChange: (page) => {
              setFilters({ ...filters, page });
            },
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default BalancePane;
