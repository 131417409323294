import { Card, Col, Statistic } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Claims Statistics - used for displaying claim percentage etc.
 * @component
 * @alias ClaimsStatistics
 * @property {object} stats
 * @property {bool} loading
 * @returns Multiple cards with claim stats
 */
const ClaimsStatistics = ({ stats, loading }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col xs={12} md={8} lg={6}>
        <Card>
          <Statistic loading={loading} title={t("cleanings.cleaningsTotal")} value={stats?.cleanings_total} />
        </Card>
      </Col>
      <Col xs={12} md={8} lg={6}>
        <Card>
          <Statistic loading={loading} title={t("cleanings.claimsTotal")} value={stats?.claims_total} />
        </Card>
      </Col>
      <Col xs={12} md={8} lg={6}>
        <Card>
          <Statistic loading={loading} title={t("cleanings.claimsInSolutionTotal")} value={stats?.in_solution_total} />
        </Card>
      </Col>
      <Col xs={12} md={8} lg={6}>
        <Card>
          <Statistic
            loading={loading}
            title={t("cleanings.claimRate")}
            value={(stats?.claim_rate * 100).toFixed(2) + "%"}
          />
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ClaimsStatistics;
