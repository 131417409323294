import {
  Col,
  Modal,
  Row,
  Table
} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {EyeTwoTone, FormOutlined} from "@ant-design/icons";
import { useParams } from "react-router";
import { coreApi } from "../api/calls";
import parse from "html-react-parser";
import moment from "moment/moment";
import BuildingContactFilterPanel from "./Buildings/BuildingContactFilterPanel.jsx";
import BuildingAddCommunication from "./Buildings/BuildingAddCommunication";

/**
 * Page with all sent emails
 * @component
 * @alias CommunicationPane
 * @returns <React.Fragment /> with content
 */
const CommunicationPane = ({ currentTabKey = '' }) => {
  // Translation
  const { t } = useTranslation();

  // Id from URL
  const { id } = useParams();

  // States
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState({});
  const [pagination, setPagination] = useState({});
  const [params, setParams] = useState({});
  const [loading, setDataLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filters, setFilters] = useState({cases: ['phone', 'email', 'action']});
  /**
   * Handle street onchange filter with lodash
   */


  const fetchEmails = useCallback(() => {
    setDataLoading(true);

    const mergedParams = {
      ...params,
      ...filters,
    };

    coreApi
      .get(`/emails/buildings/` + id, { params: mergedParams })
      .then((response) => {
        let { data, ...pagination } = response.data;

        setEmails(data);
        setPagination(pagination);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setDataLoading(false)
      });
  }, [params, filters]);

  useEffect(() => {
    fetchEmails();
  }, [fetchEmails]);

  useEffect(() => {
    if (currentTabKey === '#communication') {
      fetchEmails();
    }
    return () => {}
  }, [currentTabKey]);

  // Columns of table
  const columns = [
    {
      title: t("emails.date"),
      key: "created_at",
      dataIndex: "created_at",
      width: "10%",
      render: (rowData) => {
        return moment(rowData).format("DD") + "." + moment(rowData).format("MM") + "." + moment(rowData).format("YYYY");
      },
    },
    {
      title: t("emails.communication"),
      key: "case",
      dataIndex: "case",
      width: "15%",
      render: (rowData) => {
        const translationKey = "emails.communication_cases." + rowData;
        const translatedText = t(translationKey);
        return translatedText !== translationKey ? translatedText : t("emails.communication_cases.schedule");
      },
    },
    {
      title: t("emails.communication_type"),
      key: "type",
      dataIndex: "type",
      width: "15%",
      render: (rowData) => {
        return t("emails.types." + rowData);
      },
    },
    {
      title: t("emails.with"),
      key: "to_full",
      dataIndex: "to_full",
      width: "15%",
    },
    {
      title: t("emails.description"),
      key: "subject",
      dataIndex: "subject",
      width: "20%",
      render: (rowData, record) => {
        const containsHtmlTags = /<\/?[a-z][\s\S]*>/i.test(record.body_html);
        let plainText = record.body_html.replace(/<\/?[^>]+(>|$)/g, '');
        const maxLength = 150;
        if (!plainText.length) plainText = rowData;
        const truncatedText = plainText.length > maxLength ? plainText.substring(0, maxLength) + '...' : plainText;

        return containsHtmlTags ? rowData : truncatedText;
      },
    },
    {
      title: t("emails.content"),
      key: "content",
      width: "10%",
      render: (email) => {
        return (
          <EyeTwoTone
            style={{ fontSize: "24px" }}
            onClick={() => {
              setEmail(email);
              setIsModalVisible(true);
            }}
          />
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col span={24} gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <BuildingContactFilterPanel
            filters={filters}
            setFilters={setFilters}
            fetchEmails={fetchEmails}
            t={t}
          />
          <BuildingAddCommunication id={id} onUpdate={fetchEmails}></BuildingAddCommunication>
        </Col>
      </Row>
      <Table
        size={"small"}
        loading={loading}
        dataSource={emails}
        pagination={{
          ...pagination,
          showSizeChanger: true,
          onChange: (e, perPage) => {
            setParams({ ...params, page: e, perPage: perPage });
          },
          showTotal: (total, range) => (
            <p>
              {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
              {" " + t("common.paginationPartTwo") + " "}
              <strong>{total}</strong> {t("common.paginationPartThree")}
            </p>
          ),
        }}
        columns={columns}
        onChange={(e) => {
          console.log(e);
        }}
      />
        
      <Modal
        width={1000}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
      >
        {email.body_html?.length === 0 ? (
          email.subject
        ) : (
          email.body_html && parse(email.body_html)
        )}
      </Modal>
    </React.Fragment>
  );
};

export default CommunicationPane;
