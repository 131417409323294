import { coreApi } from "../../../api/calls";

let allowedDomains = [];

export const fetchAllowedDomains = async () => {
  try {
    const response = await coreApi.get("contacts/get-email-domains");
    allowedDomains = response.data;
  } catch (error) {
    allowedDomains = [];
  }
};

export const emailDomainValidator = () => async (_, value) => {
  if (!allowedDomains.length) {
    await fetchAllowedDomains();
  }
  if (value) {
    const isDomainValid = allowedDomains.some(domain => value.endsWith(`@${domain}`));
    return isDomainValid ? Promise.resolve(true) : Promise.resolve(false);
  }
  return Promise.resolve(true);
};
