import { Tag } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const style = {
  width: "100%",
  fontSize: "10px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  scrollbars: "none",
  whiteSpace: "nowrap",
  padding: "0",
  margin: "0",
  textAlign: "center",
  pointerEvents: "none",
};

/**
 * Cleaning Schedule Cell
 * @component
 * @alias CleaningScheduleCell
 * @property {array} cleanings
 * @returns A definition what the content of calendar cell should look like. <Tag /> component for every event (cleaning).
 */
const CleaningScheduleCell = ({ cleanings }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {cleanings.map((cleaning) => {
        return (
          <Tag
            key={cleaning?.id}
            style={style}
            color={cleaning.is_recurring ? "geekblue" : "magenta"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {cleaning.is_recurring ? t("cleanings.recurring.cleaning") : t("cleanings.oneTime.cleaning")}
          </Tag>
        );
      })}
    </React.Fragment>
  );
};
export default CleaningScheduleCell;
