import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Descriptions, Form, Input, Modal, notification, Row, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import PartnerSelect from "./PartnerSelect";
import CitySelector from "../Cities/CitySelector";
import AccountBankCodeInput from "../Accounts/AccountBankCodeInput";
import AccountIbanInput from "../Accounts/AccountIbanInput";
import AccountNumberInput from "../Accounts/AccountNumberInput";
import AccountPrefixInput from "../Accounts/AccountPrefixInput";
import AccountSwiftInput from "../Accounts/AccountSwiftInput";
import countriesData from "../../data/country-ISO3166-1.alpha2.json";
import EmailInputWithValidation from "../custom/Form/EmailInputWithValidation";

const gutter = [16, 0];

const PartnerAddForm = ({ addToPartnersList, isFormSubmitting, setFormSubmitting }) => {
  const { t } = useTranslation();
  const [addContactForm] = Form.useForm();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isExternalDataFetching, setExternalDataFetching] = useState(false);

  const [form] = Form.useForm();

  const countriesArray = Object.keys(countriesData).map(code => ({
    code,
    name: countriesData[code]
  }));

  const loadExternalData = (companyNumber) => {
    setExternalDataFetching(true);

    coreApi.get("/partners/get-public-data/" + companyNumber).then((response) => {
      setExternalDataFetching(false);

      form.setFieldsValue({
        company_name: response.data.company_name,
        billing_address_city: response.data.city,
        billing_address_street: response.data.street,
        billing_address_postal_code: response.data.postal_code.toString(),
        billing_address_house_number: response.data.house_number.toString(),
      });
    });
  };

  return (
    <React.Fragment>
      <Button
        type="primary"
        size="large"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <PlusOutlined />
        {t("partners.addPartner")}
      </Button>
      <Modal
        width={600}
        title={t("partners.addPartner")}
        open={isModalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
        okText={t("common.add")}
        okButtonProps={{ loading: isFormSubmitting }}
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            setFormSubmitting(true);

            values.contract_signed = values.contract_signed.format("YYYY-MM-DD");

            coreApi
              .post("/partners/contractors", values)
              .then((contractorResponse) => {
                notification.success({
                  message: contractorResponse.data.message,
                });
                addToPartnersList(contractorResponse.data.contractor);

                setModalOpen(false);
                form.resetFields();
              })
              .catch((error) => {
                console.log(error);
                notification.error({
                  message: error?.response?.data?.message,
                });
              })
              .finally(() => {
                setFormSubmitting(false);
              });
          }}
          name={"addPartnerForm"}
        >
          <Row gutter={gutter}>
            <Col span={12}>
              <Form.Item label={t("common.firstName")} name="first_name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t("common.lastName")} name="last_name">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t("partners.identificationNumber")}
                name="company_number"
                rules={[
                  {
                    required: true,
                    message: t("partners.validation.companyNumber"),
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="contract_signed"
                label={t("partners.contractSigned")}
                rules={[
                  {
                    required: true,
                    message: t("partners.validation.dateSigned"),
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t("partners.vatNumber")} name="vat_number">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              loading={isExternalDataFetching}
              onClick={() => {
                loadExternalData(form.getFieldValue("company_number"));
              }}
              size="small"
            >
              {t("partners.loadExternalData")}
            </Button>
            <Button
              style={{ marginLeft: "10px" }}
              onClick={() => {
                alert("Not working yet.");
              }}
              size="small"
            >
              {t("partners.selectFromLeads")}
            </Button>
          </Form.Item>
          <Form.Item
            label={t("partners.companyName")}
            name="company_name"
            rules={[
              {
                required: true,
                message: t("partners.validation.companyName"),
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
          <Row gutter={gutter}>
            <Col span={12}>
              <EmailInputWithValidation
                form={addContactForm}
                name="email"
                label={t("common.email")}
                message={t("partners.validation.email")}
                required={true}
                requiredMessage={t("partners.validation.email")}
              />
            </Col>
            <Col span={12}>
              <Form.Item label={t("common.phone")} name="phone">
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={t("common.billingAddress")}
            required
            rules={[
              {
                required: true,
                message: t("partners.validation.billingAddress"),
              },
            ]}
          >
            <Row gutter={gutter}>
              <Col span={16}>
                <Form.Item required name="billing_address_street">
                  <Input placeholder={t("common.billingAddressStreet")}></Input>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item required name="billing_address_house_number">
                  <Input placeholder={t("common.billingAddressHouseNumber")}></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col span={8}>
                <Form.Item required name="billing_address_postal_code">
                  <Input placeholder={t("common.billingAddressPostalCode")}></Input>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item required name="billing_address_city">
                  <Input placeholder={t("common.billingAddressCity")}></Input>
                </Form.Item>
              </Col>
            </Row>
            <CitySelector required={true} />
          </Form.Item>
          <Row gutter={gutter}>
            <Col span={6}>
              <AccountPrefixInput />
            </Col>
            <Col span={12}>
              <AccountNumberInput />
            </Col>
            <Col span={6}>
              <AccountBankCodeInput />
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={16}>
              <AccountIbanInput />
            </Col>
            <Col span={8}>
              <AccountSwiftInput />
            </Col>
          </Row>
          <Form.Item
            label={t("finance.accounts.accountBankOwner")}
            name="account_bank_owner"
            rules={[
              {
                required: true,
                message: t("finance.accounts.validation.accountBankOwner"),
              },
            ]}
          >
            <Input></Input>
          </Form.Item>

          <Descriptions title={t("partners.additionalInformation")}></Descriptions>

          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item
                name="partner_type"
                label={t("common.typeBusinessEntity")}
                rules={[{ required: true }]}
                hasFeedback
              >
                <Select>
                  <Select.Option value="individual">{t("common.individual")}</Select.Option>
                  <Select.Option value="company">{t("common.company")}</Select.Option>
                </Select>
              </Form.Item>
              </Col>
          </Row>

          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item
                name="country_of_birth"
                label={t("common.countryOfBirth")}
                rules={[{ required: true }]}
                hasFeedback
              >
                <Select showSearch={true}>
                  {countriesArray.map(country => (
                    <Select.Option key={country.code} value={country.code}>
                      {country.code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item
                name="birth_city"
                label={t("common.birthCity")}
                rules={[{ required: true }]}
                hasFeedback
              >
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item
                name="birth_date"
                label={t("common.birthDate")}
                rules={[{ required: true }]}
                hasFeedback
              >
                <DatePicker format={"L"}></DatePicker>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item
                name="country_for_id"
                label={t("common.countryForId")}
                rules={[{ required: true }]}
                hasFeedback
              >
                <Select showSearch={true}>
                  {countriesArray.map(country => (
                    <Select.Option key={country.code} value={country.code}>
                      {country.code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item
                name="residency"
                label={t("common.residency")}
                rules={[{ required: true }]}
                hasFeedback
              >
                <Select showSearch={true}>
                  {countriesArray.map(country => (
                    <Select.Option key={country.code} value={country.code}>
                      {country.code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item
                name="vat_number"
                label={t("common.vatNumber")}
                rules={[{
                  min: 5,
                  max: 34,
                  required: true
                }]}
                hasFeedback
              >
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item
                name="vat_payers_number"
                label={t("common.vatPayersNumber")}
                rules={[{
                  min: 5,
                  max: 34
                }]}
                hasFeedback
              >
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>

          <Descriptions title={t("common.residenceInformation")}></Descriptions>

          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item name="street" label={t("common.street")} rules={[{ required: true }]} hasFeedback>
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item name="descriptive_number" label={t("common.descriptiveNumber")} rules={[{ required: true }]} hasFeedback>
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item name="apartment" label={t("common.apartment")} rules={[{ required: false }]} hasFeedback>
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item name="postcode" label={t("common.postcode")} rules={[{ required: true }]} hasFeedback>
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item name="city" label={t("common.city")} rules={[{ required: true }]} hasFeedback>
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item name="area" label={t("common.area")} rules={[{ required: false }]} hasFeedback>
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>

          <Descriptions title={t("partners.workers")}></Descriptions>
          <Form.List name="workers">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div key={index}>
                      <Row gutter={gutter}>
                        <Col span={7}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: t("partners.validation.workerFirstName"),
                              },
                            ]}
                            name={[field.name, "first_name"]}
                          >
                            <Input placeholder={t("common.firstName")}></Input>
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: t("partners.validation.workerLastName"),
                              },
                            ]}
                            name={[field.name, "last_name"]}
                          >
                            <Input placeholder={t("common.lastName")}></Input>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: t("partners.validation.contractFrom"),
                              },
                            ]}
                            name={[field.name, "contract_from"]}
                          >
                            <DatePicker placeholder={t("partners.contractFrom")}></DatePicker>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item name={[field.name, "contract_to"]}>
                            <DatePicker placeholder={t("partners.contractTo")}></DatePicker>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item name={[field.name, "affidavit"]} label={t("partners.affidavit")}>
                            <Checkbox />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <CitySelector required={true} name={[field.name, "city_branch_id"]} />
                        </Col>
                      </Row>

                      <Row gutter={gutter}>
                        <Col span={9}>
                          <EmailInputWithValidation
                            form={addContactForm}
                            name={[field.name, "email"]}
                            message={t("partners.validation.email")}
                            placeholder={t("common.email")}
                          />
                        </Col>
                        <Col span={9}>
                          <Form.Item name={[field.name, "phone"]}>
                            <Input placeholder={t("common.phone")}></Input>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Button
                            danger
                            type="dashed"
                            onClick={() => {
                              remove(field.name);
                            }}
                            block
                          >
                            <MinusCircleOutlined />
                            {t("common.remove")}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined />
                      {t("partners.addNewWorker")}
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
          <Form.Item>
            <Col span={12}>
              <PartnerSelect name="trainer_id" label={t("employees.trainerName")} />
            </Col>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default PartnerAddForm;
