import { DatePicker, Divider, Form, Button, Space, Row } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { InvoiceContext } from "../../pages/InvoiceProfile";
import { EditOutlined, CheckOutlined, StopOutlined } from "@ant-design/icons";

const size = "normal";

const InvoiceDetailsPane = ({ readOnly, handleFormSubmit,isInvoiceFormSubmitting }) => {
  const { t } = useTranslation();

  const { invoice } = useContext(InvoiceContext);
  const [isReadOnlyDateDue, setIsReadOnlyDateDue] = useState(readOnly);
  const [dateDue, setDateDue] = useState(invoice?.date_due);

  const handleDateDueEdit = () => {
    setIsReadOnlyDateDue(true);
    invoice.date_due = moment(dateDue).format("YYYY-MM-DD");
    handleFormSubmit(invoice);
  }

  return (
    <React.Fragment>
      <Divider orientation="left">{t("finance.invoices.details")}</Divider>
      <Form.Item
        name="date_issued"
        label={t("finance.invoices.dateIssued")}
        initialValue={moment(invoice?.date_issued)}
      >
        <DatePicker
          disabled={readOnly}
          format={"LL"}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Row>
        <Form.Item
          name="date_due"
          label={t("finance.invoices.dateDue")}
          initialValue={moment(dateDue)}
        >
          <DatePicker
            disabled={isReadOnlyDateDue}
            format={"LL"}
            onChange={(val) => setDateDue(val)}
          />
        </Form.Item>
        <Form.Item>
        {invoice?.invoice_type === 1 && (isReadOnlyDateDue ? (
          <Space>
            <Button
              disabled={!isReadOnlyDateDue}
              size={size}
              onClick={() => {
                setIsReadOnlyDateDue(false);
              }}
              icon={<EditOutlined />}
            ></Button>
          </Space>

        ) : (
          <Space>
            <Button
              size={size}
              loading={isInvoiceFormSubmitting}
              onClick={() => {
                handleDateDueEdit();
              }}
              icon={<CheckOutlined />}
            ></Button>
            <Button
              size={size}
              disabled={isInvoiceFormSubmitting}
              onClick={() => {
                setIsReadOnlyDateDue(true);
              }}
              icon={<StopOutlined />}
            ></Button>
          </Space>
        ))}
        </Form.Item>
      </Row>
      <Form.Item
        name="date_sent_to_recipient"
        label={t("finance.invoices.dateSentToRecipient")}
        initialValue={
          invoice?.date_sent_to_recipient
            ? moment(invoice?.date_sent_to_recipient)
            : null
        }
      >
        {invoice?.date_sent_to_recipient || !readOnly ? (
          <DatePicker
            disabled={readOnly}
            format={"LL"}
            style={{ width: "100%" }}
          />
        ) : (
          t("finance.invoices.notSent")
        )}
      </Form.Item>
      <Form.Item
        name="date_sent_to_publisher"
        label={t("finance.invoices.dateSentToPublisher")}
        initialValue={
          invoice?.date_sent_to_publisher
            ? moment(invoice?.date_sent_to_publisher)
            : null
        }
      >
        {invoice?.date_sent_to_publisher || !readOnly ? (
          <DatePicker
            disabled={readOnly}
            format={"LL"}
            style={{ width: "100%" }}
          />
        ) : (
          t("finance.invoices.notSent")
        )}
      </Form.Item>
    </React.Fragment>
  );
};

export default InvoiceDetailsPane;
