import { Card, Col, Row } from "antd";
import React from "react";
import OfferList from "../components/Offers/OfferList";

const OfferIndex = () => {
  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <Card>
            <OfferList></OfferList>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default OfferIndex;
