import React, {useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import axios from "axios";
import {coreApi} from "../../api/calls";
import {notification} from "antd";

const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

const PartnerCustomCalendar = ({ partner, cleanings, date }) => {
  const [welcomeTime, setWelcomeTime] = useState();
  const [allCleanings, setAllCleanings] = useState(cleanings ?? []);

  const startOfWeek = date.clone().startOf('isoWeek');
  const endOfWeek = date.clone().endOf('isoWeek');

  const requestedDays = partner?.requested_days
    ? (typeof partner.requested_days === 'string'
      ? JSON.parse(partner.requested_days)
      : partner.requested_days)
    : [];

  const getCleaningsWithoutTime = (cleanings) => {
    const filteredCleanings = cleanings.filter(item => {
      const cleaningDate = moment((item.date_real ?? item.date_expected));
      return cleaningDate.isSameOrBefore(endOfWeek) &&
        cleaningDate.isSameOrAfter(startOfWeek) &&
        (item.building_unit.building.requested_day === null || item.building_unit.building.requested_day && JSON.parse(item.building_unit.building.requested_day).length < 1) &&
        (item.building_unit.building.requested_time === null || item.building_unit.building.requested_time && JSON.parse(item.building_unit.building.requested_time).length < 1);
    });

    return days.reduce((result, day, index) => {
      result[index] = filteredCleanings.filter(item => {
        const dayIndex = moment((item.date_real ?? item.date_expected)).isoWeekday() - 1;
        return dayIndex === index;
      });
      return result;
    }, {});
  };

  const getCleaningsWithTime = (cleanings) => {
    const filteredCleanings = cleanings.filter(item => {
      const requested_days = item.building_unit.building.requested_day ? JSON.parse(item.building_unit.building.requested_day) : false;
      const requested_time = item.building_unit.building.requested_time ? JSON.parse(item.building_unit.building.requested_time) : false;
      return requested_days && requested_days.length || requested_time && requested_time.length;
    });

    return days.reduce((result, day, index) => {
      result[index] = filteredCleanings.filter(item => {
        const dayIndex = moment((item.date_real ?? item.date_expected)).isoWeekday() - 1;
        return dayIndex === index;
      });
      return result;
    }, {});
  };

  const withoutTime = getCleaningsWithoutTime(allCleanings);
  const withTime = getCleaningsWithTime(allCleanings);

  useEffect(() => {
    const updatedWelcomeTime = {};

    Object.entries(requestedDays).forEach(([day, dayArray]) => {
      const dayIndex = days.indexOf(day);
      if (dayIndex !== -1 && requestedDays[day]) {
        updatedWelcomeTime[dayIndex] = requestedDays[day].map((timeRange) => {
          return timeRange ? timeRange.map(time =>
            time ? moment(time).format('HH:mm') : ''
          ) : '';
        });
      }
    });
    setWelcomeTime(updatedWelcomeTime);
  }, [partner]);

  useEffect(() => {
    getCleaningsForAdjacentMonths();
  }, [date]);

  useEffect(() => {
    setAllCleanings(prevCleanings => {
      const updatedCleanings = [...prevCleanings, ...cleanings];
      return Array.from(new Set(updatedCleanings.map(item => item.id)))
        .map(id => updatedCleanings.find(item => item.id === id));
    });
  }, [cleanings]);

  const isTimeWithinRange = (welcomeTime, dayIndex, hour) => {
    const ranges = welcomeTime ? welcomeTime[dayIndex] : false;

    if (!ranges || !Array.isArray(ranges) || ranges.length === 0) {
      return false;
    }

    const timeToCheck = moment(hour, "HH");

    return ranges.some((range) => {
      if (!range || range.length < 2 || !range[0] || !range[1]) {
        return false;
      }

      const start = moment(range[0], "HH:mm");
      const end = moment(range[1], "HH:mm");

      return timeToCheck.isSameOrAfter(start) && timeToCheck.isSameOrBefore(end);
    });
  };

  const isWorkHour = (range, hour) => {
    if (!range || !Array.isArray(range) || range.length !== 2) {
      return false;
    }

    const hourToCheck = moment(hour, "HH:mm");
    const start = moment(range[0]);
    const end = moment(range[1]);
    const startHour = moment(start).format('HH:mm');
    const endHour = moment(end).format('HH:mm');

    return hourToCheck.isSameOrAfter(moment(startHour, "HH:mm")) &&
      hourToCheck.isSameOrBefore(moment(endHour, "HH:mm"));
  };

  const getCleaningsForAdjacentMonths = () => {
    const startMonth = startOfWeek.month();
    const endMonth = endOfWeek.month();

    if (startMonth !== endMonth) {
      fetchCleaningsForMonth();
    }
  }

  const fetchCleaningsForMonth = () => {
    if (!partner?.id) return false;
    let params = {
      partner_id: partner?.id,
      date_from: startOfWeek.clone().format("YYYY-MM-DD"),
      date_to: endOfWeek.clone().format("YYYY-MM-DD"),
      page_size: 200,
    };

    axios.all([
        coreApi.get("/cleanings/recurring", {params: params}),
        coreApi.get("/cleanings/one-time", {params: params}),
      ])
      .then(
        axios.spread((recurringCleaningsResponse, oneTimeCleaningsResponse) => {
          setAllCleanings(prevCleanings => {
            const updatedCleanings = [...prevCleanings, ...recurringCleaningsResponse.data, ...oneTimeCleaningsResponse.data];
            return Array.from(new Set(updatedCleanings.map(item => item.id)))
              .map(id => updatedCleanings.find(item => item.id === id));
          });
        })
      )
      .catch((error) => { notification.error({ message: error?.response?.data?.message }) });
  };

  const renderWeekCalendar = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(startOfWeek.clone().add(i, 'days'));
    }

    return (
      <div className="week-calendar">
        <div className="calendar-header">
          <div className="time-column-header"></div>
          {days.map((day, index) => (
            <div
              key={index}
              className={`day-column-header ${moment().isSame(day, 'day') ? 'today' : ''}`}
            >
              {day.format('D.MM.YYYY')}
            </div>
          ))}
        </div>
        <div className="calendar-body">
          <div className="time-column">
            <div className="time-cell">Zakázky nevázané na čas</div>
            {Array.from({ length: 24 }, (_, hour) => (
              <div key={hour} className={`time-cell ${ hour % 2 ? '' : 'odd'}`}>{hour}:00</div>
            ))}
          </div>
          {days.map((day, dayIndex) => (
            <div key={dayIndex} className="day-column">
              <div className={`time-slot ${withoutTime[dayIndex].length ? 'red' : ''} ${withoutTime[dayIndex].length > 2 ? 'scroll' : ''}`}>
                {withoutTime[dayIndex]?.map((item, itemIndex) => (
                  <span
                    key={itemIndex}
                    className="ant-tag"
                    style={{
                      maxWidth: '100%',
                      fontSize: '10px',
                      padding: '2px 5px',
                      margin: '0px',
                      lineHeight: '10px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                  <span>
                    {item.building_unit.street} {item.building_unit.house_number}
                  </span>
                </span>
                ))}
              </div>
              {Array.from({ length: 24 }, (_, hour) => {
                const events = withTime[dayIndex]
                  ? withTime[dayIndex].filter((event) =>
                    isWorkHour(JSON.parse(event.building_unit.building.requested_time), hour) &&
                    moment((event.date_real ?? event.date_expected)).isSame(day.clone().hour(hour), 'day')
                  )
                  : [];

                const green = isTimeWithinRange(welcomeTime, dayIndex, hour) ? 'green' : '';
                const odd = hour % 2 ? '' : 'odd';

                if (events.length > 0) {
                  const eventBlocks = events.map((event) => {
                    const timeRange = JSON.parse(event.building_unit.building.requested_time);
                    const startHour = moment(timeRange[0]).hour();
                    const endHour = moment(timeRange[1]).hour();
                    const eventDuration = endHour - startHour + 1;
                    const middleHour = startHour + Math.floor(eventDuration / 2);

                    return {
                      event,
                      startHour,
                      endHour,
                      middleHour,
                    };
                  });

                  const currentEvent = eventBlocks.find(
                    (block) => hour >= block.startHour && hour <= block.endHour
                  );
                  const scroll = hour === currentEvent.middleHour && events.length > 2 ? 'scroll middle' : '';
                  if (currentEvent) {
                    return (
                      <div key={hour} className={`time-slot red ${green} ${odd} ${scroll}`} >
                        {hour === currentEvent.middleHour && (
                          eventBlocks
                            .filter((block) => block.middleHour === currentEvent.middleHour)
                            .map((block, idx) => (
                              <span
                                key={idx}
                                className="ant-tag"
                                style={{
                                  maxWidth: '100%',
                                  fontSize: '10px',
                                  padding: '2px 5px',
                                  margin: '0px',
                                  lineHeight: '10px',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                              >
                                <span>
                                  {block.event.building_unit.street} {block.event.building_unit.house_number}{' '}
                                </span>
                              </span>
                            ))
                        )}
                      </div>
                    );
                  }
                }
                return (
                  <div key={hour} className={`time-slot ${green} ${odd}`}></div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderWeekCalendar()}
    </div>
  );
};

export default PartnerCustomCalendar;
