import { Col, Form, Input, InputNumber, notification, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import AccountSelector from "../Accounts/AccountSelector";
import ManagerSelect from "../ManagerSelect";
import ProfileFormActionSpace from "../ProfileFormActionSpace";
import EmailInputWithValidation from "../custom/Form/EmailInputWithValidation";

/**
 * City Profile Pane
 * @component
 * @alias CityProfilePane
 * @property {object} city
 * @returns Structured city record, used as a profile content
 */
const CityProfilePane = ({ city }) => {
  const { t } = useTranslation();
  const [addContactForm] = Form.useForm();
  const [isFormReadOnly, setFormReadOnly] = useState(true);
  const [isFormSending, setFormSending] = useState(false);

  const [cityForm] = Form.useForm();

  return (
    <React.Fragment>
      <Form
        form={cityForm}
        onFinish={(values) => {
          setFormSending(true);
          coreApi
            .put("/cities/" + values.city_branch_id, values)
            .then((response) => {
              cityForm.setFieldsValue(values);
              setFormReadOnly(true);
              notification.success({
                message: response?.data?.message || t("successNotifications.saveChanges.title"),
              });
            })
            .catch((error) => {
              notification.error({
                message: error?.response?.data?.message || t("errorNotifications.saveChanges.title"),
              });
            })
            .finally(() => {
              setFormSending(false);
            });
        }}
      >
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <ProfileFormActionSpace
              formInstance={cityForm}
              isFormReadOnly={isFormReadOnly}
              isFormSending={isFormSending}
              setFormEditable={() => setFormReadOnly(false)}
              setFormDisabled={() => setFormReadOnly(true)}
            />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12} xl={8}>
            <Form.Item hidden name="country_branch_id" initialValue={city?.country_branch_id}>
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
            <Form.Item hidden name="city_branch_id" initialValue={city?.id}>
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
            <Form.Item name="name" label={t("expansion.cityName")} initialValue={city?.name}>
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} xl={8}>
            <Form.Item name="name_eng" label={t("expansion.cityNameEng")} initialValue={city?.name_eng}>
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} xl={8}>
            <Form.Item name="web" label={t("expansion.cityWeb")} initialValue={city?.web}>
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} xl={8}>
            <EmailInputWithValidation
              form={addContactForm}
              name="email"
              label={t("expansion.cityEmail")}
              message={t("common.emailValidation")}
              initialValue={city?.email}
              readOnly={isFormReadOnly}
              bordered={!isFormReadOnly}
            />
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item name="city_number" label={t("expansion.cityNumber")} initialValue={city?.city_number}>
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              name="default_service_share"
              label={t("expansion.cityDefaultServiceShare")}
              initialValue={city?.default_service_share}
            >
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item name="hourly_wage" label={t("expansion.cityHourlyWage")} initialValue={city?.hourly_wage}>
              <InputNumber readOnly={isFormReadOnly} bordered={!isFormReadOnly}></InputNumber>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} xl={8}>
            <ManagerSelect
              label={t("managers.selectManager")}
              validationMessage={t("managers.notifications.errorFetchingManagers")}
              initialValue={city?.manager_id}
              required={false}
              readOnly={isFormReadOnly}
              bordered={!isFormReadOnly}
            />
          </Col>
          <Col xs={24} lg={12} xl={8}>
            <AccountSelector readOnly={isFormReadOnly} initialValue={city?.account_id} required={false} />
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default CityProfilePane;
