import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, List, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import CleaningMinutesItem from "./CleaningMinutesItem";

/**
 * City Profile Cleaning Minutes - for future autofill of a offer
 * @component
 * @alias CityProfileCleaningMinutes
 * @property {object} city
 * @returns <List /> of records - average cleaning minutes for city
 */
const CityProfileCleaningMinutes = ({ city }) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  const [isAdding, setAdding] = useState(false);

  const [cleaningMinutes, setCleaningMinutes] = useState([]);

  useEffect(() => {
    setLoading(true);

    city &&
      coreApi
        .get("/cities/" + city?.id + "/cleaning-minutes-estimation")
        .then((res) => setCleaningMinutes([...res.data]))
        .catch((err) => notification.error(err.response.data.message))
        .finally(() => setLoading(false));
  }, [city]);

  return (
    <React.Fragment>
      <List loading={isLoading} size={"small"}>
        <Row
          gutter={[16, 16]}
          style={{
            textAlign: "center",
            paddingBottom: 20,
            paddingTop: 10,
            marginBottom: 10,
            backgroundColor: "#fafafa",
            fontWeight: "500",
            borderBottom: "1px solid rgba(0, 0, 0, .05)",
          }}
        >
          <Col span={6}>{t("cleaningMinutes.floorCount")}</Col>
          <Col span={6}>{t("cleaningMinutes.minutesPerGroundFloor")}</Col>
          <Col span={6}>{t("cleaningMinutes.minutesPerFloor")}</Col>
          <Col span={6}>{t("common.action")}</Col>
        </Row>
        {cleaningMinutes.map((item) => (
          <CleaningMinutesItem
            item={item}
            key={item?.id}
            cleaningMinutes={cleaningMinutes}
            setCleaningMinutes={setCleaningMinutes}
          />
        ))}
      </List>
      <Row gutter={[16, 16]}>
        <Col></Col>
        <Col span={24}>
          {!isAdding ? (
            <Button icon={<PlusOutlined />} style={{ width: "100%" }} onClick={() => setAdding(true)}>
              {t("common.add")}
            </Button>
          ) : (
            <CleaningMinutesItem
              idCityBranch={city?.id}
              cleaningMinutes={cleaningMinutes}
              setCleaningMinutes={setCleaningMinutes}
              isAdding={isAdding}
              setAdding={setAdding}
            ></CleaningMinutesItem>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CityProfileCleaningMinutes;
