import { Col, Form, notification, Row } from "antd";
import React, { useContext, useState } from "react";
import { coreApi } from "../../api/calls";
import { InvoiceContext } from "../../pages/InvoiceProfile";
import InvoiceDetailsPane from "./InvoiceDetailsPane";
import InvoiceLocalePane from "./InvoiceLocalePane";
import InvoiceRecipientPane from "./InvoiceRecipientPane";
import InvoiceSupplierPane from "./InvoiceSupplierPane";

const InvoiceOverviewContainer = () => {
  const { invoice, refreshInvoice } = useContext(InvoiceContext);

  const [isInvoiceFormSubmitting, setInvoiceFormSubmitting] = useState(false);
  const [isInvoiceFormReadOnly, setInvoiceFormReadOnly] = useState(true);

  const [invoiceForm] = Form.useForm();

  const handleFormSubmit = (values) => {
    setInvoiceFormSubmitting(true);
    coreApi
      .put("/invoices/" + invoice?.id, values)
      .then((response) => {
        refreshInvoice(response.data.invoice);
        notification.success({ description: response.data.message });
        setInvoiceFormReadOnly(true);
      })
      .catch((error) => {
        notification.error({
          description: error.response.data.message,
        });
      })
      .finally(() => {
        setInvoiceFormSubmitting(false);
      });
  }

  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          {/* <ProfileFormActionSpace
            formInstance={invoiceForm}
            isFormReadOnly={isInvoiceFormReadOnly}
            isFormSubmitting={isInvoiceFormSubmitting}
            setFormEditable={() => {
              setInvoiceFormReadOnly(false);
            }}
            setFormDisabled={() => {
              setInvoiceFormReadOnly(true);
            }}
          /> */}
        </Col>
        <Form
          onFinish={(values) => {
            handleFormSubmit(values);
          }}
          scrollToFirstError
          form={invoiceForm}
          layout="horizontal"
        >
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={12}>
              <InvoiceSupplierPane
                readOnly={isInvoiceFormReadOnly}
                name={invoice?.supplier_name}
                companyNumber={invoice?.supplier_company_number}
                vatNumber={invoice?.supplier_vat_number}
                address={invoice?.supplier_address}
                postalCode={invoice?.supplier_postal_code}
                city={invoice?.supplier_city}
              />
            </Col>
            <Col xs={24} lg={12}>
              <InvoiceRecipientPane
                readOnly={isInvoiceFormReadOnly}
                name={invoice?.recipient_name}
                companyNumber={invoice?.recipient_company_number}
                vatNumber={invoice?.recipient_vat_number}
                address={invoice?.recipient_address}
                postalCode={invoice?.recipient_postal_code}
                city={invoice?.recipient_city}
              />
            </Col>
            <Col xs={24} lg={12}>
              <InvoiceDetailsPane readOnly={isInvoiceFormReadOnly} handleFormSubmit={handleFormSubmit} isInvoiceFormSubmitting={isInvoiceFormSubmitting}/>
            </Col>
            <Col span={24} lg={12}>
              <InvoiceLocalePane readOnly={isInvoiceFormReadOnly} />
            </Col>
            <Col span={24} lg={12}></Col>
          </Row>
        </Form>
      </Row>
    </React.Fragment>
  );
};

export default InvoiceOverviewContainer;
