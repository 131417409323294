import { Button, Divider, Table, Space, Modal, notification } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../../api/calls";
import ClientLinkRepresentedForm from "./ClientLinkRepresentedForm";

const RepresentedClientsPane = ({ client, buildings }) => {
  const { t } = useTranslation();

  const [representedClients, setrepresentedClients] = useState(client?.representing);

  const addRepresentedClient = (payload) => {
    coreApi
      .post("/clients-represented", { ...payload })
      .then((res) => {
        setrepresentedClients([...representedClients, res.data.client]);
        notification.success({ message: res.data.message });
      })
      .catch((err) => notification.error({ message: err.response.data.message }));
  };

  const tableColumns = [
    {
      title: t("clients.name"),
      ellipsis: true,
      width: "80%",
      render: (rowData) => {
        return <Link to={"/clients/" + rowData?.id}>{rowData?.name}</Link>;
      },
    },
    {
      title: t("common.action"),
      width: "20%",
      render: (rowData) => {
        return (
          <Space>
            <Button
              size={"small"}
              danger
              onClick={() => {
                Modal.confirm({
                  title: t("clients.deleteRepresented.alertTitle"),
                  content: t("clients.deleteRepresented.alertContent"),
                  onOk: () => {
                    Modal.confirm({
                      title: t("common.lastWarning"),
                      onOk: () => {
                        coreApi
                          .post("/clients/representative/unlink", {
                            client_id: client?.id,
                            representative_client_id: rowData.id,
                          })
                          .then((res) => {
                            let newClients = representedClients.filter((item) => item.id !== rowData.id);
                            setrepresentedClients([...newClients]);
                            notification.success({ message: res.data.message });
                          })
                          .catch((err) =>
                            notification.error({
                              message: err.response.data.message,
                            })
                          );
                      },
                    });
                  },
                });
              }}
            >
              {t("common.deleteLink")}
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Divider orientation={"left"}>{t("clients.representedClients")}</Divider>
      <Table size={"small"} dataSource={representedClients} columns={[...tableColumns]} rowKey={"id"}></Table>
      <ClientLinkRepresentedForm buildings={buildings} client={client} addRepresentedClient={addRepresentedClient} />
    </React.Fragment>
  );
};

export default RepresentedClientsPane;
