import { Card, Form, Input, Button, notification } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import { useHistory } from "react-router-dom";

const PasswordChangeCard = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [passwordForm] = Form.useForm();

  return (
    <Card title={t("userSettings.passwordChange")} style={{ marginBottom: "16px" }}>
      <Form
        form={passwordForm}
        onFinish={(values) => {
          setLoading(true);
          coreApi
            .post("/users/password-change", { ...values })
            .then((res) => {
              notification.success({ message: res.data.message });
              history.push("/login");
              localStorage.setItem("user", null);
            })
            .catch((err) => {
              notification.error({ message: err?.response?.data?.message });
            })
            .finally(() => setLoading(false));
        }}
      >
        <Form.Item hidden name={"user_id"} initialValue={JSON.parse(localStorage.getItem("user")).id}>
          <Input />
        </Form.Item>
        <Form.Item name={"old_password"} label={"Staré heslo"}>
          <Input type={"password"} />
        </Form.Item>
        <Form.Item name={"new_password"} label={"Nové heslo"}>
          <Input type={"password"} />
        </Form.Item>
        <Form.Item name={"new_password_copy"} label={"Nové heslo znovu"}>
          <Input type={"password"} />
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          <Button type={"primary"} onClick={(e) => passwordForm.submit()} loading={loading}>
            {t("userSettings.changePassword")}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default PasswordChangeCard;
