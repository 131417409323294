import { FileSearchOutlined } from "@ant-design/icons";
import { Button, Col, Divider, List, Modal, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

/**
 * Building Offers Modal - used as a view of buildings Offers/Orders
 * @component
 * @alias BuildingOffersModal
 * @property {array} offers - array of Offers
 * @property {array} orders - array of Orders
 * @returns <Button /> which opens a <Modal /> containing two <List /> components - Offers, Orders
 */
const BuildingOffersModal = ({ offers, orders }) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <React.Fragment>
      <Button
        disabled={(offers?.length || 0) < 1 && (orders?.length || 0) < 1}
        size="small"
        onClick={() => {
          setModalOpen(true);
        }}
        icon={<FileSearchOutlined />}
      ></Button>
      <Modal
        open={isModalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
        title={t("buildings.offers")}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} xxl={12}>
            <Divider orientation={"left"}>{t("offers.offers")}</Divider>
            <List>
              {offers?.map((offer, index) => {
                let price = new Intl.NumberFormat("cs-CZ", {
                  style: "currency",
                  currency: offer.currency_iso_4217 || "CZK",
                }).format(offer.price);

                return (
                  <List.Item key={index}>
                    <Link to={"/offers/" + offer?.id}>
                      {price + " - " + t("finance.invoices.dateIssued") + " " + moment(offer.date_issued).format("LL")}
                    </Link>
                  </List.Item>
                );
              })}
            </List>
          </Col>
          <Col xs={24} xxl={12}>
            <Divider orientation={"left"}>{t("offers.orders")}</Divider>
            <List>
              {orders?.map((order, index) => {
                let price = new Intl.NumberFormat("cs-CZ", {
                  style: "currency",
                  currency: order.currency_iso_4217 || "CZK",
                }).format(order.price);

                return (
                  <List.Item key={index}>
                    <Link to={"/orders/" + order?.id}>
                      {price + " - " + t("finance.invoices.dateIssued") + " " + moment(order.date_issued).format("LL")}
                    </Link>
                  </List.Item>
                );
              })}
            </List>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default BuildingOffersModal;
