import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { addAreaScope } from "../actions";
import { coreApi } from "../api/calls";
import CountryAddCity from "./CityBranchAddForm";

const CountryCitiesPane = ({ citiesProp, IdCountryBranch }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const addCityToRedux = (city) => dispatch(addAreaScope(city));
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setCities(citiesProp);
  }, [citiesProp]);

  const addCity = (values) => {
    coreApi
      .post("/cities", values)
      .then((res, req) => {
        setCities([...cities, res.data.city_branch]);
        addCityToRedux(res.data.city_branch);
      })
      .catch((err) => console.error(err));
  };

  const columns = [
    {
      title: t("expansion.cityTable.name"),
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (city) => <Link to={`/countries/${city?.country_branch_id}/cities/${city?.id}`}>{city.name}</Link>,
    },
    {
      title: t("expansion.managerName"),
      key: "managerName",
      sorter: (a, b) => a?.manager?.last_name.localeCompare(b?.manager?.last_name),
      render: (city) => {
        return (
          <Link to={`/employees/${city?.manager?.id}`}>
            {city?.manager?.first_name + " " + city?.manager?.last_name}
          </Link>
        );
      },
    },
    {
      title: t("expansion.cityTable.web"),
      dataIndex: "web",
      key: "web",
      sorter: (a, b) => a.web.localeCompare(b.web),
      render: (rowData) => (
        <a href={correctURL(rowData)} target={"blank"}>
          {rowData}
        </a>
      ),
    },
    {
      title: t("expansion.cityTable.mail"),
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (rowData) => <a href={"mailto:" + rowData}>{rowData}</a>,
    },
  ];

  const correctURL = (url) => {
    try {
      return new URL(url.replace(/^(https?:\/\/|https?:\/|www\.|https?|['"])/, 'https://')).toString();
    } catch {
      return url;
    }
  }

  return (
    <React.Fragment>
      <Table size="small" dataSource={cities} columns={columns} rowKey={"id"}></Table>
      <CountryAddCity onFinishAction={addCity} idCountryBranch={IdCountryBranch}></CountryAddCity>
    </React.Fragment>
  );
};

export default connect()(CountryCitiesPane);
