import { CloseOutlined, FilePdfOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Input, notification, Row, Space, Table, Tooltip, Typography } from "antd";
import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "react-slidedown/lib/slidedown.css";
import { getAreaScope } from "../../actions";
import { coreApi } from "../../api/calls";
import InvoicePaymentState from "./InvoicePaymentState";
import EmployeeLink from "../Employees/EmployeeLink";
import ManagerSelect from "./../ManagerSelect";

const InvoicesList = ({
  apiEntityPath,
  partnerIssued,
  annaIssued,
  clientRecipient,
  partnerRecipient,
  annaRecipient,
  state,
  invoiceType,
  invoicesPerPage,
}) => {
  const { t } = useTranslation();

  const [invoices, setInvoices] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, page_size: invoicesPerPage});
  const [eurRate, setEurRate] = useState(25);

  const areaScope = useSelector(getAreaScope);
  const selectedCity = areaScope?.areaScope;

  const [invoicesLoading, setInvoicesLoading] = useState(false);

  const [sorter, setSorter] = useState({});

  const [params, setParams] = useState({
    date_issued_from: null,
    date_issued_to: null,
    date_due_from: null,
    date_due_to: null,
    paid: null,
    unpaid_due: null,
    unpaid_overdue: null,
    overpaid: null,
    invoice_type: invoiceType,
    manager_id: null
  });

  const [selectedRows, setSelectedRows] = useState([]);

  const [isXmlDownloading, setXmlDownloading] = useState(false);
  const [isPdfDownloading, setPdfDownloading] = useState(false);

  const columns = [
    {
      title: t("finance.invoices.invoiceNumber"),
      key: "invoice_number",
      sorter: true,
      render: (invoice) => <Link to={"/invoices/" + invoice.id}>{invoice.invoice_number}</Link>,
    },
    {
      title: t("finance.invoices.supplier"),
      key: "supplier",
      render: (invoice) => {
        if (partnerIssued) {
          return <Link to={"/partners/" + invoice.invoice_publisher_id}>{invoice.supplier_name}</Link>;
        } else if (annaIssued) {
          return <Link to={"/countries/" + invoice.country_branch_id}>{invoice.supplier_name}</Link>;
        }
      },
    },
    {
      title: t("finance.invoices.recipient"),
      key: "recipient",
      width: "20%",
      render: (invoice) => {
        if (clientRecipient) {
          return <Link to={"/buildings/" + invoice.building?.id}>
            {invoice.building?.registration_number ? invoice.building.registration_number + " - " : ""}
            {String(invoice.building?.reference)}
        </Link>;
        } else if (partnerRecipient) {
          return <Link to={"/partners/" + invoice.invoice_recipient_id}>{invoice.recipient_name}</Link>;
        } else if (annaRecipient) {
          return <Link to={"/countries/" + invoice.country_branch_id}>{invoice.recipient_name}</Link>;
        }
      },
    },
    {
      title: t("finance.invoices.totalAmount"),
      key: "total_amount",
      sorter: true,
      render: (invoice) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: invoice.currency_iso_4217 || "CZK",
        }).format(invoice.total_amount);
      },
    },
    {
      title: t("finance.invoices.dateIssued"),
      key: "date_issued",
      dataIndex: "date_issued",
      render: (date) => moment(date).format("L"),
      sorter: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Space>
            <DatePicker.RangePicker
              picker={"month"}
              value={[selectedKeys[0], selectedKeys[1]]}
              onChange={(e) => setSelectedKeys(e !== null && e.length === 2 ? [e[0], e[1]] : [])}
            />
            <Button
              onClick={() => {
                clearFilters();
                let newParams = { ...params };
                newParams.date_issued_from = null;
                newParams.date_issued_to = null;
                setParams(newParams);
              }}
              icon={<CloseOutlined />}
            />
            <Button
              type={"primary"}
              onClick={() => {
                confirm({ closeDropdown: false });
                let newParams = { ...params };
                newParams.date_issued_from = selectedKeys[0].format("YYYY-MM-DD");
                newParams.date_issued_to = selectedKeys[1].format("YYYY-MM-DD");
                setParams(newParams);
              }}
              icon={<FilterOutlined />}
            />
          </Space>
        </div>
      ),
    },
    {
      title: t("finance.invoices.dateDue"),
      key: "date_due",
      dataIndex: "date_due",
      sorter: true,
      render: (date) => moment(date).format("L"),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Space>
            <DatePicker.RangePicker
              picker={"month"}
              value={[selectedKeys[0], selectedKeys[1]]}
              onChange={(e) => setSelectedKeys(e !== null && e.length === 2 ? [e[0], e[1]] : [])}
            />
            <Button
              onClick={() => {
                clearFilters();
                let newParams = { ...params };
                newParams.date_due_from = null;
                newParams.date_due_to = null;
                setParams(newParams);
              }}
              icon={<CloseOutlined />}
            />
            <Button
              type={"primary"}
              onClick={() => {
                confirm({ closeDropdown: false });
                let newParams = { ...params };
                newParams.date_due_from = selectedKeys[0].format("YYYY-MM-DD");
                newParams.date_due_to = selectedKeys[1].format("YYYY-MM-DD");
                setParams(newParams);
              }}
              icon={<FilterOutlined />}
            />
          </Space>
        </div>
      ),
    },
    {
      title: t("expansion.city"),
      key: "city_branch_id",
      sorter: true,
      render: (invoice) => <Link to={"/cities/" + invoice.city_branch?.id}>{invoice.city_branch.name}</Link>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <Typography.Text>{t("common.useGlobalFilter")}</Typography.Text>
      ),
    },
    {
      title: t("finance.invoices.paymentState"),
      key: "status",
      render: (invoice) => <InvoicePaymentState invoice={invoice} />,
      filters: [
        { text: t("finance.invoices.paid"), value: "paid" },
        { text: t("finance.invoices.unpaidDue"), value: "unpaid_due" },
        { text: t("finance.invoices.unpaidOverdue"), value: "unpaid_overdue" },
        ...(invoiceType === 1 || invoiceType === 4 ? [{ text: t("finance.invoices.overpaid"), value: "overpaid" }] : []),
      ],
      filterMultiple: false,
    },
    ...(apiEntityPath === "partner-client" || apiEntityPath === "corrective-documents" ? [{
      title: t("invoices.manager"),
      key: "manager",
      render: (invoice) => {
        if (invoice.service_provider?.user?.employee) {
          return <EmployeeLink employee={invoice.service_provider.user.employee}/>;
        }
        return null;
      },
      filterDropdown: ({ setSelectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <ManagerSelect
            clearable
            sendOnchange={(value) => {
              setSelectedKeys([value]);
              let newParams = { ...params };
              newParams.manager_id = value;
              setParams(newParams);
              confirm();
            }}
          />
        </div>
      ),
    }] : []),
];

  const handleTableChange = (pagination, filters, sorter) => {
    let newParams = { ...params };
    newParams.paid = filters.status?.includes("paid") ? 1 : 0;
    newParams.unpaid_due = filters.status?.includes("unpaid_due") ? 1 : 0;
    newParams.unpaid_overdue = filters.status?.includes("unpaid_overdue") ? 1 : 0;
    newParams.overpaid = filters.status?.includes("overpaid") ? 1 : 0;
    newParams.manager_id = filters.manager && filters.manager.length ? filters.manager[0] : null;

    setParams(newParams);
    setSorter(sorter);
    setPagination({ ...pagination, page_size: pagination?.pageSize, page: pagination?.current });
  };

  useEffect(() => {
    setInvoicesLoading(true);

    axios
      .all([
        coreApi.get("/invoices/" + apiEntityPath, {
          params: {
            ...params,
            ...pagination,
            city_branch_id: selectedCity,
            sorterOrder: sorter?.order,
            sorterKey: sorter?.columnKey,
          },
        }),
      ])
      .then(
        axios.spread((invoices) => {
          let { data, ...pagination } = invoices.data;
          setInvoices(data);
          setPagination(pagination);
        })
      )
      .catch((err) => notification.error({ message: err.response.data.message }))
      .finally(() => {
        setInvoicesLoading(false);
      });
  }, [params, selectedCity, apiEntityPath, state, sorter]);

  return (
    <React.Fragment>
      <Row gutter={[8, 24]}>
        <Col span={24}>
          <Card size="small">
            {/* <SlideDown className={"my-dropdown-slidedown"} closed={selectedRows.length < 1}> */}
            <Space style={{ paddingBottom: "16px" }}>
              <Tooltip title="Vlastní hodnota EUR pro export:">
                <Input type="number" value={eurRate} onChange={(e) => setEurRate(e.target.value)} />
              </Tooltip>

              <Tooltip title="Exportuje všechny faktury, dle zvoleného filtru v tabulce.">
                <Button
                  loading={isXmlDownloading}
                  icon={<FilePdfOutlined />}
                  // disabled={selectedRows.length < 1}
                  onClick={() => {
                    setXmlDownloading(true);
                    coreApi
                      .request({
                        method: "post",
                        data: { ...params, ...{ eurRate: Number(eurRate) }, ...{'selectedRows': selectedRows} },
                        url: "/invoices/download/xml-list",
                        responseType: "blob",
                      })
                      .then((response) => {
                        fileDownload(response.data, "invoices.xml");
                      })
                      .catch(() => {})
                      .finally(() => {
                        setXmlDownloading(false);
                      });
                  }}
                >
                  {t("finance.invoices.downloadXml")}
                </Button>
              </Tooltip>
              <Button
                loading={isPdfDownloading}
                icon={<FilePdfOutlined />}
                disabled={true}
                onClick={() => {
                  setPdfDownloading(true);
                  coreApi
                    .request({
                      method: "post",
                      data: { invoices: selectedRows },
                      url: "/invoices/download/pdf",
                      responseType: "blob",
                    })
                    .then((response) => {
                      fileDownload(response.data, "invoices.zip");
                    })
                    .catch((error) => {})
                    .finally(() => {
                      setPdfDownloading(false);
                    });
                }}
              >
                {t("finance.invoices.downloadPdf")}
              </Button>
              <Button
                loading={invoicesLoading}
                onClick={() => {
                  setPagination({ ...pagination, page_size: pagination?.total, page: 1 });
                  setParams({
                    ...params,
                    ...pagination,
                    city_branch_id: selectedCity,
                    sorterOrder: sorter?.order,
                    sorterKey: sorter?.columnKey,
                  });
                }}
              >
                {t("invoices.showAll")}
              </Button>
            </Space>
            {/* </SlideDown> */}
            <Table
              size="small"
              rowKey="id"
              rowSelection={{
                selectedRowKeys: selectedRows,
                onChange: (selectedRowKeys) => {
                  setSelectedRows(selectedRowKeys);
                },
              }}
              onChange={handleTableChange}
              columns={columns}
              pagination={{
                current: pagination?.current_page,
                pageSize: pagination?.per_page,
                total: pagination?.total,
                showTotal: (total, range) => (
                  <p>
                    {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
                    {" " + t("common.paginationPartTwo") + " "}
                    <strong>{total}</strong> {t("common.paginationPartThree")}
                  </p>
                ),
              }}
              dataSource={invoices}
              loading={invoicesLoading}
            ></Table>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InvoicesList;
