import { Form, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import useCoreApi from "../../../hooks/useCoreApi";

/**
 * @component
 * @alias InvoiceBuildingSelect
 * @property {integer} idClient
 * @property {boolean} required
 * @property {validationMessage} string
 * @returns Select input with buildings
 */
const InvoiceBuildingSelect = ({ idClient = 0, required = false, validationMessage = "" }) => {
  const { t } = useTranslation();

  const { loading, response } = useCoreApi("get", "/buildings/by-client/" + idClient);

  return (
    <React.Fragment>
      <Form.Item
        name="building_id"
        label={t("buildings.selectBuilding")}
        rules={[{ required: required, message: validationMessage }]}
      >
        <Select loading={loading} allowClear>
          {response?.map((item, index) => (
            <Select.Option key={index} value={item?.id}>
              {item?.reference}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </React.Fragment>
  );
};

export default InvoiceBuildingSelect;
