import { Table, Tag, Button, Row, Col, notification, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../../api/calls";
import { INVOICE_TYPES } from '../../constants';

const InvoicesTableSimple = ({
  invoices,
  loading,
  fetchData,
  pagination,
  filters,
  setFilters,
  setPagination,
  setSorter,
}) => {
  const { t } = useTranslation();
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [rowSelectionEnabled, setRowSelectionEnabled] = useState(false);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    const filteredInvoices = selectedInvoices.filter(inv => inv.corrected_invoice_id);
    const combinedSelections = [...filteredInvoices, ...selectedRows.filter(row => !row.corrected_invoice_id)];
    setSelectedInvoices(combinedSelections);
  };

  const rowSelection = {
    selectedRowKeys: selectedInvoices.map(invoice => invoice.id),
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: !rowSelectionEnabled,
      name: `invoice-${record.id}`,
    }),
  };

  function sendSelectedInvoices() {
    if (selectedInvoices.length > 0) {
      setDataLoading(true);

      const invoiceData = selectedInvoices.map(invoice => invoice.invoice_number);

      coreApi
        .post("invoices/send-selected", {invoice_numbers: invoiceData})
        .then((response) => {
          notification.success({ message: response.data.message });
          fetchData();
        })
        .catch((err) => notification.error({ message: err.response.data.message }))
        .finally(() => {
          setDataLoading(false);
          setShowConfirmModal(false);
        });
    } else {
      notification.error({ message: t("finance.invoices.noInvoicesSelected") });
    }
  }

  useEffect(() => {
    setSelectedInvoices([]);
    setRowSelectionEnabled(false);
    if ([
      INVOICE_TYPES.PARTNER_CLIENT,
      INVOICE_TYPES.CORRECTIVE_DOCUMENT,
    ].includes(filters.invoice_type)) {
      setRowSelectionEnabled(true);
    }
  }, [filters, filters.invoice_type]);

  /**
   * Handle change of the table (e. g. sorting trigger)
   */
  const handleTableChange = (pagination, tableFilters, sorter) => {
    setFilters({ ...filters, page_size: pagination?.pageSize, page: pagination?.current });

    setSorter(sorter);
    setPagination(pagination);
  };

  /**
   * Columns of the table
   */
  const invoiceTableColumns = [
    {
      title: t("finance.balances.period"),
      key: "date_issued",
      dataIndex: "date_issued",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("YYYY") + "/" + moment(rowData).format("MM");
      },
    },
    {
      title: t("finance.transactions.transactionNumber"),
      key: "id",
      render: (rowData) => {
        return (
          rowData?.transactions && (
            <Link style={{ borderBottom: "1px dotted" }} to={"/transactions/" + rowData?.transactions[0]?.id}>
              {rowData?.transactions[0]?.id}
            </Link>
          )
        );
      },
    },
    {
      title: t("expansion.city"),
      key: "city_branch_id",
      render: (rowData) => rowData?.city_branch?.name,
    },
    {
      title: t("finance.invoices.invoiceNumber"),
      key: "invoice_number",
      sorter: true,
      render: (rowData) => {
        return (
          <Link style={{ fontWeight: 500 }} to={"/invoices/" + rowData?.id}>
            {rowData?.invoice_number}
          </Link>
        );
      },
    },
    {
      title: t("finance.invoices.recipientName"),
      key: "recipient_name",
      dataIndex: "recipient_name",
      sorter: true,
    },
    {
      title: t("finance.invoices.recipientAddress"),
      key: "recipient_address",
      dataIndex: "recipient_address",
      sorter: true,
    },
    {
      title: t("finance.invoices.publisher"),
      key: "invoice_publisher_id",
      sorter: true,
      render: (rowData) => {
        return (
          rowData?.publisher && (
            <Link to={"/partners/" + rowData?.publisher?.id} style={{ borderBottom: "1px dotted" }}>
              {`${rowData?.publisher?.first_name} ${rowData?.publisher?.last_name}`}
            </Link>
          )
        );
      },
    },
    {
      title: t("finance.invoices.dateIssued"),
      key: "date_issued",
      dataIndex: "date_issued",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("DD.MM.YYYY");
      },
    },
    {
      title: t("finance.invoices.dateDue"),
      key: "date_due",
      dataIndex: "date_due",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("DD.MM.YYYY");
      },
    },
    {
      title: t("finance.invoices.totalAmount"),
      key: "total_amount",
      sorter: true,
      render: (rowData) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: rowData.currency_iso_4217 || "CZK",
        }).format(rowData.total_amount);
      },
    },
    {
      title: t("finance.invoices.datePaid"),
      key: "date_paid",
      dataIndex: "date_paid",
      sorter: true,
      render: (rowData) => {
        return rowData ? (
          <Tag color="green">{moment(rowData).format("DD.MM.YYYY")}</Tag>
        ) : (
          <Tag color="red">{t("finance.invoices.unpaid")}</Tag>
        );
      },
    },
    {
      title: t("finance.invoices.sentDate"),
      key: "date_sent_to_recipient",
      dataIndex: "date_sent_to_recipient",
      sorter: true,
      render: (rowData) => {
        return rowData ? (
          <Tag color="green">{moment(rowData).format("DD.MM.YYYY")}</Tag>
        ) : (
          <Tag></Tag>
        );
      },
    },
  ];

  return (
    <>
      {rowSelectionEnabled && (<Row justify="end" style={{ marginBottom: 16 }}>
        <Col>
          <Button onClick={() => setShowConfirmModal(true)} disabled={!selectedInvoices.length}>{t("finance.invoices.sendSelectedInvoices")}</Button>
        </Col>
      </Row>)}

    <Table
      rowSelection={rowSelection}
      pagination={{
        ...pagination,
      }}
      loading={loading}
      dataSource={invoices}
      size={"small"}
      columns={invoiceTableColumns}
      rowKey={"id"}
      onChange={handleTableChange}
    />

      {showConfirmModal && (
        <Modal
          title={t("finance.invoices.confirmSendingInvoices")}
          open={showConfirmModal}
          onOk={sendSelectedInvoices}
          onCancel={() => setShowConfirmModal(false)}
          okButtonProps={{ loading: dataLoading }}
        >
          <p>{t("finance.invoices.sureSendingInvoices")}</p>
          <ul>
            {selectedInvoices.map(invoice => (
              <li key={invoice.id}>
                {invoice.invoice_number} - {moment(invoice.date_issued).format("MM/YYYY")}
              </li>
            ))}
          </ul>
        </Modal>
      )}
    </>
  );
};

export default InvoicesTableSimple;
