import { ExclamationCircleFilled } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClaimsPaneSimple from "../components/Claims/ClaimsPaneSimple";
import AuditPane from "../components/Dasboard/AuditPane";
import ReminderPane from "../components/Dasboard/ReminderPane";

const cardStyleFullHeight = {
  height: "100%",
  minHeight: "150px",
};

const Dashboard = () => {
  const { t } = useTranslation();

  const user = JSON.parse(localStorage.getItem("user"));

  const [role, setRole] = useState({});

  // Set role of the user
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));

    setRole(user?.roles[user?.roles.length - 1]);
  }, []);

  return (
    <React.Fragment>
      {/* <Title>{t("menu.dashboard")}</Title> */}
      <Row gutter={[16, 24]}>
        {/* <Col className="gutter-row" span={12}>
          <Card
            size={"small"}
            style={cardStyleFullHeight}
            title={t("dashboard.offersAndOrders")}
          ></Card>
        </Col> */}

        {/*<Col className="gutter-row" span={12}>
          <Card
            size={"small"}
            style={cardStyleFullHeight}
            title={
              <React.Fragment>
                {t("dashboard.reminder")} <ExclamationCircleFilled style={{ color: "red" }} />
              </React.Fragment>
            }
          >
            <ReminderPane />
          </Card>
        </Col>*/}

        <Col className="gutter-row" span={24}>
          <Card size={"small"} style={cardStyleFullHeight} title={t("dashboard.assignedClaims")}>
            <ClaimsPaneSimple user={user} />
          </Card>
        </Col>

        {role?.name === "admin" && (
          <Col className="gutter-row" span={24}>
            <Card size={"small"} style={cardStyleFullHeight} title={t("dashboard.audit")}>
              <AuditPane />
            </Card>
          </Col>
        )}

        {/* <Col className="gutter-row" span={12}>
          <Card
            size={"small"}
            style={cardStyleFullHeight}
            title={t("dashboard.newPartnerLeads")}
          ></Card>
        </Col>
        <Col className="gutter-row" span={12}>
          <Card
            size={"small"}
            style={cardStyleFullHeight}
            title={t("dashboard.newClientLeads")}
          ></Card>
        </Col> */}
      </Row>
    </React.Fragment>
  );
};

export default Dashboard;
