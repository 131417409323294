import { GoogleOutlined, PaperClipOutlined, BugOutlined } from "@ant-design/icons";
import { ConfigProvider, Layout } from "antd";
import csCZ from "antd/lib/locale/cs_CZ";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import { catchURL, redirectStore, store } from "./../api/calls";
import CustomBreadcrumb from "./custom/CustomBreadcrumb";
import ErrorPane from "./ErrorPane";
import HeaderBar from "./HeaderBar";
import SideNav from "./SideNav";

const Template = withRouter(({ routeList, ...props }) => {
  const { Content, Footer } = Layout;

  const [responseStatus, setResponseStatus] = useState({});
  const userDocsURL = process.env.REACT_APP_USER_DOCS;

  useEffect(() => {
    store.subscribe(() => {
      setResponseStatus(store.getState());
    });
  }, [props.location]);

  const handleBugReport = (e) => {
    e.preventDefault();
  };

  return (
    <ConfigProvider locale={csCZ}>
      {catchURL(props.location.pathname)}

      {redirectStore.getState().redirect ? (
        <Redirect to="/login"></Redirect>
      ) : (
        <Layout style={{ minHeight: "100vh" }}>
          <SideNav />
          <Layout style={{ display: "flex", alignItems: "left" }}>
            {localStorage.getItem("user") && <HeaderBar props={props} />}
            <Content
              className="site-layout"
              style={{
                marginTop: "60px",
                marginLeft: "46px",
              }}
            >
              <CustomBreadcrumb />

              <div className="site-layout-background" style={{ padding: 24, paddingTop: 0, minHeight: "80vh" }}>
                <br></br>
                {responseStatus?.message ? (
                  <ErrorPane errorCode={responseStatus.statusCode}></ErrorPane>
                ) : (
                  props.children
                )}
              </div>
            </Content>
            <Footer
              style={{
                textAlign: "right",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: "20px",
                paddingTop: "8px",
                paddingBottom: "8px",
                background: "#fff",
              }}
            >
              <div>
                <a href={userDocsURL} style={{ marginRight: "24px" }} target="_blank" rel="noreferrer">
                  <PaperClipOutlined style={{ marginRight: "6px" }} />
                  Uživatelská dokumentace
                </a>

                <a
                  href="https://drive.google.com/drive/u/0/folders/1z16gVri5DKUgELSR8bAEY6-AigdY8PqS"
                  style={{ marginRight: "24px" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <GoogleOutlined style={{ marginRight: "6px" }} />
                  Google Drive
                </a>

                <button
                  id="myCustomTrigger"
                  onClick={handleBugReport}
                  style={{
                    background: "none",
                    border: "none",
                    padding: 0,
                    cursor: "pointer",
                    color: "#1890ff",
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "underline",
                  }}
                >
                  <BugOutlined style={{ marginRight: "6px" }} />
                  Report Bug
                </button>
              </div>
            </Footer>
          </Layout>
        </Layout>
      )}
    </ConfigProvider>
  );
});

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(Template);
