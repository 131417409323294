import { Button, Col, Descriptions, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import PartnerSelect from "../../Partners/PartnerSelect";

const ScheduleThirdStepMassEdit = ({ selectedBuildingUnits, moveToSecondStep, form, submitting, isOpenStop }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>

        <Descriptions size={"small"} title={t("schedules.termination")}>
          <Descriptions.Item span={2}>{t("schedules.dateTo")}</Descriptions.Item>
          <Descriptions.Item>{form.getFieldValue("date_terminate").format("LL")}</Descriptions.Item>
        </Descriptions>

        <Descriptions>
          <Descriptions.Item span={2}>{t("schedules.selectedBuildingUnits")}:</Descriptions.Item>
          <Descriptions.Item span={2}>{ selectedBuildingUnits.length }</Descriptions.Item>
        </Descriptions>

        {!isOpenStop ? (
        <Descriptions size={"small"} title={t("schedules.newRules")}>

            {selectedBuildingUnits.map((selectedBuildingUnit) => {
              return (
                <>
                  <Descriptions.Item span={2}>
                    <strong>{t("buildingUnits.street")}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item span={2}>
                    <strong>{selectedBuildingUnit.street + " " + selectedBuildingUnit.house_number}</strong>
                  </Descriptions.Item>

                  <Descriptions.Item span={1}>{t("schedules.cleaningDays")}</Descriptions.Item>
                  <Descriptions.Item span={1}>
                        { form.getFieldValue("building-" + selectedBuildingUnit.id + "--days")
                          .map((day) => {
                          return t("rrules.byweekday." + day);
                        }).join(", ") }
                  </Descriptions.Item>

                  <Descriptions.Item span={1}>{t("schedules.serviceRatio")}: {form.getFieldValue("building-" + selectedBuildingUnit.id + "--service_ratio") * 100 + "%" || t("common.undefined")}</Descriptions.Item>

                  <Descriptions.Item span={2}>{t("schedules.dateFrom")}: {form.getFieldValue("building-" + selectedBuildingUnit.id + "--date_from")?.format("LL") || ""}</Descriptions.Item>
                  <Descriptions.Item span={2}>{t("schedules.dateTo")}: {form.getFieldValue("building-" + selectedBuildingUnit.id + "--date_to")?.format("LL") || t("common.undefined")}</Descriptions.Item>

                  <Descriptions.Item span={2}>{t("schedules.partner")}</Descriptions.Item>
                  <Descriptions.Item span={2}>
                    {form.getFieldValue("building-" + selectedBuildingUnit.id + "--partner_id") ? (
                      <PartnerSelect
                        size={"small"}
                        name={"building-" + selectedBuildingUnit.id + "--partner_id"}
                        readOnly
                        initialValue={form.getFieldValue("building-" + selectedBuildingUnit.id + "--partner_id")}
                      />
                    ) : (
                      t("common.undefined")
                    )}
                  </Descriptions.Item>
                </>
              );
            })}
        </Descriptions>
          ) : (<p></p>)}
      </Col>
      <Col span={12}>
        <Button style={{ margin: "0 8px" }} block onClick={() => moveToSecondStep()}>
          {t("common.previous")}
        </Button>
      </Col>
      <Col span={12}>
        <Button
          type="primary"
          loading={submitting}
          block
          onClick={() => {
            form.submit();
          }}
        >
          {t("common.finish")}
        </Button>
      </Col>
    </Row>
  );
};

export default ScheduleThirdStepMassEdit;
