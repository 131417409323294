import { Select } from "antd";
import { Form } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import useCoreApi from "../../../hooks/useCoreApi";

const PartnerSelect = ({
  initialValue = null,
  readOnly = false,
  onChange = null,
  required = false,
  showSearch = false,
  validationMessage = "",
  selectedKey = null,
  isSupplier = false,
}) => {
  const { t } = useTranslation();

  // Load partners who are partner_ids_service_provider(s) (workers)
  // and output of this select is a invoice publisher ()
  const { loading, response } = useCoreApi("get", "/partners/contracted");

  return (
    <React.Fragment>
      <Form.Item
        name={isSupplier ? "service_provider_id" : "service_recipient_id"}
        label={t("partners.selectPartner")}
        valuePropName={"value"}
        wrapperCol={24}
        initialValue={initialValue}
        rules={[
          {
            required: required,
            message: validationMessage,
          },
        ]}
        style={{ width: "100%" }}
      >
        <Select
          key={selectedKey}
          loading={loading}
          allowClear
          showSearch={showSearch}
          filterOption={
            showSearch ? (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : null
          }
          onChange={(e) => {
            onChange && onChange(response.find((item) => item.id === e));
          }}
          disabled={readOnly}
          bordered={!readOnly}
          placeholder={t("partners.selectPartner")}
        >
          {response?.map((item, i) => (
            <Select.Option key={i} value={item?.id}>
              {`${item?.first_name} ${item?.last_name}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="invoice_publisher_id" hidden></Form.Item>
    </React.Fragment>
  );
};

export default PartnerSelect;
