import { Layout, Result } from "antd";
import React from "react";

const MaintenancePage = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Result status="warning" title="Application is in maintenance mode!" subTitle={"Try again later."} />
    </Layout>
  );
};

export default MaintenancePage;
