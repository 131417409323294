import {Checkbox, Col, DatePicker, Divider, Form, Input, Row} from "antd";
import moment from "moment";
import React from "react";
import {useTranslation} from "react-i18next";
import CitySelector from "../Cities/CitySelector";
import EmployeeSelect from "./../Employees/EmployeeSelect";
import PartnerSelect from "./PartnerSelect";
import PartnerStateSelector from "./PartnerStateSelector";
import PartnerLocationSelector from "./PartnerLocationSelector";
import ProductTypeCheckboxes from "../ProductTypeCheckboxes";

const PartnerBasicInfoSection = ({ partner, readOnly }) => {
  const { t } = useTranslation();

  let primaryCity =
    partner?.city_branches?.find((item) => item.city_branches_partners?.is_primary === 1) ||
    (partner?.city_branches?.length > 0 ? partner?.city_branches[0] : null);

  return (
    <React.Fragment>
      <Divider orientation="left">{t("partners.generalInformation")}</Divider>
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <Form.Item name="first_name" label={t("common.firstName")} initialValue={partner?.first_name}>
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="last_name" label={t("common.lastName")} initialValue={partner?.last_name}>
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
        {partner?.company_number ? (
          <React.Fragment>
            <Col span={6}>
              <Form.Item
                name="company_number"
                label={t("partners.identificationNumber")}
                initialValue={partner?.company_number}
              >
                <Input readOnly={readOnly} bordered={!readOnly}></Input>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="vat_number" label={t("partners.vatNumber")} initialValue={partner?.vat_number}>
                <Input readOnly={readOnly} bordered={!readOnly}></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="company_name" label={t("partners.companyName")} initialValue={partner?.company_name}>
                <Input readOnly={readOnly} bordered={!readOnly}></Input>
              </Form.Item>
            </Col>
          </React.Fragment>
        ) : (
          ""
        )}
        <Col span={12}>
          <EmployeeSelect
            name="user_id"
            initialValue={partner?.user_id}
            label={t("managers.manager")}
            readOnly={readOnly}
          />
        </Col>
        <Col span={12}>
          <PartnerSelect
            name="trainer_id"
            initialValue={partner?.trainer_id}
            label={t("employees.trainerName")}
            readOnly={readOnly}
          />
        </Col>
        <Col span={12}>
          <CitySelector required={true} initialValue={primaryCity?.id} readOnly={readOnly} />
        </Col>
        <Col span={12}>
          <Form.Item name="notes" label={t("partners.note")} initialValue={partner?.notes}>
            <Input.TextArea readOnly={readOnly} bordered={!readOnly} autoSize={{ minRows: 2 }}></Input.TextArea>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"training_day_date"}
            label={t("partners.trainingDayDate")}
            initialValue={partner?.training_day_date ? moment(partner.training_day_date) : null}
          >
            <DatePicker disabled={readOnly} bordered={!readOnly} format={"L"}></DatePicker>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="is_trainer"
            label={t("partners.isTrainer")}
            initialValue={partner?.is_trainer}
            valuePropName="checked"
          >
            <Checkbox disabled={readOnly}></Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"contract_signed"}
            label={t("partners.contractSigned")}
            initialValue={partner?.contract_signed ? moment(partner.contract_signed) : null}
          >
            <DatePicker disabled={readOnly} bordered={!readOnly} format={"L"}></DatePicker>
          </Form.Item>
        </Col>
        <Col span={12} style={{ display: "flex", alignItems: "center" }}>
          <ProductTypeCheckboxes
            partner={partner}
            readOnly={readOnly}
          />
        </Col>
        <Col span={12}>
          <PartnerStateSelector required={true} initialValue={partner?.state} readOnly={readOnly} />
        </Col>
        <Col span={12}>
          <PartnerLocationSelector
            initialValue={
              partner?.preferred_location
                ? typeof partner.preferred_location === 'string'
                  ? JSON.parse(partner.preferred_location)
                  : partner.preferred_location
                : []
            }
            readOnly={readOnly}
          />

        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PartnerBasicInfoSection;
