import { Button, Form, Modal, notification } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import AccountAccessTokenInput from "./AccountAccessTokenInput";
import AccountControlTokenInput from "./AccountControlTokenInput";
import AccountInputGroupWrapper from "./AccountInputGroupWrapper";
import AccountNameAbbrWrapper from "./AccountNameAbbrWrapper";

/**
 * Account Add Form
 * @component
 * @alias AccountAddForm
 * @property {object} addNewRecord - object containing new record data
 * @returns <Button /> and <Modal /> with <Form /> inside, that contains needed inputs
 */
const AccountAddForm = ({ addNewRecord }) => {
  const { t } = useTranslation();

  const [isAccountFormOpen, setAccountFormOpen] = useState(false);
  const [isAccountFormSubmitting, setAccountFormSubmitting] = useState(false);

  const [accountForm] = Form.useForm();

  return (
    <React.Fragment>
      <Button
        type="primary"
        size="large"
        onClick={() => {
          setAccountFormOpen(true);
        }}
      >
        {t("finance.accounts.addAccount")}
      </Button>
      <Modal
        open={isAccountFormOpen}
        title={t("finance.accounts.addAccount")}
        onOk={accountForm.submit}
        okText={t("common.create")}
        okButtonProps={{ loading: isAccountFormSubmitting }}
        onCancel={() => {
          setAccountFormOpen(false);
        }}
      >
        <Form
          form={accountForm}
          layout={"vertical"}
          onFinish={(values) => {
            setAccountFormSubmitting(true);
            coreApi
              .post("/accounts", values)
              .then((response) => {
                notification.success({
                  message: response.data.message,
                });
                addNewRecord(response.data.account);
                accountForm.resetFields();
                setAccountFormOpen(false);
              })
              .catch((error) => {
                notification.error({ message: error.response.data.message });
              })
              .finally(() => {
                setAccountFormSubmitting(false);
              });
          }}
        >
          <AccountNameAbbrWrapper />
          <AccountInputGroupWrapper />
          <AccountAccessTokenInput />
          <AccountControlTokenInput />
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AccountAddForm;
