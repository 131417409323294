import { Button, Col, DatePicker, Form, InputNumber, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "react-slidedown/lib/slidedown.css";
import PartnerSelect from "../../Partners/PartnerSelect";
import RruleDaySelect from "../RruleDaySelect";

const ScheduleSecondStepMassEdit = ({ selectedBuildingUnits, form, moveToFirstStep, moveToThirdStep }) => {
  const { t } = useTranslation();

  const [nextStepEnabled, setNextStepEnabled] = useState(true);

  const validation = async () => {
    try {
      await form.validateFields();
      setNextStepEnabled(true);
      return true;
    } catch (e) {
      setNextStepEnabled(false);
      return false;
    }
  };

  return (
    <div>
      {selectedBuildingUnits && selectedBuildingUnits.map((selectedBuildingUnit, index) => {
        return (
          <>
            <Row gutter={[16, 24]} style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              borderBottom: "1px solid gainsboro",
            }}>
              <Col span={24}>
                  <strong>{index + 1}. { t("buildingUnits.street")}: {selectedBuildingUnit.street}</strong>
                  <RruleDaySelect
                    hasFeedback={true}
                    isRequired={true}
                    rrule={null}
                    name={`building-${selectedBuildingUnit.id}--days`}
                    postChange={() => validation()}
                    disabledDate={(current) =>
                      current < moment().startOf("month") ||
                      current === moment() ||
                      current.format("YYYY-MM-DD") !==
                      moment(current).endOf("month").format("YYYY-MM-DD")
                    }
                  />
              </Col>
              <Col span={12}>
                <Form.Item
                  name={`building-${selectedBuildingUnit.id}--date_from`}
                  initialValue={
                    form.getFieldValue("date_terminate") ? moment(form.getFieldValue("date_terminate")).add(1, "days") : null
                  }
                  label={t("schedules.dateFrom")}
                  hasFeedback
                  allowClear
                  bordered
                  rules={[
                    {
                      required: true,
                      message: t("schedules.dateFromRequiredMessage"),
                    },
                  ]}
                >
                  <DatePicker onChange={() => validation()} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name={`building-${selectedBuildingUnit.id}--date_to`} initialValue={null} label={t("schedules.dateTo")}>
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <PartnerSelect
                  name={`building-${selectedBuildingUnit.id}--partner_id`}
                  label={t("schedules.partner")}
                  onChange={() => validation()}
                  required={true}
                />
              </Col>
              <Col>
                <Form.Item
                  label={t("schedules.serviceRatio")}
                  name={`building-${selectedBuildingUnit.id}--service_ratio`}
                  initialValue={0.4}
                  rules={[
                    {
                      required: true,
                      message: t("schedules.serviceRatioRequiredMessage"),
                    },
                  ]}
                >
                  <InputNumber max={0.99} min={0.0} step={0.01}></InputNumber>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      )}
      <Row style={{"margin-top": "20px"}}>
        <Col span={12}>
          <Button style={{ marginRight: "5px" }} block onClick={() => moveToFirstStep()}>
            {t("common.previous")}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            style={{ marginLeft: "5px" }}
            type="primary"
            disabled={!nextStepEnabled}
            block
            onClick={async () => {
              if (await validation()) moveToThirdStep();
            }}
          >
            {t("common.next")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ScheduleSecondStepMassEdit;
