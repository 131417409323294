import { Form, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

/**
 * Cleaning Claim Tag Select
 * @component
 * @alias CleaningClaimTagSelect
 * @property {number} initialValue - tag_id
 * @property {bool} readOnly - sets <Select /> ready-only
 * @property {string|bool} label - label for the form item; if false, hides the label
 * @returns
 */
const CleaningClaimTagSelect = ({ initialValue, readOnly, label, mode = "default" }) => {
  const { t } = useTranslation();
  const [claimTags, setClaimTags] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    setLoading(true);
    coreApi
      .get("/cleanings/claim-tags", { params: {} })
      .then((response) => {
        if (isMounted) {
          setClaimTags(response.data);
        }
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const formItemLabel = label === false ? null : label || t("cleanings.claimTag");

  return (
    <Form.Item label={formItemLabel} name="claim_tag_id" initialValue={initialValue}>
      <Select mode={mode} loading={loading} disabled={loading || readOnly} placeholder={t("cleanings.claimTag")}>
        {claimTags.map((claimTag) => (
          <Select.Option key={claimTag?.id} value={claimTag?.id}>
            {claimTag.tag_name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default CleaningClaimTagSelect;
