import React from "react";
import { Col, Row, Card, Space } from "antd";
import CityStatisticsGrouped from "./CityStatisticsGrouped";
import AccountStatisticsGrouped from "./AccountStatisticsGrouped";

const CityAndBankAccountsOverviewPane = () => {

  return (
    <Space direction="vertical" size="large">
      <Row>
        <Col span={24}>
          <Card size={"small"}>
            <AccountStatisticsGrouped />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card size={"small"}>
            <CityStatisticsGrouped />
          </Card>
        </Col>
      </Row>
    </Space>
  );
};

export default CityAndBankAccountsOverviewPane;
