import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import AddableLineItem from "./AddableLineItem";

/**
 * @component
 * @alias AddableLineItem
 * @property {booleam} isSummary
 * @property {integer} idBuilding
 * @property {object} initialValue
 * @property {boolean} hiddenVat
 * @property {boolean} isCorrective
 * @property {boolean} isAnnaPartner
 * @property {object} form
 * @returns Form for line items
 */
const AddLineItemForm = ({
  isSummary = false,
  idBuilding = null,
  initialValue = [{}],
  hiddenVat = true,
  isCorrective = false,
  isAnnaPartner = false,
  form = null,
}) => {
  const { t } = useTranslation();

  return (
    <Form.List name="line_items" initialValue={initialValue}>
      {(fields, { add, remove }) => (
        <div>
          {fields.map((field) => (
            <React.Fragment key={field.key}>
              <Row gutter={[8, 8]}>
                <AddableLineItem
                  isAnnaPartner={isAnnaPartner}
                  form={form}
                  idBuilding={idBuilding}
                  field={field}
                  key={field.key}
                  readOnly={isSummary}
                  hiddenVat={hiddenVat}
                  isCorrective={isCorrective}
                />
                {!isSummary && (
                  <Col span={1}>
                    {fields.length > 1 ? (
                      <>
                        <Button
                          danger
                          style={{
                            width: "100%",
                          }}
                          onClick={() => remove(field.name)}
                          icon={<CloseOutlined />}
                        ></Button>
                      </>
                    ) : null}
                  </Col>
                )}
              </Row>
            </React.Fragment>
          ))}
          {!isSummary && (
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Button type="dashed" style={{ width: "100%" }} onClick={() => add()} icon={<PlusOutlined />}>
                  {t("common.add")}
                </Button>
              </Col>
            </Row>
          )}
        </div>
      )}
    </Form.List>
  );
};

export default AddLineItemForm;
