import { Col, Form, Input, InputNumber, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../../api/calls";
import { getAreaScope } from "../../../actions";
import {useSelector} from "react-redux";

/**
 * @component
 * @alias AddableLineItem
 * @property {string} field
 * @property {boolean} readOnly
 * @property {integer} idBuilding
 * @property {boolean} hiddenVat
 * @property {object} form
 * @property {boolean} isCorrective
 * @property {boolean} hiddenDiscount
 * @property {boolean} isAnnaPartner
 * @returns form items for line items
 */
const AddableLineItem = ({
  field,
  readOnly = false,
  idBuilding,
  hiddenVat,
  form = null,
  isCorrective = false,
  hiddenDiscount = true,
  isAnnaPartner = false,
}) => {
  const { t } = useTranslation();
  const areaScope = useSelector(getAreaScope);
  const cities = areaScope.cities;
  // States
  const [buildingUnits, setBuildingUnits] = useState(0);
  const [isLoading, setLoading] = useState(0);

  let vatPercentage = 0;
  let totalAmount = 0;

  // Get values from form
  if (form) {
    vatPercentage = form.getFieldValue("vat_percentage");
    totalAmount = form.getFieldValue("total_amount");
  }

  // Get the correct VAT
  const setVATbyCountry = () => {
    const cityId = localStorage.getItem("city")
    let countryCode = null;

    cities.forEach(country => {
      const found = country.city_branches.some(city => city.id == cityId);
      if (found) {
        countryCode = country.country_code_iso_3166;
      }
    });

    if (countryCode === 'SK') {
      return 0.20
    } else if (countryCode === 'IE') {
      return 0.23
    } else {
      return 0.21
    }
  };

  let initialVATvalue = setVATbyCountry();

  // Recalculate amounts, when VAT changes
  const handleVatPercentageChange = (value) => {
    vatPercentage = value;
    handleCalculation();
  };

  // Recalculate amounts, when total amount changes
  const handleTotalAmountChange = (value) => {
    totalAmount = value;
    handleCalculation();
  };

  // Recalculation of amounts
  const handleCalculation = () => {
    const fields = form?.getFieldsValue();
    const line_items = fields?.line_items;

    console.log(field);

    Object.assign(line_items[field.key], {
      list_price: calculateListPrice(line_items[field.key].total_amount, line_items[field.key].vat_percentage),
    });

    Object.assign(line_items[field.key], {
      vat_amount: calculateVatAmount(line_items[field.key].total_amount, line_items[field.key].vat_percentage),
    });

    form.setFieldsValue({ line_items });
  };

  // Calculate VAT
  const calculateVatAmount = (totalAmount, vatPercentage) => {
    return totalAmount - totalAmount / (1 + vatPercentage);
  };

  // Calculate list price
  const calculateListPrice = (totalAmount, vatPercentage) => {
    return totalAmount / (1 + vatPercentage);
  };

  useEffect(() => {
    setLoading(true);
    coreApi
      .get("/buildings/units/list", { params: { building_id: idBuilding } })
      .then((res) => setBuildingUnits(res.data))
      .catch((err) => notification.error({ message: err.response.data.message }))
      .finally(() => setLoading(false));
  }, [idBuilding]);

  return (
    <React.Fragment>
      <Col span={idBuilding ? 6 : 0}>
        <Form.Item
          hidden={idBuilding ? false : true}
          name={[field.name, "building_unit_id"]}
          fieldKey={[field.key, "building_unit_id"]}
          rules={[
            {
              required: idBuilding ? true : false,
              message: t("finance.lineItems.validation.building"),
            },
          ]}
        >
          <Select loading={isLoading} disabled={readOnly} bordered={!readOnly}>
            {buildingUnits?.data?.map((item, index) => (
              <Select.Option key={index} value={item?.id}>
                {item?.street + " " + item?.house_number}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {/* Default lenght is 6, when is idBuildingUnit select input hidden, you need to add lenght of that input, so you have 9. */}
      <Col span={hiddenVat ? 14 : idBuilding ? 6 : 11}>
        <Form.Item
          name={[field.name, "content"]}
          fieldKey={[field.key, "content"]}
          rules={[
            {
              required: true,
              message: t("finance.lineItems.validation.content"),
            },
          ]}
        >
          <Input.TextArea
            placeholder={t("finance.lineItems.content")}
            autoSize
            style={{ width: "100%" }}
            disabled={readOnly}
            bordered={!readOnly}
          ></Input.TextArea>
        </Form.Item>
      </Col>
      <Col span={!isAnnaPartner ? (hiddenDiscount ? 0 : 3) : null}>
        <Form.Item
          name={[field.name, "list_price"]}
          fieldKey={[field.key, "list_price"]}
          hidden={isAnnaPartner ? !hiddenDiscount : isAnnaPartner}
          rules={[
            {
              required: isCorrective ? !isCorrective : !hiddenDiscount,
              message: t("finance.lineItems.validation.unitPrice"),
            },
          ]}
        >
          <InputNumber
            disabled={readOnly}
            bordered={!readOnly}
            precision={2}
            readOnly
            placeholder={t("finance.lineItems.unitPrice")}
            style={{ width: "100%" }}
          ></InputNumber>
        </Form.Item>
      </Col>
      <Col span={hiddenDiscount ? 0 : 3}>
        <Form.Item
          name={[field.name, "discount_amount"]}
          fieldKey={[field.key, "discount_amount"]}
          hidden={hiddenDiscount}
        >
          <InputNumber
            disabled={readOnly}
            bordered={!readOnly}
            placeholder={t("finance.lineItems.discountAmount")}
            readOnly
            min={0}
            precision={2}
            style={{ width: "100%" }}
          ></InputNumber>
        </Form.Item>
      </Col>
      <Col span={hiddenVat ? 0 : 2}>
        <Form.Item
          name={[field.name, "vat_percentage"]}
          fieldKey={[field.key, "vat_percentage"]}
          initialValue={initialVATvalue}
          hidden={hiddenVat}
          readOnly
          rules={[
            {
              type: "number",
              min: 0,
              max: 1,
              message: t("finance.lineItems.validation.vatPercentage"),
            },
          ]}
        >
          <InputNumber
            onChange={(value) => handleVatPercentageChange(value)}
            disabled={readOnly}
            bordered={!readOnly}
            placeholder={t("finance.lineItems.vatPercentage")}
            min={0}
            decimalSeparator={"."}
            step={0.01}
            formatter={(value) => `${value * 100}%`}
            parser={(value) => {
              let valueParsed = value.replace("%", "") / 100;
              return valueParsed;
            }}
            style={{ width: "100%" }}
          ></InputNumber>
        </Form.Item>
      </Col>
      <Col span={hiddenVat ? 0 : 2}>
        <Form.Item
          name={[field.name, "vat_amount"]}
          fieldKey={[field.key, "vat_amount"]}
          hidden={hiddenVat}
          rules={[
            {
              required: isCorrective ? !isCorrective : !hiddenVat,
              message: t("finance.lineItems.validation.vatAmount"),
            },
          ]}
        >
          <InputNumber
            disabled={readOnly}
            bordered={!readOnly}
            placeholder={t("finance.lineItems.vatAmount")}
            precision={2}
            style={{ width: "100%" }}
          ></InputNumber>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          name={[field.name, "total_amount"]}
          fieldKey={[field.key, "total_amount"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            onChange={(value) => handleTotalAmountChange(value)}
            disabled={readOnly}
            bordered={!readOnly}
            placeholder={t("finance.lineItems.totalAmount")}
            precision={2}
            style={{ width: "100%" }}
          ></InputNumber>
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

export default AddableLineItem;
