import { InboxOutlined } from "@ant-design/icons";
import { Typography, Upload } from "antd";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../api/calls";

const UploadDocument = ({ documents, idPartnerLead }) => {
  const { t } = useTranslation();

  const [formatedDocuments, setFromatedDocuments] = useState([]);
  const [componentLoading, setComponentLoading] = useState(true);

  useEffect(() => {
    documents.forEach((item) => {
      setFromatedDocuments((formatedDocuments) => [
        ...formatedDocuments,
        {
          uid: item.document_id,
          name: item.filename,
          status: "done",
          url: "/storage/documents/" + item.path.split("/")[2],
        },
      ]);
    });
    setComponentLoading(false);
  }, [documents]);

  const draggerProps = {
    name: "file",
    multiple: false,
    accept: ".doc, .docx, .pdf, .txt",
    listType: "file",
    defaultFileList: formatedDocuments,
    customRequest: ({ onSuccess, onError, file }) => {
      let payload = new FormData();
      payload.append("path", file);
      payload.append("partner_id_lead", idPartnerLead);

      coreApi
        .post("/documents", payload)
        .then((res) => {
          onSuccess({
            uid: res.data.document.document_id,
            name: res.data.document.filename,
            status: "done",
            url: "https://nisa-be.herokuapp.com/storage/documents/" + res.data.document.path.split("/")[2],
          });
        })
        .catch((error) => console.log(error))
        .finally();
    },
    onRemove: (data) => {
      coreApi
        .delete("/documents/" + data.uid)
        .then((res) => console.log(res))
        .catch((err) => console.log(err))
        .finally();
    },
    onPreview: (data) => {
      coreApi
        .get("/documents/download/" + data.uid, {
          responseType: "blob",
        })
        .then((response) => {
          fileDownload(response.data, data.name);
        })
        .catch((error) => console.log(error));
    },
    onDownload: (data) => {
      coreApi
        .get("/documents/download/" + data.uid, {
          responseType: "blob",
        })
        .then((response) => {
          fileDownload(response.data, data.name);
        })
        .catch((error) => console.log(error));
    },
    showUploadList: {
      showDownloadIcon: true,
    },
  };

  return (
    !componentLoading && (
      <React.Fragment>
        <Upload.Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <Typography.Text type="secondary">{t("common.dragAndDropUpload")}</Typography.Text>
        </Upload.Dragger>
      </React.Fragment>
    )
  );
};

export default UploadDocument;
