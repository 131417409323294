import { Alert, Button, Checkbox, Form, Input, Spin } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { setRedirect } from "../actions";
import { coreApi, redirectStore, urlStore } from "../api/calls";

const LoginForm = (props) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const authenticate = (email, password) => {
    setLoading(true);
    coreApi
      .post("/auth/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("tokenId", response.data.token_id);
          localStorage.setItem("accessToken", response.data.access_token);

          var redirectTo = urlStore.getState() ? urlStore.getState().url : "/dashboard - bez statu";

          redirectStore.dispatch(setRedirect(false));

          history.push(redirectTo);
        } else {
          setErrorMessage("Wrong email or password.");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Form
      layout="vertical"
      onFinish={(values) => {
        authenticate(values.email, values.password);
      }}
    >
      <Form.Item
        label={t("common.email")}
        name="email"
        rules={[
          {
            required: true,
            message: t("login.usernameRequired")
          },
          {
            type: "email",
            message: t("login.emailFormat"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("login.password")}
        name="password"
        rules={[{ required: true, message: t("login.passwordRequired") }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item style={{ textAlign: "center" }} name="remember" valuePropName="checked">
        <Checkbox>{t("login.rememberMe")}</Checkbox>
      </Form.Item>
      <Form.Item style={{ textAlign: "center" }}>
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <Button id="login" size="large" type="primary" htmlType="submit">
            {t("login.login")}
          </Button>
        )}
      </Form.Item>
      {errorMessage ? <Alert message={errorMessage} type="error" /> : ""}
    </Form>
  );
};

export default LoginForm;
