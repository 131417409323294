import { Card, Col, Row, Table } from "antd";
import getSymbolFromCurrency from "currency-symbol-map";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../api/calls";
import CountryAddForm from "../components/CountryAddForm";

/**
 * @component
 * @alias CountriesIndex
 * @return <Table />
 */
const CountriesIndex = () => {
  const { t } = useTranslation();

  // States
  const [countriesList, setCountriesList] = useState([]);
  const [isCountriesListLoading, setCountriesListLoading] = useState(false);

  useEffect(() => {
    setCountriesListLoading(true);

    coreApi
      .get("/countries")
      .then((response) => {
        setCountriesList(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setCountriesListLoading(false);
      });
  }, []);

  const addCountry = (values) => {
    coreApi
      .post("/countries", values)
      .then((response) => {
        const newCountriesList = [...countriesList, response.data.country_branch];
        setCountriesList(newCountriesList);
      })
      .catch((error) => {})
      .finally();
  };

  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Card>
            <Table
              size="small"
              rowKey="country_branch_id"
              pagination={false}
              loading={isCountriesListLoading}
              dataSource={countriesList}
              columns={[
                {
                  title: t("expansion.countryName"),
                  key: "name",
                  sorter: (a, b) => a.name.localeCompare(b.name),
                  render: (country) => <Link to={`/countries/${country?.id}`}>{country.name}</Link>,
                },
                {
                  title: t("expansion.managerName"),
                  key: "managerName",
                  sorter: (a, b) => a?.manager?.last_name.localeCompare(b?.manager?.last_name),
                  render: (country) => {
                    return (
                      <Link to={`/employees/${country?.manager?.id}`}>
                        {country?.manager?.first_name + " " + country?.manager?.last_name}
                      </Link>
                    );
                  },
                },
                {
                  title: t("expansion.countryWeb"),
                  key: "web",
                  dataIndex: "web",
                  sorter: (a, b) => a.web.localeCompare(b.web),
                  render: (web) => (
                    <a href={web} rel="noopener noreferrer" target="_blank">
                      {web}
                    </a>
                  ),
                },
                {
                  title: t("expansion.countryCallingCode"),
                  key: "countryCallingCode",
                  sorter: (a, b) => a.countryCallingCode.localeCompare(b.countryCallingCode),
                  dataIndex: "country_calling_code",
                },
                {
                  title: t("expansion.currency"),
                  key: "currency",
                  dataIndex: "iso_4217_currency_code",
                  sorter: (a, b) => a.iso_4217_currency_code.localeCompare(b.iso_4217_currency_code),
                  render: (code) => <React.Fragment>{getSymbolFromCurrency(code) + " (" + code + ")"}</React.Fragment>,
                },
              ]}
            ></Table>
          </Card>
        </Col>
        <Col span={24}>
          <CountryAddForm onFinishAction={addCountry}></CountryAddForm>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CountriesIndex;
