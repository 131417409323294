import { Form, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const WorkerSelect = ({ label, name, initialValue, disabledOption, readOnly, required, validationMessage }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    let isMounted = true;

    setLoading(true);
    coreApi
      .get("/partners/workers")
      .then((response) => {
        if (isMounted) {
          setPartners(response.data);
        }
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Form.Item
      hasFeedback
      name={name}
      label={label}
      initialValue={initialValue}
      valuePropName={"value"}
      rules={[
        {
          required: required,
          message: validationMessage,
        },
      ]}
    >
      <Select
        disabled={readOnly}
        loading={loading}
        showSearch={true}
        placeholder={t("partners.selectPartner")}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {partners.map((partner) => {
          return (
            <Select.Option
              key={partner.id}
              value={partner.id}
              disabled={disabledOption && partner.id === disabledOption}
            >
              {partner.company_name ? partner.company_name : partner.first_name + " " + partner.last_name}
              {/* {partner.cities.map((city) => city.name).join(", ")} */}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default WorkerSelect;
