import { Tag } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

const InvoicePaymentState = ({ invoice }) => {
  const { t } = useTranslation();

  if (invoice.date_paid) {
    return <PaidTag label={t("finance.invoices.paid")} />;
  } else if (
    !invoice.date_paid &&
    moment(invoice.date_due).isSameOrAfter(moment(), "day")
  ) {
    return <UnpaidDueTag label={t("finance.invoices.unpaidDue")} />;
  } else if (
    !invoice.date_paid &&
    moment(invoice.date_due).isBefore(moment(), "day")
  ) {
    return <UnpaidOverdueTag label={t("finance.invoices.unpaidOverdue")} />;
  }
};

export default InvoicePaymentState;

const PaidTag = ({ label }) => <Tag color="success">{label}</Tag>;
const UnpaidOverdueTag = ({ label }) => <Tag color="error">{label}</Tag>;
const UnpaidDueTag = ({ label }) => <Tag color="processing">{label}</Tag>;
// const PartiallyPaidTag = ({ label }) => <Tag color="warning">{label}</Tag>;
