import { Form, Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import IBAN from "iban";

/**
 * Account IBAN Input Field
 * @component
 * @alias AccountIbanInput
 * @property {string} initialValue - init value for IBAN Form Item
 * @property {bool} readOnly - sets Form Item read-only
 * @property {bool} required - sets Form Items required - default false
 * @returns <Form.Item />
 */
const AccountIbanInput = ({ initialValue, readOnly, required = false }) => {
  const { t } = useTranslation();

  const [validateStatus, setValidateStatus] = useState();

  const validateIban = (value) => {
    // uses IBAN library for validation
    if (value.indexOf(" ") <= 0 && IBAN.isValid(value)) {
      setValidateStatus("success");
    } else {
      setValidateStatus("error");
    }
  };

  return (
    <Form.Item
      label={t("finance.accounts.accountIban")}
      name={"account_iban"}
      initialValue={initialValue}
      hasFeedback
      validateStatus={validateStatus}
      rules={[
        {
          min: 5,
          max: 34,
          required: required,
          message: t("finance.accounts.validation.accountIban"),
          pattern: new RegExp(/^[a-zA-Z0-9]*$/),
        },
      ]}
    >
      <Input
        maxLength={34}
        readOnly={readOnly}
        bordered={!readOnly}
        onChange={(e) => {
          validateIban(e.target.value);
        }}
      ></Input>
    </Form.Item>
  );
};

export default AccountIbanInput;
