import { Button, Card, Col, Modal, notification, Row, Space, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { removeAreaScope } from "../actions";
import { coreApi } from "../api/calls";
import CityImportDataModal from "../components/Cities/CityImportDataModal";
import CityProfileCleaningMinutes from "../components/Cities/CityProfileCleaningMinutes";
import CityProfilePane from "../components/Cities/CityProfilePane";
import CityExportModal from "../components/Cities/CityExportModal";
import CustomTabs from "../components/custom/CustomTabs";

/**
 * Page with city detail
 * @component
 * @alias CityProfile
 * @returns <Card /> with content
 */
const CityProfile = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const removeReduxCity = (city) => dispatch(removeAreaScope(city));

  // States
  const [isPageLoading, setPageLoading] = useState(false);
  const [deleteRedirect, setDeleteRedirect] = useState(false);
  const [city, setCity] = useState();

  useEffect(() => {
    setPageLoading(true);

    coreApi
      .get("/cities/" + props.match.params.id)
      .then(({ data }, req) => {
        setCity(data);
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      })
      .finally(() => setPageLoading(false));
  }, [props.match.params.id]);

  return (
    <React.Fragment>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Card loading={isPageLoading}>
            <Row gutter={[0, 24]} style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography.Title>{city?.name}</Typography.Title>
              <Space style={{ float: "right" }}>
                <CityImportDataModal props={props} />

                <CityExportModal props={props} city={city} />
                <Button
                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: t("expansion.deleteCountry.alertTitle"),
                      content: t("expansion.deleteCountry.alertContent"),
                      okText: t("common.delete"),
                      okButtonProps: { type: "danger" },
                      onOk: () => {
                        Modal.confirm({
                          title: t("common.lastWarning"),
                          okText: t("common.delete"),
                          okButtonProps: { type: "danger" },
                          onOk: () => {
                            coreApi
                              .delete("/cities/" + city?.id)
                              .then((response) => {
                                removeReduxCity(city);
                                setDeleteRedirect(true);
                                notification.success({
                                  message: t("common.recordDeleted"),
                                });
                              })
                              .catch((error) => {});
                          },
                        });
                      },
                    });
                  }}
                >
                  {t("common.delete")}
                </Button>
                {deleteRedirect ? <Redirect to={"/countries/" + city?.country_branch_id}></Redirect> : ""}
              </Space>
            </Row>

            <CustomTabs props={props} defaultActiveKey="#cityProfilePane">
              <Tabs.TabPane key="#cityProfilePane" tab={t("common.profile")}>
                <CityProfilePane city={city} />
              </Tabs.TabPane>

              <Tabs.TabPane key="#cityCleaningMinutesEstimation" tab={t("common.cleaningMinutesEstimation")}>
                <CityProfileCleaningMinutes city={city} />
              </Tabs.TabPane>
            </CustomTabs>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CityProfile;
