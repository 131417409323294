import { Card, Col, Row, Skeleton, Space, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../api/calls";
import AccountFetchModal from "../components/Accounts/AccountFetchModal";
import AccountProfilePane from "../components/Accounts/AccountProfilePane";
import AccountTransactionsPane from "../components/Accounts/AccountTransactionsPane";
import CustomTabs from "../components/custom/CustomTabs";
import RecordActionsBar from "../components/RecordActionsBar";

/**
 * Page with detail of account
 * @component
 * @alias AccountProfile
 * @returns <Card /> with content
 */
const AccountProfile = (props) => {
  const { t } = useTranslation();

  // States
  const [account, setAccount] = useState(null);
  const [isAccountLoading, setAccountLoading] = useState(false);
  const [deleteRedirect, setDeleteRedirect] = useState(false);

  useEffect(() => {
    setAccountLoading(true);

    coreApi
      .get("/accounts/" + props.match.params.id)
      .then((response) => {
        setAccount(response.data);
      })
      .catch(() => {})
      .finally(() => {
        setAccountLoading(false);
      });
  }, [props.match.params.id]);
  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Card loading={isAccountLoading}>
            <Row gutter={[0, 24]} style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography.Title>{account?.name || <Skeleton.Button size={"large"} />}</Typography.Title>

              {/* Action buttons */}
              <Space style={{ float: "right" }}>
                <AccountFetchModal account={account} />

                <RecordActionsBar
                  redirectState={deleteRedirect}
                  setRedirect={() => {
                    setDeleteRedirect(true);
                  }}
                  firstModalTitle={t("finance.accounts.delete.alertTitle")}
                  firstModalContent={t("finance.accounts.delete.alertContent")}
                  entityRoute="/accounts"
                  recordId={account?.id}
                />
              </Space>
            </Row>

            {/* Tab sections of resource */}
            <CustomTabs props={props} defaultActiveKey="#accountProfilePane">
              <Tabs.TabPane key="#accountProfilePane" tab={t("common.profile")}>
                <AccountProfilePane account={account} />
              </Tabs.TabPane>

              <Tabs.TabPane key="#accountTransactionsPane" tab={t("finance.transactions.transactions")}>
                <AccountTransactionsPane account={account} />
              </Tabs.TabPane>

              <Tabs.TabPane key="#accountBalancesPane" tab={t("finance.balances.balances")}></Tabs.TabPane>
            </CustomTabs>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AccountProfile;
