import { Col, Row } from "antd";
import React from "react";
import PartnerBasicInfoSection from "./PartnerBasicInfoSection";
import PartnerContactSection from "./PartnerContactSection";
import PartnerInsuranceSection from "./PartnerInsuranceSection";
import WorkerContractSection from "./WorkerContractSection";

const WorkerGeneralPane = ({ partner, readOnly }) => {
  return (
    <Row gutter={[16, 24]}>
      <Col xs={24} md={12}>
        <PartnerBasicInfoSection partner={partner} readOnly={readOnly} />
      </Col>
      <Col xs={24} md={12}>
        <WorkerContractSection partner={partner} readOnly={readOnly} />
      </Col>
      <Col xs={24} md={12}>
        <PartnerContactSection partner={partner} readOnly={readOnly} />
      </Col>
      <Col xs={24} md={12}>
        <PartnerInsuranceSection partner={partner} readOnly={readOnly} />
      </Col>
    </Row>
  );
};

export default WorkerGeneralPane;
