import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const CountryBranchTypeSelect = ({
  clearable = false,
  label,
  validationMessage,
  initialValue,
  required,
  readOnly,
  showSearch = false,
  sendOnchange = null,
}) => {
  const { t } = useTranslation();

  const [types, setTypes] = useState([]);

  useEffect(() => {
    coreApi
      .get("/countries/types")
      .then((response) => {
        setTypes(response.data.country_types);
      })
      .catch((error) => console.log(error));
  }, [t]);

  return (
    <Form.Item
      name="type_id"
      label={label}
      rules={[
        {
          required: required,
          message: validationMessage,
        },
      ]}
      initialValue={initialValue}
    >
      <Select
        onChange={(id) => {
          if (sendOnchange) sendOnchange(id);
        }}
        allowClear={clearable}
        disabled={readOnly}
        bordered={!readOnly}
        showSearch={showSearch}
        placeholder={t("expansion.countries.selectType")}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {types.map((type) => {
          return (
            <Select.Option key={type.value} value={type.value}>
              {t("expansion.countries.types." + type.translate_key)}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default CountryBranchTypeSelect;
