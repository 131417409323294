import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Modal, notification, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import ProfileFormActionSpace from "../ProfileFormActionSpace";
import RoleSelect from "./RoleSelect";
import UserChangePasswordForm from "./UserChangePasswordForm";
import EmailInputWithValidation from "../custom/Form/EmailInputWithValidation";

const UserProfilePane = ({ user, employee = null, setEmployee = null }) => {
  const { t } = useTranslation();
  const [addContactForm] = Form.useForm();

  const [readOnly, setReadOnly] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [userForm] = Form.useForm();

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <UserChangePasswordForm user={user} />
        <ProfileFormActionSpace
          isFormSubmitting={submitting}
          formInstance={userForm}
          isFormReadOnly={readOnly}
          setFormEditable={() => {
            setReadOnly(false);
          }}
          setFormDisabled={() => {
            setReadOnly(true);
          }}
        />
      </Col>
      <Col span={24}>
        <Form
          form={userForm}
          onFinish={(values) => {
            setSubmitting(true);

            coreApi
              .put("/users/" + user.id, values)
              .then((response) => {
                notification.success({ message: response.data.message });
                setReadOnly(true);
              })
              .catch((error) => {
                notification.error({ message: error?.response?.data?.message });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          <Row gutter={[16, 24]}>
            <Col xs={24} md={12} lg={8}>
              <Form.Item name={"username"} label={t("users.username")} initialValue={user?.username}>
                <Input readOnly={readOnly} bordered={!readOnly}></Input>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <EmailInputWithValidation
                form={addContactForm}
                name="email"
                label={t("users.email")}
                message={t("common.emailValidation")}
                readOnly={readOnly}
                bordered={!readOnly}
                initialValue={user?.email}
              />
            </Col>
            <Col xs={24} md={12} lg={8}>
              <RoleSelect initialValue={user?.roles[0]?.name} readOnly={readOnly} />
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                name={"pass_changed"}
                label={t("users.passwordLastChanged")}
                initialValue={user?.password_last_changed ? moment(user.password_last_changed) : null}
              >
                <DatePicker disabled={true} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 24]}>
            <Col xs={24} md={12} lg={8}>
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title: t("users.accessDelete"),
                    content: t("users.accessDeleteContent"),
                    okType: "danger",
                    okText: t("common.delete"),
                    okButtonProps: { type: "danger" },
                    icon: <ExclamationCircleOutlined />,
                    onOk() {
                      coreApi
                        .delete("/users/" + user?.id)
                        .then((response) => {
                          notification.success({ message: response.data.message });

                          setEmployee(response.data.employee);
                        })
                        .catch((error) => {
                          notification.error({ message: error?.response?.data?.message });
                        })
                        .finally(() => {
                          setSubmitting(false);
                        });
                    },
                  });
                }}
              >
                Odebrat přístup
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default UserProfilePane;
