import {Form, Select} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {RRule, rrulestr} from "rrule";
import { getDays } from '../helpers/rrule';

const RruleDaySelect = ({ rrule, setSelectedDaysCount, postChange, postBlur, hasFeedback, name = "days", isRequired = false, placeholder = '', showLabel = true, className = "default-item-class" }) => {
  const { t } = useTranslation();

  const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
  const [selectedDays, setSelectedDays] = useState(rrule ? getDays(rrule) : []);

  const handleChange = (values) => {
    const rruleString = new RRule({
      freq: RRule.WEEKLY,
      byweekday: values.map(day => RRule[day])
    }).toString();

    setSelectedDays(values);

    if (setSelectedDaysCount) {
      setSelectedDaysCount(values.length);
    }
    if (postChange) {
      postChange(rruleString);
    }
  };

  const handleBlur = () => {
    const rruleString = new RRule({
      freq: RRule.WEEKLY,
      byweekday: selectedDays.map(day => RRule[day])
    }).toString();

    if (postBlur) {
      postBlur(rruleString);
    }
  };

  return (
    <Form.Item
      className={className}
      label={showLabel ? (placeholder.length ? placeholder : t("rrules.weekday")) : null}
      name={name}
      initialValue={selectedDays}
      hasFeedback={hasFeedback}
      rules={[
        {
          required: isRequired,
          message: t("schedules.rruleDayRequiredMessage"),
        },
      ]}
    >
      <Select
        mode={"multiple"}
        allowClear
        bordered
        placeholder={placeholder}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {days.map((day, index) => (
          <Select.Option key={day + index} value={day}>
            {t("rrules.byweekday." + day)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default RruleDaySelect;
