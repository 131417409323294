import { Col, Divider, Form, Input, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const PartnerBillingSection = ({ partner, readOnly }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Divider orientation="left">{t("common.billingAddress")}</Divider>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Form.Item
            name="billing_address_street"
            initialValue={partner?.billing_address_street}
            label={t("common.billingAddressStreet")}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="billing_address_house_number"
            initialValue={partner?.billing_address_house_number}
            label={t("common.billingAddressHouseNumber")}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Form.Item
            name="billing_address_postal_code"
            initialValue={partner?.billing_address_postal_code}
            label={t("common.billingAddressPostalCode")}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="billing_address_city"
            initialValue={partner?.billing_address_city}
            label={t("common.billingAddressCity")}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default PartnerBillingSection;
