import { Divider } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { InvoiceContext } from "../../pages/InvoiceProfile";
import CitySelector from "../Cities/CitySelector";
import CurrencySelector from "../Currencies/CurrencySelector";

const InvoiceLocalePane = ({ readOnly }) => {
  const { t } = useTranslation();

  const { invoice } = useContext(InvoiceContext);

  return (
    <React.Fragment>
      <Divider orientation={"left"}>{t("finance.invoices.locale")}</Divider>
      <CitySelector readOnly={readOnly} initialValue={invoice?.city_branch_id} />
      <CurrencySelector readOnly={readOnly} initialValue={invoice?.currency_iso_4217} />
    </React.Fragment>
  );
};

export default InvoiceLocalePane;
