import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  List,
  Modal,
  Progress,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;

const data = [
  {
    value: "5",
    target: "10",
    description: "Nových klientů",
  },
  {
    value: "2",
    target: "5",
    description: "Nových partnerů",
  },
  {
    value: "10",
    target: "10",
    description: "Vyhrané fotbálky",
  },
];

const users = [
  {
    id: 1,
    name: "Adrien Rabiot",
  },
  {
    id: 2,
    name: "Mauro Icardi",
  },
  {
    id: 3,
    name: "Cristiano Ronaldo",
  },
];

const GoalsIndex = () => {
  const { t } = useTranslation();

  const [isGoalFormOpen, setGoalFormOpen] = useState(false);

  const [form] = Form.useForm();

  return (
    <React.Fragment>
      <Title>{t("goals.overviewHeading")}</Title>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Title level={4}>{t("goals.myGoals")}</Title>
          <List
            dataSource={data}
            renderItem={(item) => {
              return (
                <List.Item>
                  <Progress
                    percent={(item.value / item.target) * 100}
                    //steps={item.target}
                    active
                    style={{ width: "300px", marginRight: "20px" }}
                  />

                  {item.description +
                    " (" +
                    item.value +
                    "/" +
                    item.target +
                    ")"}
                </List.Item>
              );
            }}
          ></List>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Title level={4}>{t("goals.createdGoals")}</Title>
          <List
            dataSource={data}
            renderItem={(item) => {
              return (
                <List.Item>
                  <Progress
                    percent={(item.value / item.target) * 100}
                    //steps={item.target}
                    active
                    style={{ width: "300px", marginRight: "20px" }}
                  />

                  {item.description +
                    " (" +
                    item.value +
                    "/" +
                    item.target +
                    ")"}
                </List.Item>
              );
            }}
          ></List>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => {
              setGoalFormOpen(true);
            }}
          >
            {t("goals.addGoal")}
          </Button>
        </Col>
      </Row>
      <Modal
        maskClosable
        okText={t("common.add")}
        title={t("goals.addGoal")}
        open={isGoalFormOpen}
        onOk={form.submit}
        //confirmLoading={confirmLoading}
        onCancel={() => {
          setGoalFormOpen(false);
        }}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={() => {
            setGoalFormOpen(false);
          }}
        >
          <Form.Item required label={t("goals.targetVariable")}>
            <Select onChange={() => {}} allowClear>
              <Option value="newClientCount">
                {t("goals.newClientCount")}
              </Option>
              <Option value="newPartnerCount">
                {t("goals.newPartnerCount")}
              </Option>
              <Option value="claimRate">{t("goals.claimRate")}</Option>
            </Select>
          </Form.Item>
          <Form.Item required label={t("common.value")}>
            <InputNumber></InputNumber>
          </Form.Item>
          <Form.Item required label={t("goals.targetGroupCategory")}>
            <Select onChange={() => {}} allowClear>
              <Option value="cityBranch">{t("common.cityBranch")}</Option>
              <Option value="cityDivisionBranch">
                {t("common.cityDivisionBranch")}
              </Option>
              <Option value="user">{t("common.user")}</Option>
            </Select>
          </Form.Item>
          <Form.Item label={t("common.target")}>
            <Select mode="multiple" allowClear>
              {users.map((user) => (
                <Option value={user.id}>{user.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t("goals.targetDateRange")}>
            <DatePicker.RangePicker></DatePicker.RangePicker>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default GoalsIndex;
