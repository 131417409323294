import { Form, Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import validateAccountNumber from "./bbanControlAlgo";

/**
 * Account Number Input
 * @component
 * @alias AccountNumberInput
 * @property {string} initialValue - init value for Account Number field
 * @property {bool} readOnly - sets Form Item read-only
 * @property {bool} required - sets Form Item required - default false
 * @returns <Form.Item />
 */
const AccountNumberInput = ({ initialValue, readOnly, required = false }) => {
  const { t } = useTranslation();

  const [validateStatus, setValidateStatus] = useState();

  const handleValidation = (value) => {
    // uses custom fuction for validating account number
    if (validateAccountNumber(value)) {
      setValidateStatus("success");
    } else {
      setValidateStatus("error");
    }
  };

  return (
    <Form.Item
      label={t("finance.accounts.accountNumber")}
      name={"account_number"}
      initialValue={initialValue}
      hasFeedback
      validateStatus={validateStatus}
      rules={[
        {
          required: required,
          min: 2,
          max: 10,
          message: t("finance.accounts.validation.accountNumber"),
        },
      ]}
    >
      <Input
        maxLength={10}
        minLength={2}
        readOnly={readOnly}
        bordered={!readOnly}
        onChange={(e) => {
          handleValidation(e.target.value);
        }}
      ></Input>
    </Form.Item>
  );
};

export default AccountNumberInput;
