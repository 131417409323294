import { Col, Form, InputNumber, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const RruleFrequencySelect = ({
  initialInterval,
  initialFrequency,
  selectedDaysCount,
}) => {
  const { t } = useTranslation();

  const [interval, setInterval] = useState();

  useEffect(() => {
    setInterval(initialInterval);
  }, [initialInterval]);

  return (
    <Row>
      <Col span={12}>
        <Form.Item
          label={t("rrules.repeatOnceIn", { count: selectedDaysCount || 1 })}
          required
          name={"interval"}
          initialValue={initialInterval}
        >
          <InputNumber
            min={1}
            onChange={(value) => {
              setInterval(value);
            }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name={"frequency"} initialValue={initialFrequency} required>
          <Select>
            <Select.Option value={"YEARLY"}>
              {interval === 1
                ? t("rrules.freq.sgYEARLY")
                : interval > 1 && interval < 5
                ? t("rrules.freq.plYEARLY")
                : interval >= 5
                ? t("rrules.freq.mplYEARLY")
                : t("rrules.freq.YEARLY")}
            </Select.Option>
            <Select.Option value={"MONTHLY"}>
              {interval === 1
                ? t("rrules.freq.sgMONTHLY")
                : interval > 1 && interval < 5
                ? t("rrules.freq.plMONTHLY")
                : interval >= 5
                ? t("rrules.freq.mplMONTHLY")
                : t("rrules.freq.MONTHLY")}
            </Select.Option>
            <Select.Option value={"WEEKLY"}>
              {interval === 1
                ? t("rrules.freq.sgWEEKLY")
                : interval > 1 && interval < 5
                ? t("rrules.freq.plWEEKLY")
                : interval >= 5
                ? t("rrules.freq.mplWEEKLY")
                : t("rrules.freq.WEEKLY")}
            </Select.Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};
export default RruleFrequencySelect;
