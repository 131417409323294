import { Result } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ErrorPane = ({ errorCode }) => {
  const { t } = useTranslation();

  const getSubTitleText = (errorCode) => {
    switch (errorCode) {
      case 500:
        return t("errors.500");
      case 404:
        return t("errors.404");
      case 403:
        return t("errors.403");
      default:
        return t("errors.general");
    }
  };

  return (
    <Result
      status={
        errorCode === 500 || errorCode === 404 || errorCode === 403
          ? errorCode
          : 500
      }
      title={errorCode}
      subTitle={getSubTitleText(errorCode)}
    />
  );
};

export default ErrorPane;
