import { Card, Col, Row, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../api/calls";
import CountryCitiesPane from "../components/CountryCitiesPane";
import CountryGeneralPane from "../components/CountryGeneralPane";
import CustomTabs from "../components/custom/CustomTabs";
import RecordActionsBar from "../components/RecordActionsBar";

const CountryProfile = (props) => {
  const { t } = useTranslation();

  const [isPageLoading, setPageLoading] = useState(false);
  const [deleteRedirect, setDeleteRedirect] = useState(false);

  const [country, setCountry] = useState(null);

  useEffect(() => {
    setPageLoading(true);
    coreApi
      .get("/countries/" + props.match.params.id)
      .then((response) => {
        setCountry(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setPageLoading(false);
      });
  }, [props.match.params.id]);

  return (
    <React.Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card loading={isPageLoading}>
            <Row gutter={[0, 24]} style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography.Title>{country?.name}</Typography.Title>

              <RecordActionsBar
                redirectState={deleteRedirect}
                setRedirect={() => {
                  setDeleteRedirect(true);
                }}
                firstModalTitle={t("countries.delete.alertTitle")}
                firstModalContent={t("countries.delete.alertContent")}
                entityRoute="/countries"
                recordId={country?.id}
              />
            </Row>

            <CustomTabs props={props}>
              <Tabs.TabPane key="#overview" tab={t("common.profile")}>
                <CountryGeneralPane country={country} />
              </Tabs.TabPane>
              <Tabs.TabPane key="#cities" tab={t("expansion.cities")}>
                <CountryCitiesPane citiesProp={country?.city_branches} IdCountryBranch={country?.id} />
              </Tabs.TabPane>
            </CustomTabs>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CountryProfile;
