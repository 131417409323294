import { Checkbox, DatePicker, Divider, Form, Input } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

const PartnerInsuranceSection = ({ partner, readOnly }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Divider orientation="left">{t("partners.insuranceAndEarnings")}</Divider>
      <Form.Item
        name="is_insured"
        label={t("partners.insurance")}
        initialValue={partner?.is_insured}
        valuePropName="checked"
      >
        <Checkbox disabled={readOnly}></Checkbox>
      </Form.Item>
      <Form.Item
        name="insurance_expiration_date"
        label={t("partners.insuranceExpirationDate")}
        initialValue={
          partner?.insurance_expiration_date
            ? moment(partner.insurance_expiration_date)
            : null
        }
      >
        <DatePicker
          format={"L"}
          disabled={readOnly}
          bordered={!readOnly}
        ></DatePicker>
      </Form.Item>
      <Form.Item
        name="guaranteed_earnings"
        label={t("partners.guaranteedEarnings")}
        initialValue={partner?.guaranteed_earnings}
      >
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>
    </React.Fragment>
  );
};

export default PartnerInsuranceSection;
