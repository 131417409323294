import { Col, Row } from "antd";
import React from "react";
import PartnerBankingSection from "./PartnerBankingSection";
import PartnerBasicInfoSection from "./PartnerBasicInfoSection";
import PartnerBillingSection from "./PartnerBillingSection";
import PartnerContactSection from "./PartnerContactSection";
import PartnerContractSection from "./PartnerContractSection";
import PartnerInsuranceSection from "./PartnerInsuranceSection";

const CancelledPartnerGeneralPane = ({ partner, readOnly }) => {
  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        <Col span={24} md={12}>
          <PartnerBasicInfoSection partner={partner} readOnly={readOnly} />
        </Col>
        <Col xs={24} md={12}>
          <PartnerContractSection partner={partner} readOnly={readOnly} />
        </Col>
        <Col xs={24} md={12}>
          <PartnerBillingSection partner={partner} readOnly={readOnly} />
          <PartnerContactSection partner={partner} readOnly={readOnly} />
        </Col>
        <Col xs={24} md={12}>
          <PartnerBankingSection partner={partner} readOnly={readOnly} />
        </Col>
        <Col xs={24} md={12}>
          <PartnerInsuranceSection partner={partner} readOnly={readOnly} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CancelledPartnerGeneralPane;
