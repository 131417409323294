import { Button, Table, Typography, Modal, Descriptions, notification, Divider } from "antd";
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import moment from "moment";
import React, { useState } from "react";

/**
 * @component
 * @alias TransactionPane
 * @prop {object} transactions
 * @prop {object} client
 */
const TransactionPane = ({ transactions, client }) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState();

  const copyBankInfo = (transaction) => {

    const currentClientBankInfo = {
      account_prefix: client.account_prefix,
      account_number: client.account_number,
      account_bank_code: client.account_bank_code,
      account_iban: client.account_iban,
    };

    let bankInfo = {
      account_prefix: null,
      account_number: null,
      account_bank_code: transaction.bank_code_from || null,
      account_iban: null,
      country_code_iso_3166: client.country_code_iso_3166,
    };

    if (transaction.account_number_from) {
      if (transaction.account_number_from.length > 20) {
        bankInfo.account_iban = transaction.account_number_from;
      } else {
        if (transaction.account_number_from.includes("-")) {
          const [prefix, number] = transaction.account_number_from.split("-");
          bankInfo.account_prefix = prefix;
          bankInfo.account_number = number;
        } else {
          bankInfo.account_number = transaction.account_number_from;
        }
      }
    } else {
      notification.error({message: t("clients.copyBankInfo.currentBankInfoEmpty")});
    }

    Modal.confirm({
      title: t("clients.copyBankInfo.confirmContent"),
      content: (
        <>
          <Divider orientation="left">{t("clients.copyBankInfo.currentBankInfo")}</Divider>
          <Descriptions column={1}>
            <Descriptions.Item label={t("finance.accounts.accountPrefix")}>{currentClientBankInfo.account_prefix}</Descriptions.Item>
            <Descriptions.Item label={t("finance.accounts.accountNumber")}>{currentClientBankInfo.account_number}</Descriptions.Item>
            <Descriptions.Item label={t("finance.accounts.accountBankCode")}>{currentClientBankInfo.account_bank_code}</Descriptions.Item>
            <Descriptions.Item label={t("finance.accounts.accountIban")}>{currentClientBankInfo.account_iban}</Descriptions.Item>
          </Descriptions>
          <Divider orientation="left">{t("clients.copyBankInfo.newBankInfo")}</Divider>
          <Descriptions column={1}>
            <Descriptions.Item label={t("finance.accounts.accountPrefix")}>{bankInfo.account_prefix}</Descriptions.Item>
            <Descriptions.Item label={t("finance.accounts.accountNumber")}>{bankInfo.account_number}</Descriptions.Item>
            <Descriptions.Item label={t("finance.accounts.accountBankCode")}>{bankInfo.account_bank_code}</Descriptions.Item>
            <Descriptions.Item label={t("finance.accounts.accountIban")}>{bankInfo.account_iban}</Descriptions.Item>
          </Descriptions>
        </>
      ),
      onOk: () => {
        coreApi.put(`/clients/${client?.id}/update-bank-info`, bankInfo)
          .then(() => notification.success({ message: t("clients.copyBankInfo.successMessage") }))
          .catch((err) => notification.error({ message: err?.response?.data?.message }));
      },
    });
  };

  const tableColumns = [
    {
      title: t("finance.transactions.transactionNumber"),
      key: "id",
      dataIndex: "id",
    },
    {
      title: t("finance.transactions.accountNumberFrom"),
      key: "account_number_from",
      render: (transaction) => (
        <Typography.Text copyable key={transaction.id}>
          {transaction.account_number_from + "/" + transaction.bank_code_from}
        </Typography.Text>
      ),
    },
    {
      title: t("finance.transactions.accountNameFrom"),
      key: "account_name_from",
      dataIndex: "account_name_from",
    },
    {
      title: t("finance.transactions.variableSymbol"),
      key: "variable_symbol",
      dataIndex: "variable_symbol",
      render: (variableSymbol) => (
        <Typography.Text copyable key={variableSymbol}>
          {variableSymbol}
        </Typography.Text>
      ),
    },
    {
      title: t("finance.transactions.amount"),
      key: "amount",
      sorter: true,
      render: (rowData) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: rowData.currency_iso_4217 || "CZK",
        }).format(rowData.pivot.amount);
      },
    },
    {
      title: t("finance.transactions.date"),
      key: "date_transaction",
      dataIndex: "date_transaction",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("L");
      },
    },
    {
      title: t("clients.copyBankInfo.buttonText"),
      key: "actions",
      render: (transaction) => (
        <Button icon={<CopyOutlined />} onClick={() => copyBankInfo(transaction)} />
      ),
    },
  ];

  return (
    <React.Fragment>
      <Table
        key={"transaction-table"}
        size="small"
        dataSource={transactions}
        columns={tableColumns}
        pagination={{
          current: transactions?.current_page,
          pageSize: transactions?.per_page,
          total: transactions?.total,
          page: currentPage,
          showSizeChanger: true,
          onChange: (e, pageSize) => {
            setCurrentPage(e);
            setFilters({ ...filters, page: e, perPage: pageSize });
          },
        }}
        rowKey={"id"}
      ></Table>
    </React.Fragment>
  );
};

export default TransactionPane;
