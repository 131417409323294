import React, { useContext, useEffect, useState } from "react";
import { Card, Col, DatePicker, Form, Input, InputNumber, notification, Row, Select, Tabs } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import { OfferContext } from "../../pages/OfferProfile";

const { TabPane } = Tabs;

const gutter = [16, 16];

const OfferDetailsPane = ({
  url,
  formInstance,
  isFormReadOnly,
  isFormSending,
  setFormSending,
  setFormReadOnly,
  setFormEditable,
  setFormDisabled,
  offerForm,
  setClientName,
}) => {
  const { t } = useTranslation();

  const { offer, isOrder } = useContext(OfferContext);

  const [client, setClient] = useState({});

  const [building, setBuilding] = useState({});

  const [buildingUnits, setBuildingUnits] = useState([]);

  const [employees, setEmployees] = useState([]);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (offer.building_id && offer.client_id) {
      axios
        .all([
          coreApi.get("/buildings/" + offer.building_id),
          coreApi.get("/clients/" + offer.client_id),
          coreApi.get("/employees/all"),
        ])
        .then(
          axios.spread((buildings, clients, employees) => {
            let { building_units, ...building } = buildings.data;

            setClient({ ...clients.data });

            setClientName(clients.data.name);

            setBuilding(() => ({ ...building }));
            setBuildingUnits(() => [...building_units]);

            setEmployees([...employees?.data]);

            offerForm.resetFields();
          })
        )
        .catch((err) => {
          notification.error({ message: err?.response?.data?.message });
        })
    }
  }, [offer]);

  return (
    <React.Fragment>
      <Form
        name="offerForm"
        form={offerForm}
        initialValues={{ building_units: [...buildingUnits] }}
        onFinish={(values) => {
          setFormSending(true);

          if (url.includes("offer")) {
            coreApi
              .put("/offers/" + values.offer_id, values)
              .then((response) => {
                setFormReadOnly(true);
                notification.success({
                  message: t("successNotifications.saveChanges.title"),
                });
              })
              .catch((error) => {
                notification.error({
                  message: t("errorNotifications.saveChanges.title"),
                  description: t("errorNotifications.saveChanges.message"),
                });
              })
              .finally(() => {
                setFormSending(false);
                setFormReadOnly(true);
              });
          } else if (url.includes("order")) {
            coreApi
              .put("/orders/" + values.offer_id, values)
              .then((response) => {
                setFormReadOnly(true);
                notification.success({
                  message: t("successNotifications.saveChanges.title"),
                });
              })
              .catch((error) => {
                notification.error({
                  message: t("errorNotifications.saveChanges.title"),
                  description: t("errorNotifications.saveChanges.message"),
                });
              })
              .finally(() => {
                setFormSending(false);
                setFormReadOnly(true);
              });
          }
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card
              title={
                <Row>
                  <Col span={4}>{isOrder ? t("offers.orderOverview") : t("offers.offerOverview")}</Col>
                </Row>
              }
              style={{ height: "100%" }}
              loading={isLoading}
            >
              <Row gutter={gutter}>
                <Col span={24}>
                  <Form.Item hidden name="building_id" initialValue={building?.id}></Form.Item>
                  <Form.Item hidden name="client_id" initialValue={client?.id}></Form.Item>
                  <Form.Item hidden name="offer_id" initialValue={offer?.id}></Form.Item>
                  <Form.Item hidden name="currency_iso_4217" initialValue={offer?.currency_iso_4217}></Form.Item>
                  <Form.Item hidden name="header_client_name" initialValue={offer?.header_client_name}></Form.Item>
                  <Form.Item hidden name="header_street" initialValue={offer?.header_client_street}></Form.Item>
                  <Form.Item
                    label={t("offers.dateIssued")}
                    name="date_issued"
                    initialValue={offer?.date_issued && moment(offer.date_issued)}
                  >
                    <DatePicker disabled={isFormReadOnly} bordered={!isFormReadOnly}></DatePicker>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={gutter}>
                <Col span={12}>
                  <Form.Item
                    label={t("offers.dateActiveFrom")}
                    name={"date_active_from"}
                    initialValue={offer?.date_active_from && moment(offer.date_active_from)}
                  >
                    <DatePicker disabled={isFormReadOnly} bordered={!isFormReadOnly}></DatePicker>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("offers.dateActiveTo")}
                    name={"date_active_to"}
                    initialValue={offer?.date_active_to && moment(offer.date_active_to)}
                  >
                    <DatePicker disabled={isFormReadOnly} bordered={!isFormReadOnly}></DatePicker>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label={t("offers.hourSalary")}>
                    <Input
                      value={new Intl.NumberFormat("cs-CZ", {
                        style: "currency",
                        currency: offer?.currency_iso_4217 || "CZK",
                      }).format(offer?.building?.city_branch?.hourly_wage)}
                      bordered={false}
                      readOnly={true}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={"user_id"} label={t("employees.employee")} initialValue={offer.user_id}>
                    <Select
                      disabled={isFormReadOnly}
                      bordered={!isFormReadOnly}
                      showSearch={true}
                      placeholder={t("managers.selectManager")}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {employees.map((employee) => (
                        <Select.Option value={employee.user?.id} key={employee.user?.id}>
                          {employee.first_name + " " + employee.last_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card title={t("buildings.buildingOverviewHeader")} style={{ height: "100%" }} loading={isLoading}>
              <Row gutter={gutter}>
                <Col span={16}>
                  <Form.Item label={t("buildings.city")} name="city" initialValue={building?.city}>
                    <Input disabled={true} bordered={false}></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t("buildings.postalCode")} name="postal_code" initialValue={building?.postal_code}>
                    <Input disabled={true} bordered={false}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={gutter}>
                <Col span={24}>
                  <Form.Item label={t("buildings.district")} name="district" initialValue={building?.district}>
                    <Input disabled={true} bordered={false}></Input>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t("buildings.cleaningRoomDescription")}
                    name="cleaning_room"
                    initialValue={building?.cleaning_room}
                  >
                    <TextArea disabled={true} bordered={false}></TextArea>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={gutter}>
                <Col>
                  <Form.Item
                    label={t("buildings.endingDate")}
                    initialValue={building?.ending_date && moment(building.ending_date)}
                  >
                    <DatePicker disabled={true} bordered={false}></DatePicker>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Přehled klienta" style={{ height: "100%" }} loading={isLoading}>
              <Row gutter={gutter}>
                <Col span={24}>
                  <Form.Item label={"Jméno klienta"} name="name" initialValue={client?.name}>
                    <Input disabled={true} bordered={false}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={gutter}>
                <Col>
                  <Form.Item
                    label={"Ulice"}
                    name="billing_address_street"
                    initialValue={client?.billing_address_street}
                  >
                    <Input disabled={true} bordered={false}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={gutter}>
                <Col>
                  <Form.Item label={"Město"} name="billing_address_city" initialValue={client?.billing_address_city}>
                    <Input disabled={true} bordered={false}></Input>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label={"PSČ"}
                    name="billing_address_postal_code"
                    initialValue={client?.billing_address_postal_code}
                  >
                    <Input disabled={true} bordered={false}></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Přehled vchodů" loading={isLoading}>
              <Form.List name="building_units">
                {(fields) => (
                  <Tabs size="small">
                    {fields.map((field) => (
                      <TabPane
                        key={field.key}
                        tab={buildingUnits[field.key]?.street + " " + buildingUnits[field.key]?.house_number}
                      >
                        <Row gutter={gutter}>
                          <Form.Item
                            key={field.key}
                            initialValue={field?.id}
                            name={[field.key, "building_unit_id"]}
                            hidden
                          >
                            <Input disabled={true} bordered={false}></Input>
                          </Form.Item>

                          <Col span={24}>
                            <Form.Item
                              key={field.key}
                              initialValue={field?.district}
                              name={[field.key, "district"]}
                              label="Městská čtvrť"
                            >
                              <Input disabled={true} bordered={false}></Input>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              key={field.key}
                              initialValue={field?.floor_count}
                              name={[field.key, "floor_count"]}
                              label="Počet pater"
                            >
                              <InputNumber disabled={true} bordered={false}></InputNumber>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              key={field.key}
                              initialValue={field?.basement_floor_count}
                              name={[field.key, "basement_floor_count"]}
                              label="Počet sklepních pater"
                            >
                              <InputNumber disabled={true} bordered={false}></InputNumber>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              key={field.key}
                              initialValue={field?.ground_floor_count}
                              name={[field.key, "ground_floor_count"]}
                              label="Počet přízemních pater"
                            >
                              <InputNumber disabled={true} bordered={false}></InputNumber>
                            </Form.Item>
                          </Col>
                        </Row>
                      </TabPane>
                    ))}
                  </Tabs>
                )}
              </Form.List>
            </Card>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default OfferDetailsPane;
