import { Divider, Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import AccountBankCodeInput from "../Accounts/AccountBankCodeInput";
import AccountIbanInput from "../Accounts/AccountIbanInput";
import AccountNumberInput from "../Accounts/AccountNumberInput";
import AccountPrefixInput from "../Accounts/AccountPrefixInput";
import AccountSwiftInput from "../Accounts/AccountSwiftInput";

const PartnerBankingSection = ({ partner, readOnly }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Divider orientation="left">{t("common.bankingInformation")}</Divider>
      <AccountPrefixInput
        initialValue={partner?.account_prefix}
        readOnly={readOnly}
      />
      <AccountNumberInput
        initialValue={partner?.account_number}
        readOnly={readOnly}
      />
      <AccountBankCodeInput
        initialValue={partner?.account_bank_code}
        readOnly={readOnly}
      />
      <AccountIbanInput
        initialValue={partner?.account_iban}
        readOnly={readOnly}
      />
      <AccountSwiftInput
        initialValue={partner?.account_swift}
        readOnly={readOnly}
      />
        <Form.Item
          name="account_bank_owner"
          label={t("finance.accounts.accountBankOwner")}
          initialValue={partner?.account_bank_owner}
          rules={[
            {
              required: true,
              message: t("finance.accounts.validation.accountBankOwner"),
            },
          ]}
        >
          <Input readOnly={readOnly} bordered={!readOnly}></Input>
        </Form.Item>
    </React.Fragment>
  );
};

export default PartnerBankingSection;
