import { useEffect, useState } from "react";

/**
 * Handle tab change.
 *
 * @hook
 * @alias useTabChange
 * @param {object} props
 * @param {string} initialValue
 * @returns {array} state, setState
 */
function useTabChange(props, initialValue) {
  const [state, setState] = useState(props.location.hash || initialValue);

  useEffect(() => {
    props.history.push(state);
  }, [state, props.history]);

  return [state, setState];
}

export default useTabChange;
