import { Badge, Tag, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Cleaning Claim Status - displaying claim status of selected cleaning
 * @component
 * @alias CleaningClaimStatusCircle
 * @param {object} cleaning
 * @returns Shows a cleanings claim tag
 */
const CleaningClaimStatusCircle = ({ cleaning }) => {
  return (
    <React.Fragment>
      <div></div>

      {Boolean(cleaning.is_claim_in_solution) === true ? <ClaimInSolutionTag /> : ""}
      {Boolean(cleaning.is_claim_solved) === true ? <ClaimSolvedTag /> : ""}
      {Boolean(cleaning.is_claim_in_solution) === false && Boolean(cleaning.is_claim_solved) === false ? (
        <NoClaimTag />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

const ClaimInSolutionTag = () => {
  const { t } = useTranslation();
  return <Tag color={"volcano"}>{t("cleanings.claimInSolution")}</Tag>;
};
const ClaimSolvedTag = () => {
  const { t } = useTranslation();
  return <Tag color={"yellow"}>{t("cleanings.claimSolved")}</Tag>;
};
const NoClaimTag = () => {
  const { t } = useTranslation();
  return (
    <Tooltip placement="bottom" title={t("cleanings.noClaim")}>
      <Badge color={"lime"} size={"large"} count={1} />
    </Tooltip>
  );
};
export default CleaningClaimStatusCircle;
