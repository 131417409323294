import { Button, Calendar, Col, Divider, Row, Space, Spin, Tabs } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import CleaningOverview from "./CleaningOverview";
import OneTimeCleaningAddForm from "./OneTimeCleaningAddForm";
import RecurringCleaningAddForm from "./RecurringCleaningAddForm";

/**
 * Cleanings Calendar
 * @component
 * @alias CleaningsCalendar
 * @property {function} handleCalendarChange - parent function that changes changing selected calendars cell
 * @property {function} dateCellRender - parent function for rendering calendar cell content
 * @property {function} setSelectedDate - set currently selected cell
 * @property {object} selectedDate - currently selected cell
 * @property {array} selectedCleanings - selected cleanings array
 * @property {function} setSelectedCleanings - set selected cleanings state array function
 * @property {bool} isOneTimeCleaningFormOpen
 * @property {function} setOneTimeCleaningFormOpen
 * @property {object} buildingUnit
 * @property {bool} loading
 * @returns <Calendar /> component with "side-bar" that contains event (cleaning) informations
 * and adds ability to change them or create new one-time event (cleaning)
 */
const CleaningsCalendar = ({
  handleCalendarChange,
  dateCellRender,
  setSelectedDate,
  selectedDate,
  selectedCleanings,
  setSelectedCleanings,
  isOneTimeCleaningFormOpen,
  setOneTimeCleaningFormOpen,
  isRecurringCleaningFormOpen,
  setRecurringCleaningFormOpen,
  buildingUnit,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Divider orientation={"left"}>{t("schedules.scheduleOverview")}</Divider>
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <Spin spinning={loading}>
            <Calendar
              dateCellRender={dateCellRender}
              onSelect={(value) => {
                setSelectedDate(() => moment(value));
              }}
              onPanelChange={(date) => {
                handleCalendarChange(moment(date));
              }}
            />
          </Spin>
        </Col>
        <Col span={8}>
          <Divider orientation={"left"}>{t("schedules.eventsInfo")}</Divider>
          <Col span={24}>
            <Spin spinning={loading}>
              <Tabs>
                {selectedCleanings.length > 0 ? (
                  selectedCleanings?.map((cleaning) => {
                    return (
                      <Tabs.TabPane
                        key={cleaning?.id}
                        tab={
                          cleaning.is_recurring === 1
                            ? t("cleanings.recurring.cleaning")
                            : t("cleanings.oneTime.cleaning")
                        }
                      >
                        <CleaningOverview
                          cleaning={cleaning}
                          cleanings={selectedCleanings}
                          setCleanings={setSelectedCleanings}
                          refreshCalendarView={() => {
                            handleCalendarChange(selectedDate);
                          }}
                        ></CleaningOverview>
                      </Tabs.TabPane>
                    );
                  })
                ) : (
                  <Tabs.TabPane style={{ textAlign: "center" }}>
                    <Row>
                      <Col>
                        <Space>
                          <Button type="primary" onClick={() => setOneTimeCleaningFormOpen(true)}>
                            {t("cleanings.oneTime.createCleaning")}
                          </Button>
                          <Button type="primary" onClick={() => setRecurringCleaningFormOpen(true)}>
                            {t("cleanings.recurring.createCleaning")}
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                )}
              </Tabs>
            </Spin>
          </Col>
        </Col>
      </Row>
      <OneTimeCleaningAddForm
        isOpen={isOneTimeCleaningFormOpen}
        close={() => {
          setOneTimeCleaningFormOpen(false);
        }}
        buildingUnit={buildingUnit}
        selectedDate={selectedDate}
        refreshCalendarView={() => {
          handleCalendarChange(selectedDate);
        }}
      />
      <RecurringCleaningAddForm
        isOpen={isRecurringCleaningFormOpen}
        close={() => {
          setRecurringCleaningFormOpen(false);
        }}
        buildingUnit={buildingUnit}
        selectedDate={selectedDate}
        refreshCalendarView={() => {
          handleCalendarChange(selectedDate);
        }}
      />
    </React.Fragment>
  );
};

export default CleaningsCalendar;
