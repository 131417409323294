import { Col, Form, Input, notification, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../api/calls";
import ManagerSelect from "./ManagerSelect";
import ProfileFormActionSpace from "./ProfileFormActionSpace";
import CountryBranchTypeSelect from "./Countries/CountryBranchTypeSelect";

const CountryGeneralPane = ({ country }) => {
  const { t } = useTranslation();

  const [isFormReadOnly, setFormReadOnly] = useState(true);
  const [isFormSending, setFormSending] = useState(false);

  const [countryForm] = Form.useForm();

  return (
    <React.Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <ProfileFormActionSpace
            formInstance={countryForm}
            isFormReadOnly={isFormReadOnly}
            isFormSending={isFormSending}
            setFormEditable={() => setFormReadOnly(false)}
            setFormDisabled={() => setFormReadOnly(true)}
          />
        </Col>
      </Row>
      <Form
        requiredMark={!isFormReadOnly}
        form={countryForm}
        onFinish={(values) => {
          setFormSending(true);
          values.vat = values.vat / 100;
          coreApi
            .put("/countries/" + country?.id, values)
            .then((response) => {
              setFormReadOnly(true);
              notification.success({
                message: response.data.message,
              });
            })
            .catch((error) => console.log(error))
            .finally(() => {
              setFormSending(false);
            });
        }}
      >
        <Row gutter={[24, 0]}>
          <Col span={8}>
            <Form.Item name="name" label={t("expansion.countryName")} initialValue={country?.name}>
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="web" label={t("expansion.countryWeb")} initialValue={country?.web}>
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="vat" label={t("expansion.vat")} initialValue={country?.vat * 100}>
              <Input
                style={{ width: "75px" }}
                readOnly={isFormReadOnly}
                bordered={!isFormReadOnly}
                suffix={"%"}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="iso_4217_currency_code"
              label={t("expansion.iso4217")}
              initialValue={country?.iso_4217_currency_code}
            >
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="country_code_iso_3166"
              label={t("expansion.iso3166")}
              initialValue={country?.country_code_iso_3166}
            >
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="country_calling_code"
              label={t("expansion.countryCallingCode")}
              initialValue={country?.country_calling_code}
            >
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <ManagerSelect
              required={true}
              initialValue={country?.manager_id}
              label={t("expansion.countries.countryManager")}
              readOnly={isFormReadOnly}
              validationMessage={""}
            />
          </Col>
          <Col span={8}>
            <Form.Item
              name="company_name"
              label={t("expansion.countries.companyName")}
              initialValue={country?.company_name}
            >
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="company_number"
              label={t("expansion.countries.companyNumber")}
              initialValue={country?.company_number}
            >
              <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <CountryBranchTypeSelect
                required={true}
                initialValue={country?.type_id}
                label={t("expansion.countries.countryType")}
                readOnly={isFormReadOnly}
                validationMessage={""}
            />
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default CountryGeneralPane;
