import React from "react";
import { Button, Col, notification, Row } from "antd";
import { useState } from "react";
import { coreApi } from "./../../api/calls";

const Command = ({ title, apiURL, ...props }) => {
  const [loading, setLoading] = useState();

  return (
    <Row>
      <Col span={20}>
        <p>{title}</p>
      </Col>
      <Col span={4}>
        <Button
          loading={loading}
          onClick={() => {
            setLoading(true);

            coreApi
              .post(apiURL)
              .then((res) => notification.success({ message: res.data.message }))
              .catch((err) => notification.error({ title: err.response.data.message }))
              .finally(() => setLoading(false));
          }}
        >
          {loading ? "Running" : "Run"}
        </Button>
      </Col>
    </Row>
  );
};

export default Command;
