// area scope
export const initAreaScope = (cities) => ({
  type: "INIT_AREA_SCOPE",
  cities: cities,
});

export const setAreaScope = (areaScope) => ({
  type: "SET_AREA_SCOPE",
  areaScope: areaScope,
});

export const addAreaScope = (city) => ({
  type: "ADD_AREA_SCOPE",
  city: city,
});

export const removeAreaScope = (city) => ({
  type: "REMOVE_AREA_SCOPE",
  city: city,
});

export const getAreaScope = (store) => store.areaScope;

// response handling
export const setErrorResponseStatus = (error) => ({
  type: "SET_RESPONSE_STATUS",
  statusCode: error.response.status,
  message: error.response.statusText,
});

export const setSuccessResponseStatus = (response) => ({
  type: "SET_RESPONSE_STATUS",
  statusCode: response.status,
  message: response.statusText,
});

export const setURL = (url) => ({
  type: "SET_URL",
  url: url,
});

export const setRedirect = (redirect) => ({
  type: "SET_REDIRECT",
  redirect: redirect,
});

export const getRedirect = (store) => store?.redirect;

export const getURL = (store) => store?.url;

export const removeURL = () => ({
  type: "REMOVE_URL",
});
