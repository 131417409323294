import { Card, Col, notification, Row, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../api/calls";
import EmployeeGeneralPane from "../components/EmployeeGeneralPane";
import RecordActionsBar from "../components/RecordActionsBar";
import UserAddForm from "../components/Users/UserAddForm";
import UserProfilePane from "../components/Users/UserProfilePane";
import CustomTabs from "../components/custom/CustomTabs";

const EmployeeProfile = (props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [deleteRedirect, setDeleteRedirect] = useState(false);
  const [employee, setEmployee] = useState();

  useEffect(() => {
    setLoading(true);
    coreApi
      .get("/employees/" + props.match.params.id)
      .then((response) => {
        setEmployee(response.data);
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.match.params.id]);

  return (
    <React.Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card loading={loading}>
            <Row gutter={[0, 24]} style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography.Title>{employee?.first_name + " " + employee?.last_name}</Typography.Title>
              <RecordActionsBar
                redirectState={deleteRedirect}
                setRedirect={() => {
                  setDeleteRedirect(true);
                }}
                firstModalTitle={t("employees.delete.alertTitle")}
                firstModalContent={t("employees.delete.alertContent")}
                entityRoute="/employees"
                recordId={employee?.id}
              />
            </Row>

            <CustomTabs props={props}>
              <Tabs.TabPane tab={t("common.profile")} key={"#overview"}>
                <EmployeeGeneralPane employee={employee} loading={loading} />
              </Tabs.TabPane>

              <Tabs.TabPane tab={t("users.userProfile")} key={"#profile"}>
                {employee?.user && employee?.user.deleted_at === null ? (
                  <UserProfilePane user={employee.user} employee={employee} setEmployee={setEmployee} />
                ) : (
                  <UserAddForm employee={employee} />
                )}
              </Tabs.TabPane>
            </CustomTabs>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default EmployeeProfile;
