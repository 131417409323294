import { notification } from "antd";
import { useState, useEffect } from "react";
import { coreApi } from "../api/calls";

export default function useCoreApi(method, url, body = null, headers = null) {
  const [loading, setloading] = useState(true);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState({});

  useEffect(() => {
    const fetchData = () => {
      coreApi[method](
        url,
        headers ? JSON.stringify(headers) : null,
        body ? JSON.stringify(body) : null
      )
        .then((res) => {
          setResponse(res?.data);
        })
        .catch((err) => {
          setError(err);
          notification.error({ message: err?.reponse?.data?.message });
        })
        .finally(() => {
          setloading(false);
        });
    };

    fetchData();
  }, [method, url, body, headers]);

  return { loading, response, error };
}
