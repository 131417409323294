import { Tag } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Cleaning Claim Status - displaying claim status of selected cleaning
 * @component
 * @alias CleaningClaimStatus
 * @param {object} cleaning
 * @returns Shows a cleanings claim tag
 */
const CleaningClaimStatus = ({ cleaning }) => {
  return (
    <React.Fragment>
      {Boolean(cleaning.is_claim_in_solution) === true ? (
        <ClaimInSolutionTag />
      ) : (
        ""
      )}
      {Boolean(cleaning.is_claim_solved) === true ? <ClaimSolvedTag /> : ""}
      {Boolean(cleaning.is_claim_in_solution) === false &&
      Boolean(cleaning.is_claim_solved) === false ? (
        <NoClaimTag />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

const ClaimInSolutionTag = () => {
  const { t } = useTranslation();
  return <Tag color={"volcano"}>{t("cleanings.claimInSolution")}</Tag>;
};
const ClaimSolvedTag = () => {
  const { t } = useTranslation();
  return <Tag color={"yellow"}>{t("cleanings.claimSolved")}</Tag>;
};
const NoClaimTag = () => {
  const { t } = useTranslation();
  return <Tag color={"lime"}>{t("cleanings.noClaim")}</Tag>;
};
export default CleaningClaimStatus;
