import React, { useState, useEffect } from "react";
import { Form, Select, DatePicker, Button, Input, Row, Col, notification, Descriptions, Card } from "antd";
import ManagerSelect from '../../ManagerSelect';
import PartnerSelect from './../../Partners/PartnerSelect';
import RruleDaySelect from '../../Scheduler/RruleDaySelect';
import { coreApi } from "../../../api/calls";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

const { Option } = Select;
const { TextArea } = Input;
const formItemStyle = { marginBottom: 0 };
const contentStyle={
    direction: "rtl",
    fontWeight: "bold",
    paddingRight: "10px",
};

const EditForm = ({ item, onSave, onCancel }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    fetchActions();

    if (item) {
      const action_week_from = item.action_week_from ? moment(item.action_week_from) : null;
      const action_week_to = item.action_week_to ? moment(item.action_week_to) : null;

      form.setFieldsValue({
        building_unit_ids: item.building_unit_ids,
        partner_id: item.partner_id,
        manager_id: item.manager_id,
        action_id: item.action_id,
        action_period: action_week_to && action_week_to ? [action_week_from, action_week_to] : [],
        substitute_partner_id: item.substitute_partner_id,
        substitute_rrule: item.substitute_rrule,
        notes: item.notes,
      });
    }
  }, [item]);

  const fetchActions = async () => {
    try {
      const response = await coreApi.get("/building-unit-setup-changes/actions/list-actions");
      setActions(response.data);
    } catch (error) {
      notification.error({ message: t("buildingSetupChanges.messages.failedLoadData") });
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    const payload = {
      ...values,
      id: item.id,
    };

    coreApi
      .put(`/building-unit-setup-changes/${item.id}`, payload)
      .then((response) => {
        notification.success({ message: response.data?.message || t("common.saveSuccess") });
        onSave();
      })
      .catch((error) => notification.error({ message: t("common.saveFailed") + (error.response?.data?.message || "") }))
      .finally(() => setLoading(false));
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Row gutter={16}>
        <Col span={24} >
          <Card size={"small"}  style={{ marginBottom: "16px" }}>
          <Descriptions column={1}>
            <Descriptions.Item label={t("common.country")} contentStyle={contentStyle}>{item.country_branch?.name}</Descriptions.Item>
            <Descriptions.Item label={t("common.city")} contentStyle={contentStyle}>{item.city_branch?.name}</Descriptions.Item>
            <Descriptions.Item label={t("buildingSetupChanges.buildingUnit")} contentStyle={contentStyle}> {item.building_unit?.street + " " + item.building_unit?.house_number}</Descriptions.Item>
          </Descriptions>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="partner_id" initialValue={item.partner_id} noStyle>
            <PartnerSelect
              label={t("common.selectedPartner")}
              name="partner_id"
              onChange={(value) => form.setFieldsValue({ partner_id: value?.id })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="manager_id" style={formItemStyle}>
            <ManagerSelect
              label={t("buildingSetupChanges.manager")}
              showSearch={true}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("buildingSetupChanges.action")} name="action_id">
            <Select placeholder={t("buildingSetupChanges.selectAction")} allowClear>
              {actions.map(action => {
                const translation = action.translations.find(t => t.language_ietf === i18n.language) || action.translations[0];
                return (
                  <Option key={action.id} value={action.id}>
                    {translation?.tag_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("buildingSetupChanges.actionWeek")} name="action_period">
           <DatePicker.RangePicker
              placeholder={[t("buildingSetupChanges.selectStartDate"), t("buildingSetupChanges.selectEndDate")]}
              allowEmpty={[false, false]}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("common.substitutePartner")} name="substitute_partner_id"  style={formItemStyle}>
            <PartnerSelect
              name="substitute_partner_id"
              allowClear
              onChange={(value) => form.setFieldsValue({ substitute_partner_id: value?.id })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("common.day")} name="substitute_rrule">
            <RruleDaySelect
              size="small"
              className="mb-0"
              rrule={item.substitute_rrule}
              postBlur={(rruleString) => form.setFieldsValue({ substitute_rrule: rruleString })}
              showLabel={false}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="notes">
            <TextArea
              rows={4}
              placeholder={t("common.notes")}
              maxLength={250}
              showCount
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
              {t("common.save")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditForm;
