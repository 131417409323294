import { notification } from "antd";
import axios from "axios";
import { createStore } from "redux";
import { setErrorResponseStatus, setRedirect, setURL } from "../actions";
import throwError from "../components/helpers/helpers";
import i18n from "../i18n";
import logoutHandler from "../reducers/logoutHandler";
import redirectHandler from "../reducers/redirectHandler";
import responseStatusHandler from "../reducers/responseStatusHandler";

const store = createStore(responseStatusHandler);
const urlStore = createStore(logoutHandler);
const redirectStore = createStore(redirectHandler);

const coreApi = axios.create({
  baseURL: process.env.REACT_APP_CORE_API,
});

coreApi.interceptors.request.use((config) => {
  config.headers.Authorization = "Bearer " + localStorage.getItem("accessToken") ?? null;
  config.headers["Accept-Language"] = i18n.language;
  return config;
});

coreApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === error.response.status && error.response.config.url !== "/auth/logout") {
      redirectStore.dispatch(setRedirect(() => true));

      openLogoutNotification();
    } else {
      if (error.response.status === 403 || error.response.status === 404 || error.response.status === 500) {
        store.dispatch(setErrorResponseStatus({ ...error }));
      } else if (error.response.status === 422) {
        throwError(error);
      }
    }

    return Promise.reject(error);
  }
);

const catchURL = (myUrl) => {
  urlStore.dispatch(setURL(myUrl));
};

const openLogoutNotification = (myUrl = null) => {
  notification.error({
    message: i18n.t("login.tokenRevokedMessage"),
    description: i18n.t("login.tokenRevokedDescription"),
    duration: null,
  });

  return true;
};

export { coreApi, store, urlStore, catchURL, redirectStore, openLogoutNotification };
