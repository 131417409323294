import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SmallMessage = ({ resource }) => {
  const { t } = useTranslation();

  return (
    <div style={{ color: "#aaa", fontWeight: 100, fontSize: "12px", marginTop: "4px" }}>
      {t("common.moreFilteringPartOne") + " "}
      <Link to={`/${resource}`} style={{ fontWeight: 300 }}>
        {t("common.moreFilteringPartTwo")}
      </Link>
      .
    </div>
  );
};

export default SmallMessage;
