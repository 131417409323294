import { Form, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const UserAccountTypeSelect = ({ initialValue, readOnly }) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name={"is_admin"}
      label={t("users.accountType")}
      rules={[{ required: true }]}
      initialValue={initialValue || false}
      hasFeedback
    >
      <Select disabled={readOnly} bordered={!readOnly}>
        <Select.Option value={false}>{t("users.userAccount")}</Select.Option>
        <Select.Option value={true}>{t("users.adminAccount")}</Select.Option>
      </Select>
    </Form.Item>
  );
};

export default UserAccountTypeSelect;
