import { DatePicker, Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Account Access Token Input form field
 * @component
 * @alias AccountAccessTokenInput
 * @param {bool} required
 * @returns <React.Fragment /> containing two <Form.Item /> with inputs for bank account tokens
 */
const AccountAccessTokenInput = ({ required }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Form.Item
        label={t("finance.accounts.accessToken")}
        name={"access_token"}
        required={required}
      >
        <Input.TextArea rows={2} />
      </Form.Item>
      <Form.Item
        label={t("finance.accounts.accessTokenValidUntil")}
        name={"access_token_valid_until"}
        required={required}
      >
        <DatePicker />
      </Form.Item>
    </React.Fragment>
  );
};
export default AccountAccessTokenInput;
