import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

/**
 * Cleanign Minures Item - used for adding/editing record
 * @component
 * @alias CleaningMinutesItem
 * @property {number} idCityBranch - id of a city_branch - default null
 * @property {object} item - Cleaning Minutes Item - default null
 * @property {bool} isAdding - default null
 * @property {function} setAdding - default null
 * @property {array} cleaningMinutes - defaul []
 * @property {function} setCleaningMinutes - defaul null
 * @returns Adds created/edited record to parent state
 */
const CleaningMinutesItem = ({
  idCityBranch = null,
  item = null,
  isAdding = false,
  setAdding = null,
  cleaningMinutes = [],
  setCleaningMinutes = null,
}) => {
  const { t } = useTranslation();

  const [isEditing, setEditing] = useState(false);
  const [isSubmiting, setSubmiting] = useState(false);

  const [itemForm] = Form.useForm();

  const putRequest = (item) => {
    setSubmiting(() => true);

    coreApi
      .put("/cities/cleaning-minutes-estimation/" + item.id, { ...item })
      .then((res) => {
        let index = cleaningMinutes.findIndex((x) => x.id === item.id);
        let newCleaningMinutes = cleaningMinutes;
        newCleaningMinutes[index] = { ...res.data.expected_minutes_estimation };
        setCleaningMinutes(() => [...newCleaningMinutes]);
      })
      .catch((err) => console.log(err.response.data.message))
      .finally(() => setSubmiting(() => false));
  };

  const deleteRequest = (item) => {
    setSubmiting(() => true);

    coreApi
      .delete("/cities/cleaning-minutes-estimation/" + item.id)
      .then((res) => setCleaningMinutes([...cleaningMinutes.filter((x) => x !== item)]))
      .catch((err) => console.log(err))
      .finally(() => setSubmiting(() => false));
  };

  const postRequest = (item) => {
    setSubmiting(true);

    coreApi
      .post("/cities/" + idCityBranch + "/cleaning-minutes-estimation", {
        ...item,
      })
      .then((res) => {
        setCleaningMinutes([...cleaningMinutes, { ...res.data.expected_minutes_estimation }]);
      })
      .catch((err) => console.log(err))
      .finally(() => setSubmiting(false));
  };

  const DefaultButtons = () => {
    return (
      <Space size={"small"}>
        <Button icon={<EditOutlined />} onClick={() => setEditing(() => true)}></Button>
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() =>
            Modal.confirm({
              title: t("cleaningMinutes.delete.alertTitle"),
              icon: <ExclamationCircleOutlined />,
              okButtonProps: { danger: true, loading: isSubmiting },
              onOk: () => {
                deleteRequest(item);
              },
            })
          }
        ></Button>
      </Space>
    );
  };

  const AddingButtons = () => {
    return (
      <Space size={"small"}>
        <Button
          icon={<CheckOutlined />}
          loading={isSubmiting}
          onClick={() => {
            itemForm.submit();
          }}
        ></Button>
        <Button
          icon={<StopOutlined />}
          onClick={() => {
            itemForm.resetFields();
            if (isAdding) setAdding(false);
            setEditing(() => false);
          }}
        ></Button>
      </Space>
    );
  };

  return isAdding ? (
    <React.Fragment>
      <Form
        form={itemForm}
        onFinish={(values) => {
          postRequest({ ...values });
          setAdding(false);
        }}
      >
        <Row gutter={[16, 16]} style={{ textAlign: "center" }}>
          <Col span={6}>
            <Form.Item name={"floor_count"} required>
              <Input style={{ textAlign: "center" }} placeholder={t("cleaningMinutes.floorCount")}></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={"minutes_per_ground_floor"} required>
              <InputNumber
                placeholder={t("cleaningMinutes.minutesPerGroundFloor")}
                min={1.0}
                max={99.99}
                step={0.5}
                style={{ textAlign: "center" }}
              ></InputNumber>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={"minutes_per_floor"} required>
              <InputNumber
                placeholder={t("cleaningMinutes.minutesPerFloor")}
                min={1.0}
                max={99.99}
                step={0.5}
                style={{ textAlign: "center" }}
              ></InputNumber>
            </Form.Item>
          </Col>
          <Col span={6}>
            <AddingButtons />
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Form
        form={itemForm}
        onFinish={(values) => {
          putRequest({ ...values });
          setEditing(false);
        }}
      >
        <Row gutter={[16, 16]} style={{ textAlign: "center" }}>
          <Form.Item hidden name={"id"} initialValue={item?.id}></Form.Item>
          <Form.Item hidden name={"city_branch_id"} initialValue={item?.city_branch_id}></Form.Item>
          <Col span={6}>
            <Form.Item name={"floor_count"} initialValue={item?.floor_count}>
              <Input style={{ textAlign: "center" }} bordered={isEditing} readOnly={!isEditing}></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={"minutes_per_ground_floor"} initialValue={item?.minutes_per_ground_floor}>
              <InputNumber
                min={1.0}
                max={99.99}
                step={0.5}
                style={{ textAlign: "center" }}
                bordered={isEditing}
                readOnly={!isEditing}
              ></InputNumber>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={"minutes_per_floor"} initialValue={item?.minutes_per_floor}>
              <InputNumber
                min={1.0}
                max={99.99}
                step={0.5}
                style={{ textAlign: "center" }}
                bordered={isEditing}
                readOnly={!isEditing}
              ></InputNumber>
            </Form.Item>
          </Col>
          <Col span={6}>{isEditing ? <AddingButtons /> : <DefaultButtons />}</Col>
        </Row>
      </Form>
      <Divider style={{ padding: 10, margin: "15px 0px 0px 0px" }}></Divider>
    </React.Fragment>
  );
};

export default CleaningMinutesItem;
