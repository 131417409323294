import { Checkbox, Col, DatePicker, Form, Input, Modal, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import EmailInputWithValidation from "../custom/Form/EmailInputWithValidation";

const PartnerAddWorkerForm = ({ isOpen, close, addWorker, idContractor, submitting }) => {
  const { t } = useTranslation();
  const [addContactForm] = Form.useForm();

  const [addWorkerForm] = Form.useForm();

  return (
    <React.Fragment>
      <Modal
        open={isOpen}
        title={t("partners.addNewWorker")}
        okText={t("common.add")}
        okButtonProps={{ loading: submitting }}
        onOk={() => {
          addWorkerForm.submit();
        }}
        onCancel={() => close()}
      >
        <Form
          form={addWorkerForm}
          onFinish={(values) => {
            addWorker(values);
          }}
          layout="vertical"
        >
          <Row gutter={[24, 0]}>
            <Form.Item hidden name="contractor_id" initialValue={idContractor}>
              <Input></Input>
            </Form.Item>
            <Col span={12}>
              <Form.Item
                name="first_name"
                label={t("common.firstName")}
                rules={[
                  {
                    required: true,
                    message: "Vyplň jméno!",
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="last_name"
                label={t("common.lastName")}
                rules={[
                  {
                    required: true,
                    message: "Vyplň příjmení!",
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <EmailInputWithValidation
                form={addContactForm}
                name="email"
                label={t("common.email")}
                message={t("common.emailValidation")}
              />
            </Col>
            <Col span={12}>
              <Form.Item name="phone" label={t("common.phone")}>
                <Input></Input>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="date_from"
                label={t("partners.contractFrom")}
                rules={[
                  {
                    required: true,
                    message: "Vyplň datum od!",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) =>
                    current.format("YYYY-MM-DD") !== current.clone().startOf("month").format("YYYY-MM-DD")
                  }
                ></DatePicker>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="date_to" label={t("partners.contractTo")}>
                <DatePicker></DatePicker>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name={"affidavit"} valuePropName={"checked"} label={t("partners.affidavit")}>
                <Checkbox></Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default PartnerAddWorkerForm;
