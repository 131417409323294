import { Form, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const PartnerBalanceCitySelector = ({
  label,
  name,
  initialValue,
  disabledOption,
  readOnly,
  required,
  validationMessage,
  size,
  setValue,
  idPartner,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    let isMounted = true;

    setLoading(true);
    coreApi
      .get("/cities")
      .then((response) => {
        if (isMounted) {
          setCities(response.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Form.Item
      hasFeedback
      name={name}
      label={label}
      initialValue={initialValue}
      valuePropName={"value"}
      rules={[
        {
          required: required,
          message: validationMessage,
        },
      ]}
    >
      <Select
        size={size}
        allowClear
        disabled={readOnly}
        loading={loading}
        showSearch={true}
        placeholder={t("expansion.selectCity")}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(e) => {
          setValue(e);
        }}
      >
        {cities.map((city) => {
          return (
            <Select.Option key={city?.id} value={city?.id} disabled={disabledOption && city?.id === disabledOption}>
              {city.name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default PartnerBalanceCitySelector;
