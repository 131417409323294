import { DownOutlined, EditOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import InitialInvoiceAddForm from "../Invoices/InitialInvoiceAddForm";
import AddSchedule from "./AddSchedule";
import DeleteRuleButton from "./DeleteRuleButton";
import RruleCell from "./RruleCell";
import ScheduleMassEditForm from "./ScheduleMassEditForm";

const SchedulesList = ({ schedules, loading, refreshScheduleList, buildingUnit, displayed }) => {
  const { t } = useTranslation();

  const [isMassEditModelOpen, setMassEditModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  const columns = [
    {
      title: t("schedules.serviceGroup"),
      key: "cleaning_service_group",
      dataIndex: "cleaning_service_group",
      render: (serviceGroup) => serviceGroup?.name,
    },
    {
      title: t("schedules.scheduleRules"),
      key: "rrule",
      dataIndex: "rrule",
      render: (rrule) => <RruleCell rule={rrule} />,
    },
    {
      title: t("schedules.serviceRatio"),
      key: "service_ratio",
      dataIndex: "service_ratio",
      render: (ratio) => {
        let percentage = ratio * 100;
        return percentage + "%";
      },
    },
  ];

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Table
          size={"small"}
          loading={loading}
          columns={[
            {
              title: t("offers.order"),
              key: "offer_id",
              dataIndex: "offer_id",
              render: (offerId) => {
                return <Link to={"/orders/" + offerId}>{offerId}</Link>;
              },
            },
            {
              title: t("schedules.status"),
              key: "valid",
              dataIndex: "valid",
              render: (valid) => {
                let color = valid === 0 ? "red" : "green";
                let title = valid === 0 ? t("schedules.invalid") : t("schedules.valid");

                return <Tag color={color}>{title}</Tag>;
              },
            },
            {
              title: t("schedules.dateFrom"),
              key: "date_from",
              dataIndex: "date_from",
              render: (dateFrom) => {
                return moment(dateFrom).format("DD.MM.YYYY");
              },
            },
            {
              title: t("schedules.dateTo"),
              key: "date_to",
              dataIndex: "date_to",
              render: (dateTo) => {
                return dateTo ? moment(dateTo).format("DD.MM.YYYY") : t("schedules.notFilled");
              },
            },
            {
              title: t("schedules.partner"),
              key: "partner_id",
              render: (rowData) => {
                if (!rowData.partner_id) return t("schedules.notFilled");

                return (
                  <Link to={"/partners/" + rowData?.partner?.id}>
                    {rowData?.partner?.company_name
                      ? rowData.partner.company_name
                      : rowData.partner.first_name + " " + rowData.partner.last_name}
                  </Link>
                );
              },
            },
            {
              title: t("schedules.serviceRatio"),
              key: "service_ratio",
              dataIndex: "service_ratio",
              render: (ratio) => {
                let percentage = ratio * 100;
                return percentage + "%";
              },
            },
            {
              title: t("common.actions"),
              key: "options",
              render: (schedule) => (
                <Space>
                  <Button
                    size={"small"}
                    icon={<EditOutlined />}
                    onClick={() => {
                      setMassEditModalOpen(true);
                      setSelectedSchedule(schedule);
                    }}
                  ></Button>
                  <DeleteRuleButton
                    schedules={schedules}
                    schedule={schedule}
                    refreshScheduleList={refreshScheduleList}
                  />
                </Space>
              ),
            },
          ]}
          dataSource={schedules}
          rowKey={(row) => row.date_from + row.date_to + row.partner_id}
          expandable={{
            rowExpandable: (row) => row?.rules?.length > 0,
            expandedRowRender: (row) => (
              <Table
                style={{ margin: "30px 30px" }}
                dataSource={row?.rules}
                columns={columns}
                pagination={false}
              ></Table>
            ),
            expandIcon: ({ expanded, onExpand, record, expandable }) =>
              expandable &&
              (expanded ? (
                <Tooltip placement="top" title={t("schedules.hideRules")}>
                  <DownOutlined onClick={(e) => onExpand(record, e)} style={{ transition: "linear 1s 0.2s" }} />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title={t("schedules.showRules")}>
                  <RightOutlined onClick={(e) => onExpand(record, e)} style={{ transition: "linear 1s 0.2s" }} />
                </Tooltip>
              )),
          }}
        ></Table>
      </Col>
      <Col span={24}>
        <Space>
          <AddSchedule
            buildingUnitId={buildingUnit?.id}
            schedules={schedules}
            refreshScheduleList={refreshScheduleList}
          ></AddSchedule>
          <InitialInvoiceAddForm
            buildingUnitIds={[buildingUnit?.id]}
            displayed={displayed}
            refreshScheduleList={refreshScheduleList}
          />
        </Space>
      </Col>

      <ScheduleMassEditForm
        isOpen={isMassEditModelOpen}
        close={() => {
          setMassEditModalOpen(false);
        }}
        schedule={selectedSchedule}
        refreshScheduleList={refreshScheduleList}
        buildingUnitId={buildingUnit?.id}
      ></ScheduleMassEditForm>
    </Row>
  );
};

export default SchedulesList;
