import { Button, Col, Row } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { InvoiceContext } from "../../pages/InvoiceProfile";
import LineItem from "./LineItem";

const LineItemContainer = ({ editDisabled }) => {
  const { t } = useTranslation();

  const { invoice } = useContext(InvoiceContext);

  const [isAddingNewItem, setAddingNewItem] = useState(false);

  return (
    <React.Fragment>
      <Row gutter={16} style={{ fontSize: "12px" }}>
        <Col span={8}>{t("finance.lineItems.content")}</Col>
        {invoice?.invoice_type === 2 && (
          <>
            <Col span={3} style={{ textAlign: "right" }}>
              {t("finance.lineItems.unitPrice")}
            </Col>
            <Col span={3} style={{ textAlign: "right" }}>
              {t("finance.lineItems.discountAmount")}
            </Col>
            <Col span={2} style={{ textAlign: "right" }}>
              {t("finance.lineItems.vatPercentage")}
            </Col>{" "}
            <Col span={3} style={{ textAlign: "right" }}>
              {t("finance.lineItems.vatAmount")}
            </Col>
          </>
        )}
        <Col span={3} style={{ textAlign: "right" }}>
          {t("finance.lineItems.totalAmount")}
        </Col>{" "}
      </Row>
      {invoice?.line_items?.map((lineItem, index) => {
        return (
          <LineItem
            idInvoice={invoice?.id}
            readOnly={true}
            key={lineItem.id}
            lineItem={lineItem}
            invoiceType={invoice?.invoice_type}
          />
        );
      })}
      {isAddingNewItem ? (
        <LineItem
          resetAddingNewItem={() => {
            setAddingNewItem(false);
          }}
          idInvoice={invoice?.id}
          readOnly={false}
          invoiceType={invoice?.invoice_type}
        ></LineItem>
      ) : (
        <Button
          disabled={editDisabled}
          onClick={() => {
            setAddingNewItem(true);
          }}
          block
        >
          {t("finance.lineItems.addLineItem")}
        </Button>
      )}
    </React.Fragment>
  );
};

export default LineItemContainer;
