import { BellOutlined, BellTwoTone } from "@ant-design/icons";
import { Alert, Badge, Button, Popover } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const notificationsPopperStyle = {
  maxWidth: "350px",
  marginBottom: "10px",
};

const HeaderBarNotificationsPopper = () => {
  const { t } = useTranslation();

  const [notifications, updateNotifications] = useState([]);

  return (
    <Popover
      placement="bottomRight"
      title={t("common.notifications")}
      content={
        <HeaderBarNotificationsList
          notifications={notifications}
          onCloseHandler={(id) => {
            const newNotifications = notifications.filter((item) => item.id !== id);
            updateNotifications(newNotifications);
          }}
        />
      }
    >
      <Badge count={notifications.length}>
        <Button
          disabled
          icon={notifications.length > 0 ? <BellTwoTone size="large" /> : <BellOutlined size="large" />}
        />
      </Badge>
    </Popover>
  );
};

const HeaderBarNotificationsList = ({ notifications, onCloseHandler }) => {
  const { t } = useTranslation();

  if (notifications.length > 0) {
    return (
      <React.Fragment>
        {notifications.map((item) => {
          return (
            <Alert
              key={item.id}
              description={item.content}
              closable
              message={item.heading}
              type={item.type}
              showIcon
              onClose={() => {
                onCloseHandler(item.id);
              }}
              style={notificationsPopperStyle}
            />
          );
        })}
      </React.Fragment>
    );
  } else {
    return <p>{t("notifications.noNew")}</p>;
  }
};

export default HeaderBarNotificationsPopper;
