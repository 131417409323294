import React, { useState, useEffect, useRef } from "react";
import { Table, notification, Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import { SendOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const AccountStatisticsGrouped = () => {
  const { t } = useTranslation();

  const user = useRef({ ...JSON.parse(localStorage.getItem("user")) }).current;
  const role = user?.roles[user?.roles?.length - 1];
  const isAdmin = role?.name === "admin";

  const [statisticsData, setStatisticsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAccountStatistics = () => {
    setLoading(true);
    coreApi
      .get("/approval-payment/account-statistics")
      .then((res) => {
        const sortedData = res.data.sort((a, b) => {
          const nameA = a.account?.name || "";
          const nameB = b.account?.name || "";
          return nameA.localeCompare(nameB);
        });
        setStatisticsData(sortedData);
      })
      .catch((err) => {
        notification.error({
          message: t("finance.transactions.loadError"),
          description: err.message,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAccountStatistics();
  }, []);

  const columns = [
    {
      title: t("finance.accounts.accountName"),
      dataIndex: "account",
      key: "accountName",
      render: (account) => <Link to={"/accounts/" + account?.id}>{account.name}</Link>,
    },
    {
      title: t("finance.transactions.totalPaymentsForApproval"),
      dataIndex: "totalPendingAmount",
      key: "totalPending",
      render: (value, record) => new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: record.currency || "CZK",
      }).format(value),
    },
    {
      title: t("finance.transactions.totalPaymentsSent"),
      dataIndex: "totalPaidAmount",
      key: "totalPaid",
      render: (value, record) => new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: record.currency || "CZK",
      }).format(value),
    }
  ];

  if (isAdmin) {
    columns.push({
      title: t("finance.moneyReturn.sendPayment"),
      dataIndex: "btn",
      key: "btn",
      render: (_, record) => (
        <Button
          type={"primary"}
          icon={<SendOutlined />}
          onClick={() => confirmSendPayment(record)}
          loading={loading}
          disabled={record?.totalPendingCount === 0}
        >
          {t("finance.invoices.sendToRecipient")} ({record?.totalPendingCount})
        </Button>
      ),
    });
  }

  const confirmSendPayment = (record) => {
    Modal.confirm({
      title: t("finance.paymentsForApproval.confirmPaymentForAccount"),
      content:  record.account.name,
      onOk() {
        handleSendAccountApprovalPayment(record.account.id);
      },
    });
  };

  const handleSendAccountApprovalPayment = (accountId) => {
    setLoading(true);
    coreApi
      .post("/approval-payment/send-account-approval-payment/" + accountId)
      .then((res) => {
        notification.success({
          message: t("finance.transactions.selectedRowsSavedSuccessfully"),
        });
        fetchAccountStatistics();
      })
      .catch((err) =>
        notification.error({
          message: err?.response?.data?.message,
          description: err?.response?.data?.error,
        }),
      )
      .finally(() => setLoading(false));
  };

  return (
    <Table
      columns={columns}
      dataSource={statisticsData}
      loading={loading}
      rowKey={(record) => record.id || Math.random()}
      pagination={false}
      size="small"
    />
  );
};

export default AccountStatisticsGrouped;
