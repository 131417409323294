import { Divider, Form, Modal, notification } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../../api/calls";
import OneTimeCleaningEditForm from "./OneTimeCleaningEditForm";
import RecurringCleaningEditForm from "./RecurringCleaningEditForm";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

/**
 * Cleaning Edit Modal
 * @component
 * @alias CleaningEditModal
 * @property {object} cleaning
 * @property {bool} isOpen
 * @property {function} close - parent function for closing Modal
 * @property {function} refreshParent - calls a function in parent component to refetch data
 * @returns <Modal /> component that contains <Form /> for editing cleaning
 */
const CleaningEditModal = ({ cleaning, setCleaning, isOpen, close, refreshParent, changeCleaning }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);

  const onFinish = (values, path) => {
    setSubmitting(true);

    values.date_real = values?.date_real ? values.date_real.format("YYYY-MM-DD") : null;

    values.date_satisfaction_check = values?.date_satisfaction_check
      ? values.date_satisfaction_check.format("YYYY-MM-DD")
      : null;

    coreApi
      .put(path + cleaning?.id, values)
      .then((response) => {
        notification.success({
          message: response.data.message,
        });
        let newCleaningData = response.data.cleaning;

        newCleaningData.date_expected = newCleaningData.date_expected ? moment(newCleaningData.date_expected) : null;

        newCleaningData.date_real = newCleaningData.date_real ? moment(newCleaningData.date_real) : null;

        newCleaningData.date_satisfaction_check = newCleaningData.date_satisfaction_check
          ? moment(newCleaningData.date_satisfaction_check)
          : null;

        form.setFieldsValue(newCleaningData);
        close();
        refreshParent();
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    form.resetFields();
  }, [form, cleaning]);

  return (
    <Modal
      key={cleaning?.id}
      width={"650px"}
      open={isOpen}
      onCancel={() => {
        form.resetFields();
        close();
      }}
      title={cleaning?.is_recurring ? t("cleanings.recurring.editCleaning") : t("cleanings.oneTime.editCleaning")}
      onOk={() => {
        form.submit();
      }}
      okButtonProps={{ loading: submitting }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "12px" }}>
        <Link
          to="#"
          onClick={() => {
            changeCleaning(-1, cleaning?.id);
          }}
        >
          <LeftOutlined />
          Předchozí
        </Link>
        <Link
          to="#"
          onClick={() => {
            changeCleaning(+1, cleaning?.id);
          }}
        >
          Další
          <RightOutlined />
        </Link>
      </div>

      <Divider />

      {cleaning?.is_recurring ? (
        <RecurringCleaningEditForm form={form} cleaning={cleaning} onFinish={onFinish} />
      ) : (
        <OneTimeCleaningEditForm form={form} cleaning={cleaning} onFinish={onFinish} />
      )}
    </Modal>
  );
};

export default CleaningEditModal;
