import { Col, Row, Table, Typography, Card } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../api/calls";
import AccountAddForm from "../components/Accounts/AccountAddForm";

/**
 * Page with all accounts
 * @component
 * @alias AccountsIndex
 * @returns <Card /> with content
 */
const AccountsIndex = () => {
  const { t } = useTranslation();

  // States
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const addNewRecord = (newRecord) => {
    setAccounts([...accounts, newRecord]);
  };

  useEffect(() => {
    setLoading(true);

    coreApi
      .get("/accounts/all")
      .then((response) => {
        setAccounts(response.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Columns of table
  const columns = [
    {
      title: t("finance.accounts.accountName"),
      key: "name",
      render: (account) => <Link to={"/accounts/" + account?.id}>{account.name}</Link>,
    },
    {
      title: t("finance.accounts.accountAbbr"),
      key: "abbr",
      dataIndex: "account_abbr",
    },
    {
      title: t("finance.accounts.accountNumber"),
      key: "accountNumber",
      render: (account) => (
        <Typography.Text copyable>
          {account.account_prefix ? account.account_prefix + "-" : ""}
          {account.account_number + "/" + account.account_bank_code}
        </Typography.Text>
      ),
    },
    {
      title: t("finance.accounts.accountIban"),
      key: "accountNumber",
      dataIndex: "account_iban",
      render: (iban) => <Typography.Text copyable>{iban}</Typography.Text>,
    },
    {
      title: t("finance.accounts.accessTokenValidUntil"),
      key: "accessTokenValidity",
      dataIndex: "access_token_valid_until",
    },
    {
      title: t("finance.accounts.controlTokenValidUntil"),
      key: "controlTokenValidity",
      dataIndex: "control_token_valid_until",
    },
  ];

  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        {/* Table with resource data */}
        <Col span={24}>
          <Card>
            <Table
              size={"small"}
              loading={loading}
              dataSource={accounts}
              columns={columns}
              rowKey={"id"}
            ></Table>
          </Card>
        </Col>

        {/* Form for new account */}
        <Col span={24}>
          <AccountAddForm addNewRecord={addNewRecord} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AccountsIndex;
