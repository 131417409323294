import React from "react";
import {Form, Select, Checkbox} from "antd";

const BuildingContactFilterPanel = ({
   filters,
   setFilters,
   fetchEmails,
   t,
 }) => {

  const [form] = Form.useForm();
  const communications = ["review", "verification", "finance", "operational", "other", "reminder", "document", "invoice", "claim"];
  
  const handleFilterChange = (changedValues) => {
    const currentValues = form.getFieldsValue();

    const updatedFilters = {
      ...filters,
      ...currentValues,
      ...changedValues,
      claimTagIds: currentValues.claim_tag_id || filters.claimTagIds || [],
    };

    setFilters(updatedFilters);
  };

  const handleSearch = () => {

    fetchEmails();
  };

  const handleResetClick = () => {
    form.resetFields();
    setFilters({});
    fetchEmails();
  };

  return (
    <Form
      form={form}
      layout="inline"
      onValuesChange={handleFilterChange}
      onFinish={handleSearch}
      style={{ maxWidth: '1010px' }}
    >
      <Form.Item>
        <label style={{ marginRight: "15px"}}>{t("emails.communication")}</label>
        {['phone', 'email', 'schedule','action'].map((name) => (
          <Checkbox
            key={name}
            value={name}
            checked={filters.cases && filters.cases.includes(name)}
            onChange={(e) => {
              const cases = e.target.checked
                ? [...(filters.cases || []), name]
                : (filters.cases || []).filter((type) => type !== name);

              setFilters({
                ...filters,
                cases: cases,
              });
            }}
          >
            { t("emails.communication_cases." + name) }
          </Checkbox>
        ))}
      </Form.Item>

      <Form.Item
        name="type"
        label={t("emails.communication_type")}
        style={{minWidth: '370px'}}
      >
        <Select
          allowClear={true}
          /*onChange={(e) => {
            console.log('e.target', e.target);
            const name = e.target.value
            const newTypes = e.target.checked
              ? [...filters.communication_type, name]
              : filters.communication_type.filter((type) => type !== name);

            setFilters({
              ...filters,
              communication_type: newTypes,
            });
          }}*/
        >
          {communications.map((type) => (
            <Select.Option key={type} value={type}>
              {t("emails.types." + type)}
            </Select.Option>
          ))}
          })}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default BuildingContactFilterPanel;
