import { Button, Card, Form, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../api/calls";
import { SaveOutlined } from "@ant-design/icons";

const buttonGroupStyle = {
  textAlign: "center",
};

const UserSettingsForm = () => {
  const [settings, setSettings] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [settingsForm] = Form.useForm();

  useEffect(() => {
    coreApi
      .get("/auth/settings")
      .then((response) => {
        setSettings({ ...response.data });
        settingsForm.resetFields();
      })
      .catch((error) => console.log(error));
  }, []);

  const { t } = useTranslation();

  const onFinish = (values) => {
    setSubmitting(true);

    coreApi
      .put("/auth/settings", values)
      .then((response) => {
        notification.success({
          message: response.data.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      <Card title={t("common.userSettings")}>
        <Form form={settingsForm} layout="vertical" onFinish={onFinish}>
          <Form.Item label={t("userSettings.firstName")} name={"first_name"} initialValue={settings?.first_name}>
            <Input></Input>
          </Form.Item>
          <Form.Item label={t("userSettings.lastName")} name={"last_name"} initialValue={settings?.last_name}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t("userSettings.email")}
            name={"email_work"}
            initialValue={settings?.email_work}
            required={false}
            rules={[
              {
                type: "email",
                message: t("common.emailValidation"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("userSettings.emailPersonal")}
            name={"email_personal"}
            initialValue={settings?.email_personal}
            rules={[
              {
                type: "email",
                message: t("common.emailValidation"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("userSettings.phone")}
            name={"phone_work"}
            initialValue={settings?.phone_work}
            required={false}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("userSettings.phonePersonal")}
            name={"phone_personal"}
            initialValue={settings?.phone_personal}
            required={false}
          >
            <Input />
          </Form.Item>

          <Form.Item style={buttonGroupStyle}>
            <Button type="primary" htmlType="submit" disabled={submitting} icon={<SaveOutlined />}>
              {t("userSettings.changeSettings")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default UserSettingsForm;
