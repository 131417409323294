import React, { useState } from "react";
import { Button, Col, DatePicker, Divider, Form, Modal, notification, Row } from "antd";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import moment from "moment";

const CancelAndDuplicateModal = ({ offer, refreshOffer }) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState();
  const [isSubmiting, setSubmiting] = useState(false);

  const [form] = Form.useForm();

  return (
    <React.Fragment>
      <Button
        danger
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t("offers.cancelAndDuplicate")}
      </Button>
      <Modal
        open={isModalOpen}
        title={t("offers.confirmCancelAndDuplicate")}
        okText={t("offers.cancelAndDuplicate")}
        okButtonProps={{ type: "danger", loading: isSubmiting }}
        onCancel={() => setModalOpen(false)}
        onOk={() => {
          form.submit();
        }}
      >
        <p>{t("offers.confirmCancelAndDuplicateDesc")}</p>
        <Divider></Divider>
        <Form
          form={form}
          layout={"vertical"}
          onFinish={(values) => {
            setSubmiting(true);
            coreApi
              .post("/offers/cancel-and-copy", {
                ...values,
              })
              .then((res) => {
                refreshOffer({ ...res.data.offer });

                setSubmiting(false);

                setTimeout(() => {
                  window.location.href = "/offers/" + res.data.new_offer?.id;
                }, 1000);

                notification.success({
                  message: res.data.message,
                });
              })
              .catch((err) =>
                notification.error({
                  message: err?.response?.data?.message,
                })
              );
          }}
        >
          <Row gutter={[16, 16]}>
            <Form.Item hidden name={"offer_id"} initialValue={offer?.id}></Form.Item>
            <Col span={12}>
              <Form.Item name={"date_to"} label={t("offers.dateActiveTo")} labelAlign={"left"} initialValue={moment()}>
                <DatePicker></DatePicker>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"date_from"}
                label={t("offers.dateActiveFrom")}
                labelAlign={"left"}
                initialValue={moment()}
              >
                <DatePicker></DatePicker>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default CancelAndDuplicateModal;
