import { Col } from "antd";
import React from "react";
import CitySelector from "../../Cities/CitySelector";
import CurrenySelector from "../../Currencies/CurrencySelector";

/**
 * @component
 * @alias InvoiceLocationForm
 * @property {boolean} isSummary
 * @returns selects with cities and currencies
 */
const InvoiceLocationForm = ({ isSummary }) => {
  return (
    <React.Fragment>
      <Col span={24}>
        <CitySelector readOnly={isSummary} required={true} />
      </Col>
      <Col span={24}>
        <CurrenySelector readOnly={isSummary} required={true} />
      </Col>
    </React.Fragment>
  );
};

export default InvoiceLocationForm;
