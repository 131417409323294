import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, notification, Row, Space, Table } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAreaScope } from "../../actions";
import { coreApi } from "../../api/calls";
import BuildingUnitLink from "../Buildings/BuildingUnitLink";
import CleaningClaimStatus from "../Cleanings/CleaningClaimStatus";
import CleaningEditModal from "../Cleanings/CleaningEditModal";
import EmployeeLink from "../Employees/EmployeeLink";
import PartnerLink from "../Partners/PartnerLink";
import ClaimsStatistics from "./ClaimsStatistics";
import ClaimsFilterPanel from "./ClaimsFilterPanel";
import ClaimAddForm from "./ClaimAddForm";

/**
 * Claims pane
 * @param partner
 * @param building
 * @param buildingUnit
 * @param cityBranch
 * @param user
 * @param currentTabKey
 * @param substitute
 * @param showStatistics
 * @returns {Element}
 * @constructor
 */
const ClaimsPane = ({ partner, building, buildingUnit, cityBranch, user, currentTabKey = '', substitute, showStatistics = false }) => {
  const { t } = useTranslation();

  const defaultPageSize = 10;
  const checkUrlPattern = () => /\/buildings\/\d+$/.test(window.location.pathname);

  // Get city id from global filter
  const areaScope = useSelector(getAreaScope);
  const selectedCity = areaScope.areaScope;

  // LOADING FLAGS
  const [claimsLoading, setClaimsLoading] = useState(false);
  const [statsLoading, setStatsLoading] = useState(false);

  // EDIT
  const [selectedCleaning, setSelectedCleaning] = useState();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddFormModalOpen, setAddFormModalOpen] = useState(false);

  // DATASOURCES
  const [stats, setStats] = useState();
  const [claims, setClaims] = useState([]);

  // PARAMS
  const defaultParams = {
    date_from: checkUrlPattern() ? null : moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
    date_to: checkUrlPattern() ? null : moment().endOf("month").format("YYYY-MM-DD"),
    users: user ? [user.id] : [],
    partners: partner ? [partner.id] : [],
    substitute: substitute ? [substitute.id] : [],
    in_solution_only: null,
    solved_only: null,
    building_unit_id: buildingUnit ? buildingUnit?.id : null,
    building_id: building ? building?.id : null,
    city_branch_id: selectedCity ?? null,
    page: 1,
    page_size: 10,
  };

  const [params, setParams] = useState(defaultParams);
  const [filters, setFilters] = useState({});

  // SELECTIONS
  const [selectedRows, setSelectedRows] = useState([]);

  // PAGINATIONS
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (currentTabKey === '#claims') {
      fetchClaims();
    }
    return () => {}
  }, [currentTabKey]);

  // Column definition for <Table /> component
  const columns = [
    {
      title: t("cleanings.dateExpected"),
      key: "date_expected",
      dataIndex: "date_expected",
      sorter: true,
      render: (date) => moment(date).format("L")
    },
    {
      title: t("cleanings.claimStatus"),
      key: "is_claim_solved",
      render: (cleaning) => <CleaningClaimStatus cleaning={cleaning} />,
      sorter: true
    },
    {
      title: t("cleanings.promo"),
      key: "promo",
      render: (cleaning) => cleaning?.promo,
      sorter: true
    },
    {
      title: t("cleanings.claimTag"),
      key: "claim_tag_id",
      render: (cleaning) => cleaning?.claim_tag?.claim_tag_translations[0]?.tag_name,
      sorter: true,
    },
    {
      title: t("cleanings.satisfactionCheckDate"),
      key: "date_satisfaction_check",
      sorter: true,
      render: (cleaning) =>
        cleaning.date_satisfaction_check ? moment(cleaning.date_satisfaction_check).format("L") : null,
    },
    {
      title: t("common.actions"),
      key: "actions",
      render: (cleaning) => (
        <Space>
          <Button
            size={"small"}
            icon={<EditOutlined />}
            onClick={() => {
              setSelectedCleaning(cleaning);
              setEditModalOpen(true);
            }}
          ></Button>
        </Space>
      ),
    },
  ];

  if (!partner) {
    columns.splice(4, 0, {
      title: t("cleanings.partner"),
      key: "partner.last_name",
      ellipsis: true,
      render: (rowData) => <PartnerLink partner={rowData?.partner} />,
      sorter: (a, b) => a?.partner?.company_name?.localeCompare(b?.partner?.company_name),
    });
  }

  if (!substitute) {
    columns.splice(5, 0, {
      title: t("cleanings.substitute"),
      key: "substitute.last_name",
      ellipsis: true,
      render: (rowData) => <PartnerLink partner={rowData?.substitute} />,
      sorter: (a, b) => a?.substitute?.company_name?.localeCompare(b?.substitute?.company_name),
    });
  }

  if (!buildingUnit) {
    columns.splice(6, 0, {
      title: t("schedules.buildingUnit"),
      key: "buildingUnit.street",
      dataIndex: "building_unit",
      render: (buildingUnit) => <BuildingUnitLink buildingUnit={buildingUnit} />,
      sorter: true
    });
  }

  if (!user) {
    columns.splice(columns.length - 1, 0, {
      title: t("roles.manager"),
      key: "responsibility.employee.first_name",
      ellipsis: true,
      render: (rowData) =>
        rowData?.responsibility?.employee ? (
          <EmployeeLink toUser employee={rowData?.responsibility?.employee} />
        ) : (
          rowData?.partner?.user?.employee ? (
            <EmployeeLink toUser employee={rowData?.partner?.user?.employee} />
          ) : (
            t("schedules.notFilled")
          )
        ),
      sorter: true,
    });
  }

  const handleTableChange = (pagination, filters, sorter) => {
    let newParams = { ...params, ...filters };
    newParams.partner = filters["partner.last_name"] ? filters["partner.last_name"][0] : null;
    newParams.building_unit = filters["buildingUnit.street"] ? filters["buildingUnit.street"][0] : null;
    newParams.user = filters["responsibility.employee.first_name"]
      ? filters["responsibility.employee.first_name"][0]
      : null;
    newParams.zeroPromoOnly = filters.promo?.includes(1) ? 1 : 0;
    newParams.notZeroPromoOnly = filters.promo?.includes(2) ? 1 : 0;
    newParams.in_solution_only = filters.is_claim_solved?.includes("in_solution_only") ? 1 : 0;
    newParams.solved_only = filters.is_claim_solved?.includes("solved_only") ? 1 : 0;
    newParams.page = pagination.current;
    newParams.page_size = pagination.pageSize;
    newParams.sorterOrder = sorter?.order;
    newParams.sorterKey = sorter?.columnKey;
    newParams.claimTagIds = filters?.claim_tag_id;

    setParams(newParams);
  };

  const fetchClaims = useCallback(() => {
    setStatsLoading(true);
    setClaimsLoading(true);

    const allParams = { ...params, ...filters, city_branch_id: selectedCity ?? null };

    coreApi
      .get("/claims/stats", { params: allParams })
      .then((response) => {
        setStats(response.data);
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setStatsLoading(false);
      });

    coreApi
      .get("/claims", { params: allParams })
      .then((response) => {
        setClaims(response.data.data);
        setCurrentPage(response.data.current_page);
        setPageSize(response.data.per_page);
        setTotal(response.data.total);
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setClaimsLoading(false);
      });
  }, [params, filters, selectedCity]);


  useEffect(() => {
    fetchClaims();
  }, [fetchClaims, selectedCity]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('new')) {
      setAddFormModalOpen(true);
    }
  }, [window.location.search]);

  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        {isAddFormModalOpen && <ClaimAddForm
          isOpen={isAddFormModalOpen}
          close={() => setAddFormModalOpen(false)}
          refreshParent={fetchClaims}
        />}
        {showStatistics && (<ClaimsStatistics stats={stats} loading={statsLoading} />)}
        <Col span={1}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setAddFormModalOpen(true)}
          >
            {/*{t("claims.addClaim")}*/}
          </Button>
        </Col>
        <Col span={23}>
          <ClaimsFilterPanel
            filters={filters}
            setFilters={setFilters}
            fetchClaims={fetchClaims}
            t={t}
            clearDate = {checkUrlPattern()}
          />
        </Col>
        <Col span={24}>
          <Table
            size={"small"}
            rowKey={"id"}
            rowSelection={{
              selectedRowKeys: selectedRows,
              selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
              onChange: (selectedRowKeys) => {
                setSelectedRows(selectedRowKeys);
              },
            }}
            columns={columns}
            loading={claimsLoading}
            dataSource={claims}
            onChange={handleTableChange}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: total,
              showSizeChanger: true,
              showTotal: (total, range) => (
                <p>
                  {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
                  {" " + t("common.paginationPartTwo") + " "}
                  <strong>{total}</strong> {t("common.paginationPartThree")}
                </p>
              ),
            }}
          ></Table>

          <CleaningEditModal
            refreshParent={() => {
              fetchClaims(params);
            }}
            cleaning={selectedCleaning}
            isOpen={isEditModalOpen}
            close={() => {
              setEditModalOpen(false);
            }}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ClaimsPane;
