import { notification, Table } from "antd";
import arrayMove from "array-move";
import React from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { coreApi } from "../../api/calls";

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

class DraggableTable extends React.Component {
  state = {
    dataSource: this.props.data,
    columns: this.props.columns,
    showHeader: this.props.showHeader || false,
    pagination: this.props.pagination || false,
  };

  /**
   * Save positions of table line when some of items are dragged.
   *
   * @param {int} oldIndex
   * @param {int} newIndex
   */
  onSortEnd = ({ oldIndex, newIndex }) => {
    // Get data source
    const { dataSource } = this.state;

    if (oldIndex !== newIndex) {
      // Switch positions of dragged items
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter((el) => !!el);

      // Prepare indexes for backend saving
      let positions = newData.map((item, index) => ({
        cleaning_service_group_id: item.id,
        name: item.name,
        sortWeight: index,
      }));

      // Save positions of items
      coreApi
        .put(this.props.endpointUrl + "sort-weight", { positions: positions })
        .then((res) => notification.success({ message: res.data.message }))
        .catch((err) => console.log(err));

      // Set new sorted positions to the data source
      this.setState({ dataSource: newData });
    }
  };

  DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={this.onSortEnd}
      {...props}
    />
  );

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { dataSource } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource?.findIndex((x) => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  render() {
    const { dataSource, columns, pagination, showHeader } = this.state;

    return (
      <Table
        pagination={pagination}
        showHeader={showHeader}
        dataSource={dataSource}
        columns={[...columns]}
        rowKey="index"
        components={{
          body: {
            wrapper: this.DraggableContainer,
            row: this.DraggableBodyRow,
          },
        }}
      />
    );
  }
}

export default DraggableTable;
