import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";

/**
 * @component
 * @alias IconTooltip
 * @prop {*} icon - default value: antd - <InforCircleOutlined />
 * @prop {string} message - default value: "" - message which is displayed on hover (tooltip)
 * @prop {string} placement - default value: "right" - where the tooltip message will be displayed (ex. "left")
 * @prop
 */
const IconTooltip = ({
  icon = <InfoCircleOutlined style={{ color: "#1890ff" }} />,
  message = "",
  placement = "right",
}) => {
  return (
    <Tooltip
      title={message}
      placement={placement}
      style={{ right: "-15px !important" }}
    >
      <span style={{ marginLeft: "10px" }}>{icon}</span>
    </Tooltip>
  );
};

export default IconTooltip;
