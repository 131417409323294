import React, { useState } from "react";
import { Modal, Button, Form, Input, Row, Col, InputNumber, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import ManagerSelect from "./ManagerSelect";
import AccountSelector from "./Accounts/AccountSelector";

const CityBranchAddForm = ({ onFinishAction, idCountryBranch }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [addCityForm] = Form.useForm();

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Button
        size="large"
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t("expansion.addCity")}
      </Button>
      <Modal
        title={t("expansion.addCity")}
        open={isModalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
        okText={t("common.add")}
        onOk={addCityForm.submit}
      >
        <Form
          form={addCityForm}
          onFinish={async (values) => {
            await onFinishAction(values);
            addCityForm.resetFields();
            setModalOpen(false);
          }}
          layout="vertical"
        >
          {idCountryBranch ? (
            <Form.Item hidden name="country_branch_id" initialValue={idCountryBranch}>
              <Input></Input>
            </Form.Item>
          ) : (
            <Form.Item>
              <Select></Select>
            </Form.Item>
          )}

          <Form.Item
            name="name"
            label={t("expansion.cityName")}
            rules={[
              {
                required: true,
                message: t("expansion.validation.city.name"),
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            name="name_eng"
            label={t("expansion.cityNameEng")}
            rules={[
              {
                required: true,
                message: t("expansion.validation.city.nameEng"),
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            name="web"
            label={t("expansion.cityWeb")}
            rules={[
              {
                required: true,
                pattern: "^https://([a-z0-9]+([-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?)?$",
                message: t("expansion.validation.city.web"),
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            name="email"
            label={t("expansion.cityEmail")}
            rules={[
              {
                required: true,
                message: t("expansion.validation.city.email"),
              },
              {
                type: "email",
                message: t("common.emailValidation"),
              },
            ]}
          >
            <Input></Input>
          </Form.Item>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="city_number"
                label={t("expansion.cityNumber")}
                rules={[
                  {
                    required: true,
                    message: t("expansion.validation.city.number"),
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                name="default_service_share"
                label={t("expansion.cityDefaultServiceShare")}
                rules={[
                  {
                    required: true,
                    type: "number",
                    min: 0,
                    max: 1,
                    message: t("expansion.validation.city.defaultServiceShare"),
                  },
                ]}
              >
                <InputNumber
                  decimalSeparator={"."}
                  step={0.1}
                  formatter={(value) => `${value}`}
                  parser={(value) => value.replace("%", "")}
                ></InputNumber>
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item name="hourly_wage" label={t("expansion.cityHourlyWage")}>
                <InputNumber></InputNumber>
              </Form.Item>
            </Col>
          </Row>
          <AccountSelector required />
          <ManagerSelect
            label={t("managers.selectManager")}
            validationMessage={t("managers.notifications.errorFetchingManagers")}
          />
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default CityBranchAddForm;
