import { Col, Divider, Row, Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ClientBillingAddressSection = ({ client, readOnly }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Divider>{t("clients.billingAddress")}</Divider>
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <Form.Item
            name={"billing_address_street"}
            label={t("clients.billingAddressStreet")}
            initialValue={client?.billing_address_street}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"billing_address_house_number"}
            label={t("clients.billingAddressHouseNumber")}
            initialValue={client?.billing_address_house_number}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"billing_address_city"}
            label={t("clients.billingAddressCity")}
            initialValue={client?.billing_address_city}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"billing_address_postal_code"}
            label={t("clients.billingAddressPostalCode")}
            initialValue={client?.billing_address_postal_code}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ClientBillingAddressSection;
