import { Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import useCoreApi from "../../hooks/useCoreApi";
import { Form } from "antd";

const InvoiceTypeSelect = ({
  initialValue = null,
  onChange = null,
  readOnly = false,
  required = false,
  validationMessage = null,
}) => {
  const { t } = useTranslation();
  const { loading, response } = useCoreApi("get", "/invoices/invoice-types");

  return (
    <React.Fragment>
      <Form.Item
        name={"invoice_type"}
        initialValue={initialValue}
        valuePropName="value"
        label={t("finance.invoices.invoiceType")}
        rules={[
          {
            required: required,
            message: validationMessage,
          },
        ]}
      >
        <Select
          loading={loading}
          disabled={readOnly}
          bordered={!readOnly}
          style={{ width: "100%" }}
          onChange={(e) => onChange(e)}
        >
          {response?.invoice_types?.map((item, i) => (
            <Select.Option key={item?.value} value={item?.value}>
              {t("finance.invoices." + item.translate_key) +
                (i !== 3 ? " " + t("finance.invoices.invoice") : "")}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </React.Fragment>
  );
};

export default InvoiceTypeSelect;
