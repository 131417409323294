import { Form, Select } from "antd";
import getSymbolFromCurrency from "currency-symbol-map";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @alias CurrencySelector
 * @property {bool} loading
 * @property {bool} readOnly
 * @property {number} initialValue - initial value for <Form.Item /> 0-CZK, 1-EUR
 * @returns <Form.Item /> with <Select /> component in it.
 */
const CurrencySelector = ({
  loading,
  readOnly,
  initialValue,
  required = false,
}) => {
  const { t } = useTranslation();

  // const [currencies, setCurrencies] = useState(["CZK", "EUR"]);

  const currencies = ["CZK", "EUR"];

  return (
    <Form.Item
      name="currency_iso_4217"
      label={t("finance.currencies.currency")}
      rules={[
        {
          required: required,
          message: t("finance.currencies.validation.currency"),
        },
      ]}
      initialValue={initialValue}
    >
      <Select loading={loading} disabled={readOnly}>
        {currencies.map((currency) => {
          return (
            <Select.Option key={currency} value={currency}>
              {currency + " - " + getSymbolFromCurrency(currency)}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default CurrencySelector;
