import { Divider, Timeline } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const WorkerContractSection = ({ partner, readOnly }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Divider orientation="left">{t("partners.contractDetails")}</Divider>
      <Timeline></Timeline>
      {partner?.contractors?.map((contractor, index) => {
        return (
          <Timeline.Item key={contractor.id}>
            <Link to={"/partners/" + contractor.id}>{contractor.company_name}</Link>
            <span>
              {" ("}
              {contractor.pivot.date_from ? moment(contractor.pivot.date_from).format("LL") : "N/A"}
              {" - "}
              {contractor.pivot.date_to ? moment(contractor.pivot.date_to).format("LL") : "N/A"}
              {") "}
              {contractor.pivot.affidavit ? " - " + t("partners.affidavit") : ""}
            </span>
          </Timeline.Item>
        );
      })}
    </React.Fragment>
  );
};

export default WorkerContractSection;
