import { Col, DatePicker, Form } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @alias InvoiceDetailsForm
 * @property {boolean} isSummary
 * @returns <Form.Item /> - Details of invoice form
 */
const InvoiceDetailsForm = ({ isSummary = false }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col span={24}>
        <Form.Item
          name={"date_issued"}
          label={t("finance.invoices.dateIssued")}
          initialValue={moment()}
          rules={[{ required: true, message: "" }]}
        >
          <DatePicker disabled={isSummary} bordered={!isSummary} style={{ width: "100%" }}></DatePicker>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name={"date_due"} label={t("finance.invoices.dateDue")} rules={[{ required: true, message: "" }]}>
          <DatePicker disabled={isSummary} bordered={!isSummary} style={{ width: "100%" }}></DatePicker>
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

export default InvoiceDetailsForm;
