import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import ReactDOM from "react-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const PrivateRoute = ({ component: Component, roles, name, ...rest }) => {
  const history = useHistory();

  useEffect(() => {
    document.getElementById("pageTitle") &&
      ReactDOM.render(
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <h2
            style={{ fontSize: "18px", marginRight: "6px", color: "#888", cursor: "pointer" }}
            onClick={() => {
              history.goBack();
            }}
          >
            <ArrowLeftOutlined />
          </h2>
          <h1>{name}</h1>
        </div>,
        document.getElementById("pageTitle")
      );

    document.title = name + " | Naše Andulka";
  }, [name]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!localStorage.getItem("user")) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }

        // logged in so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
