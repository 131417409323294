import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "./api/calls";

/**
 * Custom breadcrumb item with building name.
 *
 * @alias BuildingName
 * @param {object} match
 * @returns <span>
 */
const BuildingName = ({ match }) => {
  // States
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  // Translations
  const { t } = useTranslation();

  // Fetch name
  useEffect(() => {
    coreApi
      .get("buildings/name/" + match.params.building_id)
      .then((response) => {
        setName(response.data.name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [match]);

  return loading ? <span>{t("buildings.buildingOverviewHeader")}</span> : <span>{name}</span>;
};

/**
 * Custom breadcrumb item with building unit name.
 *
 * @alias BuildingUnitName
 * @param {object} match
 * @returns <span>
 */
const BuildingUnitName = ({ match }) => {
  // States
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  // Translations
  const { t } = useTranslation();

  // Fetch name
  useEffect(() => {
    coreApi
      .get("buildings/units/name/" + match.params.id)
      .then((response) => {
        setName(response.data.name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [match]);

  return loading ? <span>{t("buildings.buildingUnitOverview")}</span> : <span>{name}</span>;
};

/**
 * Custom breadcrumb item with partner name.
 *
 * @alias PartnerName
 * @param {object} match
 * @returns <span>
 */
const PartnerName = ({ match }) => {
  // States
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  // Translations
  const { t } = useTranslation();

  // Fetch name
  useEffect(() => {
    coreApi
      .get("partners/name/" + match.params.id)
      .then((response) => {
        setName(response.data.name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [match]);

  return loading ? <span>{t("partners.partnersOverviewHeader")}</span> : <span>{name}</span>;
};

/**
 * Custom breadcrumb item with client name.
 *
 * @alias ClientName
 * @param {object} match
 * @returns <span>
 */
const ClientName = ({ match }) => {
  // States
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  // Translations
  const { t } = useTranslation();

  // Fetch name
  useEffect(() => {
    coreApi
      .get("clients/name/" + match.params.id)
      .then((response) => {
        setName(response.data.name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [match]);

  return loading ? <span>{t("clients.clientOverviewHeader")}</span> : <span>{name}</span>;
};

/**
 * Custom breadcrumb item with contact name.
 *
 * @alias ContactName
 * @param {object} match
 * @returns <span>
 */
const ContactName = ({ match }) => {
  // States
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  // Translations
  const { t } = useTranslation();

  // Fetch name
  useEffect(() => {
    coreApi
      .get("contacts/name/" + match.params.id)
      .then((response) => {
        setName(response.data.name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [match]);

  return loading ? <span>{t("breadcrumbs.contactProfile")}</span> : <span>{name}</span>;
};

/**
 * Custom breadcrumb item with invoice name.
 *
 * @alias InvoiceName
 * @param {object} match
 * @returns <span>
 */
const InvoiceName = ({ match }) => {
  // States
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  // Translations
  const { t } = useTranslation();

  // Fetch name
  useEffect(() => {
    coreApi
      .get("invoices/name/" + match.params.id)
      .then((response) => {
        setName(response.data.name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [match]);

  return loading ? <span>{t("breadcrumbs.invoiceProfile")}</span> : <span>{name}</span>;
};

/**
 * Custom breadcrumb item with account name.
 *
 * @alias AccountName
 * @param {object} match
 * @returns <span>
 */
const AccountName = ({ match }) => {
  // States
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  // Translations
  const { t } = useTranslation();

  // Fetch name
  useEffect(() => {
    coreApi
      .get("accounts/name/" + match.params.id)
      .then((response) => {
        setName(response.data.name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [match]);

  return loading ? <span>{t("breadcrumbs.accountProfile")}</span> : <span>{name}</span>;
};

/**
 * Custom breadcrumb item with country name.
 *
 * @alias CountryName
 * @param {object} match
 * @returns <span>
 */
const CountryName = ({ match }) => {
  // States
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  // Translations
  const { t } = useTranslation();

  // Fetch name
  useEffect(() => {
    coreApi
      .get("countries/name/" + match.params.id)
      .then((response) => {
        setName(response.data.name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [match]);

  return loading ? <span>{t("breadcrumbs.countryProfile")}</span> : <span>{name}</span>;
};

/**
 * Custom breadcrumb item with city name.
 *
 * @alias CityName
 * @param {object} match
 * @returns <span>
 */
const CityName = ({ match }) => {
  // States
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  // Translations
  const { t } = useTranslation();

  // Fetch name
  useEffect(() => {
    coreApi
      .get("cities/name/" + match.params.id)
      .then((response) => {
        setName(response.data.name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [match]);

  return loading ? <span>{t("breadcrumbs.cityProfile")}</span> : <span>{name}</span>;
};

/**
 * Custom breadcrumb item with employee name.
 *
 * @alias EmployeeName
 * @param {object} match
 * @returns <span>
 */
const EmployeeName = ({ match }) => {
  // States
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  // Translations
  const { t } = useTranslation();

  // Fetch name
  useEffect(() => {
    coreApi
      .get("employees/name/" + match.params.id)
      .then((response) => {
        setName(response.data.name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [match]);

  return loading ? <span>{t("breadcrumbs.employeeProfile")}</span> : <span>{name}</span>;
};

/**
 * Custom breadcrumb item with order name.
 *
 * @alias OrderName
 * @param {object} match
 * @returns <span>
 */
const OrderName = ({ match }) => {
  // Translations
  const { t } = useTranslation();

  return <span>{t("offers.order") + " " + match.params.id.padStart(6, "0")}</span>;
};

/**
 * Custom breadcrumb item with offer name.
 *
 * @alias OfferName
 * @param {object} match
 * @returns <span>
 */
const OfferName = ({ match }) => {
  // Translations
  const { t } = useTranslation();

  return <span>{t("offers.offer") + " " + match.params.id.padStart(6, "0")}</span>;
};

/**
 * Custom link for building's units breadcrumb item.
 *
 * @alias CustomUnitLink
 * @param {object} match
 * @returns <span>
 */
const CustomUnitLink = ({ match }) => {
  // Translations
  const { t } = useTranslation();

  return (
    <Link to={{ pathname: "/buildings/" + match.params.building_id, hash: "#units", state: { isNew: true } }}>
      {t("buildings.buildingUnitsOverview")}
    </Link>
  );
};

/**
 * Custom link for cities breadcrumb item.
 *
 * @alias CustomCityLink
 * @param {object} match
 * @returns <span>
 */
const CustomCityLink = ({ match }) => {
  // Translations
  const { t } = useTranslation();

  return (
    <Link to={{ pathname: "/countries/" + match.params.country_id, hash: "#cities", state: { isNew: true } }}>
      {t("expansion.cities")}
    </Link>
  );
};

/**
 * Custom link for orders breadcrumb item.
 *
 * @alias CustomOrderLink
 * @param {object} match
 * @returns <span>
 */
const CustomOrderLink = ({ match }) => {
  // Translations
  const { t } = useTranslation();

  return <Link to={{ pathname: "/offers" }}>{t("offers.orders")}</Link>;
};

/**
 * Make array from rules
 *
 * @returns array
 * @param cleaningGroup
 */
const ParseRegularityFromRrule = (cleaningGroup) => {
  let parsed = {};
  let rrule = cleaningGroup?.rrule_blueprint;
  rrule = rrule.replace("RRULE:", "");
  rrule = rrule.split(";");
  rrule.forEach((item) => {
    item = item.split("=");
    parsed[item[0]] = item[1];
  });

  if (!parsed.INTERVAL) {
    parsed.INTERVAL = 1;
  }

  if (parsed.BYDAY) {
    parsed.BYDAY = parsed.BYDAY.split(",");
    parsed.REPETITION_COUNT = parsed.BYDAY.length;
  }
  return parsed;
};

/**
 * Take days from rule
 *
 * @returns array
 * @param days
 * @param element
 */
const ParseRegularityFromRruleDays = (days, element) => {
  const extendedDays = element.cleaning_service_groups && element.cleaning_service_groups[0]
    ? ParseRegularityFromRrule(element.cleaning_service_groups[0])?.BYDAY || []
    : [];

  if (days && extendedDays.length > days.length) days = extendedDays

  if (Array.isArray(days) && Array.isArray(extendedDays) && extendedDays.length > days.length) {
    days = extendedDays;
  }

  return days || [];
};

// Export of helpers
export {
  BuildingName,
  BuildingUnitName,
  CustomUnitLink,
  OfferName,
  OrderName,
  CustomOrderLink,
  PartnerName,
  ClientName,
  ContactName,
  InvoiceName,
  AccountName,
  CountryName,
  CustomCityLink,
  CityName,
  EmployeeName,
  ParseRegularityFromRrule,
  ParseRegularityFromRruleDays,
};
