import { DeleteOutlined } from "@ant-design/icons";
import { Button, notification, Modal } from "antd";
import React, { useContext, useState } from "react";
import { coreApi } from "../../api/calls";
import { InvoiceContext } from "../../pages/InvoiceProfile";
import { useTranslation } from "react-i18next";

const LineItemRemoveButton = ({ locked, resetAddingNewItem, lineItem, size }) => {
  const { refreshInvoice } = useContext(InvoiceContext);

  const { t } = useTranslation();

  const [isDeleting, setDeleting] = useState(false);

  return (
    <Button
      disabled={locked}
      loading={isDeleting}
      danger
      size={size}
      icon={<DeleteOutlined />}
      onClick={() => {
        Modal.confirm({
          title: t("finance.invoices.delete.alertTitle"),
          content: t("finance.invoices.delete.alertMessage"),
          okText: t("common.delete"),
          okButtonProps: { type: "danger" },
          onOk: () => {
            setDeleting(true);

            if (lineItem) {
              coreApi
                .delete("/line-items/" + lineItem?.id)
                .then((response) => {
                  notification.success({
                    description: response.data.message,
                  });
                  refreshInvoice(response.data.invoice);
                  resetAddingNewItem();
                })
                .catch((error) => {
                  notification.error({
                    description: error?.response?.data?.message,
                  });
                })
                .finally(() => {
                  setDeleting(false);
                });
            } else {
              resetAddingNewItem();
            }
          },
        });
      }}
    ></Button>
  );
};

export default LineItemRemoveButton;
