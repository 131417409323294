import {Button, Input, Space, Table, Tag, Typography} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import AssignTransactionModal from "./AssignTransactionModal";
import { useSelector } from "react-redux";
import { getAreaScope } from "../../actions";
import { Link } from "react-router-dom";
import AssignTransactionBalanceModal from "./AssignTransactionBalanceModal";

const UnassignedTransactionsPane = () => {
  const { t } = useTranslation();

  const areaScope = useSelector(getAreaScope);
  const selectedCity = areaScope?.areaScope;

  const [dataLoading, setDataLoading] = useState(true);

  const [transactions, setTransactions] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({ total: 0 });
  const [currentPage, setCurrentPage] = useState(1);

  const [isModalPartnerOpen, setModalPartnerOpen] = useState(false);
  const [isModalClientOpen, setModalClientOpen] = useState(false);
  const [assigningTransaction, setAssigningTransaction] = useState({});

  const [sorter, setSorter] = useState();
  const [filters, setFilters] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  const decrementTotalTransactions = () => {
    setPaginationDetails({
      ...paginationDetails,
      total: paginationDetails.total - 1,
    });
  };

  const tableColumns = [
    {
      title: t("finance.transactions.transactionNumber"),
      key: "id",
      dataIndex: "id",
      width: 115,
      render: (id) => (
        <Link to={"/transactions/" + id} style={{ fontWeight: "bold" }}>
          {id}
        </Link>
      ),
    },
    {
      title: t("finance.transactions.city"),
      key: "city",
      width: 125,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm)}
                style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button onClick={() => handleReset(clearFilters, selectedKeys)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
              >
                {t("common.search")}
              </Button>
            </Space>
          </div>
      ),
      filterIcon: () => <SearchOutlined style={{ color: isFiltered ? "#1890ff" : "rgba(0, 0, 0, 0.45)" }} />,
      render: (transaction) =>  (
        <Typography.Text>{transaction?.client?.city_branch?.name || '-'} </Typography.Text>
      ),
    },
    {
      title: t("finance.transactions.accountNumberFrom"),
      key: "account_number_from",
      render: (transaction) => (
        <Typography.Text copyable key={transaction.id}>
          {transaction.account_number_from + "/" + transaction.bank_code_from}
        </Typography.Text>
      ),
    },
    {
      title: t("finance.transactions.accountNameFrom"),
      key: "account_name_from",
      dataIndex: "account_name_from",
    },
    {
      title: t("finance.transactions.variableSymbol"),
      key: "variable_symbol",
      dataIndex: "variable_symbol",
      width: 135,
      render: (variableSymbol) => {
        if (variableSymbol) {
          return (
            <Typography.Text copyable key={variableSymbol}>
              {variableSymbol}
            </Typography.Text>
          );
        }
      },
    },
    {
      title: t("finance.transactions.amount"),
      key: "amount_free",
      sorter: true,
      width: 135,
      render: (rowData) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: rowData.currency_iso_4217 || "CZK",
        }).format(rowData.comment?.includes("Ref#1:") ? rowData.amount : rowData.amount_free);
      },
      filterMultiple: false,
      filters: [
        {
          text: t("finance.transactions.incoming"),
          value: "incoming",
        },
        {
          text: t("finance.transactions.outgoing"),
          value: "outgoing",
        },
      ],
    },
    {
      title: t("finance.transactions.syncDate"),
      key: "date_transaction",
      dataIndex: "date_transaction",
      sorter: true,
      width: 110,
      render: (rowData) => {
        return moment(rowData).format("L");
      },
    },
    {
      title: t("finance.accounts.accountTo"),
      key: "accounts.account_number",
      render: (rowData) => {
        if (rowData?.account)
          return (
            (rowData?.account?.account_prefix ? rowData?.account?.account_prefix + "-" : "") +
            rowData.account.account_number +
            "/" +
            rowData.account.account_bank_code
          );
        else return <Tag color={"red"}>{t("finance.accounts.unknownAccount")}</Tag>;
      },
    },
    {
      title: t("common.actions"),
      key: "link",
      width: 135,
      render: (rowData) => {
        return (
          <React.Fragment>
            <Button
              size="small"
              onClick={() => {
                setModalPartnerOpen(true);
                setAssigningTransaction({ ...rowData });
              }}
            >
              {t("finance.transactions.partner")}
            </Button>
            <Button
              size="small"
              onClick={() => {
                setModalClientOpen(true);
                setAssigningTransaction({ ...rowData });
              }}
            >
              {t("finance.transactions.client")}
            </Button>
          </React.Fragment>
        );
      },
    },
  ];

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setFilters({ ...filters, 'city': selectedKeys[0]});
    setIsFiltered(true);
  };

  const handleReset = (clearFilters, selectedKeys) => {
    clearFilters();
    let clearedFilters = filters;
    delete clearedFilters.city;
    setFilters({ ...clearedFilters });
    selectedKeys[0] = null;
    setIsFiltered(false);
  };

  useEffect(() => {
    setDataLoading(true);
    const order_by_column = (sorter === undefined || sorter && sorter.order === undefined) ? 'amount_free' : (sorter?.columnKey || null)
    const order_by_desc = (sorter === undefined || sorter && sorter.order === undefined) ? 'DESC' : (sorter?.order === "descend" ? "DESC" : null)
    coreApi
      .get("/transactions", {
        params: {
          city_branch_id: selectedCity,
          linked: 0,
          page: currentPage,
          order_by_column: order_by_column,
          order_by_desc: order_by_desc,
          ...filters,
        },
      })
      .then((res) => {
        let { data, ...pagination } = res.data;
        setTransactions(data);
        setPaginationDetails(pagination);
      })
      .catch((err) => console.log(err))
      .finally(() => setDataLoading(false));
  }, [currentPage, sorter, selectedCity, filters]);

  return (
    <React.Fragment>
      <Table
        size="small"
        loading={dataLoading}
        columns={tableColumns}
        title={() => <h4>{t("finance.transactions.unassignedTransactionsCount") + ": " + paginationDetails?.total}</h4>}
        dataSource={transactions}
        pagination={{
          current: paginationDetails?.current_page,
          pageSize: paginationDetails?.perPage,
          total: paginationDetails?.total,
          page: currentPage,
          showSizeChanger: true,
          onChange: (e) => {
            setCurrentPage(e);
          },
          showTotal: (total, range) => (
            <p>
              {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
              {" " + t("common.paginationPartTwo") + " "}
              <strong>{total}</strong> {t("common.paginationPartThree")}
            </p>
          ),
        }}
        onChange={(pagination, filters, sorter) => {
          setSorter(sorter);
          setFilters({
            paymentType: filters?.amount_free?.[0] || null,
            perPage: pagination?.pageSize,
          });
        }}
        rowKey={"id"}
      ></Table>

      {/*&& !assigningTransaction?.comment?.includes("Ref#1:")*/}
      <AssignTransactionModal
        transaction={assigningTransaction}
        isModalOpen={isModalClientOpen}
        setModalOpen={setModalClientOpen}
        transactions={transactions}
        setTransactions={setTransactions}
        decrementTotalTransactions={decrementTotalTransactions}
      ></AssignTransactionModal>

      {/*&& assigningTransaction?.comment?.includes("Ref#1:")*/}
      <AssignTransactionBalanceModal
        transaction={assigningTransaction}
        isModalOpen={isModalPartnerOpen}
        setModalOpen={setModalPartnerOpen}
        transactions={transactions}
        setTransactions={setTransactions}
        decrementTotalTransactions={decrementTotalTransactions}
      ></AssignTransactionBalanceModal>
    </React.Fragment>
  );
};

export default UnassignedTransactionsPane;
