import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {Form, Input, Modal} from 'antd';
import {useTranslation} from 'react-i18next';
import {emailDomainValidator, fetchAllowedDomains} from '../Validation/EmailValidtion';

const EmailInputWithValidation = forwardRef(({form, name, label, message, readOnly, bordered, required, requiredMessage, initialValue, validationMessage, placeholder, validationOnEnd = false, formRef = false}, ref) => {
    const { t } = useTranslation();
    const [isConfirmVisible, setConfirmVisible] = useState(false);
    const [emailValue, setEmailValue] = useState(initialValue || "");
    const [isChecked, setChecked] = useState(false);

    useImperativeHandle(ref, () => ({
    validateEmail: async (emailValue) => {
      if (emailValue === undefined) emailValue = document.getElementById('new-contact-email').value
      const isDomainValid = await emailDomainValidator()(null, emailValue);
      if (!isDomainValid && !isChecked) {
        setConfirmVisible(true);
          return false;
      }
      return true;
    },
    triggerFormFinish: () => {
        if (formRef && formRef.current) {
            formRef.current.submit();
        }
    }
  }));

  useEffect(() => {
    const loadAllowedDomains = async () => {
      await fetchAllowedDomains();
    };

    loadAllowedDomains();
  }, []);

  const handleEmailBlur = async () => {
    const isDomainValid = await emailDomainValidator()(null, emailValue);
    if (!isDomainValid && !validationOnEnd) {
      setConfirmVisible(true);
    }
  };

  const handleConfirmOk = () => {
    setChecked(true);
    ref.current.triggerFormFinish();
    setTimeout(function () {
      setChecked(false);
    }, 1000);
    setConfirmVisible(false);
  };

  const handleConfirmCancel = () => {
    setConfirmVisible(false);
    setChecked(true);
    setTimeout(function () {
      setChecked(false);
    }, 5000);
  };

  return (
    <>
      <Form.Item
        name={name}
        label={label}
        initialValue={initialValue}
        validationMessage={validationMessage}
        rules={[
          {
            required: required,
            message: requiredMessage,
          },
          {
            type: 'email',
            message: message,
          },
          {
            validator: async (_, value) => {},
          },
        ]}
      >
        <Input
          placeholder={placeholder}
          readOnly={readOnly}
          bordered={bordered}
          value={emailValue}
          onBlur={handleEmailBlur}
          onChange={(e) => setEmailValue(e.target.value)}
          id={"new-contact-email"}
        />
      </Form.Item>
      <Modal
        open={isConfirmVisible}
        onOk={handleConfirmOk}
        onCancel={handleConfirmCancel}
        okText={t("common.confirmEmailDomain")}
        cancelText={t("common.denyEmailDomain")}
        maskClosable={false}
        keyboard={false}
      >
        <p>{t("common.invalidEmailDomain")}</p>
      </Modal>
    </>
  );
});

export default EmailInputWithValidation;
