import { Divider, Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import EmailInputWithValidation from "../custom/Form/EmailInputWithValidation";

const PartnerContactSection = ({ partner, readOnly }) => {
  const { t } = useTranslation();
  const [addContactForm] = Form.useForm();

  return (
    <React.Fragment>
      <Divider orientation="left">{t("common.contactInformation")}</Divider>
      <EmailInputWithValidation
        form={addContactForm}
        name="email"
        label={t("common.email")}
        message={t("common.emailValidation")}
        initialValue={partner?.email}
        readOnly={readOnly}
        bordered={!readOnly}
      />
      <Form.Item
        name="phone"
        label={t("common.phone")}
        initialValue={partner?.phone}
      >
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>
    </React.Fragment>
  );
};

export default PartnerContactSection;
