import { Col, Row, Tabs } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTabs from "../components/custom/CustomTabs";
import InvoiceAddForm from "../components/Invoices/CreateForm/InvoiceAddForm";
import InvoiceList from "../components/Invoices/InvoicesList";

const InvoicesIndex = (props) => {
  const { t } = useTranslation();

  const [partnerClientInvoices, setPartnerClientInvoices] = useState([]);
  const [annaPartnerInvoices, setAnnaPartnerInvoices] = useState([]);
  const [partnerPartnerInvoices, setPartnerPartnerInvoices] = useState([]);
  const [partnerAnnaInvoices, setPartnerAnnaInvoices] = useState([]);
  const [correctiveDocuments, setCorrectiveDocuments] = useState([]);

  const invoiceTabs = [
    {
      tabLabel: t("finance.invoices.partnerClientInvoices"),
      apiEntityPath: "partner-client",
      partnerIssued: true,
      annaIssued: false,
      clientRecipient: true,
      partnerRecipient: false,
      annaRecipient: false,
      state: partnerClientInvoices,
      setState: setPartnerClientInvoices,
      key: "#partnerClient",
      invoiceType: 1,
      invoicesPerPage: 10,
    },
    {
      tabLabel: t("finance.invoices.annaPartnerInvoices"),
      apiEntityPath: "anna-partner",
      partnerIssued: false,
      annaIssued: true,
      clientRecipient: false,
      partnerRecipient: true,
      annaRecipient: false,
      state: annaPartnerInvoices,
      setState: setAnnaPartnerInvoices,
      key: "#annaPartner",
      invoiceType: 2,
      invoicesPerPage: 10,
    },
    {
      tabLabel: t("finance.invoices.partnerPartnerInvoices"),
      apiEntityPath: "partner-partner",
      partnerIssued: true,
      annaIssued: false,
      clientRecipient: false,
      partnerRecipient: true,
      annaRecipient: false,
      state: partnerPartnerInvoices,
      setState: setPartnerPartnerInvoices,
      key: "#partnerPartner",
      invoiceType: 3,
      invoicesPerPage: 10,
    },
    {
      tabLabel: t("finance.invoices.partnerAnnaInvoices"),
      apiEntityPath: "partner-anna",
      partnerIssued: true,
      annaIssued: false,
      clientRecipient: false,
      partnerRecipient: false,
      annaRecipient: true,
      state: partnerAnnaInvoices,
      setState: setPartnerAnnaInvoices,
      key: "#partnerAnna",
      invoiceType: 5,
      invoicesPerPage: 10,
    },
    {
      tabLabel: t("finance.invoices.correctiveDocuments"),
      apiEntityPath: "corrective-documents",
      partnerIssued: true,
      annaIssued: false,
      clientRecipient: true,
      partnerRecipient: false,
      annaRecipient: false,
      state: correctiveDocuments,
      setState: setCorrectiveDocuments,
      key: "#correctiveDocuments",
      invoiceType: 4,
      invoicesPerPage: 10,
    },
  ];

  const extraInvoiceSlot = {
    right: (
      <InvoiceAddForm
        partnerClientInvoices={partnerClientInvoices}
        setPartnerClientInvoices={setPartnerClientInvoices}
        annaPartnerInvoices={annaPartnerInvoices}
        setAnnaPartnerInvoices={setAnnaPartnerInvoices}
        partnerPartnerInvoices={partnerPartnerInvoices}
        setPartnerPartnerInvoices={setPartnerPartnerInvoices}
        correctiveDocuments={correctiveDocuments}
        setCorrectiveDocuments={setCorrectiveDocuments}
        partnerAnnaInvoices={partnerAnnaInvoices}
        setPartnerAnnaInvoices={setPartnerAnnaInvoices}
      ></InvoiceAddForm>
    ),
  };

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <CustomTabs props={props} tabBarExtraContent={extraInvoiceSlot} defaultActiveKey="#partnerClient">
            {invoiceTabs.map((invoiceTab, index) => {
              return (
                <Tabs.TabPane key={invoiceTab.key} tab={invoiceTab.tabLabel}>
                  <InvoiceList
                    apiEntityPath={invoiceTab.apiEntityPath}
                    partnerIssued={invoiceTab.partnerIssued}
                    annaIssued={invoiceTab.annaIssued}
                    clientRecipient={invoiceTab.clientRecipient}
                    partnerRecipient={invoiceTab.partnerRecipient}
                    annaRecipient={invoiceTab.annaRecipient}
                    state={invoiceTab.state}
                    setState={invoiceTab.setState}
                    invoiceType={invoiceTab.invoiceType}
                    invoicesPerPage={invoiceTab.invoicesPerPage}
                  />
                </Tabs.TabPane>
              );
            })}
          </CustomTabs>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InvoicesIndex;
