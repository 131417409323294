import React, { useEffect, useState } from "react";
import { Select, Form } from "antd";
import { useTranslation } from "react-i18next";

/**
 * Partner State Selector - on mount fetches all partners states
 * @component
 * @alias PartnerStateSelector
 * @property {number} initialValue - init value for select (state_id)
 * @property {bool} readOnly - sets Form Item read-only
 * @property {bool} required - sets Form Item required - default false
 * @returns <Form.Item /> containing <Select />
 */
const PartnerStateSelector = ({ initialValue, required, readOnly, sendOnchange = null, clearable = false,}) => {
  const { t } = useTranslation();

  const [states, setStates] = useState([]);

  useEffect(() => {
    const states = t('partners.states', { returnObjects: true });
    setStates(states);
  }, []);
  return (
    <Form.Item
      name="state"
      label={t("partners.selectPartnerState")}
      rules={[
        {
          required: required,
          message: t("partners.validation.state"),
        },
      ]}
      initialValue={initialValue}
      style={{minWidth: '370px'}}
    >
      <Select
        disabled={readOnly}
        allowClear={clearable}
        onChange={(id) => {
          if (sendOnchange) sendOnchange(id);
        }}
      >
        {Object.keys(states).map((key) => {
          return (
            <Select.Option key={key} value={key}>
              {states[key]}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default PartnerStateSelector;
