import React, { useState, useEffect } from "react";
import { Table, notification } from "antd";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const CityStatisticsGrouped = () => {
  const { t } = useTranslation();
  const [statisticsData, setStatisticsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchGroupedCityStatistics = () => {
    setLoading(true);
    coreApi
      .get("/approval-payment/city-statistics-grouped")
      .then((res) => {
        const sortedData = res.data.sort((a, b) => a.city_branch.name.localeCompare(b.city_branch.name));
        setStatisticsData(sortedData);
      })
      .catch((err) => {
        notification.error({
          message: t("finance.transactions.loadError"),
          description: err.message,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchGroupedCityStatistics();
  }, []);

  const columns = [
    {
      title: t("finance.transactions.city"),
      dataIndex: ["city_branch", "name"],
      key: "cityBranchName",
    },
    {
      title: t("finance.transactions.totalPaymentsForApproval"),
      dataIndex: "totalPaymentsForApproval",
      key: "totalPaymentsForApproval",
      render: (value, record) => new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: record?.city_branch?.currency || "CZK",
      }).format(value),
    },
    {
      title: t("finance.transactions.totalRewardsForApproval"),
      dataIndex: "totalRewardsForApproval",
      key: "totalRewardsForApproval",
      render: (value, record) => new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: record?.cityBranch?.currency || "CZK",
      }).format(value),
    },
    {
      title: t("finance.transactions.totalRewardsInReporting"),
      dataIndex: "totalRewardsInReporting",
      key: "totalRewardsInReporting",
      render: (value, record) => new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: record?.cityBranch?.currency || "CZK",
      }).format(value),
    },
    {
      title: t("finance.transactions.rewardDifference"),
      dataIndex: "rewardDifference",
      key: "rewardDifference",
      render: (value, record) => new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: record?.cityBranch?.currency || "CZK",
      }).format(value),
    },
    {
      title: t("finance.transactions.overpayments"),
      dataIndex: "overpayments",
      key: "overpayments",
      render: (value, record) => new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: record?.cityBranch?.currency || "CZK",
      }).format(value),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={statisticsData}
      loading={loading}
      rowKey={(record) => record.city_branch?.id || Math.random()}
      pagination={false}
      size="small"
      scroll={{ y: 500 }}
    />
  );
};

export default CityStatisticsGrouped;
