import { Col, Divider, Form, Input, notification, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import ProfileFormActionSpace from "../../components/ProfileFormActionSpace";

/**
 * Building units details content
 * @component
 * @alias BuildingUnitDetails
 * @property {object} buildingUnit - contains buildingUnit details
 * @property {function} setBuildingUnit - React state set function
 * @returns Structured content of building unit with <Row(s) /> and <Col(s) />
 */
const BuildingUnitDetails = ({ buildingUnit, setBuildingUnit }) => {
  const { t } = useTranslation();

  const [isFormReadOnly, setFormReadOnly] = useState(true);
  const [isFormSending, setFormSending] = useState(false);

  const [buildingUnitForm] = Form.useForm();

  const gutter = [8, 0];

  return (
    <React.Fragment>
      <Row gutter={gutter}>
        <Col span={24}>
          <Form
            form={buildingUnitForm}
            name="building_unit"
            onFinish={(values) => {
              setFormSending(true);
              coreApi
                .put("/buildings/units/" + values.building_unit_id, values)
                .then((response) => {
                  setFormReadOnly(true);
                  notification.success({
                    message: t("successNotifications.saveChanges.title"),
                  });
                })
                .catch((error) => {
                  notification.error({
                    message: t("errorNotifications.saveChanges.title"),
                    description: t("errorNotifications.saveChanges.message"),
                  });
                })
                .finally(() => {
                  setFormSending(false);
                });
            }}
          >
            <Row gutter={gutter}>
              <Col>
                <Form.Item hidden name="building_unit_id" initialValue={buildingUnit?.id}>
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item hidden name="building_id" initialValue={buildingUnit?.building_id}>
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <ProfileFormActionSpace
                  formInstance={buildingUnitForm}
                  isFormReadOnly={isFormReadOnly}
                  isFormSending={isFormSending}
                  setFormEditable={() => setFormReadOnly(false)}
                  setFormDisabled={() => setFormReadOnly(true)}
                />
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col span={6}>
                <Form.Item name="city" label={t("buildings.city")} initialValue={buildingUnit?.city}>
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="district" label={t("buildings.district")} initialValue={buildingUnit?.district}>
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col span={6}>
                <Form.Item name="street" label={t("buildingUnits.street")} initialValue={buildingUnit?.street}>
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="house_number"
                  label={t("buildingUnits.houseNumbering")}
                  initialValue={buildingUnit?.house_number}
                >
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </Row>
            <Divider></Divider>
            <Row gutter={gutter}>
              <Col span={6}>
                <Form.Item
                  name="floor_count"
                  label={t("buildingUnits.floorCount")}
                  initialValue={buildingUnit?.floor_count}
                >
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="ground_floor_count"
                  label={t("buildingUnits.groundFloorCount")}
                  initialValue={buildingUnit?.ground_floor_count}
                >
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="basement_floor_count"
                  label={t("buildingUnits.basementFloorCount")}
                  initialValue={buildingUnit?.basement_floor_count}
                >
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BuildingUnitDetails;
