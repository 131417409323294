import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

const PartnerLink = ({ partner }) => {
  const fullNameOrCompany = partner?.first_name || partner?.last_name
    ? `${partner?.first_name} ${partner?.last_name}`
    : partner?.company_name;

  return (
    <Tooltip title={fullNameOrCompany}>
      <Link to={"/partners/" + partner?.id} style={{ borderBottom: "1px dotted" }}>
        {fullNameOrCompany}
      </Link>
    </Tooltip>
  );
};

export default PartnerLink;
