import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, Modal, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ManagerSelect from "./ManagerSelect";
import CountryBranchTypeSelect from "./Countries/CountryBranchTypeSelect";

const CountryAddForm = ({ onFinishAction }) => {
    const { t } = useTranslation();

    const [isModalOpen, setModalOpen] = useState(false);

    const [addCountryForm] = Form.useForm();

    return (
        <React.Fragment>
            <Button
                size="large"
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                {t("expansion.addCountry")}
            </Button>
            <Modal
                title={t("expansion.addCountry")}
                open={isModalOpen}
                onCancel={() => {
                    setModalOpen(false);
                }}
                okText={t("common.add")}
                onOk={addCountryForm.submit}
            >
                <Form
                    form={addCountryForm}
                    onFinish={async (values) => {
                        values.vat = values.vat / 100;
                        await onFinishAction(values);
                        addCountryForm.resetFields();
                        setModalOpen(false);
                    }}
                    layout="vertical"
                >
                    <Form.Item
                        name="name"
                        label={t("expansion.countryName")}
                        rules={[
                            {
                                required: true,
                                message: t("expansion.validation.country.name"),
                            },
                        ]}
                    >
                        <Input max={2}></Input>
                    </Form.Item>
                    <Form.Item
                        name="company_name"
                        label={t("expansion.countries.companyName")}
                    >
                        <Input></Input>
                    </Form.Item>
                    <Form.Item name="web" label={t("expansion.countryWeb")}>
                        <Input></Input>
                    </Form.Item>
                    <Row gutter={[24, 0]}>
                        <Col span={8}>
                            <Form.Item
                                name="vat"
                                label={t("expansion.vat")}
                                rules={[
                                    {
                                        required: true,
                                        type: "number",
                                        min: 0,
                                        max: 100,
                                        message: t("expansion.validation.country.vat"),
                                    },
                                ]}
                            >
                                <InputNumber
                                    style={{ width: "100%" }}
                                    formatter={(value) => `${value}%`}
                                    parser={(value) => value.replace("%", "")}
                                ></InputNumber>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="iso_4217_currency_code"
                                label={t("expansion.iso4217")}
                                rules={[
                                    {
                                        required: true,
                                        pattern: "[A-Z]{3}",
                                        message: t("expansion.validation.country.iso4217"),
                                    },
                                ]}
                            >
                                <Input></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={8}>
                            <Form.Item
                                name="country_code_iso_3166"
                                label={t("expansion.iso3166")}
                                rules={[
                                    {
                                        required: true,
                                        pattern: "[A-Z]{2}",
                                        message: t("expansion.validation.country.iso3166"),
                                    },
                                ]}
                            >
                                <Input></Input>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="country_calling_code"
                                label={t("expansion.countryCallingCode")}
                                rules={[
                                    {
                                        required: true,
                                        pattern: "[0-9]{2,3}",
                                        message: t("expansion.validation.country.callingCode"),
                                    },
                                ]}
                            >
                                <Input prefix={"+"}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <ManagerSelect
                        label={t("expansion.countries.countryManager")}
                        validationMessage={t("expansion.validation.country.manager")}
                    />
                    <CountryBranchTypeSelect
                        label={t("expansion.countries.countryType")}
                        validationMessage={t("expansion.validation.country.type")}
                    />
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default CountryAddForm;
