const initialState = {
  url: "/dashboard"
};

const logoutHandler = (state = initialState, action) => {
  switch (action.type) {
    case "SET_URL":
      return { url: action.url };
    case "GET_URL":
      return { ...state };
    case "REMOVE_URL":
      return { url: null };
    default:
      return state;
  }
};

export default logoutHandler;
