import { Checkbox, TimePicker, Divider, Form } from "antd";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

const PartnerRequestedDateTimeSection = ({ partner, readOnly }) => {
  const { t } = useTranslation();
  const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

  const savedValue = partner?.requested_days
    ? (typeof partner.requested_days === 'string'
      ? JSON.parse(partner.requested_days)
      : partner.requested_days)
    : [];

  const [showSecondRange, setShowSecondRange] = useState(days.map(() => false));
  const [unblockFirstRange, setUnblockFirstRange] = useState(days.map(() => false));

  const handleDayChange = (e, dayIndex) => {
    const isChecked = e.target.checked;
    const updatedFirstRange = [...unblockFirstRange];
    updatedFirstRange[dayIndex] = isChecked ? 1 : 0;
    setUnblockFirstRange(updatedFirstRange);
  };

  const handleTimeChange = (value, dayIndex) => {
    const hasValue = value && value.length > 0;
    const updatedShowSecondRange = [...showSecondRange];
    updatedShowSecondRange[dayIndex] = hasValue;
    setShowSecondRange(updatedShowSecondRange);
  };

  useEffect(() => {
    const updatedFirstRange = [...unblockFirstRange];
    const updatedShowSecondRange = [...showSecondRange];

    Object.entries(savedValue).forEach(([day, dayArray]) => {
      const dayIndex = days.indexOf(day);
      if (dayIndex !== -1) {
        updatedFirstRange[dayIndex] = 1;
        updatedShowSecondRange[dayIndex] = dayArray.length && (dayArray[1] !== null || dayArray[0] !== null) ? 1 : 0;
      }
    });

    setUnblockFirstRange(updatedFirstRange);
    setShowSecondRange(updatedShowSecondRange);
  }, []);

  return (
    <React.Fragment>
      <Divider orientation="left">{t("partners.capacity.title")}</Divider>
        <div style={{ display: 'flex', marginLeft: '20px' }}>
          {/* Days Section */}
          <Form.Item
            label={t("partners.capacity.days")}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
              {days.map((day, index) => (
                <Form.Item
                  key={day}
                  name={["requested_days", day]}
                  valuePropName="checked"
                  initialValue={Array.isArray(savedValue[day]) && (savedValue[day][0] !== null || savedValue[day][1] !== null)}
                  noStyle
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', marginTop: '5px' }}>
                    <span>{t("rrules.byweekday." + day)}</span>
                    <Checkbox
                      value={day}
                      disabled={readOnly}
                      onChange={(e) => handleDayChange(e, index)}
                      defaultChecked={Array.isArray(savedValue[day]) && (savedValue[day][0] !== null || savedValue[day][1] !== null)}
                    />
                  </div>
                </Form.Item>
              ))}
            </div>
          </Form.Item>

          {/* Time Section */}
          <Form.Item
            label={t("partners.capacity.time")}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {days.map((day, index) => (
                <div key={day} style={{ display: 'flex', maxHeight: '37px' }}>
                  {/* First Time Range Picker */}
                  <Form.Item
                    name={["requested_time", day]}
                    valuePropName={"value"}
                    style={{ marginBottom: '5px', display: 'flex', justifyContent: 'space-between' }}
                    initialValue={savedValue && savedValue[day] && savedValue[day][0] ? savedValue[day][0].map(time => moment(time)) : []}
                  >
                    <TimePicker.RangePicker
                      disabled={readOnly || unblockFirstRange[index] < 1}
                      format="HH:mm"
                      use12Hours={false}
                      onChange={(value) => handleTimeChange(value, index)}
                    />
                  </Form.Item>

                  {/* Second Time Range Picker */}
                  <Form.Item
                    name={["requested_time_2", day]}
                    valuePropName={"value"}
                    style={{ marginBottom: '5px', marginLeft: '10px', opacity: showSecondRange[index] ? 1 : 0 }}
                    initialValue={savedValue && savedValue[day] && savedValue[day][1] ? savedValue[day][1].map(time => moment(time)) : []}
                  >
                    <TimePicker.RangePicker
                      disabled={readOnly || !showSecondRange[index] || unblockFirstRange[index] < 1}
                      format="HH:mm"
                      use12Hours={false}
                    />
                  </Form.Item>
                </div>
              ))}
            </div>
          </Form.Item>
        </div>
    </React.Fragment>
  );
};

export default PartnerRequestedDateTimeSection;
