import { Form, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import { sortPartners } from "../helpers/sort";

const PartnerSelect = ({
 label,
 name,
 initialValue,
 disabledOption,
 readOnly,
 required,
 validationMessage,
 size,
 setIdPartner = null,
 onChange = null,
 defaultPartners = [],
 className = "default-item-class",
 mode = "single",
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState(defaultPartners);

  useEffect(() => {
    if (partners.length > 0) return;
    let isMounted = true;

    setLoading(true);
    coreApi
      .get("/partners/all")
      .then((response) => {
        setPartners(sortPartners(response.data));
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const handleClear = () => {
    onChange && onChange(null);
  };

  const handlePartnerChange = (partnerId) => {
    const selectedPartner = partners.find((partner) => partner.id === partnerId);
    onChange && onChange(selectedPartner);
  };

  const filterOption = (input, option) => {
    if (typeof option.children === "string") {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    return false;
  };

  return (
    <Form.Item
      className={className}
      hasFeedback
      name={name}
      label={label}
      initialValue={initialValue}
      valuePropName={"value"}
      rules={[
        {
          required: required,
          message: validationMessage,
        },
      ]}
    >
      <Select
        size={size}
        allowClear
        disabled={readOnly}
        bordered={!readOnly}
        loading={loading}
        showSearch={true}
        mode={mode}
        placeholder={t("partners.selectPartner")}
        filterOption={filterOption}
        onChange={handlePartnerChange}
        onClear={handleClear}
      >
        {partners.map((partner) => (
          <Select.Option
            key={partner.id}
            value={partner.id}
            disabled={disabledOption && partner.id === disabledOption}
          >
            {partner.contract_ended === null ? (
              `${partner.first_name} ${partner.last_name}`
            ) : (
              <s>{`${partner.first_name} ${partner.last_name}`}</s>
            )}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default PartnerSelect;
