const initialState = {
  statusCode: 0,
  message: "",
};

const responseStatusHandler = (state = initialState, action) => {
  switch (action.type) {
    case "SET_RESPONSE_STATUS":
      return {
        ...state,
        statusCode: action.statusCode,
        message: action.message,
      };
    case "GET_RESPONSE_STATUS":
      return state;
    default:
      return state;
  }
};

export default responseStatusHandler;
