import { notification, Space, Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { coreApi } from "../../api/calls";
import OfferAddForm from "../Offers/OfferAddForm";
import BuildingOffersModal from "./BuildingOffersModal";

/**
 * Building List with Offers
 * @component
 * @alias BuildingListWithOffers
 * @property {object} clientLead - contains details about client(lead)
 * @property {array} buildings - array of buildings that belongs to the client
 * @returns <Table />
 */
const BuildingListWithOffers = ({ clientLead, buildings }) => {
  const { t } = useTranslation();

  const [addOfferRedirectId, setAddOfferRedirectId] = useState(null);

  const addOffer = (values) => {
    const payload = {
      ...values,
      date_active_to: values.date_active_to?.toISOString(),
      date_active_from: values.date_active_from?.toISOString(),
    };

    coreApi
      .post("/offers", payload)
      .then((response) => {
        notification.success({
          message: response.data.message,
        });
        setAddOfferRedirectId(response.data.offer?.id);
      })
      .catch((error) =>
        notification.error({
          message: error.response.data.message,
        })
      );
  };

  // defined columns for <Table /> component
  const buildingListColumns = [
    {
      title: t("buildings.reference"),
      key: "reference",
      ellipsis: true,
      width: "30%",
      render: (building) => {
        return building?.registration_number ? (
          <Link to={"/buildings/" + building?.id} style={{ fontWeight: 500 }}>
            {building.registration_number + " - " + building.reference}
          </Link>
        ) : (
          <Link to={"/buildings/" + building?.id} style={{ fontWeight: 500 }}>
            {building.reference}
          </Link>
        );
      },
    },
    {
      title: t("common.city"),
      sorter: (a, b) => a.city_branch.name.localeCompare(b.city_branch.name),
      render: (data) => data.city_branch.name,
    },
    {
      title: t("buildings.district"),
      key: "district",
      dataIndex: "district",
    },
    {
      title: t("buildings.postalCode"),
      key: "postal_code",
      dataIndex: "postal_code",
    },
    {
      title: t("clientLeads.createViewOffer"),
      render: (building) => (
        <Space>
          <BuildingOffersModal offers={building.offers} orders={building.orders} />
          <OfferAddForm client={clientLead} building={building} buttonSize="small" addOffer={addOffer} />
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Table
        size="small"
        dataSource={buildings}
        columns={buildingListColumns}
        rowKey="id"
        expandable={{
          rowExpandable: (buildings) => {
            return buildings?.building_units?.length > 0;
          },
          expandedRowRender: (buildings) => {
            return (
              <span style={{ margin: 0 }}>
                {buildings.building_units.map((buildingUnit) => {
                  return (
                    <React.Fragment key={buildingUnit?.id}>
                      <Link to={"/buildings/" + buildingUnit.building_id + "/unit/" + buildingUnit?.id}>
                        {buildingUnit.street + " " + buildingUnit.house_number}
                      </Link>
                      <br />
                    </React.Fragment>
                  );
                })}
              </span>
            );
          },
        }}
      ></Table>
      {addOfferRedirectId ? <Redirect to={"/offers/" + addOfferRedirectId} /> : ""}
    </React.Fragment>
  );
};

export default BuildingListWithOffers;
