// const weights = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];
// const weights = [1, 2, 4, 8, 5, 10, 9, 7, 3, 6];

const weights = [10, 5, 8, 4, 2, 1, 6, 3, 7, 9];

/**
 * Validate account number function - validates account number based on strict rules
 * @property {string} accountNumber
 * @returns Boolean value
 */
const validateAccountNumber = (accountNumber) => {
  let explodedAccountNumber = [...accountNumber];
  let checkSum = 0;

  for (let i = 0; i < explodedAccountNumber.length; i++) {
    checkSum += explodedAccountNumber[i] * weights[i];
  }

  return (
    explodedAccountNumber.length === 0 ||
    (explodedAccountNumber.length > 2 && checkSum % 11 === 0)
  );
};

export default validateAccountNumber;
