import { rrulestr } from "rrule";

export const formatRruleDays = (rrule, t) => {
  if (!rrule) {
    return '';
  }

  try {
    const daysMap = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
    const daysNames = {
      MO: t("rrules.byweekday.MO"),
      TU: t("rrules.byweekday.TU"),
      WE: t("rrules.byweekday.WE"),
      TH: t("rrules.byweekday.TH"),
      FR: t("rrules.byweekday.FR"),
      SA: t("rrules.byweekday.SA"),
      SU: t("rrules.byweekday.SU"),
    };
    const rruleObject = rrulestr(rrule);

    if (!rruleObject.origOptions.byweekday) {
      return '';
    }

    const selectedDays = rruleObject.origOptions.byweekday.map((weekday) => daysMap[weekday?.weekday || weekday]);
    return selectedDays.map(day => daysNames[day]).join(", ");
  } catch (error) {
    console.error("Invalid rrule format", error);
    return '';
  }
};

export const getDays = (ruleString) => {
  const byDayMatch = ruleString.match(/BYDAY=([^;]+)/);

  if (byDayMatch) {
    const byDayString = byDayMatch[1] || "";
    return byDayString.split(",")
  }

  return [];
}