import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Account Banck Code Input
 * @component
 * @alias AccountBankCodeInput
 * @property {string} initialValue - initial value for bank code form item
 * @property {bool} readOnly - sets form item read-only
 * @property {bool} required - sets form item required - default false
 * @returns <Form.Item>
 */
const AccountBankCodeInput = ({ initialValue, readOnly, required = false }) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      label={t("finance.accounts.accountBankCode")}
      name={"account_bank_code"}
      initialValue={initialValue}
      hasFeedback
      rules={[
        {
          len: 4,
          required: required,
          message: t("finance.accounts.validation.accountBankCode"),
        },
      ]}
    >
      <Input maxLength={4} readOnly={readOnly} bordered={!readOnly}></Input>
    </Form.Item>
  );
};

export default AccountBankCodeInput;
