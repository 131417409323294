import { Col, DatePicker, Divider, Row, Tabs, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAreaScope } from "../actions";
import { coreApi } from "../api/calls";
import CityOverviewReport from "../components/Reports/City/CityOverviewReport";
import SpinnerFullPage from "../components/SpinnerFullPage";

/**
 * Page with city reports
 * @component
 * @alias BuildingUnitProfile
 * @returns <Card /> with content
 */
const CityReports = () => {
  const { t } = useTranslation();

  const areaScope = useSelector(getAreaScope);
  const selectedCity = areaScope?.areaScope;

  // States
  const [city, setCity] = useState(null);
  const [dateFrom, setDateFrom] = useState(moment().startOf("month"));
  const [dateTo, setDateTo] = useState(moment().endOf("month"));
  const [cityLoading, setCityLoading] = useState(false);

  useEffect(() => {
    setCityLoading(true);

    if (selectedCity) {
      coreApi
        .get("/cities/" + selectedCity)
        .then(({ data }) => {
          setCity(data);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setCityLoading(false);
        });
    } else {
      setCity(null);
      setCityLoading(false);
    }
  }, [selectedCity]);

  return (
    <React.Fragment>
      {cityLoading ? (
        <SpinnerFullPage />
      ) : (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Typography.Text>{t("common.selectedPeriod")}: </Typography.Text>

            <DatePicker.RangePicker
              allowEmpty={[false, false]}
              allowClear={false}
              format={"MMMM YYYY"}
              defaultValue={[dateFrom, dateTo]}
              onChange={([dateFromChanged, dateToChanged]) => {
                setDateFrom(dateFromChanged.startOf("month"));
                setDateTo(dateToChanged.endOf("month"));
              }}
              picker={"month"}
            />
          </Col>

          <Divider />

          <Col span={24}>
            {city ? (
              <Tabs defaultActiveKey={"generalInformation"} size={"large"}>
                <Tabs.TabPane tab={t("reports.city.operationsOverview")} key={"operationsOverview"}>
                  <CityOverviewReport city={city} dateFrom={dateFrom} dateTo={dateTo} />
                </Tabs.TabPane>
              </Tabs>
            ) : (
              <Typography.Paragraph>{t("reports.city.selectCity")}</Typography.Paragraph>
            )}
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default CityReports;
