import { Tag } from "antd";
import React from "react";

/**
 * Daily Agenda Pane - containts information about events (cleanings) for the day in form of <Tag /> components
 * @component
 * @alias DailyAgendaPane
 * @property {array} cleanings
 * @returns <React.Fragment /> containing <Tag /> component for every cleaning that day
 */
const DailyAgendaPane = ({ cleanings }) => {
  return (
    <React.Fragment>
      {cleanings?.length > 0
        ? cleanings.map((cleaning) => {
            return (
              <Tag
                key={cleaning?.id}
                size={"small"}
                color={cleaning.is_recurring ? "geekblue" : "magenta"}
                style={{
                  maxWidth: "100%",
                  fontSize: "10px",
                  padding: "2px 5px",
                  margin: "0",
                  lineHeight: "10px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                <span>{cleaning.building_unit.street + " " + cleaning.building_unit.house_number}</span>
              </Tag>
            );
          })
        : ""}
    </React.Fragment>
  );
};
export default DailyAgendaPane;
