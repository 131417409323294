import { DollarCircleOutlined } from "@ant-design/icons";
import { Button, Col, notification } from "antd";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const PaymentExportButton = ({ exportAll, idCityBranch, cityName, primary = false, dateFrom, dateTo }) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  return (
    <Col span={24}>
      <Button
        type={primary ? "primary" : "none"}
        loading={isLoading}
        icon={<DollarCircleOutlined />}
        style={{ width: "100%" }}
        onClick={() => {
          setLoading(true);
          coreApi
            .get("/payments/export", {
              responseType: "blob",
              params: {
                exportAll: exportAll,
                dateFrom,
                dateTo,
              },
            })
            .then((response) => {
              fileDownload(response.data, cityName !== "" ? cityName + "-payments-export.csv" : "payments-export.csv");
            })
            .catch((err) => {
              notification.error({ message: t("exports.exportError") });
            })
            .finally(() => setLoading(false));
        }}
      >
        {t("exports.paymentExport")}
      </Button>
    </Col>
  );
};

export default PaymentExportButton;
