import { Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RRule } from "rrule";
import RruleFrequencySelect from "../Scheduler/RruleFrequencySelect";

const CustomRegularityModal = ({ isModalOpen, setModalOpen, addCleaningGroup }) => {
  const { t } = useTranslation();

  const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
  const defaultFrequency = "WEEKLY";
  const defaultInterval = 1;

  const [form] = Form.useForm();

  const [freq, setFreq] = useState(defaultFrequency);
  const [interval, setInterval] = useState(1);

  const [showRepetitionPerWeek, setShowRepetitionPerWeek] = useState(false);

  useEffect(() => {
    if (freq === "WEEKLY" && interval === 1) {
      setShowRepetitionPerWeek(true);
    } else {
      setShowRepetitionPerWeek(false);
    }
  }, [freq, interval]);

  return (
    <Modal
      title={t("offers.regularityModal.customRepetition")}
      open={isModalOpen}
      onCancel={() => setModalOpen(false)}
      onOk={() => form.submit()}
    >
      <Form
        name={"custom_regularity"}
        form={form}
        onFinish={(values) => {
          addCleaningGroup(values.regularity_name, values.rrule);
          setModalOpen(false);
        }}
        onValuesChange={(_, allValues) => {
          if (allValues.frequency) {
            setFreq(allValues.frequency);
            setInterval(allValues.interval);

            let selectedDays = days.slice(0, showRepetitionPerWeek ? allValues?.days : [1]);
            let selectedRruleDays = selectedDays.map((day) => RRule[day]);

            let rrule = new RRule({
              freq: RRule[allValues.frequency],
              dtstart: allValues?.date_from?.toDate() || null,
              interval: allValues?.interval || null,
              byweekday: selectedRruleDays || [],
            });

            form.setFieldsValue({
              rrule: rrule.toString().replace("RRULE:", ""),
            });
          }
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <RruleFrequencySelect initialFrequency={defaultFrequency} initialInterval={defaultInterval} />
          </Col>
        </Row>
        {showRepetitionPerWeek && (
          <Row>
            <Col>
              <Form.Item name="days" label={t("offers.regularityModal.repetitionsPerWeek")} initialValue={1}>
                <Select>
                  <Select.Option value={1}>1x</Select.Option>
                  <Select.Option value={2}>2x</Select.Option>
                  <Select.Option value={3}>3x</Select.Option>
                  <Select.Option value={4}>4x</Select.Option>
                  <Select.Option value={5}>5x</Select.Option>
                  <Select.Option value={6}>6x</Select.Option>
                  <Select.Option value={7}>7x</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Form.Item name={"regularity_name"} label={t("offers.regularityModal.name")} required>
          <Input placeholder={t("offers.regularityModal.placeholder")} />
        </Form.Item>
        <Form.Item name={"rrule"} required hidden>
          <Input.TextArea readOnly hidden></Input.TextArea>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CustomRegularityModal;
