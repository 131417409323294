import { notification, Select } from "antd";
import { Form } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../../api/calls";

/**
 * @component
 * @alias ClientSelect
 * @property {string} initialValue
 * @property {boolean} readOnly
 * @property {boolean} onChange
 * @property {boolean} required
 * @property {string} validationMessage
 * @property {boolean} showSearch
 * @returns Select input with clients
 */
const ClientSelect = ({
  initialValue = null,
  readOnly = false,
  onChange = null,
  required = false,
  validationMessage = "",
  showSearch = false,
}) => {
  const { t } = useTranslation();

  // States
  const [clients, setClients] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .all([coreApi.get("/clients-represented"), coreApi.get("/clients-representing")])
      .then(
        axios.spread((representing, represeted) => {
          setClients([...representing.data, ...represeted.data]);
        })
      )
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      <Form.Item
        name={"client_id"}
        label={t("clients.selectClient")}
        valuePropName={"value"}
        wrapperCol={24}
        initialValue={initialValue && initialValue}
        rules={[
          {
            required: required,
            message: validationMessage,
          },
        ]}
      >
        <Select
          loading={loading}
          allowClear
          showSearch={showSearch}
          filterOption={
            showSearch ? (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : null
          }
          onChange={(e) => onChange && onChange({ ...clients.find((item) => item.id === e) })}
          disabled={readOnly}
          bordered={!readOnly}
        >
          {clients?.map((item, i) => (
            <Select.Option key={item?.id} value={item?.id}>
              {`${item?.name}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </React.Fragment>
  );
};

export default ClientSelect;
