import { MinusCircleOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, Modal, notification, Progress, Row, Select } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const AssignTransactionModal = ({
  isModalOpen,
  setModalOpen,
  transaction,
  setTransactions,
  transactions,
  decrementTotalTransactions,
}) => {
  const { t } = useTranslation();

  const [invoices, setInvoices] = useState([]);
  const [amountUsed, setAmountUsed] = useState(0);

  const [isOutgoing, setIsOutgoing] = useState(false);

  const [assignForm] = Form.useForm();
  const [infoForm] = Form.useForm();

  useEffect(() => {
    setAmountUsed(0);
    setIsOutgoing(() => (transaction.amount < 0 ? true : false));

    infoForm.resetFields();
    assignForm.resetFields();
  }, [transaction]);

  /**
   * Calculate total amount used from transaction amount.
   */
  const recalculateTotalUsed = () => {
    const fields = assignForm.getFieldsValue();
    const { assignments } = fields;
    let total = 0;

    for (const item of assignments) {
      total += item.amount;
    }

    setAmountUsed(total);
    infoForm.setFieldsValue({ amount_used: Number(total) });
  };

  /**
   * Define validation rule
   */
  const rule = {
    type: "number",
    min: isOutgoing ? transaction.amount_free : 0.01,
    max: isOutgoing ? -1 : transaction.amount_free,
    message: t("finance.transactions.validation.transactionTotal"),
  };

  /**
   * Function for state manipulation. Removes transaction or edits it's record based on form input.
   *
   * @param object values from form submission
   */
  const handleAfterTransactionAssignment = (values) => {
    // Remove from transaction list if transaction is fully assigned
    // Do NOT remove if transaction has remaining amount
    // Update remaining amount if has some

    values.assignments.forEach((value) => {
      let transaction = transactions.find((item) => item.id === value.transaction_id);

      if (transaction?.amount_free === value?.amount) {
        setTransactions([...transactions.filter((transaction) => transaction.id !== value.transaction_id)]);
        decrementTotalTransactions();
      } else {
        setTransactions([
          ...transactions.map((item) => {
            if (item.id === value.transaction_id)
              item.amount_free = isOutgoing
                  ? (item.amount_free + Math.abs(value.amount))
                  : (item.amount_free - value.amount)
            return item
          }),
        ]);
      }
    });
  };

  return (
    <Modal
      key={transaction?.id}
      title={t("finance.transactions.assignTransaction", { transaction_number: transaction.id })}
      open={isModalOpen}
      width={800}
      onCancel={() => {
        infoForm.resetFields();
        assignForm.resetFields();
        setAmountUsed(0);
        setModalOpen(false);
      }}
      okText={t("finance.transactions.assign")}
      onOk={() => {
        assignForm.submit();
      }}
    >
      <Form form={infoForm} layout={"vertical"}>
        <Row gutter={[16, 24]}>
          <Col span={8}>
            <Form.Item
              name={"transaction_id"}
              label={t("finance.transactions.transactionNumber")}
              initialValue={transaction.id}
            >
              <Input readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={"transaction_total_amount"}
              label={t("finance.transactions.amount")}
              initialValue={transaction.amount_free}
            >
              <InputNumber readOnly={true} bordered={false}></InputNumber>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name={"amount_used"}
              initialValue={0}
              label={t("finance.transactions.amountUsed")}
              rules={[rule]}
            >
              <Progress
                size="small"
                type="circle"
                percent={(amountUsed / transaction.amount_free) * 100}
                width={100}
                showInfo={true}
                format={() => `${amountUsed} Kč`}
                status={(amountUsed / transaction.amount_free) * 100 > 100 && "exception"}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Form
        form={assignForm}
        layout={"vertical"}
        onFinish={(values) => {
          infoForm.validateFields().then(() => {
            assignForm.validateFields().then(() => {
              setModalOpen(false);
              coreApi
                .post("/transactions/assign-to-invoice", { ...values, is_outgoing: isOutgoing })
                .then((res) => {
                  notification.success({
                    message: res.data.message,
                  });

                  assignForm.resetFields();
                  infoForm.resetFields();

                  handleAfterTransactionAssignment(values);
                })
                .catch(
                  (err) =>
                    err?.response?.status === 405 &&
                    notification.error({
                      message: err?.response?.data?.message,
                    })
                );
            });

            infoForm.resetFields();
          });
        }}
      >
        <Form.List name={"assignments"} initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <div>
              {fields.map((field) => (
                <Row key={field.key} gutter={[16, 24]}>
                  <Form.Item hidden name={[field.name, "transaction_id"]} initialValue={transaction.id}></Form.Item>
                  <Col span={8}>
                    <Form.Item
                      label={t("finance.transactions.assignToInvoice")}
                      name={[field.name, "invoice_number"]}
                      rules={[
                        {
                          type: "string",
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder={t("finance.invoices.invoiceNumber")}
                        showSearch
                        onSearch={(val) =>
                          val.length > 0 &&
                          coreApi
                            .get("invoices/invoice-by-number", {
                              params: {
                                invoice_number: val,
                              },
                            })
                            .then((res) => setInvoices([...res.data.invoices]))
                            .catch((err) => console.log(err))
                        }
                        onSelect={(val) => {
                          let invoice = invoices.find((item) => item.invoice_number === val);

                          const fields = assignForm.getFieldsValue();
                          const { assignments } = fields;
                          Object.assign(assignments[field.key], { invoice_total_amount: invoice.total_amount });
                          assignForm.setFieldsValue({ assignments });
                        }}
                      >
                        {invoices.map((invoice) => {
                          return (
                            <Select.Option key={invoice?.id} value={invoice?.invoice_number}>
                              {invoice?.invoice_number}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={t("finance.invoices.totalAmount")} name={[field.name, "invoice_total_amount"]}>
                      <InputNumber precision={2} readOnly={true} bordered={false}></InputNumber>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={t("finance.transactions.amount")}
                      name={[field.name, "amount"]}
                      initialValue={0}
                      rules={[
                        {
                          type: "number",
                          required: true,
                          min: isOutgoing ? transaction.amount_free : 0.01,
                          max: isOutgoing ? -1 : transaction.amount_free,
                        },
                      ]}
                    >
                      <InputNumber
                        precision={2}
                        controls={false}
                        onChange={() => {
                          recalculateTotalUsed();
                        }}
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label={" "}>
                      <Button disabled={fields.length <= 1} onClick={() => remove(field.name)}>
                        <MinusCircleOutlined />
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                disabled={amountUsed >= transaction.amount}
                onClick={() => {
                  assignForm.validateFields().then(() => {
                    add();
                  });
                }}
                style={{ width: "100%" }}
              >
                <PlusOutlined />
                {t("common.add")}
              </Button>
            </div>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default AssignTransactionModal;
