import { Col, Layout, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import LoginForm from "../components/LoginForm";
import mainLogo from "../images/mainLogo.png";

const Login = () => {
  return (
    <React.Fragment>
      <Layout style={{ minHeight: "100vh" }}>
        <Modal open={true} closable={false} footer={null}>
          <div className="logo">
            <img
              src={mainLogo}
              alt="Main Logo"
              style={{ maxWidth: "70px", display: "block", margin: "auto" }}
            ></img>
          </div>
          <Row gutter={[16, 8]}>
            <Col style={{ margin: "auto" }} span={20}>
              <LoginForm />
            </Col>
          </Row>
        </Modal>
      </Layout>
    </React.Fragment>
  );
};

export default Login;
