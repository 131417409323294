import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-slidedown/lib/slidedown.css";
import { RRule, rrulestr } from "rrule";
import { coreApi } from "../../../api/calls";
import throwError from "../../helpers/helpers";

/**
 * Mass create form for invocies during the month.
 *
 * @component
 * @alias MassInvoiceDuringMonthForm
 * @property {object} partner
 * @returns  <Button /> which opens a <Modal />
 */
const MassInvoiceDuringMonthForm = ({ partner }) => {
  // Ant design components
  const { Option } = Select;

  // Translations
  const { t } = useTranslation();

  // Array with days in week
  const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

  // States
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceForm] = Form.useForm();
  const [buildingUnits, setBuildingUnits] = useState([]);
  const [selectedBuildingUnits, setSelectedBuildingUnits] = useState([]);
  const [buildingUnitsList, setBuildingUnitsList] = useState([]);
  const [buildingUnitsSummary, setBuildingUnitsSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const searchTermMinLength = 3;

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length > searchTermMinLength) {
        coreApi
          .get("building-units/for-initial-invoices", { params: { term: searchTerm } })
          .then((response) => {
            setBuildingUnits(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getInitialBuildingUnitsList();
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    getInitialBuildingUnitsList();
  }, []);

  useEffect(() => {
    invoiceForm.resetFields(["summary"]);
  }, [buildingUnitsSummary]);

  useEffect(() => {
    invoiceForm.resetFields(["buildingUnits"]);
  }, [buildingUnitsList]);

  useEffect(() => {
    prepareBuildingUnits();
  }, [selectedBuildingUnits]);

  // Prepare building units for the form list
  const prepareBuildingUnits = () => {
    let units = [];

    // Filter selected builing units and prepare list with building units for next step of the form
    selectedBuildingUnits?.forEach((id) => {
      var buildingUnit = buildingUnits.find((buildingUnit) => buildingUnit.id === id);

      var building = buildingUnit ? buildingUnit : buildingUnitsList.find((item) => item.id === id);

      // Create necessary attributes
      building.cleanings_start_from = moment();
      building.priceThisMonth = building?.building?.active_order?.price || 0;

      units.push(building);
    });

    //Split invoice/order price between units in one building
    units.forEach(unit => {
      const unit_building_id = unit.building_id
      const buildingUnitsCount = units.filter(item => item.building_id === unit_building_id).length;

      unit.priceThisMonth = buildingUnitsCount > 0 ? (unit.priceThisMonth / buildingUnitsCount).toFixed(2) : unit.priceThisMonth;
    });

    setBuildingUnitsList(units);
  };

  /**
   * Prepare summary for displaying.
   *
   * @param {array} values
   */
  const showSummary = () => {
    let buildingUnits = invoiceForm.getFieldsValue("buildingUnits")?.buildingUnits;

    // Prepare cleanings for each building unit
    buildingUnits.forEach((buildingUnit) => {
      // Parse rrules
      const rule = buildingUnit?.cleaning_service_groups?.filter((cleaningServiceGroup) => {
        return cleaningServiceGroup?.rrule_blueprint?.includes("WEEKLY");
      })[0];
      let ruleParsed = rrulestr(rule.rrule_blueprint);
      const subtractDays = rule.name && rule.name.includes("14 DNÍ") ? "30" : "60";

      // Get potential rules for current month
      let potentialRule = new RRule({
        ...ruleParsed.origOptions,
        byweekday: buildingUnit?.cleaning_days,
        dtstart: moment(buildingUnit?.cleanings_start_from).subtract(subtractDays, "days").toDate(),
      });

      // Get new rules for current month
      let newRule = new RRule({
        ...ruleParsed.origOptions,
        byweekday: buildingUnit?.cleaning_days,
        dtstart: moment(buildingUnit?.cleanings_start_from).toDate(),
      });

      // Get all potential cleaning dates for current month
      let potentialCleaningsDates = potentialRule.between(
        moment(buildingUnit?.cleanings_start_from).startOf("month").toDate(),
        moment(buildingUnit?.cleanings_start_from).endOf("month").toDate(),
        true
      );

      // Get all new cleanings dates for new month
      let newCleaningsDates = newRule.between(
        moment(buildingUnit?.cleanings_start_from).toDate(),
        moment(buildingUnit?.cleanings_start_from).endOf("month").toDate(),
        true
      );

      // Attach cleanings that should be created
      buildingUnit.newCleanings = newCleaningsDates;

      // Set the price for the current month
      buildingUnit.priceThisMonth = (
        buildingUnit?.priceThisMonth *
        (newCleaningsDates.length / potentialCleaningsDates.length)
      ).toFixed(2);
    });

    setBuildingUnitsSummary(buildingUnits);
  };

  /**
   * Handle submit of the form.
   */
  const submitForm = () => {
    setLoading(true);

    coreApi
      .post("/cleanings/recurring/mass-during-month", {
        building_units: buildingUnitsSummary,
        partner_id: partner?.id,
      })
      .then((response) => {
        notification.success({
          message: "Faktury byly úspěšně vytvořené.",
        });

        setBuildingUnits([]);
        setBuildingUnitsList([]);
        setBuildingUnitsSummary([]);
        invoiceForm.resetFields();
        setIsModalVisible(false);
      })
      .catch((error) => {
        throwError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getInitialBuildingUnitsList = () => {
    coreApi
      .get("building-units/for-initial-invoices")
      .then((response) => {
        setBuildingUnits(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFieldsChange = (_, allFields) => {
    setTimeout(() => {
      const cleaningsStartFromFields = document.querySelectorAll(
        'input[id^="buildingUnits_"][id$="_cleanings_start_from"]'
      );
      const cleaningDaysFields = document.querySelectorAll(
        'input[id^="buildingUnits_"][id$="_cleaning_days"]'
      );

      const areFieldsValid = Array.from(cleaningsStartFromFields).every(
        (field) => field.value
        ) && Array.from(cleaningDaysFields).every((field) => {
        const selectionOverflow = field.closest('.ant-select-selection-overflow');
        if (selectionOverflow) {
          const children = selectionOverflow.querySelectorAll('.ant-select-selection-overflow-item');
          return Array.from(children).some(child =>
            !child.classList.contains('ant-select-selection-overflow-item-suffix')
          );
        }
        return false
      });

      setIsButtonDisabled(!areFieldsValid);
    }, 300);
  };

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)}>
        {t("finance.invoices.addMultipleInitialInvoice")}
      </Button>

      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width={1000}
        title={"Vytvořit faktury v průběhu mesíce"}
        footer={[
          <Button
            key="finish"
            onClick={submitForm}
            loading={loading}
            type="primary"
            disabled={buildingUnitsSummary.length <= 0 && true}
          >
            Potvrdit a vytvořit faktury v průběhu měsíce
          </Button>,
        ]}
      >
        {/* Render form */}
        <Form form={invoiceForm} initialValues={{ buildingUnits: buildingUnitsList, summary: buildingUnitsSummary }} onFieldsChange={handleFieldsChange}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder={t("buildings.selectUnits")}
                onChange={(e) => {
                  setSelectedBuildingUnits(e)
                  handleFieldsChange()
                }}
                onSearch={(e) => {
                  setSearchTerm(e);
                }}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {buildingUnits?.map((buildingUnit) => {
                  return (
                    <Option value={buildingUnit?.id} key={buildingUnit?.id}>
                      {buildingUnit?.street + " " + buildingUnit?.house_number + " " + buildingUnit?.city + "(" + buildingUnit?.building_id + ")"}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>

          {buildingUnitsList.length > 0 && <Divider />}

          <Row gutter={[8, 8]}>
            {buildingUnitsList.length > 0 && (
              <Col span={24}>
                <Row gutter={[12, 12]}>
                  <Col span={4}>
                    <strong>Název vchodu</strong>
                  </Col>

                  <Col span={4}>
                    <strong>Cena z objednávky</strong>
                  </Col>

                  <Col span={3}>
                    <strong>Frekvence</strong>
                  </Col>

                  <Col span={4}>
                    <strong>Začátek úklidu</strong>
                  </Col>

                  <Col span={9}>
                    <strong>Úklidové dny</strong>
                  </Col>
                </Row>
              </Col>
            )}

            <Col span={24}>
              <Form.List name="buildingUnits">
                {(fields) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row gutter={[12, 12]} key={key}>
                        <Col span={4}>
                          <Form.Item>
                            <Input
                              disabled={true}
                              bordered={false}
                              value={
                                invoiceForm?.getFieldsValue("buildingUnits")?.buildingUnits?.[key]?.street +
                                " " +
                                invoiceForm?.getFieldsValue("buildingUnits")?.buildingUnits?.[key]?.house_number
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, "priceThisMonth"]}
                            fieldKey={(restField.key, "priceThisMonth")}
                          >
                            <Input disabled={true} bordered={false} />
                          </Form.Item>
                        </Col>

                        <Col span={3}>
                          <Form.Item>
                            <Input
                              disabled={true}
                              bordered={false}
                              value={
                                invoiceForm
                                  ?.getFieldsValue("buildingUnits")
                                  ?.buildingUnits?.[key]?.cleaning_service_groups?.filter((cleaningServiceGroup) => {
                                    return cleaningServiceGroup?.rrule_blueprint?.includes("WEEKLY");
                                  })[0]?.name
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            {...restField}
                            name={[name, "cleanings_start_from"]}
                            fieldKey={(restField.key, "cleanings_start_from")}
                            required
                          >
                            <DatePicker />
                          </Form.Item>
                        </Col>

                        <Col span={9}>
                          <Form.Item
                            {...restField}
                            name={[name, "cleaning_days"]}
                            fieldKey={(restField.key, "cleaning_days")}
                          >
                            <Select
                              mode="multiple"
                              allowClear
                              style={{
                                width: "100%",
                              }}
                              placeholder="Úklidové dny"
                            >
                              {days.map((day) => {
                                return (
                                  <Select.Option key={day} value={day}>
                                    {t("rrules.byweekday." + day)}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Form.List>

              {buildingUnitsList.length > 0 && (
                <Col span={12} style={{ float: "right", marginBottom: "24px" }}>
                  <Button onClick={() => showSummary()} type={"primary"} disabled={isButtonDisabled}>
                    Zobrazit shrnutí a pokračovat
                  </Button>
                </Col>
              )}

              {buildingUnitsSummary.length > 0 && <Divider />}

              {buildingUnitsSummary.length > 0 && (
                <Form.List name="summary">
                  {(fields) => (
                    <Card title="Shrnutí vchodů">
                      {fields.map(({ key, name, ...restField }) => (
                        <Col span={24} key={key} style={{ marginBottom: "12px" }}>
                          <Card
                            type="inner"
                            size="small"
                            style={{ width: "100%" }}
                            title={
                              invoiceForm?.getFieldValue("summary")?.[key]?.street +
                              " " +
                              invoiceForm?.getFieldValue("summary")?.[key]?.house_number
                            }
                          >
                            {invoiceForm?.getFieldValue("summary")?.[key]?.newCleanings?.map((cleaning, i) => {
                              return (
                                <div key={i + key}>
                                  <p>
                                    {i + 1}. Úklid proběhne <strong>{moment(cleaning)?.format("DD.MM.YYYY")}</strong> za
                                    cenu{" "}
                                    <strong>
                                      {new Intl.NumberFormat("cs-CZ", {
                                        style: "currency",
                                        currency:
                                          invoiceForm?.getFieldValue("summary")?.[key]?.building?.active_order
                                            ?.currency_iso_4217 || "CZK",
                                      }).format(
                                        (
                                          invoiceForm?.getFieldValue("summary")?.[key]?.priceThisMonth /
                                          invoiceForm?.getFieldValue("summary")?.[key]?.newCleanings.length
                                        ).toFixed(2) || 0
                                      )}
                                    </strong>
                                  </p>
                                </div>
                              );
                            })}

                            <hr />

                            <p>
                              Celkem za tento měsíc{" "}
                              <strong>
                                {new Intl.NumberFormat("cs-CZ", {
                                  style: "currency",
                                  currency:
                                    invoiceForm?.getFieldValue("summary")?.[key]?.building?.active_order
                                      ?.currency_iso_4217 || "CZK",
                                }).format(
                                  (invoiceForm?.getFieldValue("summary")?.[key]?.building?.active_order
                                    ?.currency_iso_4217 === "CZK")
                                    ? Math.round(invoiceForm?.getFieldValue("summary")?.[key]?.priceThisMonth || 0)
                                    : (invoiceForm?.getFieldValue("summary")?.[key]?.priceThisMonth || 0)
                                )}
                              </strong>
                            </p>
                          </Card>
                        </Col>
                      ))}
                    </Card>
                  )}
                </Form.List>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default MassInvoiceDuringMonthForm;
