import {Calendar, Modal, notification, Radio, Select, Spin} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import CleaningDetailPopup from "../Cleanings/CleaningDetailPopup";
import CleaningListSimple from "../Cleanings/CleaningListSimple";
import DailyAgendaPane from "../Cleanings/DailyAgendaPane";
import PartnerCustomCalendar from "./PartnerCustomCalendar";
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/cs';

dayjs.extend(localeData);

const PartnerCalendarPane = ({ partner }) => {
  const { t } = useTranslation();

  const [recurringCleanings, setRecurringCleanings] = useState([]);
  const [oneTimeCleanings, setOneTimeCleanings] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isCleaningDetailModalOpen, setCleaningDetailModalOpen] = useState(false);
  const [isDailyAgendaOpen, setDailyAgendaOpen] = useState(false);

  //! unused state - temporarly replaced with const
  // const [selectedCleaning, setSelectedCleaning] = useState({
  //   price: 0.0,
  //   type: "",
  //   building: "",
  // });

  const selectedCleaning = {
    price: 0.0,
    type: "",
    building: "",
  };

  const [dayDetailEvents, setDayDetailEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [clickedDate, setClickedDate] = useState();
  const [mode, setMode] = useState('week');

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month());
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [weeksInMonth, setWeeksInMonth] = useState([]);

  useEffect(() => {
    const startOfMonth = selectedDate.clone().startOf('month');
    const endOfMonth = selectedDate.clone().endOf('month');
    const today = dayjs();

    const weeks = [];
    let currentWeekStart = startOfMonth.clone().startOf('isoWeek');
    let currentWeekIndex = 0;

    while (currentWeekStart.isBefore(endOfMonth)) {
      weeks.push(currentWeekStart.clone());

      if (today.isSame(currentWeekStart, 'week')) {
        setSelectedWeek(currentWeekIndex);
      }

      currentWeekStart.add(1, 'week');
      currentWeekIndex++;
    }

    setWeeksInMonth(weeks);
  }, [selectedDate]);

  const onModeChange = (e) => {
    setMode(e.target.value);
    if (e.target.value !== 'week') openUsualCalendar();
  };

  const handleCalendarChange = useCallback(
    (date) => {
      if (!partner?.id) return false;
      let params = {
        partner_id: partner?.id,
        date_from: date.clone().startOf("month").format("YYYY-MM-DD"),
        date_to: date.clone().endOf("month").format("YYYY-MM-DD"),
        page_size: 200,
      };

      setLoading(true);

      axios
        .all([
          coreApi.get("/cleanings/recurring", {
            params: params,
          }),
          coreApi.get("/cleanings/one-time", {
            params: params,
          }),
        ])
        .then(
          axios.spread((recurringCleaningsResponse, oneTimeCleaningsResponse) => {
            setRecurringCleanings(recurringCleaningsResponse.data);
            setOneTimeCleanings(oneTimeCleaningsResponse.data);
          })
        )
        .catch((error) => {
          notification.error({ message: error?.response?.data?.message });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [partner]
  );

  useEffect(() => {
    handleCalendarChange(selectedDate);
  }, [handleCalendarChange, selectedDate]);

  const openUsualCalendar = () => {
    setTimeout(function () {
      const calendarModeSwitch = document.querySelector('.ant-radio-group.ant-radio-group-outline.ant-picker-calendar-mode-switch');
      if (calendarModeSwitch && !document.querySelector('.custom-week-button')) {
        const newButton = document.createElement('label');
        newButton.className = 'ant-radio-button-wrapper custom-week-button';
        newButton.innerHTML = `
          <span class="ant-radio-button">
            <input class="ant-radio-button-input" type="radio" value="week">
            <span class="ant-radio-button-inner"></span>
          </span><span>Týden</span>`;

        newButton.addEventListener('click', () => {
          setMode('week');
        });

        const firstChild = calendarModeSwitch.firstChild;
        calendarModeSwitch.insertBefore(newButton, firstChild);
      }
    });
  }

  useEffect(() => {
    const currentYear = dayjs().year();
    const endYear = currentYear + 10;
    const startYear = currentYear - 10;
    const locale = t.language || 'cs';
    dayjs.locale(locale);

    setYears(Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i));
    setMonths(dayjs.localeData().monthsShort());
  }, []);

  const handleYearChange = (value) => {
    setSelectedYear(value);
    setSelectedDate((prevDate) => prevDate.clone().year(value));
    setSelectedWeek(0);
  };

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
    setSelectedDate((prevDate) => prevDate.clone().month(value));
    setSelectedWeek(0);
  };

  const handleWeekChange = (value) => {
    setSelectedWeek(parseInt(value, 10));
  };

  const dateCellRender = (value) => {
    let cleanings = [...recurringCleanings, ...oneTimeCleanings].filter((o) => o.date_expected === value.format("YYYY-MM-DD"));

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
        onClick={() => {
          setDailyAgendaOpen(true);
        }}
      >
        <DailyAgendaPane cleanings={cleanings} />
      </div>
    );
  };

  return (
    <React.Fragment>
      <CleaningDetailPopup
        isOpen={isCleaningDetailModalOpen}
        cleaningDate={selectedCleaning.date_expected ? moment(selectedCleaning.date_expected).format("LL") : ""}
        cleaningPrice={selectedCleaning.price}
        cleaningType={selectedCleaning.type}
        buildingStreet={selectedCleaning.building}
        onCancel={() => {
          setCleaningDetailModalOpen(false);
        }}
      ></CleaningDetailPopup>
      <Modal
        onCancel={() => {
          setDailyAgendaOpen(false);
        }}
        open={isDailyAgendaOpen}
        title={clickedDate ? clickedDate.format("LL") : ""}
        cancelText={t("common.close")}
      >
        <CleaningListSimple cleanings={dayDetailEvents} />
      </Modal>
      <Spin spinning={loading}>
        {mode === 'week' && (
          <>
        <Radio.Group value={mode} onChange={onModeChange} style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '20px',
          marginTop: '12px'
        }}>
          <Select style={{ width: "80px", marginRight: "10px" }} value={selectedYear} onChange={handleYearChange}>
            {years.map((year) => (
              <Select.Option key={year} value={year}>
                {year}
              </Select.Option>
            ))}
          </Select>
          <Select style={{ width: "70px", marginRight: "10px" }} value={selectedMonth} onChange={handleMonthChange}>
            {months.map((month, index) => (
              <Select.Option key={index} value={index}>
                {month}
              </Select.Option>
            ))}
          </Select>
          <Select
            id="week-select"
            value={selectedWeek}
            onChange={handleWeekChange}
            style={{ width: "200px", marginRight: "10px" }}
          >
            {weeksInMonth.map((week, index) => (
              <option key={index} value={index}>
                {week.format('D.MM.YYYY')} -{' '}
                {week.clone().endOf('isoWeek').format('D.MM.YYYY')}
              </option>
            ))}
          </Select>
          <Radio.Button value="week">Týden</Radio.Button>
          <Radio.Button value="month">Měsíc</Radio.Button>
          <Radio.Button value="year">Rok</Radio.Button>
        </Radio.Group>
        </>
        )}
        {mode === 'week' && (
          <PartnerCustomCalendar
            partner={partner}
            cleanings={[...recurringCleanings, ...oneTimeCleanings]}
            date={weeksInMonth[selectedWeek] || selectedDate}
          />
        )}
        {mode !== 'week' && (
        <Calendar
          onSelect={(moment) => {
            let cleaningsMerged = [...recurringCleanings, ...oneTimeCleanings];
            let cleanings = cleaningsMerged.filter((o) => o.date_expected === moment.format("YYYY-MM-DD"));
            setDayDetailEvents([...cleanings]);
            setClickedDate(moment);
          }}
           onPanelChange={(moment) => {
             setSelectedDate(moment);
             setSelectedYear(moment.year());
             setSelectedMonth(moment.month());
          }}
          dateCellRender={dateCellRender}
          ></Calendar>
        )}
      </Spin>
    </React.Fragment>
  );
};

export default PartnerCalendarPane;
