import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { initAreaScope, setAreaScope } from "../../actions";
import { coreApi } from "../../api/calls";

/**
 * Global City Selector - uses Redux - on mount fetches all cities
 * @component
 * @alias CityGlobalSelector
 * @property {object} state - redux state
 * @returns <Select />
 */
export const CityGlobalSelector = ({
  state: {
    areaScope: { cities },
  },
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [_cities, _setCities] = useState([]);

  const initReduxCities = (cities) => dispatch(initAreaScope(cities));

  useEffect(() => {
    setLoading(true);
    coreApi
      .get("/cities/list")
      .then((res) => {
        initReduxCities(res.data);
        _setCities(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    _setCities(cities);
  }, [cities]);

  return (
    <Select
      showSearch
      optionFilterProp="children"
      loading={loading}
      onChange={(value) => {
        dispatch(setAreaScope(value));
      }}
      defaultValue={null}
      style={{ width: "230px" }}
    >
      <Select.Option value={null}>{t("expansion.allCities")}</Select.Option>
      {_cities.map((country) => {
        return (
          <Select.OptGroup key={country?.id} label={country?.name}>
            {country?.city_branches.map((city) => {
              return (
                <Select.Option key={city?.id} value={city?.id}>
                  {city?.name}
                </Select.Option>
              );
            })}
          </Select.OptGroup>
        );
      })}
      ;
    </Select>
  );
};

// function for mapping state to Redux state
const mapStateToProps = (state) => {
  return {
    state: state,
  };
};
// connect is used to connect states (React and Redux) via mapping function
export default connect(mapStateToProps)(CityGlobalSelector);
