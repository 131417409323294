import React from "react";
import ClaimsPane from "../components/Claims/ClaimsPane";

/**
 * Page with all claims
 * @component
 * @alias ClaimsIndex
 * @returns <ClaimsPane />
 */
const ClaimsIndex = () => {
  return (
    <React.Fragment>
      <ClaimsPane />
    </React.Fragment>
  );
};

export default ClaimsIndex;
