import { notification, Tabs, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { OfferContext } from "../../pages/OfferProfile";
import { coreApi } from "./../../api/calls";
import OfferItemListContainer from "./OfferItemListContainer";
import OfferCopyModal from "./OfferCopyModal";

const { TabPane } = Tabs;

const OfferItemList = () => {
  const { offer, isOrder, refreshOffer } = useContext(OfferContext);

  const [buildingUnits, setBuildingUnits] = useState([]);
  const [separatedData, setSeparatedData] = useState([]);

  const [isCopyFormOpen, setCopyFormOpen] = useState(false);
  const [currentBuildingUnitId, setCurrentBuildingUnitId] = useState();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      coreApi
        .get("/buildings/" + offer?.building_id)
        .then((res) => {
          setCurrentBuildingUnitId(res.data.building_units[0].id);
          setBuildingUnits(res.data?.building_units);
        })
        .catch((err) => notification.error({ message: err?.response?.data?.message }));
    }

    return () => {
      isMounted = false;
    };
  }, [offer]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      let data = [];
      let groups = [];

      buildingUnits?.forEach((unit) => {
        offer?.cleaning_service_groups?.forEach((group) => {
          if (unit?.id === group?.building_unit_id) {
            groups.push(group);
          }
        });
        data.push({ building_unit: unit, cleaning_service_groups: [...groups] });
        groups = [];
      });

      setSeparatedData(data);
    }

    return () => {
      isMounted = false;
    };
  }, [buildingUnits, offer?.cleaning_service_groups]);

  return (
    <React.Fragment>
      <Tabs
        onChange={(value) => {
          setCurrentBuildingUnitId(value);
        }}
        size={"medium"}
        tabBarExtraContent={
          !isOrder && (
            <Button style={{ border: "0px" }} icon={<CopyOutlined />} onClick={() => setCopyFormOpen(true)}></Button>
          )
        }
        defaultActiveKey={currentBuildingUnitId}
      >
        {buildingUnits.map((buildingUnit) => {
          return (
            <TabPane key={buildingUnit.id} tab={buildingUnit.street + " " + buildingUnit.house_number}>
              <OfferItemListContainer
                separatedData={separatedData.filter((item) => item.building_unit.id === buildingUnit.id)[0]}
              />
            </TabPane>
          );
        })}
      </Tabs>

      {buildingUnits.length > 1 && !isOrder && (
        <OfferCopyModal
          key={"offerCopyModal"}
          refreshOffer={refreshOffer}
          idOffer={offer?.id}
          currentBuildingUnitId={currentBuildingUnitId}
          isOpen={isCopyFormOpen}
          setOpen={setCopyFormOpen}
          buildingUnits={buildingUnits.map((buildingUnit) => {
            return {
              building_unit_id: buildingUnit.id,
              street: buildingUnit.street,
              house_number: buildingUnit.house_number,
            };
          })}
        ></OfferCopyModal>
      )}
    </React.Fragment>
  );
};

export default OfferItemList;
