import { Tabs } from "antd";
import React from "react";
import useTabChange from "../../hooks/useTabChange";

/**
 * Ant Design tabs with active key as a hash in the URL.
 *
 * @param defaultActiveKey
 * @param props
 * @param onChange
 * @returns {Element}
 * @constructor
 */
const CustomTabs = ({ defaultActiveKey = "#overview", onChange = null, ...props }) => {
  const [activeTab, setActiveTab] = useTabChange(props.props, defaultActiveKey);

  return (
    <Tabs size={"small"} activeKey={activeTab} onChange={(e) => {
      setActiveTab(e);
      if (onChange) onChange(e);
    }} {...props}>
      {props.children}
    </Tabs>
  );
};

export default CustomTabs;
