import { Button, Col, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

/**
 * Money Return Pane
 * @component
 * @alias MoneyReturnPane
 * @property {number} buildingId - id of a building unit
 * @property {number} total - total sum of invoice and transaction(s)
 * @return
 */
const MoneyReturnPane = ({ idClient, buildingId, invoice, total, client }) => {
  const [building, setBuilding] = useState({});

  const { t } = useTranslation();

  const [XML, setXML] = useState(null);
  const xmlRef = useRef();

  useEffect(() => {
    total < 0 &&
      coreApi.get("/buildings/" + buildingId).then((response) => {
        setBuilding(response.data);
      });
  }, [buildingId, idClient, total]);

  let firstBuildingUnit = building?.building_units?.find((unit, index) => index === 0);
  let address = invoice?.country_branch_id === 2 ? building?.city + " " + firstBuildingUnit?.street : building?.registration_number;

  const showModal = () => {
    let payload = {
      message:
        moment(invoice?.date_issued).add(1, 'days').format("YYYY") + "/" + moment(invoice?.date_issued).add(1, 'days').format("MM") +
        " " +
        address + `-${t("finance.moneyReturn.overpayment")}`,
      account_id_from: client?.city_branch?.account_id || invoice?.city_branch?.account_id,
      date: moment(new Date(moment()?.format("M") + "-16-" + moment()?.format("YYYY")))?.format("YYYY-MM-DD"),
      client_id_to: client?.id,
      country_branch_id_to: client?.city_branch?.country_branch_id || invoice?.city_branch?.country_branch_id,
      variable_symbol: invoice?.corrective_document?.invoice_number || invoice?.invoice_number,
      amount: total,
      invoice_id: invoice?.corrective_document?.id || invoice?.id,
    };

    coreApi
      .post("payments/generate-payment", {
        transactions: [{ ...payload }],
      })
      .then((res) => {
        setXML(res.data.xml);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsModalVisible(true);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCopy = () => {
    if (xmlRef.current) {
      navigator.clipboard.writeText(xmlRef.current.innerText);
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <React.Fragment>
      {/* check if is overpaid or underpaid, if total < 0, that means invoice is overpaid */}
      {total < 0 ? (
        <Button type="primary" size="small" onClick={showModal}>
          {t("finance.moneyReturn.XMLcode")}
        </Button>
      ) : (
        <Button type="primary" size="small" disabled="true">
          {t("finance.moneyReturn.XMLcode")}
        </Button>
      )}
      <Modal
        title={t("buildings.moneyReturn")}
        open={isModalVisible}
        onOk={handleCopy}
        okText={t("common.copy")}
        onCancel={handleCancel}
        width={600}
      >
        {XML && (
          <>
            <Col span={24}>
              <pre style={{ fontStyle: "italic", marginBottom: "0 !important" }} ref={xmlRef}>
                {XML?.split("<Orders>")[1]?.split("</Orders>")[0]}
              </pre>
            </Col>
          </>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default MoneyReturnPane;
