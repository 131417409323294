import { Button, Modal, notification, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { coreApi } from "../api/calls";

const RecordActionsBar = ({
  redirectState,
  setRedirect,
  recordId,
  entityRoute,
  firstModalTitle,
  firstModalContent,
  redirectRoute = entityRoute,
}) => {
  const { t } = useTranslation();
  return (
    <Space style={{ float: "right" }}>
      <Button
        danger
        onClick={() => {
          Modal.confirm({
            title: firstModalTitle,
            content: firstModalContent,
            okText: t("common.delete"),
            okButtonProps: { type: "danger" },
            onOk: () => {
              Modal.confirm({
                title: t("common.lastWarning"),
                okText: t("common.delete"),
                okButtonProps: { type: "danger" },
                onOk: () => {
                  coreApi
                    .delete(entityRoute + "/" + recordId)
                    .then((response) => {
                      setRedirect();
                      notification.success({
                        message: t("common.recordDeleted"),
                      });
                    })
                    .catch((error) => console.log(error));
                },
              });
            },
          });
        }}
      >
        {t("common.delete")}
      </Button>
      {redirectState ? <Redirect to={redirectRoute}></Redirect> : ""}
    </Space>
  );
};

export default RecordActionsBar;
