import { Card, Col, Row } from "antd";
import React from "react";
import UnassignedTransactionsPane from "../components/Transactions/UnassignedTransactionsPane";

const UnassignedTransactionsIndex = () => {
  return (
    <React.Fragment>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Card>
            <UnassignedTransactionsPane />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UnassignedTransactionsIndex;
