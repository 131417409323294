import React, { useContext, useState } from "react";
import { Statistic, Card, Button, Col, Row, Form, Input, notification } from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import { OfferContext } from "../../pages/OfferProfile";

const OfferSummary = ({ isOrder, isLoading }) => {
  const { t } = useTranslation();
  const { offer, setOffer } = useContext(OfferContext);
  const [isEditingPrice, setEditingPrice] = useState(false);
  const [priceForm] = Form.useForm();

  return (
    <Card loading={isLoading} title={t("offers.summary")}>
      <Statistic
        title={t("offers.summaryDetails.subtotal")}
        value={new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: offer?.currency_iso_4217 || "CZK",
        }).format(offer?.price)}
      />
      <hr />
      <Row gutter={[8, 16]}>
        <Col span={18}>
          {isEditingPrice ? (
            <Form
              form={priceForm}
              onKeyPress={(e) => (e.key === "Enter" ? e.preventDefault() : null)}
              onFinish={(values) => {
                coreApi
                  .put("/offers/" + offer?.id, { ...offer, ...values })
                  .then((res) => {
                    notification.success({ message: res.data.message });
                    setOffer((prevOffer) => ({ ...prevOffer, price: res.data.offer.price }));
                    setEditingPrice(false);
                  })
                  .catch((err) => {
                    notification.error({ message: err.response.data.message });
                  });
              }}
            >
              <Form.Item name={"price"} initialValue={offer?.price}>
                <Input />
              </Form.Item>
            </Form>
          ) : (
            <Statistic
              title={t("offers.summaryDetails.total")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: offer?.currency_iso_4217 || "CZK",
              }).format(offer?.price)}
            />
          )}
        </Col>
        {!isOrder && (
          <Col span={6}>
            {isEditingPrice ? (
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <Button
                    icon={<CheckOutlined />}
                    onClick={() => {
                      priceForm.submit();
                    }}
                  />
                </Col>
                <Col span={12}>
                  <Button
                    icon={<CloseOutlined />}
                    onClick={() => setEditingPrice(false)}
                  />
                </Col>
              </Row>
            ) : (
              <Col span={24}>
                <Button
                  icon={<EditOutlined />}
                  style={{ border: "0px" }}
                  onClick={() => setEditingPrice(true)}
                />
              </Col>
            )}
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default OfferSummary;
