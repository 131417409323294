import { Card, Form, Select, Switch } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

const SystemSettingsForm = () => {
  const { t, i18n } = useTranslation();

  const langChange = (language) => {
    i18n.changeLanguage(language);
    moment.locale(language);
  };

  return (
    <React.Fragment>
      <Card title={t("common.systemSettings")}>
        <Form>
          <Form.Item label={t("systemSettings.theme")}>
            <Switch
              value="dark"
              checkedChildren={t("systemSettings.lightTheme")}
              unCheckedChildren={t("systemSettings.darkTheme")}
            ></Switch>
          </Form.Item>
          <Form.Item label={t("systemSettings.language")}>
            <Select onChange={langChange} value={i18n.language}>
              <Select.Option value="cs">Čeština</Select.Option>
              <Select.Option value="en">English</Select.Option>
              <Select.Option value="sk" disabled>
                Slovenčina
              </Select.Option>
              <Select.Option value="de" disabled>
                Deutsch
              </Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default SystemSettingsForm;
